import React, { useState , useEffect } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBTypography,
} from "mdb-react-ui-kit";

//Const
import Sample from '../../../Assets/Images/sample.png';
import Loading from '../../../Assets/Images/loading-gif.gif';

// Components
import YAddToBasketHeader from '../../../Components/Product/YAddToBasketHeader';
import YText from "../../../Components/Basic/YText";
import YSeperator from "../../../Components/Basic/YSeperator";
import YImage from "../../../Components/Basic/YImage";
import "../../../Assets/Utils/Header"

export default function Basket(props) {

    const { data , i , controllIndex, length , onDeleteItem , onEvent} = props;
 
    const MAX_LENGTH = 40;

    let result = data.count.replace(",", ".");

    useEffect(() => {
        setCount(Number(result));
    }, [data])

    const [count, setCount] = useState(Number(result));

    const upDateBasket = (newCount) => {
        //setCount(newCount);
        onEvent(data , newCount , 1);
    };

    const [showLoader, setShowLoader] = useState(false);

    const [itemNumber, setItemNumber] = useState(0);
  
    const onDeletingItem = (id) => {
      setShowLoader(true);
      setItemNumber(id);
      onDeleteItem(id)
    };

    return (
        <MDBCard className="mb-3 basket-card">
            <MDBCardBody>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                        <div className="basket-items-img-container">
                        {data.image !== '' ?
                            <div 
                                className="basket-items-img" 
                                style={{backgroundImage: `url(${data.image})`}}
                            />
                        :
                            <div 
                                className="basket-items-img" 
                                style={{backgroundImage: `url(${Sample})`}}
                            />
                        }
                        </div>
                        <div className="basket-items-mobile">
                            <MDBTypography tag="h5" className="basket-item-title-text">
                                {data.title.length > MAX_LENGTH ?
                                (
                                    data.title.substring(0, MAX_LENGTH)+"..."
                                ) :
                                    data.title
                                }
                            </MDBTypography>
                            <div className="basket-item-control-icon-container">
                                {/*<a href="#!" className="basket-items-contorls-icon">
                                    <MDBIcon fas icon="edit" onClick={() => onDeleteItem(data.id)} /> Ürün Notu Ekle
                                </a>*/}
                                {itemNumber === data.id && showLoader === true ?
                                    <div className='cart-delete-container'>
                                        <YImage imageSource={Loading} inComingStyle={'card-delete'} />
                                    </div>
                                :
                                    <a href="#!" className="basket-items-contorls-icon-delete" onClick={() => onDeletingItem(data.id)}>
                                        <MDBIcon fas icon="trash-alt"  /> Sil
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div>
                            <div className="d-flex justify-content-between basket-item-price-text-container align-content-between">
                                {data.discountPrice !== 0 ?
                                    <div className='priceAreaBallance basket-price-container-new'>
                                       {/* <span className="outer">
                                            <span className="inner">{data.discountPrice + " TL"}</span>
                                        </span>*/}
                            
                                    <YText
                                        incomingStyle={"newPrice-basket"}
                                        value={Number(data.price).toFixed(2)+" TL"}
                                        type={"h4"}
                                    />
                                    </div>
                                :
                                    <div className='priceAreaBallance basket-price-container-new'>
                                        <YText
                                            incomingStyle={"newPrice"}
                                            value={Number(data.price).toFixed(2)+" TL"}
                                            type={"h4"}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="d-flex justify-content-between">
                                <YAddToBasketHeader unit={data.unit} count={count} upDateBasket={upDateBasket} incomingStyle={'basket-add-to-card-container-basket-item'} />
                            </div>
                        </div>
                    </div>
                </div>
            </MDBCardBody>
            {length !== i +1 ? <div className="basket-seperator-margin"><YSeperator /></div> : null }
        </MDBCard>
    );
};
