import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBTextArea,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';
import YImage from '../../Components/Basic/YImage';
import YLoading from '../../Components/Basic/YLoading';

import OrderList from './Components/OrderList';
import SingleOrder from './Components/SingleOrder';
import Refund from './Components/Refund';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import {
  getAddress,
  deleteAddress,
  getUserOrder,
  deleteORder,
  refundOrderEndPonit,
} from '../../Redux/Actions/UserAction';

// Styles
import './styles/LastOrders.css';

// Utils
import UserMenu from '../../Assets/Utils/UserMenu';

export default function LastOrders() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const userOrders = useSelector(state => state.user.userOrder);

  const [showContent, setShowContent] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [orders, setOrders] = useState([]);

  const [refundOneByOne, setRefundOneByOne] = useState(false);

  const [productsPerPage] = useState(5);

  const [showSingle, setShowSingle] = useState(false);

  const indexOfLastItem = currentPage * productsPerPage;

  const indexOfFirstItem = indexOfLastItem - productsPerPage;

  const currentItems = userOrders.slice(indexOfFirstItem, indexOfLastItem);

  const [reason, setReason] = React.useState('');

  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [refundModalVisible, setRefundModalVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cancelORderModal, setCancelOrderModal] = useState(false);

  const [itemData, setItemData] = useState([]);

  const [tempOrderId, setTempOrderId] = useState(0);

  const [selectedOrderRefund, setSelectedOrderRefund] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const menu = useSelector(state => state.menu.menu);

  const [orderData, setOrderData] = useState([]);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  const [count, setCount] = React.useState(0);

  const [selectedItemRefund, setSelectedItemRefund] = React.useState();

  const [visible, setVisable] = React.useState(false);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = 'Ozhan Market ';
    dispatch(getUserOrder(setShowLoading));
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    setOrders(userOrders);
  }, [userOrders]);

  useEffect(() => {
    let tmp = orders;
    tmp?.map(item => {
      let flag = true;
      item.products?.map(items => {
        if (items.isRefundable === false) {
          flag = false;
          return false;
        }
      });
      item.flag = flag;
    });
    setOrders(tmp);
  }, [orders]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const onApprove = () => {
    setShowLoading(true);
    dispatch(deleteORder(tempOrderId, setShowLoading, toast));
    setCancelOrderModal(false);
  };

  const onClickOrder = (id, data) => {
    setItemData(data);
    setShowSingle(true);
    setRefundOneByOne(false);
  };

  const onCloseOrder = () => {
    setShowSingle(false);
  };

  const onRefundOrder = item => {
    setSelectedOrderRefund(item);
    onChangeRefundModal();
  };

  const onChangeRefundModal = () => {
    setRefundModalVisiable(!refundModalVisible);
  };

  const cancelAllOrders = () => {
    if (reason.length > 3) {
      let data = {};
      data.returnReason = reason;
      data.orderId = selectedOrderRefund.orderId;
      let tmp = [];
      for (let i = 0; i < selectedOrderRefund.products.length; i++) {
        tmp.push({
          cartProductId: selectedOrderRefund.products[i].cartProductId,
          quantityToReturn: selectedOrderRefund.products[i].quantity,
          returnReason: reason,
        });
      }
      data.cartProductsToReturn = tmp;
      setShowLoading(true);
      dispatch(
        refundOrderEndPonit(
          tmp,
          selectedOrderRefund.orderId,
          reason,
          setShowLoading
        )
      );
      toast.success('Geri ödeme talebiniz şu anda inceleniyor! 👋', {
        toastId: 'success',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      dispatch(getUserOrder(setShowLoading));
      setShowSingle(false);
      setRefundModalVisiable(!refundModalVisible);
    } else {
      alert('Lütfen iade sebebinizi giriniz! 👋');
    }
  };

  const onSelectRefundOneOrder = item => {
    setSelectedItemRefund(item);
    setCount(item.quantity);
    onModalViewChange();
  };

  const onModalViewChange = () => {
    setVisable(!visible);
  };

  const add = () => {
    if (count === selectedItemRefund.quantity) {
      alert('Miktarınızdan fazlasını seçemezsiniz');
    } else {
      setCount(count + 1);
    }
  };

  const remove = () => {
    if (count !== 1) {
      setCount(count - 1);
    }
  };

  const onRefundOneItem = () => {
    if (reason.target.value.length > 3) {
      let tmp = orderData;
      if (tmp.length === 0) {
        tmp.push({
          cartProductId: selectedItemRefund.cartProductId,
          quantityToReturn: count,
          returnReason: reason.target.value,
        });
        // first time adding
      } else {
        tmp?.map(items => {
          if (items.cartProductId === selectedItemRefund.cartProductId) {
            items.returnReason = reason.target.value;
            items.quantityToReturn = count;
          } else {
            tmp.push({
              cartProductId: selectedItemRefund.cartProductId,
              quantityToReturn: count,
              returnReason: reason.target.value,
            });
          }
        });
      }
      setReason('');
      setOrderData(tmp);
      onModalViewChange();
    } else {
      alert('Lütfen iade sebebinizi giriniz! 👋');
    }
  };

  const finishRefunding = () => {
    setShowLoading(true);
    if (orderData.length > 0) {
      dispatch(
        refundOrderEndPonit(
          orderData,
          selectedOrderRefund.orderId,
          'some reason',
          setShowLoading
        )
      );
      toast.success('Geri ödeme talebiniz şu anda inceleniyor! 👋', {
        toastId: 'success',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      dispatch(getUserOrder(setShowLoading));
      setShowSingle(false);
    } else {
      alert('Lütfen geri ödeme yapılacak ürün veya daha fazlasını ekleyin! 👋');
    }
  };

  const removeFromRefund = e => {
    let havij = [];
    let count = 0;
    for (let i = 0; i < orderData.length; i++) {
      if (orderData[i].cartProductId !== e.cartProductId) {
        havij[count] = {
          cartProductId: orderData[i].cartProductId,
          quantityToReturn: orderData[i].quantityToReturn,
          returnReason: orderData[i].returnReason,
        };
        count = count + 1;
      }
    }
    setOrderData(havij);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="user-order-history-container">
              <MDBCardBody className="p-4 ">
                <MDBRow>
                  <MDBCol lg="3">
                    <UserMenu userAddress={'user-order'} />
                  </MDBCol>
                  <MDBCol lg="9">
                    {showSingle === false ? (
                      <>
                        {orders.length !== 0 ? (
                          <OrderList
                            setCancelOrderModal={setCancelOrderModal}
                            setTempOrderId={setTempOrderId}
                            currentItems={currentItems}
                            currentPage={currentPage}
                            productsPerPage={productsPerPage}
                            userOrders={orders}
                            handlePageChange={handlePageChange}
                            onClickOrder={onClickOrder}
                            onRefundOrder={onRefundOrder}
                            removeFromRefund={removeFromRefund}
                          />
                        ) : (
                          <MDBCard className="rounded-3 user-order-history-container">
                            <MDBCardBody>
                              <MDBRow>
                                <MDBCol>
                                  <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                                    <MDBTypography className="mb-0 user-order-history-headers">
                                      Siparişlerim
                                    </MDBTypography>
                                  </div>
                                  <p className="no-order-text">
                                    Henüz bir siparişiniz bulunmamaktadır.
                                  </p>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                        )}
                      </>
                    ) : refundOneByOne === true ? (
                      <Refund
                        onCloseOrder={onCloseOrder}
                        itemData={selectedOrderRefund}
                        orderData={orderData}
                        setOrderData={setOrderData}
                        onSelectRefundOneOrder={onSelectRefundOneOrder}
                        finishRefunding={finishRefunding}
                        removeFromRefund={removeFromRefund}
                      />
                    ) : (
                      <SingleOrder
                        onCloseOrder={onCloseOrder}
                        itemData={itemData}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
      <YModal
        show={refundModalVisible}
        onClose={() => setRefundModalVisiable(!refundModalVisible)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        {showContent === false ? (
          <div className="address-alert-contorller-container-col">
            {selectedOrderRefund.flag === true ? (
              <div
                className="address-alert-accpet-container refund-btn"
                onClick={() => setShowContent(true)}
              >
                <p className="address-alert-text">
                  Bütün ürünleri iade edeceğim
                </p>
              </div>
            ) : null}
            <div
              className="address-alert-cancel-container refund-btn-second"
              onClick={() => {
                setShowSingle(true);
                setRefundOneByOne(true);
                setRefundModalVisiable(false);
              }}
            >
              <p className="address-alert-text">
                Bir veya daha fazla ürünü iade ediyorum
              </p>
            </div>
          </div>
        ) : (
          <div>
            <p>Siparişinizin tamamını iade etmek istiyor musunuz?</p>
            <MDBTextArea
              className={'mb-4'}
              placeholder="İade nedeninizi yazın"
              id="addressText"
              rows={3}
              size="lg"
              onChange={e => setReason(e.target.value)}
            />
            <div
              className="address-alert-accpet-container refund-btn-full"
              onClick={() => cancelAllOrders()}
            >
              <p className="address-alert-text">Tüm ürünleri iade edeceğim</p>
            </div>
          </div>
        )}
      </YModal>
      <YModal
        show={cancelORderModal}
        onClose={() => setCancelOrderModal(!cancelORderModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <div>
          <div className="cancel-order-image-error-container">
            <YImage
              imageSource={require('../../Assets/Images/modal-error.png')}
              inComingStyle={'cancel-order-image-error'}
            />
          </div>
          <div className="address-title-alert-container">
            <p>Siparişi iptal etmek istediğinize emin misiniz?</p>
          </div>
          <div className="address-alert-contorller-container">
            <div
              className="address-alert-accpet-container"
              onClick={() => onApprove()}
            >
              <p className="address-alert-text">Evet</p>
            </div>
            <div
              className="address-alert-cancel-container"
              onClick={() => setCancelOrderModal(false)}
            >
              <p className="address-alert-text">Hayır</p>
            </div>
          </div>
        </div>
      </YModal>
      <YModal
        show={visible}
        onClose={() => onModalViewChange(!visible)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <div>
          <div className="cancel-order-image-error-container">
            <p>{selectedItemRefund?.name}</p>
          </div>
          {selectedItemRefund?.isScalable === true ? (
            <div className="poshet-container">
              <div className="poshet-box-container-holder">
                <div className="poshet-box-container">
                  <div className="poshet-corner-box" onClick={() => remove()}>
                    <MDBIcon fas icon="minus" />
                  </div>
                  <div className="posht-middle-box">{count} Adet</div>
                  <div className="poshet-corner-box" onClick={() => add()}>
                    <MDBIcon fas icon="plus" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="poshet-count-product-text-fixed">
              {count} {selectedItemRefund?.unit}
            </p>
          )}
          <div className="address-title-alert-container">
            <MDBTextArea
              className={'mb-4 refund-placeholder'}
              placeholder="İade nedeninizi yazın"
              id="addressText"
              rows={3}
              size="lg"
              onChange={e => setReason(e)}
            />
          </div>
          <div className="address-alert-contorller-container">
            <div
              className="address-alert-accpet-container refund-btn-full"
              onClick={() => onRefundOneItem()}
            >
              <p className="address-alert-text">İade listesine ekle</p>
            </div>
          </div>
        </div>
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
