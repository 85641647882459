import React from 'react';
import { Form } from 'react-bootstrap';

import YImage from '../../Components/Basic/YImage';

// Styles
import './styles/YInputStyles.css'

export default function YInputSearch(props) {

    const {
        name,
        setSearchKeyword,
        placeholder,
        type,
        onKeyDown,
        onEvent
    } = props;

    const container = {
        'display' : 'flex',
        'width' : '100%'
    };
    
    return(
        <Form.Group style={container} controlId={name}>
            <YImage imageSource={require('../../Assets/Images/search.png')} inComingStyle={'search'}/>
            <input 
                name={name}
                type={type}
                autoFocus={true}
                placeholder={placeholder}
                className={'form-control search-box'}
                onChange={(e) =>
                    setSearchKeyword(e.target.value)
                }
                onKeyDown={onKeyDown}
            />
            <div className='yellow-search-btn' onClick={() => onEvent()}>Ara</div>
        </Form.Group>
    );
};