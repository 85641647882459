import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import YImage from '../../Components/Basic/YImage';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Menu } from '@mui/material';

export default function CategoryMenu(props) {
  const navigate = useNavigate();

  const [showSub, setShowSub] = useState(false);

  const [sub, subMenu] = useState([]);

  const changeWidth = state => {
    if (state === 1) {
      document.getElementById('menuCat').style.width = '750px';
    } else {
      //document.getElementById('menuCat').style.width = '200px';
    }
  };

  const _RenderSubMenuHeader = (data, categoryId) => {
    return data.map((last, key) => {
      return (
        <div
          className="custom-menu-header-sub"
          key={'differ' + key + sub.categoryName}
        >
          <div
            className="col-lg-12 mb-12"
            onClick={() =>
              navigate({
                pathname: '/products',
                search:
                  '?catgoryId=' +
                  last.categoryId +
                  '&categoryParent=' +
                  categoryId,
              })
            }
          >
            <h6 className="font-weight-bold text-uppercase category-menu-text-child">
              {last.categoryName}
            </h6>
          </div>
        </div>
      );
    });
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-white py-3 shadow-sm mega ${
        showSub === true ? 'longWidth' : 'shortWidth'
      }`}
    >
      <div className="container-fluid">
        <div
          id="navbarSupportedContent"
          className="collapse navbar-collapse flew-grow-off category-menu-mobile"
        >
          <ul className="navbar-nav mx-auto">
            <li className="nav-item nav-margin-left">
              <ul id="menu">
                <li className="parent">
                  <p>
                    <YImage
                      imageSource={require('../../Assets/Images/category.png')}
                      inComingStyle={'category-menu-icon'}
                    />
                    KATEGORİLER
                  </p>
                  <ul className="child" id="menuCat">
                    {props.menu?.map((sub, i) => {
                      return (
                        <li
                          className="parentTwo"
                          key={'category_' + i}
                          onMouseEnter={() => changeWidth(1)}
                          onMouseLeave={() => changeWidth(2)}
                        >
                          <p
                            onClick={() =>
                              navigate({
                                pathname: '/products',
                                search:
                                  '?catgoryId=' +
                                  sub.categoryId +
                                  '&categoryParent=' +
                                  sub.categoryId,
                              })
                            }
                          >
                            {sub.categoryName}
                          </p>
                          <div className="childTwo">
                            <div className="childTwo-f">
                              {_RenderSubMenuHeader(
                                sub.children,
                                sub.categoryId
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </li>
            {/* Campaings button is hided from this version*/}
            {/* 
                        <li className="nav-item nav-margin-left">
                            <p className="nav-link font-weight-bold text-uppercase" onClick={() => navigate('/campaign')}>
                                <YImage imageSource={require('../../Assets/Images/etiket.png')} inComingStyle={'category-menu-icon'}/>KAMPANYALAR
                            </p>
                        </li>
                         */}
            {/*
                                <li className="nav-item nav-margin-left">
                                    <p className="nav-link font-weight-bold text-uppercase">
                                        <YImage imageSource={require('../../Assets/Images/gift.png')} inComingStyle={'category-menu-icon'}/>YENİ ÜRÜNLER
                                    </p>
                                </li>
                                */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
