import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import ImageGallery from 'react-image-gallery';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YBanner from '../../Components/Basic/YBanner';
import { MDBContainer } from 'mdb-react-ui-kit';
import ScrollTo from './Components/ScrollTo';
import YModal from '../../Components/Basic/YModal';
import YUserLoginModal from '../../Components/User/YUserLoginModal';
import YAddressList from '../../Components/Address/YAddressList';
import YLoading from '../../Components/Basic/YLoading';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Sections
import Discounts from './Components/Discounts';
import BreakfastItems from './Components/BreakfastItems';
import FeaturedItems from './Components/FeaturedItems';
import FreshFoods from './Components/FreshFoods';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/homeStyles.css';
import 'react-image-gallery/styles/css/image-gallery.css';

// Redux
import {
  getProduct,
  getDiscountProduct,
  getMarketByGeoLocation,
  getProductCateOne,
  getProductCateTwo,
} from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';
//import { getCampaigns } from "../../Redux/Actions/CampaignAction";

export default function Home() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const [loginModal, setLoginModal] = useState(false);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [showLoading, setShowLoading] = useState(true);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [discountItems, setDiscountItems] = useState([]);

  const [categoriesItem, setategoriesItem] = useState([]);

  const [cateOne, setCateone] = useState([]);

  const [cateTwo, setCateTwo] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const images = [
    {
      original:
        ' https://yawawlstorage.blob.core.windows.net/yawa-wl-product-image/21cfcc61-27be-11ef-bf97-0242ac160004.jpg',
      thumbnail:
        ' https://yawawlstorage.blob.core.windows.net/yawa-wl-product-image/21cfcc61-27be-11ef-bf97-0242ac160004.jpg',
      nav: '',
    },
    {
      original:
        'https://yawawlstorage.blob.core.windows.net/yawa-wl-product-image/8b367959-1cf5-11ef-b3b9-6045bd8af738.jpg',
      thumbnail:
        'https://yawawlstorage.blob.core.windows.net/yawa-wl-product-image/8b367959-1cf5-11ef-b3b9-6045bd8af738.jpg',
      nav: '',
    },
    {
      original:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/1.jpg',
      thumbnail:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/1.jpg',
      nav: 0,
    },
    {
      original:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/2.jpg',
      thumbnail:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/2.jpg',
      nav: 4,
    },
    {
      original:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/3.jpg',
      thumbnail:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/3.jpg',
      nav: 17,
    },
    {
      original:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/4.jpg',
      thumbnail:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/4.jpg',
      nav: 1,
    },
    {
      original:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/5.jpg',
      thumbnail:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/5.jpg',
      nav: 1,
    },
    {
      original:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/6.jpg',
      thumbnail:
        'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/6.jpg',
      nav: 1,
    },
  ];

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = process.env.REACT_APP_WEBSITE_TITLE;

    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getMenu(marketId));
          dispatch(getAddress());
          dispatch(getBasket());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (menu?.length > 0) {
      dispatch(getDiscountProduct(marketId, setDiscountItems));
      dispatch(getProduct(menu[0]?.categoryId, marketId, setategoriesItem));
      dispatch(getProductCateOne(menu[1]?.categoryId, marketId, setCateone));
      dispatch(
        getProductCateTwo(
          menu[2]?.categoryId,
          marketId,
          setCateTwo,
          setShowLoading
        )
      );
    }
  }, [dispatch, menu]);

  useEffect(() => {
    dispatch(getMenu(marketId));
  }, [dispatch, marketId]);

  /*useEffect(() => {
    if (typeof campaing !== "undefined") {
      setCampaignItems(...campaing);
    }
  }, [campaing]);*/

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const renderImages = item => {
    return (
      <a href={item.nav !== 0 ? '/products?catgoryId=' + item.nav : '/'}>
        <img src={item.original} alt="ozhan market" className="banner-images" />
      </a>
    );
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <ImageGallery
          items={images}
          showBullets={false}
          renderItem={renderImages}
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex={false}
          showNav={false}
        />
        {discountItems.length !== 0 ? (
          <Discounts
            data={discountItems}
            onEvent={onEvent}
            basket={basket}
            navigate={navigate}
            token={tokenData}
            onLoginModal={setLoginModal}
          />
        ) : null}
        <BreakfastItems
          title={menu[0]?.categoryName}
          data={categoriesItem}
          onEvent={onEvent}
          basket={basket}
          navigate={navigate}
          token={tokenData}
          onLoginModal={setLoginModal}
          cateId={menu[0]?.categoryId}
        />
        <FeaturedItems
          title={menu[1]?.categoryName}
          data={cateOne}
          onEvent={onEvent}
          basket={basket}
          navigate={navigate}
          token={tokenData}
          onLoginModal={setLoginModal}
          cateId={menu[1]?.categoryId}
        />
        <FreshFoods
          title={menu[2]?.categoryName}
          data={cateTwo}
          onEvent={onEvent}
          basket={basket}
          navigate={navigate}
          token={tokenData}
          onLoginModal={setLoginModal}
          cateId={menu[2]?.categoryId}
        />
        {/* Banner */}
        <YBanner />
        {/* Banner Products */}
        <ScrollTo />
      </MDBContainer>
      <Footer />
      <YModal
        show={loginModal}
        onClose={() => setLoginModal(!loginModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <YUserLoginModal
          loginModal={loginModal}
          setLoginModal={setLoginModal}
        />
      </YModal>
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
