import React from 'react';

// Styles
import './styles/YSeperatorStyles.css';

export default function YSeperator(props) {

  return (
    <div className='seperator-container'>
        <div className='seperator-box' />
    </div>
  );
}
