import { SET_PROVINCE , SET_DISTRICT , SET_NEIGHBORHOOD , SET_SHOP , SET_DELIVERY_DATE_TIME } from '../Constants/action-types';
  
  const initialState = [

  ]
  
  export default function delivery(state = initialState, action) {
    switch (action.type) {
      case SET_PROVINCE:
        return { ...state, province: action.payload };
      case SET_DISTRICT:
        return { ...state, district: action.payload };
      case SET_NEIGHBORHOOD:
        return { ...state, neighborhood: action.payload };
      case SET_SHOP:
        return { ...state, shop: action.payload };
      case SET_DELIVERY_DATE_TIME:
        return { ...state, delivery: action.payload };
      default:
        return state
    }
  };
  