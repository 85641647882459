import React, { useEffect, useState } from 'react';

// Components
import { MDBIcon } from 'mdb-react-ui-kit';

// Styles
import '../styles/scrollToStyles.css'

function ScrollTo() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div className="scroll-to-top-container" style={{ opacity: isVisible ? 1 : 0 }}>
            <button className="scroll-to-top-button" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <MDBIcon icon='angle-up' size='2x' />
            </button>
        </div>
    );
}

export default ScrollTo;