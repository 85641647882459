import { SET_CAMPAIGNS } from "../Constants/action-types";

const initialState = [

]

export default function campaign(state = initialState, action) {
    switch (action.type) {
        case SET_CAMPAIGNS:
            return { ...state, campaign: action.payload };
        default:
            return state
    }
};
