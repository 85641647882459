import React from "react";

// Styles
import "./styles/YBreadcrumbStyles.css";

// Props must be like that:
/*
const items = [
    { name: "Home", link: "/", active: false },
    { name: "Library", link: "/library", active: true },
    { name: "Data", link: "/library/data", active: false },
  ];
*/

export default function YBreadcrumb(props) {
  const activeIndex = props.items.findIndex((item) => item.active);
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        {props.items.slice(0, activeIndex + 1).map((item, index) => {
          return (
            <li
              key={index}
              className={`breadcrumb-item ${item.active ? "active" : ""}`}
            >
              {item.active ? item.name : <a href={item.link}>{item.name}</a>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
