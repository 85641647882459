import React from 'react';
import { MDBCard, MDBCardBody, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
// Utils

// Styles
import '../Css/general.css';

// Icons
import creditCardWhite from '../Icons/user-credit-card-white.png';
import creditCardBlack from '../Icons/user-credit-card-black.png';

import addressWhite from '../Icons/user-address-white.png';
import addressBlack from '../Icons/user-address-black.png';

import basketWhite from '../Icons/user-basket-white.png';
import basketBlack from '../Icons/user-basket-black.png';

import settingsWhite from '../Icons/user-settings-white.png';
import settingsBlack from '../Icons/user-settings-black.png';

import logOutImage from '../Icons/user-logout.png';

//Auth
import { useUserAuth } from '../Context/UserAuthContext';

// Components
import '@fortawesome/fontawesome-free/css/all.min.css';
import YImage from '../../Components/Basic/YImage';

export default function UserMenu(props) {
  const { logOut, user } = useUserAuth();

  const navigate = useNavigate();

  let userInformation;
  let userInformationIc;
  if (props.ativeMenu === 'user-information') {
    userInformation =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu address-link-active';
    userInformationIc = settingsWhite;
  } else {
    userInformation =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu';
    userInformationIc = settingsBlack;
  }

  //------------------

  let userAddress;
  let userAddressIc;
  if (props.userAddress === 'user-address') {
    userAddress =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu address-link-active';
    userAddressIc = addressWhite;
  } else {
    userAddress =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu';
    userAddressIc = addressBlack;
  }

  //------------------

  let userOrder;
  let userOrderIc;
  if (props.userAddress === 'user-order') {
    userOrder =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu address-link-active';
    userOrderIc = basketWhite;
  } else {
    userOrder =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu';
    userOrderIc = basketBlack;
  }

  //------------------

  let userCards;
  let userCardsIc;
  if (props.userAddress === 'user-cards') {
    userCards =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu address-link-active';
    userCardsIc = creditCardWhite;
  } else {
    userCards =
      'mb-3 d-flex justify-content-start d-flex align-items-center address-link-menu';
    userCardsIc = creditCardBlack;
  }

  const setUserLogout = () => {
    cookie.remove('cartId', { path: '', domain: 'localhost' });
    cookie.remove('userAddress', { path: '', domain: 'localhost' });
    cookie.remove('marketId', { path: '', domain: 'localhost' });
    cookie.remove('basketId', { path: '', domain: 'localhost' });
    cookie.remove('token', { path: '', domain: 'localhost' });
    cookie.remove('tempDataItem', { path: '', domain: 'localhost' });

    cookie.remove('userAddress', {
      path: '',
      domain: 'main.d2dy9h886ievkm.amplifyapp.com',
    });
    cookie.remove('marketId', {
      path: '',
      domain: 'main.d2dy9h886ievkm.amplifyapp.com',
    });
    cookie.remove('basketId', {
      path: '',
      domain: 'main.d2dy9h886ievkm.amplifyapp.com',
    });
    cookie.remove('token', {
      path: '',
      domain: 'main.d2dy9h886ievkm.amplifyapp.com',
    });
    cookie.remove('tempDataItem', {
      path: '',
      domain: 'main.d2dy9h886ievkm.amplifyapp.com',
    });

    logOut();
  };

  return (
    <MDBContainer>
      <MDBCard className="user-menu-card-container">
        <MDBCardBody>
          <div
            className={userAddress}
            onClick={() => navigate('/user-address')}
          >
            <YImage
              imageSource={userAddressIc}
              inComingStyle={'user-menu-card-item-icon-container'}
            />
            <p className="user-menu-card-item-icon-text">Adreslerim</p>
            <div className="user-menu-card-icon-arrow-container">
              <MDBIcon
                fas
                icon="angle-right"
                className="user-menu-card-icon-arrow-icon"
              />
            </div>
          </div>
          <div
            className={userOrder}
            onClick={() => navigate('/user-order-history')}
          >
            <YImage
              imageSource={userOrderIc}
              inComingStyle={'user-menu-card-item-icon-container'}
            />
            <p className="user-menu-card-item-icon-text">Siparişlerim</p>
            <div className="user-menu-card-icon-arrow-container">
              <MDBIcon
                fas
                icon="angle-right"
                className="user-menu-card-icon-arrow-icon"
              />
            </div>
          </div>
          {/* For now It is hided. */}
          {/*
                    <div className={userCards} onClick={() => navigate('/user-cards')}>
                        <YImage imageSource={userCardsIc} inComingStyle={'user-menu-card-item-icon-container'}/>
                        <p className="user-menu-card-item-icon-text" >Kartlarım</p>
                        <div className='user-menu-card-icon-arrow-container'>
                        <MDBIcon fas icon="angle-right" className='user-menu-card-icon-arrow-icon'/>
                        </div>
                    </div>
                     */}
          <div
            className={userInformation}
            onClick={() => navigate('/user-information')}
          >
            <YImage
              imageSource={userInformationIc}
              inComingStyle={'user-menu-card-item-icon-container'}
            />
            <p className="user-menu-card-item-icon-text">Hesap Ayarları</p>
            <div className="user-menu-card-icon-arrow-container">
              <MDBIcon
                fas
                icon="angle-right"
                className="user-menu-card-icon-arrow-icon"
              />
            </div>
          </div>
          <div
            className="mb-3 d-flex justify-content-start d-flex align-items-center address-link"
            onClick={() => setUserLogout()}
          >
            <YImage
              imageSource={logOutImage}
              inComingStyle={'user-menu-card-item-icon-container'}
            />
            <p className="user-menu-card-item-icon-logout-text">Çıkış Yap</p>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}
