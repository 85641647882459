import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import cookie from 'js-cookie';

// Components
import YImage from '../../Components/Basic/YImage';
import YText from '../../Components/Basic/YText';
import YMapComponent from '../../Components/Basic/YMapComponent';

// Redux
import { getDistrict, getShopName } from '../../Redux/Actions/DeliveryAction';

export default function Address(props) {
  const {
    province,
    onChangeProvince,
    onChangeDistrict,
    deliveryType,
    onChangeMap,
    mapError,
  } = props;

  const [sortState, setSortState] = useState(2);

  const dispatch = useDispatch();

  const district = useSelector(state => state.delivery.district);

  const shopList = useSelector(state => state.delivery.shop);

  const [long, setLong] = useState();

  const [lat, setLat] = useState();

  const onChangeShopList = () => {};

  const [valuess, setValue] = useState(null);

  useEffect(() => {
    setSortState(1);
  }, [deliveryType]);

  const changeModalContent = e => {
    setSortState(e);
  };

  const showMap = () => {
    setLat('41.112663');
    setLong('29.021330');
  };

  const onSelectProvince = e => {
    const { options, selectedIndex } = e.target;
    dispatch(getDistrict(e.target.value));
    onChangeProvince(e.target.value, options[selectedIndex].innerHTML);
  };

  const onSelectDistrict = e => {
    const { options, selectedIndex } = e.target;
    dispatch(getShopName(e.target.value));
    onChangeDistrict(e.target.value, options[selectedIndex].innerHTML);
  };

  const onSelectShop = e => {
    const { options, selectedIndex } = e.target;
    onChangeShopList(e.target.value, options[selectedIndex].innerHTML);
  };

  const onChangeAddess = e => {
    geocodeByAddress(e.label)
      .then(results => {
        let diraction = getLatLng(results[0]);
        diraction.then(value => {
          setLong(value.lng);
          setLat(value.lat);
          console.log(e.label);
          setValue(e.label);
        });
      })
      .catch(error => console.error(error));
  };

  const _onSelectAddressMap = () => {
    if (typeof lat !== 'undefined') {
      onChangeMap(lat, long, valuess);
    } else {
      toast.error('You must pick one Address first!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const onChangeMapNew = async (lat, lng) => {
    setLong(lng);
    setLat(lat);
  };

  const renderModalContent = () => {
    switch (sortState) {
      case 3:
        return (
          <>
            <YText
              value={'Merhaba ,'}
              type={'p'}
              incomingStyle={'modal-location-welcome-text'}
            />
            <YText
              value={'Siparişini nasıl getirelim?'}
              type={'p'}
              incomingStyle={'modal-location-welcome-text'}
            />
            <div className="modal-location-items-aglonside">
              <div
                className="modal-location-pick-up-container"
                onClick={() => changeModalContent(2)}
              >
                <YImage
                  imageSource={require('../../Assets/Images/home.png')}
                  inComingStyle={'home-icon'}
                />
                <YText
                  value={'Adresime Gelsin'}
                  type={'span'}
                  incomingStyle={'modal-location-delivery-text'}
                />
              </div>
            </div>
            <div className="modal-location-items-aglonside">
              <div
                className="modal-location-pick-up-container"
                onClick={() => changeModalContent(3)}
              >
                <YImage
                  imageSource={require('../../Assets/Images/cart.png')}
                  inComingStyle={'home-icon'}
                />
                <YText
                  value={'Tıkla Gel Al'}
                  type={'span'}
                  incomingStyle={'modal-location-delivery-text'}
                />
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="google-auto-input-location">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                selectProps={{
                  placeholder: 'Lütfen adresinizi yazınız...',
                  valuess,
                  onChange: onChangeAddess,
                }}
              />
            </div>

            <div className="header-map-container">
              {long ? (
                <YMapComponent
                  lat={lat}
                  long={long}
                  setLat={setLat}
                  setLong={setLong}
                  onChangeMap={onChangeMapNew}
                />
              ) : (
                <>
                  <img
                    src={require('./../Images/map.jpg')}
                    alt={'map'}
                    className="header-map-address-image"
                    onClick={() => showMap()}
                  />
                  <img
                    src={require('./../Images/mmap.jpg')}
                    alt={'map'}
                    className="header-map-address-image-mobile"
                    onClick={() => showMap()}
                  />
                </>
              )}
            </div>
            {mapError !== '' ? (
              <Alert variant="danger" className="map-none-user-error">
                {mapError}
              </Alert>
            ) : null}
            <Button
              variant="primary"
              type="submit"
              className="w-100 mb-4 set-address-btn"
              onClick={() => _onSelectAddressMap()}
            >
              İşaretlediğim Konum ile Güncelle
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <YText
              value={'Mağazadan Alacağım'}
              type={'p'}
              incomingStyle={'modal-location-welcome-text'}
            />
            <YText
              value={'Siparişini nereden almak istersin?'}
              type={'p'}
              incomingStyle={'modal-location-welcome-text'}
            />
            <Form.Group className="mb-3" controlId={'province'}>
              <Form.Select
                aria-label={'province'}
                defaultValue={0}
                onChange={e => onSelectProvince(e)}
              >
                <option value={0} disabled={true}>
                  {'İl'}
                </option>
                {province?.map((item, i) => {
                  return (
                    <option key={i + item.name} value={item.cityId}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId={'district'}>
              <Form.Select
                aria-label={'district'}
                defaultValue={0}
                onChange={e => onSelectDistrict(e)}
                disabled={district ? false : true}
              >
                <option value={0} disabled={true}>
                  {'İlçe'}
                </option>
                {district?.map((item, i) => {
                  return (
                    <option key={i + item.name} value={item.districtId}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId={'district'}>
              <Form.Select
                aria-label={'district'}
                defaultValue={0}
                onChange={e => onSelectShop(e)}
                disabled={shopList ? false : true}
              >
                <option value={0} disabled={true}>
                  {'Shop Name'}
                </option>
                {shopList?.map((item, i) => {
                  return (
                    <option key={i + item.addressHint} value={item.marketId}>
                      {item.addressHint}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderModalContent()}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
