import React from 'react';

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBTypography,
} from 'mdb-react-ui-kit';

// Components
import YImage from '../../../Components/Basic/YImage';
import YStep from '../../../Components/Basic/YStep';

// Styles
import './styles/SingleOrderStyles.css';
import '../../../Components/Basic/styles/YSeperatorStyles.css';

//Icons&Images
import backIcon from '../../../Assets/Images/backIcon.png';

export default function SingleOrder(props) {
  const { onCloseOrder, itemData } = props;

  let activeStep = 0;
  if (itemData.trackingStatuses[0].colorRGBCode == '#94CF71') {
    activeStep = 0;
  }
  if (itemData.trackingStatuses[1].colorRGBCode == '#94CF71') {
    activeStep = 1;
  }
  if (itemData.trackingStatuses[2].colorRGBCode == '#94CF71') {
    activeStep = 2;
  }
  if (itemData.trackingStatuses[3].colorRGBCode == '#94CF71') {
    activeStep = 3;
  }

  return (
    <MDBCard className="rounded-3 user-order-history-container">
      <MDBRow>
        <MDBCol md="8">
          <MDBCardBody>
            <MDBRow>
              <MDBCol>
                <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                  <MDBTypography
                    id="last-order-details-my-orders-text"
                    className="mb-0 user-order-history-headers"
                  >
                    Siparişlerim
                  </MDBTypography>
                </div>
                <div onClick={() => onCloseOrder()}>
                  <YImage
                    inComingStyle="last-order-details-back-icon"
                    imageSource={backIcon}
                  />
                </div>
                <MDBCard>
                  <MDBCardBody className="detaits-section-1">
                    <MDBRow className="">
                      <MDBCol>
                        <MDBTypography className="last-order-details-order-container-no-header">
                          Sipariş No:&nbsp;
                          <span id="last-order-details-order-container-no">
                            {itemData.orderNumber}
                          </span>
                        </MDBTypography>
                      </MDBCol>
                      {itemData.isCancelled === true ? (
                        <MDBCol className="d-flex justify-content-end">
                          <span
                            id="last-order-details-add-sepet-section"
                            className="d-flex justify-content-end"
                          >
                            <p className="order-is-canceled-text-single">
                              Sipariş iptal edildi
                            </p>
                          </span>
                        </MDBCol>
                      ) : null}
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBTypography className="last-order-details-history">
                          {itemData.dateCreated}
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <MDBTypography className="last-order-details-history">
                          Sipariş Durumu:{' '}
                          <span className="user-order-history-order-number-header">
                            {itemData.status}
                          </span>
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                    <hr style={{ marginInline: '-16px' }}></hr>
                    <MDBRow className="" id="last-order-adress">
                      <MDBCol size="12">
                        <MDBRow>
                          <MDBTypography className="last-order-details-adress-text">
                            Teslimat Adresi:
                          </MDBTypography>
                          <MDBTypography className="last-order-details-adress-text">
                            {itemData.address}
                          </MDBTypography>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <hr style={{ marginInline: '-16px' }}></hr>
                    <div style={{ marginTop: '40px' }}>
                      <MDBRow>
                        <MDBCol
                          id="order-details-count-header"
                          className="col-set-set"
                          md="2"
                          size="2"
                        >
                          <MDBTypography className="d-flex justify-content-start last-order-details-product-headers">
                            Miktar
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol
                          id="order-details-basket-header"
                          className="col-set-set"
                          md="6"
                          size="6"
                        >
                          <MDBTypography
                            style={{ display: 'block' }}
                            className="d-flex justify-content-start last-order-details-product-headers"
                          >
                            Sepetteki Ürünler
                            <span>({itemData.products.length})</span>
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol
                          id="order-details-price-header"
                          className="col-set-set"
                          md="4"
                          size="4"
                        >
                          <MDBTypography className="d-flex justify-content-center last-order-details-product-headers">
                            Ücret
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      {itemData.products?.map((e, i) => (
                        <MDBRow
                          className="last-order-details-product-container-row"
                          key={i}
                        >
                          <MDBCol
                            id="user-details-count-map"
                            className="col-set-set"
                            md="2"
                            size="2"
                          >
                            <MDBTypography
                              id="last-details-count"
                              className="d-flex justify-content-start"
                            >
                              {e.quantity} {e.unit}{' '}
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            id="order-details-basket-map"
                            className="col-set-set"
                            md="6"
                            size="6"
                          >
                            <MDBTypography
                              id="last-details-image"
                              className="d-flex justify-content-start"
                            >
                              {e?.thumbnailUri !== '' ? (
                                <img
                                  src={e?.thumbnailUri}
                                  className="last-order-details-product-pic"
                                  alt="..."
                                />
                              ) : (
                                <YImage
                                  imageSource={require('../../../Assets/Images/sample.png')}
                                  inComingStyle={
                                    'last-order-details-product-pic'
                                  }
                                />
                              )}
                              <span className="last-order-details-text-title-item">
                                {e.name}
                              </span>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            className="col-set-set"
                            id="order-details-discount-price-map"
                            md="4"
                            style={{ display: 'block' }}
                            size="4"
                          >
                            <MDBTypography
                              id=""
                              className="d-flex justify-content-center"
                            >
                              <span id="last-order-details-price-overline-text"></span>
                            </MDBTypography>
                            <MDBTypography
                              id="last-order-details-price-text"
                              className="d-flex justify-content-center"
                            >
                              {e.price} TL
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCol>
        <MDBCol className="" md="4">
          <MDBCardBody>
            <MDBRow>
              <MDBCol>
                <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                  <MDBTypography
                    id="last-order-details-my-orders-text-disabled"
                    className="mb-0 user-order-history-headers"
                  >
                    Siparişlerim
                  </MDBTypography>
                </div>
                <YImage
                  inComingStyle="last-order-details-back-icon-disabled"
                  imageSource={backIcon}
                ></YImage>
                <MDBCard>
                  <MDBCardBody className="detaits-section-1">
                    <MDBRow className="">
                      <MDBCol>
                        <MDBRow className="">
                          <MDBCol>
                            <MDBTypography
                              id="las-order-details-fish-siparis-ozeti"
                              className="last-order-details-fish-text"
                            >
                              Sipariş Özeti
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <span className="d-flex justify-content-end">
                              <MDBTypography className="last-order-details-fish-count">
                                (<span>{itemData.products.length}</span> Ürün)
                              </MDBTypography>
                            </span>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="">
                          <MDBCol>
                            <MDBTypography className="last-order-details-fish-text">
                              Sipariş Tutarı
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <span className="d-flex justify-content-end">
                              <MDBTypography>
                                {Number(
                                  itemData.paymentDetail.totalPrice
                                ).toFixed(2)}{' '}
                                TL
                              </MDBTypography>
                            </span>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="">
                          <MDBCol>
                            <MDBTypography className="last-order-details-fish-text">
                              Teslimat Ücreti
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <span className="d-flex justify-content-end">
                              <MDBTypography>
                                {Number(
                                  itemData.paymentDetail.deliveryFee
                                ).toFixed(2)}{' '}
                                TL
                              </MDBTypography>
                            </span>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <MDBTypography
                              id="last-order-details-fish-toplam-tutar"
                              className="d-flex justify-content"
                            >
                              Toplam Tutar:{' '}
                              {Number(
                                itemData.paymentDetail.totalPrice
                              ).toFixed(2)}{' '}
                              TL
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBCard>
  );
}
