import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YImage from '../../Components/Basic/YImage';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
  getOrderById,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

// Styles
import './styles/orderDetails.css';
import '../../Components/Basic/styles/YSeperatorStyles.css';

//Icons&Images
import GreenBasket from '../../Assets/Images/green-basket.png';
import ArrowRight from '../../Assets/Images/arrow-right-white.png';

export default function OrderDetails() {
  const dispatch = useDispatch();

  const location = useLocation();

  const pair = location.search.split('=');

  const result = pair[1].split('&');

  const orderId = pair[2];

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const [cartVisiable, setCartVisiable] = useState(false);

  const addressList = useSelector(state => state.user.address);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [orderDetails, setOrderDetails] = useState([]);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    dispatch(getMenu(marketId));
    dispatch(getAddress());
    cookie.remove('cartId', { path: '', domain: 'localhost' });
    cookie.remove('tempDataItem', { path: '', domain: 'localhost' });
    cookie.remove('basketId', { path: '', domain: 'localhost' });
    cookie.remove('totalPrice', { path: '', domain: 'localhost' });
    cookie.remove('productTotalAmount', { path: '', domain: 'localhost' });
    cookie.remove('amountOfFreeDelivery', { path: '', domain: 'localhost' });
    cookie.remove('deliveryFee', { path: '', domain: 'localhost' });

    cookie.remove('cartId', {
      path: '',
    });
    cookie.remove('tempDataItem', {
      path: '',
    });
    cookie.remove('basketId', {
      path: '',
    });
    cookie.remove('totalPrice', {
      path: '',
    });
    cookie.remove('productTotalAmount', {
      path: '',
    });
    cookie.remove('amountOfFreeDelivery', {
      path: '',
    });
    cookie.remove('deliveryFee', {
      path: '',
    });
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  useEffect(() => {
    dispatch(getOrderById(orderId, toast, setOrderDetails));
  }, [location]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="user-order-history-container">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="rounded-3 user-order-history-container">
                      <div className="sucess-order-container-box">
                        <MDBRow>
                          <MDBCol
                            lg="8"
                            className="payment-border-green-dashed"
                          >
                            <div>
                              <YImage
                                imageSource={GreenBasket}
                                inComingStyle={'payment-green-basket-icon'}
                              />
                              <span className="payment-green-basket-title-text">
                                Siparişiniz alındı.
                              </span>
                            </div>
                            <p className="payment-green-basket-help">
                              Siparişiniz ile ilgili detaylar{' '}
                              <b>{orderDetails.userEmail}</b> adresinize
                              gönderildi. Siparişiniz ile ilgili detaylara
                              Siparişlerim sayfasından ulaşabilirsiniz.
                            </p>
                            <Button
                              variant="primary"
                              type="button"
                              className="payment-go-to-my-order-btn"
                              onClick={() => navigate('/user-order-history')}
                            >
                              <span>Siparişlerim</span>
                              <YImage
                                imageSource={ArrowRight}
                                inComingStyle={
                                  'payment-green-basket-arrow-whilte-icon'
                                }
                              />
                            </Button>
                          </MDBCol>
                          <MDBCol lg="4">
                            <p className="payment-green-title-text">
                              SİPARİŞ NUMARASI
                            </p>
                            <p className="payment-green-order-number-text">
                              {orderDetails.orderNumber}
                            </p>
                            <p className="payment-green-title-text">
                              TESLİMAT ADRESİ
                            </p>
                            <p className="payment-green-order-address-text">
                              {orderDetails.address}
                            </p>
                            <p className="payment-green-title-text">
                              ÖDENEN TUTAR
                            </p>
                            <p className="payment-green-order-number-text">
                              {orderDetails.amountPaid} TL
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
