import { LEGAL_KVKK, LEGAL_GDPR, LEGAL_PRIVACYPOLICY, LEGAL_TERMOFUSE } from '../Constants/action-types';

const initialState = { kvkkContent: '', gdprContent: '', privacyPolicyContent: '', termOfUseContent: '', };

export default function legal(state = initialState, action) {
    switch (action.type) {
        case LEGAL_KVKK:
            return { ...state, kvkkContent: action.payload };
        case LEGAL_GDPR:
            return { ...state, gdprContent: action.payload };
        case LEGAL_PRIVACYPOLICY:
            return { ...state, privacyPolicyContent: action.payload };
        case LEGAL_TERMOFUSE:
            return { ...state, termOfUseContent: action.payload };
        default:
            return state
    }
};
