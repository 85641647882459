import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBIcon,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YBreadcrumb from '../../Components/Basic/YBreadcrumb';
import YAddToBasketSingle from '../../Components/Product/YAddToBasketSingle';
import Related from './Components/Related';
import YModal from '../../Components/Basic/YModal';
import YUserLoginModal from '../../Components/User/YUserLoginModal';
import YAddressList from '../../Components/Address/YAddressList';
import YImage from '../../Components/Basic/YImage';
import YLoading from '../../Components/Basic/YLoading';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/SingleStyles.css';

// Redux
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import {
  getProduct,
  getMarketByGeoLocation,
} from '../../Redux/Actions/ProductAction';
import { getSingleProduct } from '../../Redux/Actions/ProductAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

export default function Single(props) {
  const location = useLocation();

  const pair = location.search.split('&');

  let proIdArray = pair[0].split('=');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const product = useSelector(state => state.product.product);

  const single = useSelector(state => state.product.single);

  const addressList = useSelector(state => state.user.address);

  const basket = useSelector(state => state.basket.basket);

  const inBasket = basket?.find(item => item.id === Number(proIdArray[1]));

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [loginModal, setLoginModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [categoriesItem, setategoriesItem] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [showLoading, setShowLoading] = useState(true);

  const [count, setCount] = useState(0);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Ozhan Market ';
    let proIdArray = pair[0].split('=');
    let marketArray = pair[1].split('=');
    dispatch(getSingleProduct(proIdArray[1], marketArray[1], setShowLoading));
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    dispatch(getMenu(marketId));
  }, [dispatch, marketId]);

  useEffect(() => {
    dispatch(getProduct(menu[0]?.categoryId, marketId, setategoriesItem));
  }, [dispatch, menu, marketId]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let proIdArray = pair[0].split('=');
    let marketArray = pair[1].split('=');
    dispatch(getSingleProduct(proIdArray[1], marketArray[1], setShowLoading));
  }, [location]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const breadCrumbData = [
    {
      name: 'Ana sayfa',
      link: '/',
      active: false,
    },
    {
      name: single?.name,
      link: '/single/',
      active: true,
    },
  ];

  const upDateBasket = newCount => {
    let proIdArray = pair[0].split('=');
    let temp = { productId: proIdArray[1] };
    onEvent(temp, newCount, 1);
  };

  useEffect(() => {
    if (typeof inBasket !== 'undefined') {
      let result = inBasket?.count.replace(',', '.');
      setCount(Number(result));
    } else {
      setCount(0);
    }
  }, [inBasket]);

  const currency_symbol = '₺';

  const formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <YBreadcrumb items={breadCrumbData} />
        <MDBRow className="single-item-product-container">
          <MDBCol md="6">
            <div className="d-flex justify-content-center d-flex align-items-center  single-item-product-image-container">
              {single?.imageUrl !== '' ? (
                <img
                  src={single?.imageUrl}
                  className="single-item-product-image"
                  alt="..."
                />
              ) : (
                <YImage
                  imageSource={require('../../Assets/Images/sample.png')}
                  inComingStyle={'single-item-product-image'}
                />
              )}
            </div>
          </MDBCol>
          <MDBCol md="6">
            <div className="single-item-product-details">
              <MDBTypography tag="h5" className="mb-2">
                {single?.name}
              </MDBTypography>
              <MDBTypography tag="h5" className="mb-2">
                1 {single?.unit}
              </MDBTypography>
              <MDBTypography tag="h6" className="mb-0">
                {single?.discount !== 0 ? (
                  <span className="single-item-product-old-price">
                    {formattedOutput
                      .format(single?.previousPrice)
                      .replace(currency_symbol, '')}{' '}
                    TL
                  </span>
                ) : null}
              </MDBTypography>
              <MDBTypography tag="h4" className="mb-0">
                <span className="">
                  {formattedOutput
                    .format(single?.price)
                    .replace(currency_symbol, '')}{' '}
                  TL
                </span>
              </MDBTypography>
              <div className="single-product-basket-button my-1">
                <YAddToBasketSingle
                  unit={single?.unit}
                  count={Number(count)}
                  upDateBasket={upDateBasket}
                  incomingStyle={'product-go-left'}
                  token={tokenData}
                  onLoginModal={setLoginModal}
                />
              </div>
            </div>
            <div className="single-item-warning-container">
              <MDBIcon fas icon="circle" className="single-item-red-dot" />
              Mağaza stoklarıyla sınırlıdır. Ürünün, stok, fiyat ve kampanya
              bilgisi teslimat bölgenize göre değişiklik gösterebilir.
            </div>
          </MDBCol>
          {product?.length !== 0 ? (
            <Related
              data={categoriesItem}
              onEvent={onEvent}
              basket={basket}
              navigate={navigate}
            />
          ) : null}
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={loginModal}
        onClose={() => setLoginModal(!loginModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <YUserLoginModal
          loginModal={loginModal}
          setLoginModal={setLoginModal}
        />
      </YModal>
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
