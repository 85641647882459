import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YImage from '../../Components/Basic/YImage';
import YButton from '../../Components/Basic/YButton';
import Address from './Components/Address';
import EditAddress from './Components/EditAddress';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import {
  getBasket,
  setBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { deleteAddress, getAddress } from '../../Redux/Actions/UserAction';

// Styles
import './styles/UserAddress.css';

// Icons
import addressEditIc from '../../Assets/Icons/user-address-edit.png';
import addressDeleteIc from '../../Assets/Icons/user-address-delete.png';
import closeModalIc from '../../Assets/Icons/ic-exit.png';
import WarnIcon from '../../Assets/Icons/warnIcon.png';

// Utils
import UserMenu from '../../Assets/Utils/UserMenu';

export default function UserAddress() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [deleteAddressModal, setDeleteAddressModal] = useState(false);

  const deleteAddressModalShow = (state, id) => {
    setDeleteAddressModal(!deleteAddressModal);
    setActiveRemoveId(id);
  };

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [deliveryType, setDeliveryType] = useState(0);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [userAddressModals, setUserAddressModals] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const menu = useSelector(state => state.menu.menu);

  const [showAddress, setShowAddress] = useState(false);

  const tokenData = cookie.get('token');

  const [addresses, setAddresses] = useState([]);

  const [activeRemoveId, setActiveRemoveId] = useState(false);

  const showUserEditAddress = () => {
    setEditAddress(false);
  };

  const showUserAddress = () => {
    setShowAddress(!showAddress);
  };

  const [editAddress, setEditAddress] = useState(false);

  const [editAddressData, setEditAddressData] = useState(false);

  const sendEditItems = item => {
    setEditAddressData(item);
    setEditAddress(true);
  };

  /*const [editBillingAddress, setEditBillingAddress] = useState(false);

    const [editBillingAddressData, setEditBillingAddressData] = useState(false);

    const sendEditBillingItems = (item) => {
        setEditBillingAddressData(item)
        setEditBillingAddress(true)
    }*/

  /*const showUserBillingAddress = () => {
        setShowBillingAddress(!showBillingAddress)
    }*/

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof addressList !== 'undefined') {
      setAddresses([...addressList]);
    }
  }, [addressList]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onDeleteItemAddress = () => {
    dispatch(deleteAddress(activeRemoveId));
    setEditAddress(false);
    setDeleteAddressModal(false);
  };

  const addingNewDevlieryAddress = () => {
    showUserAddress();
  };

  /*const addingNewBillingAddress = () => {
        showUserBillingAddress();
    }*/

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const onApprove = () => {
    dispatch(deleteAddress(activeRemoveId));
    setUserAddressModals(false);
  };

  const cancelChange = () => {
    setUserAddressModals(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        deliveryType={deliveryType}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        {/* DELETE MODAL */}
        <MDBModal
          show={deleteAddressModal}
          setShow={setDeleteAddressModal}
          tabIndex="-1"
          className="user-address-item-delete-modal"
        >
          <MDBModalDialog className="user-address-item-delete-modal-box">
            <MDBModalContent>
              <MDBModalBody>
                <div className="user-address-item-delete-modal-container">
                  <div
                    onClick={deleteAddressModalShow}
                    className="user-address-item-delete-modal-icon"
                  >
                    <YImage
                      imageSource={closeModalIc}
                      inComingStyle={'user-address-item-delete-modal-icon'}
                    />
                  </div>
                  <div className="user-address-item-warn">
                    <YImage
                      imageSource={WarnIcon}
                      inComingStyle={'user-address-item-warn'}
                    />
                  </div>
                  <MDBTypography className="user-address-item-delete-modal-header mb-3">
                    Adresi Sil
                  </MDBTypography>
                  <MDBTypography className="user-address-item-delete-modal-text mb-3">
                    Adresi silmek istediğinizden emin misiniz?
                  </MDBTypography>
                  <YButton
                    inComingClassName={'user-address-item-delete-modal-button'}
                    text={'Sil'}
                    onEvent={onDeleteItemAddress}
                  />
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* ------------- */}
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="user-address-container">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol lg="3">
                    <UserMenu userAddress={'user-address'} />
                  </MDBCol>
                  <MDBCol lg="9">
                    <MDBCard className="rounded-3 user-address-container">
                      <MDBCardBody>
                        <MDBRow className="user-address-sections-container">
                          <MDBCol lg="12" size="12">
                            <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                              <MDBTypography className="mb-0 user-address-headers">
                                Teslimat Adreslerim
                              </MDBTypography>
                            </div>

                            {showAddress === true ? (
                              <Address
                                onClickNewAddressBilling={showUserAddress}
                                type={1}
                              />
                            ) : null}
                            {editAddress === true ? (
                              <EditAddress
                                onClickNewAddressBilling={showUserEditAddress}
                                type={1}
                                item={editAddressData}
                              />
                            ) : null}
                            {addresses?.map((item, i) => {
                              if (item.addressType === 'Delivery') {
                                return (
                                  <MDBCard
                                    className="rounded-3 user-address-item-container"
                                    key={item.title + i}
                                  >
                                    <MDBRow>
                                      <MDBCol
                                        lg="10"
                                        id="user-address-item-header"
                                      >
                                        <MDBTypography
                                          tag="span"
                                          className="mb-0 user-address-item-header"
                                        >
                                          {addresses[i].title}
                                        </MDBTypography>
                                      </MDBCol>
                                      <MDBCol
                                        lg="1"
                                        className="user-address-item-icon-parent-container edit-icon"
                                      >
                                        <div
                                          onClick={() => sendEditItems(item)}
                                          className="user-address-item-icon-container-edit"
                                        >
                                          <YImage
                                            imageSource={addressEditIc}
                                            inComingStyle={
                                              'user-address-item-icon-edit'
                                            }
                                          />
                                        </div>
                                      </MDBCol>
                                      <MDBCol
                                        lg="1"
                                        className="user-address-item-icon-parent-container"
                                      >
                                        <div
                                          onClick={() =>
                                            deleteAddressModalShow(
                                              true,
                                              addresses[0]
                                            )
                                          }
                                          className="user-address-item-icon-container"
                                        >
                                          <YImage
                                            imageSource={addressDeleteIc}
                                            inComingStyle={
                                              'user-address-item-icon'
                                            } /*onClick={() => onDeleteItemAddress(item.id)}*/
                                          />
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBTypography
                                      tag="span"
                                      className="mb-0 user-address-item-content-text"
                                    >
                                      {addresses[i].addressLine}
                                    </MDBTypography>
                                  </MDBCard>
                                );
                              }
                            })}
                            <MDBCard
                              className="rounded-3 justify-content-center align-items-center user-address-add-button"
                              onClick={addingNewDevlieryAddress}
                            >
                              <MDBRow>
                                <MDBCol
                                  lg="1"
                                  md={1}
                                  className="user-address-item-icon-col"
                                >
                                  <MDBIcon fas icon="plus me-2" />
                                </MDBCol>
                                <MDBCol
                                  lg="10"
                                  md={10}
                                  className="user-address-item-icon-col"
                                >
                                  <MDBTypography tag="span" className="mb-0">
                                    Yeni Teslimat Adresi Ekle
                                  </MDBTypography>
                                </MDBCol>
                              </MDBRow>
                            </MDBCard>
                          </MDBCol>
                          {/*<MDBCol lg="5">
                                                        <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                                                            <MDBTypography className="mb-0 user-address-headers">
                                                                Fatura Adreslerim
                                                            </MDBTypography>
                                                        </div>
                                                        <MDBCard className="rounded-3 justify-content-center align-items-center user-address-add-button" onClick={addingNewBillingAddress}>
                                                            <MDBRow>
                                                                <MDBCol lg="1" md={1} className='user-address-item-icon-col'><MDBIcon fas icon="plus me-2" /></MDBCol>
                                                                <MDBCol lg="10" md={10} className='user-address-item-icon-col'>
                                                                    <MDBTypography tag="span" className="mb-0 justify-content-center align-items-center text-align-center">
                                                                        Yeni Fatura Adresi Ekle
                                                                    </MDBTypography>
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCard>
                                                        {addresses?.map((item, i) => {
                                                            if (item.addressType === 'Billing') {
                                                                return (
                                                                    <MDBCard className="rounded-3 user-address-item-container" key={item.addressTitle + i}>
                                                                        <MDBRow >
                                                                            <MDBCol lg="10">
                                                                                <MDBTypography tag="span" className="mb-0 user-address-item-header">
                                                                                    {addresses[i].title}
                                                                                </MDBTypography>
                                                                            </MDBCol>
                                                                            <MDBCol lg="1">
                                                                                <div onClick={() => sendEditBillingItems(item)} className='user-address-item-icon-container'>
                                                                                    <YImage imageSource={addressEditIc} inComingStyle={'user-address-item-icon'} />
                                                                                </div>
                                                                            </MDBCol>
                                                                            <MDBCol lg="1">
                                                                                <div onClick={() => deleteAddressModalShow(true, addresses[0])} className='user-address-item-icon-container'>
                                                                                    <YImage imageSource={addressDeleteIc} inComingStyle={'user-address-item-icon'} />
                                                                                </div>
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                        <MDBTypography tag="span" className="mb-0 user-address-item-content-text">
                                                                            {addresses[i].addressLine}
                                                                        </MDBTypography>
                                                                    </MDBCard>
                                                                );
                                                            }
                                                        })}
                                                        
                                                        {showBillingAddress === true ?
                                                            <Address onClickNewAddressBilling={showUserBillingAddress} type={2} />
                                                            :
                                                            null
                                                        }

                                                        {editBillingAddress === true ?
                                                            <EditAddress onClickNewAddressBilling={showUserEditAddress} type={2} item={editBillingAddressData} />
                                                            :
                                                            null
                                                        }
                                                    </MDBCol>*/}
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YModal
        show={userAddressModals}
        onClose={() => setUserAddressModals(!userAddressModals)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <div>
          <div className="user-address-item-warn-userAddress">
            <YImage
              imageSource={WarnIcon}
              inComingStyle={'user-address-item-warn-userAddress'}
            />
          </div>
          <div className="address-title-alert-container">
            <p>
              Bu adresinize teslim edilecek devam eden bir alışverişiniz mevcut.
              Adresinizi silerseniz sepetinizdeki ürünlerin bazılarını
              kaybedebilirsiniz.
            </p>
          </div>
          <div className="address-alert-contorller-container">
            <div
              className="address-alert-accpet-container"
              onClick={() => onApprove()}
            >
              <p className="address-alert-text">Anladım, evet sil</p>
            </div>
            <div
              className="address-alert-cancel-container"
              onClick={() => cancelChange()}
            >
              <p className="address-alert-text">Vazgeç</p>
            </div>
          </div>
        </div>
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
