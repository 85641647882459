export function listenForOutsideClick(listening, setListening, ref, setIsOpen) {
  return () => {
    if (listening) return;
    if (!ref.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        if(evt.target.id == 's') return;
        if(evt.target.id == 'de') return;
        if (ref?.current?.contains(evt.target)) return;
        setIsOpen(false);
      });
    });
  }
}