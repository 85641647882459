import React from 'react';
import { MDBCol } from 'mdb-react-ui-kit';

// Components
import YTouchableContainer from '../../../Components/Basic/YTouchableContainer';
import YSingle from '../../../Components/Product/YSingle';

// Styles
import '../styles/productsStyles.css';

export default function Products(props) {
  const {
    data,
    inComingCardWidth,
    onEvent,
    basket,
    navigate,
    token,
    onLoginModal,
  } = props;

  return (
    <>
      {data?.map((data, index) => (
        <MDBCol key={index} size="md" md="4">
          <YTouchableContainer incomingStyle="touchable">
            <YSingle
              onEvent={onEvent}
              basket={basket}
              inComingCardWidth={inComingCardWidth}
              data={data}
              navigate={navigate}
              token={token}
              onLoginModal={onLoginModal}
            />
          </YTouchableContainer>
        </MDBCol>
      ))}
    </>
  );
}
