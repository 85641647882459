import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { MDBCol, MDBRow, MDBIcon } from 'mdb-react-ui-kit';

// Utils
import CategoryMenu from './CategoryMenu';
import Address from './Address';
import cookie from 'js-cookie';

// Components
import YImage from '../../Components/Basic/YImage';
import YInputSearch from '../../Components/Form/YInputSearch';
import YText from '../../Components/Basic/YText';
import YModal from '../../Components/Basic/YModal';
import { onChangeDistrictHandler } from '../../Components/Address/YAddressHandler';
import YBasket from '../../Components/Basket/YBasket';
import YMenu from '../../Components/Basket/YMenu';
import OrderTrackDetails from './OrderTrackDetails';
import useCheckMobileScreen from './CheckMobileScreen';

// Icons
import orderTrackIc from '../Images/header-order-track.png';
import headerUserkIc from '../Images/header-user.png';

// Redux
import { lastOrders } from '../../Redux/Actions/UserAction';

// Auth
import { AuthChecker } from '../Context/UserAuthChecker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

export default function Header(props) {
  const {
    basket,
    cartVisiable,
    setCartVisiable,
    navigate,
    totalPrice,
    onEvent,
    onDeleteItem,
    locationVisiable,
    setLocationVisiable,
    onSelectAddressMap,
    setUserAddressModal,
  } = props;

  const dispatch = useDispatch();

  const [mapError, setMapError] = useState('');

  const [searchKeyword, setSearchKeyword] = useState('');

  const [orderTrachNumber, setOrderTrackNumber] = useState('');

  const [orderTrackDetails, setOrderTrackDetails] = useState([]);

  const [sortState, setSortState] = useState(1);

  const [track, setTrack] = useState(1);

  const [reOrderState, setReOrderState] = useState(false);

  const [userAddress, setUSerAddress] = useState();

  const perviosOrders = useSelector(state => state.user.lastOrder);

  const province = useSelector(state => state.delivery.province);

  const token = AuthChecker();

  const MAX_LENGTH = 14;

  const address = cookie.get('addressText');

  const updateBasket = (item, e) => {
    onEvent(item, e, 1);
  };

  useEffect(() => {
    dispatch(lastOrders());
    if (typeof address !== 'undefined') {
      let str = address.replaceAll('"', '');
      setUSerAddress(str);
    }
  }, []);

  useEffect(() => {
    if (typeof address !== 'undefined') {
      let str = address.replaceAll('"', '');
      setUSerAddress(str);
    }
  }, [address]);

  const toggleCart = () => {
    setCartVisiable(!cartVisiable);
  };

  const toggleLocation = () => {
    if (token?.stsTokenManager?.accessToken != null) {
      setUserAddressModal(true);
    } else {
      setLocationVisiable(!locationVisiable);
      if (locationVisiable === true) {
        setSortState(1);
      }
    }
  };

  const toggleReorder = () => {
    setReOrderState(!reOrderState);
    setTrack(1);
  };

  const onSelectProvince = (e, text) => {
    setUSerAddress(text);
  };

  const onSelectDistrict = (e, text) => {
    onChangeDistrictHandler(e, text, userAddress, setUSerAddress);
  };

  const _onSelectAddressMap = (lat, lng, addressText) => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/store-around?pageNumber=1&pageSize=1&latitude=' +
          lat +
          '&longitude=' +
          lng,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        onSelectAddressMap(lat, lng, addressText);
        toggleLocation();
      })
      .catch(error => {
        setMapError(error.response.data.message);
      });
  };

  const onSubmit = (e, id, orderId) => {
    setOrderTrackNumber(orderId);
    e.preventDefault();
    const data = cookie.get('token');
    const token = JSON.parse(data);
    axios
      .get(process.env.REACT_APP_BASE_URL_V2 + 'order/' + id + '/status', {
        'Content-Type': 'text/plain',
        accept: 'application/json',
        headers: {
          Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
          clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
        },
      })
      .then(response => {
        setOrderTrackDetails(response.data.data);
        console.log(orderId, response.data.data);
      });
    setTrack(2);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      navigate({
        pathname: '/search',
        search: '?keyword=' + searchKeyword,
      });
    }
  };

  const onSearch = () => {
    if (searchKeyword !== '') {
      navigate({
        pathname: '/search',
        search: '?keyword=' + searchKeyword,
      });
    }
  };

  return (
    <div>
      <Navbar
        bg="light"
        expand="lg"
        className="header-top-menu d-none d-lg-block"
      >
        <Container className="header-top-menu-container-yellow">
          <Navbar.Brand href="/" className="header-top-logo-container">
            <YImage
              imageSource={require('../../Assets/Images/ozhan.png')}
              inComingStyle={'logo'}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px', padding: 0 }}
              navbarScroll
            ></Nav>

            {token === false ? (
              <Nav.Link
                onClick={() => navigate('/login')}
                className="d-none d-sm-block"
              >
                {/* Login - Not Logged */}
                <div className="header-order-track">
                  <div className="header-order-track-icon-left">
                    <YImage
                      imageSource={headerUserkIc}
                      inComingStyle={'header-login-not-logged-left-icon'}
                    />
                  </div>
                  <div className="header-order-track-text">
                    Giriş Yap / Üye Ol
                  </div>
                  <div className="header-order-track-icon-right">
                    <MDBIcon fas icon="angle-down" />
                  </div>
                </div>
              </Nav.Link>
            ) : (
              <>
                <Nav.Link
                  href="#javascript"
                  onClick={toggleReorder}
                  className="d-none d-sm-block"
                >
                  {/* Order Track */}
                  <div className="header-order-track">
                    <div className="header-order-track-icon-left">
                      <YImage
                        imageSource={orderTrackIc}
                        inComingStyle={'header-order-track-left-icon'}
                      />
                    </div>
                    <div className="header-order-track-text">
                      Sipariş Takibi
                    </div>
                    <div className="header-order-track-icon-right"></div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate('/user-information')}
                  className="d-none d-sm-block"
                >
                  {/* Login - Logged */}
                  <div className="header-order-track">
                    <div className="header-order-track-icon-left">
                      <YImage
                        imageSource={headerUserkIc}
                        inComingStyle={'header-login-not-logged-left-icon'}
                      />
                    </div>
                    <div className="header-login-logged-container">
                      <p className="header-login-logged-text">Hesabım</p>
                    </div>
                    <div className="header-order-track-icon-right"></div>
                  </div>
                </Nav.Link>
              </>
            )}
            <Nav.Link
              href="#"
              onClick={toggleReorder}
              className="d-block d-md-none"
            >
              Sipariş Takibi
            </Nav.Link>
            <Nav.Link href="/login" className="d-block d-md-none">
              Giriş Yap / Üye Ol
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={toggleLocation}
              className="d-block d-md-none"
            >
              Teslimat Adresi
            </Nav.Link>
            <div
              className="d-block d-md-none"
              onClick={() => navigate('/basket')}
            >
              <div href="#javascript" id="basket-mobile">
                <YImage
                  imageSource={require('../../Assets/Images/cart.png')}
                  inComingStyle={'basket-icon'}
                />
                <div>
                  <YText
                    value={'Sepetim'}
                    type={'p'}
                    incomingStyle={'basket-title-card'}
                  />
                  <YText
                    value={Number(totalPrice).toFixed(2) + ' TL'}
                    type={'p'}
                    incomingStyle={'basket-total-price-text-card'}
                  />
                </div>
              </div>
              {cartVisiable === true ? (
                <YBasket
                  basket={basket}
                  onDeleteItem={onDeleteItem}
                  updateBasket={updateBasket}
                  onEvent={onEvent}
                  navigate={navigate}
                />
              ) : null}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar bg="light" expand="lg" className="mobile-menu d-block d-lg-none">
        <Container className="mob-first-header-container">
          <Navbar.Brand href="/">
            <YImage
              imageSource={require('../../Assets/Images/ozhanlogo.png')}
              inComingStyle={'logo'}
            />
          </Navbar.Brand>
          <MDBRow>
            <MDBCol onClick={toggleReorder}>
              <YImage
                imageSource={require('../../Assets/Images/mob-track-order.png')}
                inComingStyle={'mobile-header-icon'}
              />
            </MDBCol>
            <MDBCol onClick={toggleLocation}>
              <YImage
                imageSource={require('../../Assets/Images/location.png')}
                inComingStyle={'mobile-location-icon'}
              />
            </MDBCol>
          </MDBRow>
        </Container>
        <Container fluid>
          <div className="mobile-address-finder-container">
            <YImage
              imageSource={require('../../Assets/Images/search.png')}
              inComingStyle={'search-mobile-icon'}
            />
            <input
              autoFocus
              placeholder={'Ara'}
              className={'form-control search-mob-box-input'}
              onChange={e => setSearchKeyword(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </div>
        </Container>
      </Navbar>
      <div className="mobile-menu d-block d-lg-none footer-mob-container">
        <a className="mobile-menu-item-container" href="/">
          <YImage
            imageSource={require('../../Assets/Images/mobile-home.png')}
            inComingStyle={'mobile-menu-icon-bottom-home'}
          />
          <p className="mobile-menu-item-text">Ana sayfa</p>
        </a>
        <a className="mobile-menu-item-container" href="/categories-mobile">
          <YImage
            imageSource={require('../../Assets/Images/mobile-cate.png')}
            inComingStyle={'mobile-menu-icon-bottom-cate'}
          />
          <p className="mobile-menu-item-text">Kategoriler</p>
        </a>
        <a className="mobile-menu-item-container" href="/basket">
          <YImage
            imageSource={require('../../Assets/Images/mobile-basket.png')}
            inComingStyle={'mobile-menu-icon-bottom-basket'}
          />

          <p className="mobile-menu-item-text">Sepetim</p>
          <div className="mobile-number-of-item-in-basket-container">
            <span id="basket-item-number">
              {basket?.length > 0 ? basket?.length : 0}
            </span>
          </div>
        </a>
        {/*<a className='mobile-menu-item-container' href='/campaign'>
                    <YImage imageSource={require('../../Assets/Images/mobile-camp.png')} inComingStyle={'mobile-menu-icon-bottom-home'}/>
                    <p className='mobile-menu-item-text'>Kampanya</p>
                </a>*/}
        <a className="mobile-menu-item-container" href="/user-information">
          <YImage
            imageSource={require('../../Assets/Images/mobile-user.png')}
            inComingStyle={'mobile-menu-icon-bottom-home'}
          />
          <p className="mobile-menu-item-text">Hesabım</p>
        </a>
      </div>
      {useCheckMobileScreen() !== true ? (
        <>
          <Navbar expand="lg">
            <Container className="header-second-height">
              <Navbar.Collapse id="navbarScroll">
                <MDBRow className="header-new-style-row">
                  <MDBCol size={5}>
                    {userAddress ? (
                      <MDBRow onClick={toggleLocation}>
                        <MDBCol size={6}>
                          <MDBRow className="location-container-box">
                            <MDBCol className="location-address-container-box">
                              <MDBRow>
                                <MDBCol size={2}>
                                  <YImage
                                    imageSource={require('../../Assets/Images/location.png')}
                                    inComingStyle={'location'}
                                  />
                                </MDBCol>
                                <MDBCol size={8}>
                                  <YText
                                    value={'Teslimat Adresi'}
                                    type={'p'}
                                    incomingStyle={'location-title-text'}
                                  />
                                  {userAddress.length > MAX_LENGTH ? (
                                    <YText
                                      value={`${userAddress.substring(
                                        0,
                                        MAX_LENGTH
                                      )}...`}
                                      type={'p'}
                                      incomingStyle={
                                        'location-title-text-selected'
                                      }
                                    />
                                  ) : (
                                    <>
                                      <YText
                                        value={userAddress}
                                        type={'p'}
                                        incomingStyle={
                                          'location-title-text-selected'
                                        }
                                      />
                                    </>
                                  )}
                                </MDBCol>
                                <MDBCol size={2}>
                                  <YImage
                                    imageSource={require('../../Assets/Images/down.png')}
                                    inComingStyle={'arrow-down-location'}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        <MDBCol size={6}>
                          <MDBRow className="location-container-box">
                            <MDBCol className="location-address-container-box">
                              <MDBRow size="2">
                                <YImage
                                  imageSource={require('../../Assets/Images/motor.png')}
                                  inComingStyle={'delivery-time-logo'}
                                />
                              </MDBRow>
                              <MDBRow size="10">
                                <YText
                                  value={'Tahmini Varış Süresi'}
                                  type={'p'}
                                  incomingStyle={'location-title-text-another'}
                                />
                                <YText
                                  value={'45 DK'}
                                  type={'p'}
                                  incomingStyle={
                                    'location-title-text-selected-greeen'
                                  }
                                />
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    ) : (
                      <MDBRow onClick={toggleLocation}>
                        <MDBCol size={6}>
                          <MDBRow className="location-container-box">
                            <MDBCol className="location-address-container-box">
                              <MDBRow>
                                <MDBCol size={2}>
                                  <YImage
                                    imageSource={require('../../Assets/Images/location.png')}
                                    inComingStyle={'location'}
                                  />
                                </MDBCol>
                                <MDBCol size={8}>
                                  <YText
                                    value={'Teslimat Adresi'}
                                    type={'p'}
                                    incomingStyle={'location-title-text'}
                                  />
                                  <YText
                                    value={'Konum seçiniz'}
                                    type={'p'}
                                    incomingStyle={
                                      'location-title-text-selected'
                                    }
                                  />
                                </MDBCol>
                                <MDBCol size={2}>
                                  <YImage
                                    imageSource={require('../../Assets/Images/down.png')}
                                    inComingStyle={'arrow-down-location'}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        <MDBCol size={6}>
                          <MDBRow className="location-container-box">
                            <MDBCol className="location-address-container-box">
                              <MDBRow size="2">
                                <YImage
                                  imageSource={require('../../Assets/Images/motor.png')}
                                  inComingStyle={'delivery-time-logo'}
                                />
                              </MDBRow>
                              <MDBRow size="10">
                                <YText
                                  value={'Tahmini Varış Süresi'}
                                  type={'p'}
                                  incomingStyle={'location-title-text-another'}
                                />

                                <YText
                                  value={'45 DK'}
                                  type={'p'}
                                  incomingStyle={
                                    'location-title-text-selected-greeen'
                                  }
                                />
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    )}
                  </MDBCol>
                  <MDBCol size={5}>
                    <div className="search-container-box">
                      <YInputSearch
                        name={'search'}
                        id={'search'}
                        placeholder={''}
                        type={'text'}
                        onKeyDown={handleKeyPress}
                        setSearchKeyword={setSearchKeyword}
                        onEvent={onSearch}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol size={2}>
                    <YMenu
                      totalPrice={totalPrice}
                      basket={basket}
                      toggleCart={toggleCart}
                      onDeleteItem={onDeleteItem}
                      updateBasket={updateBasket}
                      onEvent={onEvent}
                      navigate={navigate}
                    />
                  </MDBCol>
                </MDBRow>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Navbar expand="lg" className="menu-container-box">
            <Container>
              <Navbar.Collapse id="navbarScroll">
                <CategoryMenu menu={props.menu} />
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      ) : null}
      <YModal
        show={locationVisiable}
        onClose={toggleLocation}
        title={'Konum Seçme'}
        inComingStyleHeader={'address-modal-header'}
      >
        <Address
          sortState={sortState}
          province={province}
          onChangeProvince={onSelectProvince}
          onChangeDistrict={onSelectDistrict}
          onChangeMap={_onSelectAddressMap}
          mapError={mapError}
        />
      </YModal>
      <YModal
        show={reOrderState}
        onClose={toggleReorder}
        title={''}
        inComingStyleTitle={'modal-location-title-text'}
        inComingStyleHeader={'modal-reoder-header-border'}
        inComingStyleDialogModal={'modal-content-track-modal-container'}
      >
        <OrderTrackDetails
          onSubmit={onSubmit}
          track={track}
          token={token}
          perviosOrders={perviosOrders}
          orderTrachNumber={orderTrachNumber}
          orderTrackDetails={orderTrackDetails}
        />
      </YModal>
    </div>
  );
}
