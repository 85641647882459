export const onChangeProvinceHandler = (text , setUSerAddress) => {
    setUSerAddress(text);
};

export const onChangeDistrictHandler = (
        e,
        text,
        userAddress,
        setUSerAddress
    ) => {
    let newValue = userAddress;
    newValue = newValue + " - "+text;
    setUSerAddress(newValue);
};

export const onChangeNeighborhoodHandler = (
        e,
        text,
        setLocationVisiable,
        setNeighborhoodData,
        userAddress,
        setUSerAddress,
    ) => {
    setLocationVisiable(false);
    setNeighborhoodData(e);
    let newValue = userAddress;
    newValue = newValue + " - "+text;
    setUSerAddress(newValue);
};

export const onChangeShopListHandler = (
        e,
        text,
        setLocationVisiable,
        userAddress,
        setUSerAddress,
    ) => {
    setLocationVisiable(false);
    let newValue = userAddress;
    newValue = newValue + " - "+text;
    setUSerAddress(newValue);
};