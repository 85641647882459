import {
  SET_SEARCH,
  SET_PRODUCT,
  SET_RELATED_PRODUCT,
  SET_BRANDS,
  SINGLE_PRODUCT,
  DISCOUNT_PRODUCT,
  SET_MARKET,
  SET_CATEONE,
  SET_CATETWO,
  SET_SEARCH_CATEGORIES,
} from '../Constants/action-types';
import axios from 'axios';

export const getProduct = (categoryId, marketId, setategoriesItem) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'markets/' +
          Number(marketId) +
          '/products?categoryId=' +
          categoryId +
          '&pageSize=10',
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        setategoriesItem(response.data.data);
        dispatch({
          type: SET_PRODUCT,
          payload: response.data.data,
        });
      });
  };
};

export const getProductByCategory = (
  categoryId,
  marketId,
  sort,
  setShowLoading,
  currentPage
) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'markets/' +
          Number(marketId) +
          '/products?categoryId=' +
          categoryId +
          '&pageSize=1000&sortOrder=' +
          sort +
          '&pageNumber=' +
          currentPage,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        //console.log(response);
        dispatch({
          type: SET_PRODUCT,
          payload: response.data,
        });
        setShowLoading(false);
      });
  };
};

export const getRelatedProduct = () => {
  return async dispatch => {
    const dummyData = [
      {
        barcodes: '',
        name: 'Product1',
        productId: 1,
        thumbnailUrl:
          'https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg',
        unit: 'Kg',
        unitPrice: {
          amount: 19.9,
          previousAmount: 21.9,
          currency: 'TRY',
          discount: 9,
        },
      },
      {
        barcodes: '',
        name: 'Product1',
        productId: 1,
        thumbnailUrl:
          'https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg',
        unit: 'Kg',
        unitPrice: {
          amount: 19.9,
          previousAmount: 21.9,
          currency: 'TRY',
          discount: 9,
        },
      },
      {
        barcodes: '',
        name: 'Product1',
        productId: 1,
        thumbnailUrl:
          'https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg',
        unit: 'Kg',
        unitPrice: {
          amount: 19.9,
          previousAmount: 21.9,
          currency: 'TRY',
          discount: 9,
        },
      },
      {
        barcodes: '',
        name: 'Product1',
        productId: 1,
        thumbnailUrl:
          'https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg',
        unit: 'Kg',
        unitPrice: {
          amount: 19.9,
          previousAmount: 21.9,
          currency: 'TRY',
          discount: 9,
        },
      },
      {
        barcodes: '',
        name: 'Product1',
        productId: 1,
        thumbnailUrl:
          'https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg',
        unit: 'Kg',
        unitPrice: {
          amount: 19.9,
          previousAmount: 21.9,
          currency: 'TRY',
          discount: 9,
        },
      },
    ];
    dispatch({
      type: SET_RELATED_PRODUCT,
      payload: dummyData,
    });
  };
};

export const getBrands = () => {
  return async dispatch => {
    const dummyData = [
      { id: 1, brand: 'A Brand' },
      { id: 2, brand: 'B Brand' },
      { id: 3, brand: 'C Brand' },
      { id: 4, brand: 'D Brand' },
      { id: 5, brand: 'E Brand' },
      { id: 6, brand: 'F Brand' },
      { id: 7, brand: 'G Brand' },
    ];
    dispatch({
      type: SET_BRANDS,
      payload: dummyData,
    });
  };
};

export const getSingleProduct = (id, marketId, setShowLoading) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'product/' +
          id +
          '?marketId=' +
          Number(marketId),
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        setShowLoading(false);
        dispatch({
          type: SINGLE_PRODUCT,
          payload: response.data.data,
        });
      });
  };
};

export const getDiscountProduct = (marketId, setDiscountItems) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'market/' +
          Number(marketId) +
          '/discounted-products?pageSize=10',
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      ) // will need to be change 1 is market id
      .then(response => {
        setDiscountItems(response.data.data);
        dispatch({
          type: DISCOUNT_PRODUCT,
          payload: response.data.data,
        });
      });
  };
};

export const getMarketByGeoLocation = (lat, lng, toast) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/store-around?pageNumber=1&pageSize=1&latitude=' +
          lat +
          '&longitude=' +
          lng,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        //console.log(response);
        dispatch({
          type: SET_MARKET,
          payload: response.data.data,
        });
      })
      .catch(error => {});
  };
};

export const getSearch = (marketId, keyword, setShowLoading, currentPage) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/' +
          Number(marketId) +
          '/products?searchTerm=' +
          keyword +
          '&pageNumber=' +
          currentPage,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        //console.log(response);
        dispatch({
          type: SET_SEARCH,
          payload: response.data,
        });
        setShowLoading(false);
      })
      .catch(error => {
        dispatch({
          type: SET_SEARCH,
          payload: [],
        });
        setShowLoading(false);
      });
  };
};

export const getProductCateOne = (categoryId, marketId, setCateone) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'markets/' +
          Number(marketId) +
          '/products?categoryId=' +
          categoryId +
          '&pageSize=10',
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        setCateone(response.data.data);
        dispatch({
          type: SET_CATEONE,
          payload: response.data.data,
        });
      });
  };
};

export const getProductCateTwo = (
  categoryId,
  marketId,
  setCateTwo,
  setShowLoading
) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'markets/' +
          Number(marketId) +
          '/products?categoryId=' +
          categoryId +
          '&pageSize=10',
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        setShowLoading(false);
        setCateTwo(response.data.data);
        dispatch({
          type: SET_CATETWO,
          payload: response.data.data,
        });
      });
  };
};

export const getSearchGategories = (keyword, marketId) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'markets/' +
          Number(marketId) +
          '/products/search/' +
          keyword,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        dispatch({
          type: SET_SEARCH_CATEGORIES,
          payload: response.data.data,
        });
      });
  };
};

export const getSearchByCategory = (
  marketId,
  keyword,
  categoryId,
  setShowLoading,
  currentPage
) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'markets/' +
          Number(marketId) +
          '/products?categoryId=' +
          categoryId +
          '&productName=' +
          keyword +
          '&pageNumber=' +
          currentPage,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        console.log(response);
        dispatch({
          type: SET_SEARCH,
          payload: response.data,
        });
        setShowLoading(false);
      });
  };
};
