import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { MDBContainer } from 'mdb-react-ui-kit';
import axios from 'axios';

// Components
import YTheme from '../../Components/Layout/YTheme';
import Steps from './Components/Steps';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';
import YLoading from '../../Components/Basic/YLoading';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import { getDeliveryProductDateTime } from '../../Redux/Actions/DeliveryAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';
import { getMenu } from '../../Redux/Actions/MenuAction';

// Styles
import './styles/OrderStyles.css';
import 'react-toastify/dist/ReactToastify.css';

export default function Order() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [transferModalAddress, setTransferModalAddress] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [showLegal, setShowLegal] = useState(false);

  const [legalData, setLegalData] = useState('');

  const [step, setStep] = useState(4);

  const [newMarketId, setNewMarkerId] = useState(0);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [selectedUserAddressDelivery, setSelectedUserAddressDelivery] =
    useState(0);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  const menu = useSelector(state => state.menu.menu);

  const [formState, setFormState] = useState({
    cardName: '',
    cardNumber: '',
    cardExp: '',
    cardSecurity: '',
  });

  useEffect(() => {
    document.title = 'Ozhan Market ';
    dispatch(getDeliveryProductDateTime());
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket());
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onRenderContent = () => {
    let error = 0;
    if (step === 4) {
      if (selectedUserAddressDelivery === 0) {
        error = 1;
        toast.error('Lütfen teslimat adresi seçiniz!', {
          toastId: 'error1',
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
    //-----------
    if (error === 0) {
      setStep(step + 1);
    }
  };

  const prevousRenderContent = () => {
    setStep(step - 1);
  };

  const selectedAddress = id => {
    setSelectedUserAddressDelivery(id);
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onDeleteAddress = data => {
    dispatch(deleteAddress(data));
  };

  const startPayment = (data, bagNumber) => {
    setShowLoading(true);
    let cardNumber = data.cardNumber.replaceAll(' ', '');
    const date = data.cardExp.split('/');
    let year = '20' + date[1];
    let month = date[0];
    let param = {
      cardName: data.cardName,
      cardNumber: cardNumber,
      cardMonth: month,
      cardYear: year,
      cardSecurity: data.cardSecurity,
    };
    const tokenArray = cookie.get('token');
    const token = JSON.parse(tokenArray);
    axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'order/create',
        {
          bagQuantity: bagNumber,
          addressId: selectedUserAddressDelivery,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        let orderId = response.data.data.orderId;
        //dispatch(getOrderId(shopId , toast));
        //dispatch(payment(param , orderId , toast)); // need to be changed
        axios
          .post(
            process.env.REACT_APP_BASE_URL_V2 + 'payment/pay-3d',
            {
              fullName: param.cardName,
              cardNumber: param.cardNumber,
              expiryMonth: Number(param.cardMonth),
              expiryYear: Number(param.cardYear),
              cvv: param.cardSecurity,
              orderId: Number(orderId),
              callbackSuccessUrl: window.location.origin + '/order-details',
              callbackErrorUrl: window.location.origin + '/order-failed',
            },
            {
              'Content-Type': 'text/plain',
              accept: 'application/json',
              headers: {
                Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
                clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
              },
            }
          )
          .then(response => {
            var data = new Blob([response.data.htmlContent], {
              type: 'text/html',
            });
            var csvURL = window.URL.createObjectURL(data);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'filename.html');
            setShowLoading(false);
            window.location.replace(tempLink);
          })
          .catch(error => {
            setShowLoading(false);
            console.log(error.response.data.errors.innerExceptions[0]);
            if (error.response.data.errors.innerExceptions[0] !== null) {
              toast.error(error.response.data.errors.innerExceptions[0], {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            } else if (error.response.data.errors.innerExceptions[1] != null) {
              toast.error(error.response.data.errors.innerExceptions[1], {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            } else if (error.response.data.errors.innerExceptions[2] != null) {
              toast.error(error.response.data.errors.innerExceptions[2], {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            }
          });
      })
      .catch(error => {
        setShowLoading(false);
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const checkLegalContract = type => {
    setShowLoading(true);
    if (type === 1) {
      const data = cookie.get('token');
      const token = JSON.parse(data);
      axios
        .get(
          process.env.REACT_APP_BASE_URL_V2 +
            'legal/contract/sales?addressId=' +
            selectedUserAddressDelivery,
          {
            headers: {
              Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
              clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            },
          }
        )
        .then(response => {
          setLegalData(response.data.data.content);
          setShowLoading(false);
          setShowLegal(true);
        })
        .catch(error => {
          console.log(error);
        });
    } else if (type === 2) {
      const data = cookie.get('token');
      const token = JSON.parse(data);
      axios
        .get(
          process.env.REACT_APP_BASE_URL_V2 +
            'legal/contract/information-form?addressId=' +
            selectedUserAddressDelivery,
          {
            headers: {
              Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
              clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            },
          }
        )
        .then(response => {
          setLegalData(response.data.data.content);
          setShowLoading(false);
          setShowLegal(true);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <Steps
          id={step}
          userAddress={addressList}
          totalPrice={totalPrice}
          nextStepEvent={onRenderContent}
          basket={basket}
          navigate={navigate}
          selectedAddress={selectedAddress}
          formState={formState}
          setFormState={setFormState}
          onClickAddress={selectedAddress}
          selectedUserAddressDelivery={selectedUserAddressDelivery}
          prevousRenderContent={prevousRenderContent}
          onDeleteAddress={onDeleteAddress}
          startPayment={startPayment}
          setTransferModalAddress={setTransferModalAddress}
          transferModalAddress={transferModalAddress}
          newMarketId={newMarketId}
          setNewMarkerId={setNewMarkerId}
          showLoading={showLoading}
          setShowLegal={setShowLegal}
          showLegal={showLegal}
          checkLegalContract={checkLegalContract}
          legalData={legalData}
        />
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
    </YTheme>
  );
}
