export const kisiselVeriBeyanı =
`
<div class="">
<br/>
    <div _ngcontent-serverapp-c10="" class="">
        <h4 _ngcontent-serverapp-c10="" class="">Kişisel Verilerin İşlenmesi Rıza Beyanı</h4>
    </div> <br/>
    <div _ngcontent-serverapp-c10="" class="">
        <p>Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni”nde yer alan amaçlar doğrultusunda kişisel verilerim
            ile özel nitelikli kişisel verilerimin herhangi bir etki altında kalmaksızın veri sorumlusu sıfatıyla
            Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ. tarafından toplanmasına, kullanılmasına, işlenmesine ve
            yukarıda belirtilen mevzuat kapsamında paylaşımın gerekli olduğu diğer kişi ve kuruluşlar, istihbarat
            amacıyla önceki işverenler, bağlı ortaklıklarınız ve/veya doğrudan/dolaylı yurt içi/yurt dışı
            iştirakleriniz, Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ.’nin yerine getirilebilmesi için aldığı
            hizmetler çerçevesinde sözleşme imzaladığı kişiler ve onların işbirliği içinde olduğu üçüncü kişilerle
            ayrıca, Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ.’nin yasal yükümlülüklerini yerine getirmesi amacıyla
            yetkili mercilere aktarmasına açık bir şekilde rıza verdiğimi, kabul, beyan ve taahhüt ederim.</p>
    </div>
</div>
`