import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

export default function ContactLocation(props) {
    const { locationLng, locationLat } = props;

    const containerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: '16px',
    };

    const center = {
        lat: locationLat,
        lng: locationLng
    };

    return (
        <LoadScript
            googleMapsApiKey= {process.env.REACT_APP_MAP_KEY}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={16}
            >
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
}

//apiKey: 'AIzaSyCBgnTUaL3vvRyDhNXZdwb2EyPjttwnuOE'