import React from 'react';
import Carousel from 'react-multi-carousel';

import useCheckMobileScreen from '../../Assets/Utils/CheckMobileScreen';

import 'react-multi-carousel/lib/styles.css';

// Components
import YSingle from '../../Components/Product/YSingle';

// Styles
import './styles/YProductSliderStyles.css';

export default function YProductSlider(props) {
  const {
    data,
    showArrow,
    showDots,
    onEvent,
    basket,
    navigate,
    token,
    onLoginModal,
    useLocation,
  } = props;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <div
          className={currentSlide === 0 ? 'disable' : ''}
          onClick={() => previous()}
        />
        <div onClick={() => next()} />
        <div onClick={() => goToSlide(currentSlide + 1)}></div>
      </div>
    );
  };

  return (
    <div
      style={{
        paddingBottom: '30px',
        position: 'relative',
        left: '-2.8%',
      }}
    >
      <Carousel
        className="gd-carousel"
        responsive={responsive}
        removeArrowOnDeviceType={['mobile']}
        showDots={showDots}
        arrows={showArrow}
        containerClass="carousel-container"
        dotListClass="product-slider-dots"
        renderArrowsWhenDisabled={false}
        renderDotsOutside={true}
        partialVisible={true}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      >
        {data?.map((item, i) => {
          return (
            <div key={i + item.id} className="single-product-container-box">
              <YSingle
                data={item}
                onEvent={onEvent}
                basket={basket}
                navigate={navigate}
                token={token}
                onLoginModal={onLoginModal}
              />
            </div>
          );
        })}
      </Carousel>
      {useCheckMobileScreen() !== true ? (
        <div className="shadow-testing-last-item" />
      ) : null}
    </div>
  );
}
