import React from 'react';
// Styles
import './styles/YBannerStyles.css';

export default function YBanner(props) {
  const {
    styleBannerContainer,
    styleImageContainer,
    bannerImage,
    bannerImageAltText,
    event,
  } = props;

  // Const Texts
  const IMAGE_DOESNT_EXIST_URL =
    'https://yawawlblob.blob.core.windows.net/ozhan-banner-images/banner4.jpg';

  function handleClick() {
    console.log('Event problem');
  }

  return (
    <div
      className={
        styleBannerContainer ? styleBannerContainer : 'home-special-product-div'
      }
    >
      <img
        src={bannerImage ? bannerImage : IMAGE_DOESNT_EXIST_URL}
        alt={bannerImageAltText ? bannerImageAltText : 'Special Product'}
        className={
          styleImageContainer
            ? styleImageContainer
            : 'home-speacial-product-image'
        }
        onClick={event ? event : handleClick}
      />
    </div>
  );
}
