import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import cookie from 'js-cookie';

//Const
import Sample from '../../Assets/Images/sample.png';

// Components
import YText from '../Basic/YText';
import YAddToBasket from './YAddToBasket';
// Styles
import './styles/YSingleStyles.css';

export default function YDiscountHomePageItem(props) {
  const {
    inComingStyle,
    onEvent,
    data,
    basket,
    navigate,
    inComingCardWidth,
    token,
    onLoginModal,
  } = props;

  const inBasket = basket?.find(item => item.id === data.productId);

  const [basketVisible, setBasketVisible] = useState(false);

  const [count, setCount] = useState();

  const MAX_LENGTH = 24;

  useEffect(() => {
    if (typeof inBasket !== 'undefined') {
      let basketCount = inBasket.count;
      let result = basketCount.replace(',', '.');
      setCount(Number(result));
    } else {
      setCount(Number(0));
    }
  }, [inBasket]);

  useEffect(() => {
    if (basket?.length === 0 && typeof inBasket === 'undefined') {
      setCount(0);
    }
  }, [basket]);

  const upDateBasket = newCount => {
    onEvent(data, newCount);
  };

  const onClickProduct = (id, title) => {
    const marketId = cookie.get('marketId');
    navigate({
      pathname: '/single',
      search: '?productId=' + id + '&marketId=' + marketId,
    });
  };

  const onMouseLeaveProduct = () => {
    if (count === 0) {
      setBasketVisible(false);
    }
  };

  const currency_symbol = '₺';

  const formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });

  return (
    <Card
      id="product-align-mobile-search"
      className={inComingCardWidth ? inComingCardWidth : 'common-product-size'}
      onMouseEnter={() => setBasketVisible(true)}
      onMouseLeave={onMouseLeaveProduct}
    >
      <div onClick={() => onClickProduct(data.productId, data.name)}>
        {data.name.length > MAX_LENGTH ? (
          <YText
            incomingStyle={inComingStyle ? inComingStyle : 'CDiscountItemTitle'}
            value={`${data.name.substring(0, MAX_LENGTH)}...`}
            type={'h5'}
          />
        ) : (
          <YText
            incomingStyle={inComingStyle ? inComingStyle : 'CDiscountItemTitle'}
            value={data.name}
            type={'h5'}
          />
        )}
      </div>
      <div
        className="single-product-image-container"
        onClick={() => onClickProduct(data.productId, data.name)}
      >
        {data.thumbnailUrl !== '' && data.thumbnailUrl !== null ? (
          <div
            className="product-single-image"
            style={{ backgroundImage: `url(${data.thumbnailUrl})` }}
          />
        ) : (
          <div
            className="product-single-image"
            style={{ backgroundImage: `url(${Sample})` }}
          />
        )}
      </div>
      <Card.Body className="custom-single-body">
        {typeof inBasket?.count !== 'undefined' ? (
          <>
            {data.unitPrice?.discount !== 0 ? (
              <div
                className="priceArea-basket"
                onClick={() => onClickProduct(data.productId, data.name)}
              >
                <p className="make-it-work">
                  <span className="single-item-product-old-price">
                    {formattedOutput
                      .format(data.unitPrice?.previousAmount)
                      .replace(currency_symbol, '')}{' '}
                    TL
                  </span>
                </p>

                <YText
                  incomingStyle={
                    inComingStyle ? inComingStyle : 'newPrice-basket'
                  }
                  value={
                    formattedOutput
                      .format(data.unitPrice?.amount)
                      .replace(currency_symbol, '') + ' TL'
                  }
                  type={'h4'}
                />
              </div>
            ) : (
              <div
                className="priceArea-basket"
                onClick={() => onClickProduct(data.productId, data.name)}
              >
                <YText
                  incomingStyle={
                    inComingStyle ? inComingStyle : 'newPrice-basket'
                  }
                  value={
                    data.unitPrice?.discount !== 0
                      ? formattedOutput
                          .format(data.unitPrice?.previousAmount)
                          .replace(currency_symbol, '')
                      : formattedOutput
                          .format(data.unitPrice?.amount)
                          .replace(currency_symbol, '') + ' TL'
                  }
                  type={'h4'}
                />
              </div>
            )}
            <div className="generalAlignRight">
              <YAddToBasket
                unit={data.unit}
                count={count}
                upDateBasket={upDateBasket}
                incomingStyle={'product-go-left'}
                token={token}
                onLoginModal={onLoginModal}
              />
            </div>
          </>
        ) : (
          <>
            {basketVisible === true ? (
              <>
                {data.unitPrice?.discount !== 0 ? (
                  <div
                    className="priceArea-basket"
                    onClick={() => onClickProduct(data.productId, data.name)}
                  >
                    <p className="make-it-work">
                      <span className="single-item-product-old-price">
                        {formattedOutput
                          .format(data.unitPrice?.previousAmount)
                          .replace(currency_symbol, '')}{' '}
                        TL
                      </span>
                    </p>

                    <YText
                      incomingStyle={
                        inComingStyle ? inComingStyle : 'newPrice-basket'
                      }
                      value={
                        formattedOutput
                          .format(data.unitPrice?.amount)
                          .replace(currency_symbol, '') + ' TL'
                      }
                      type={'h4'}
                    />
                  </div>
                ) : (
                  <div
                    className="priceArea-basket"
                    onClick={() => onClickProduct(data.productId, data.name)}
                  >
                    <YText
                      incomingStyle={
                        inComingStyle ? inComingStyle : 'newPrice-basket'
                      }
                      value={
                        formattedOutput
                          .format(data.unitPrice?.amount)
                          .replace(currency_symbol, '') + ' TL'
                      }
                      type={'h4'}
                    />
                  </div>
                )}
                <div className="generalAlignRight">
                  <YAddToBasket
                    unit={data.unit}
                    count={count}
                    upDateBasket={upDateBasket}
                    incomingStyle={'product-go-left'}
                    token={token}
                    onLoginModal={onLoginModal}
                  />
                </div>
              </>
            ) : data.unitPrice?.discount !== 0 ? (
              <div
                className="priceArea price-discount-on-middile"
                onClick={() => onClickProduct(data.productId, data.name)}
              >
                <span className="single-item-product-old-price">
                  {formattedOutput
                    .format(data.unitPrice?.previousAmount)
                    .replace(currency_symbol, '')}{' '}
                  TL
                </span>

                <YText
                  incomingStyle={inComingStyle ? inComingStyle : 'newPrice'}
                  value={
                    formattedOutput
                      .format(data.unitPrice?.amount)
                      .replace(currency_symbol, '') + ' TL'
                  }
                  type={'h4'}
                />
              </div>
            ) : (
              <div
                className="priceArea"
                onClick={() => onClickProduct(data.productId, data.name)}
              >
                <YText
                  incomingStyle={inComingStyle ? inComingStyle : 'newPrice'}
                  value={
                    formattedOutput
                      .format(data.unitPrice?.amount)
                      .replace(currency_symbol, '') + ' TL'
                  }
                  type={'h4'}
                />
              </div>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}
