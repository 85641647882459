import React from 'react';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

export default function YTheme(props) {

    const {
        children,
    } = props;

    
    return(
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >
            {children}
        </ThemeProvider>
    );
};