import { SET_DETAILS_PRODUCT, SET_PAYMENT, SET_REPRODUCT } from '../Constants/action-types';
  
  const initialState = [
  
  ]

export default function peyment(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT:
      return { ...state, payment: action.payload };
    case SET_REPRODUCT:
      return { ...state, reorder: action.payload };
    case SET_DETAILS_PRODUCT:
      return { ...state, reorderdetails: action.payload };
    default:
      return state
  }
};
  