import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { MDBIcon } from 'mdb-react-ui-kit';

// Styles
import './styles/YStepStyles.css';

// Order Steps
const steps = ['Sipariş Alındı', 'Hazırlanıyor', 'Teslimata Çıkarıldı', 'Teslim Edildi'];

export default function YStep({ activeStep }) {
    const getStepLabelProps = (stepIndex, labelProps) => {
        if (activeStep > stepIndex) {
            labelProps.style = { color: '#4BD34B' };
        } else if (activeStep === stepIndex) {
            labelProps.style = { color: '#4BD34B' };
        }
        return labelProps;
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep ? activeStep : 0} alternativeLabel={false}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = getStepLabelProps(index, {});
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );

    function CustomStepIcon(props) {
        const { active, completed } = props;
        return (
            <div className={`step-icon ${active ? 'active' : completed ? 'completed' : ''}`}>
                {active || completed ? <MDBIcon far icon="check-circle" /> : <MDBIcon far icon="circle" />}
            </div>
        );
    }
}