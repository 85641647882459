import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';

// Components
import YTheme from '../../Components/Layout/YTheme';
import { MDBContainer } from 'mdb-react-ui-kit';

// Contracts
import { kvkk } from './Constants/kvkk.js';
import { bilgiGuvenligi } from './Constants/bilgiGuvenligi';
import { uyelikSozlesmesi } from './Constants/uyelikSozlesmesi';
import { gizlilikSozlesmesi } from './Constants/gizlilikPolitikası';
import { teslimatBolgesi } from './Constants/teslimatBolgesi';
import { kisiselVeriBeyanı } from './Constants/kisiselVeri';
import { WebMobilİzinBeyanı } from './Constants/webMobilBeyan';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import 'react-image-gallery/styles/css/image-gallery.css';

// Redux
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getAddress } from '../../Redux/Actions/UserAction';

export default function Contracts(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [deliveryType, setDeliveryType] = useState(0);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    if (typeof marketId !== 'undefined') {
      if (typeof tokenData !== 'undefined') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData, marketId]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const { search } = useLocation();
  const id = new URLSearchParams(search).get('id');

  const onSelectedContract = id => {
    switch (id) {
      case '1':
        return parse(kvkk);
      case '2':
        return parse(bilgiGuvenligi);
      case '3':
        return parse(uyelikSozlesmesi);
      case '4':
        return parse(gizlilikSozlesmesi);
      case '5':
        return parse(teslimatBolgesi);
      case '6':
        return parse(kisiselVeriBeyanı);
      case '7':
        return parse(WebMobilİzinBeyanı);
      default:
        return <p>Görüntülenemiyor.</p>;
    }
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        deliveryType={deliveryType}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        {onSelectedContract(id)}
      </MDBContainer>
      <Footer />
    </YTheme>
  );
}
