import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YButton from '../../Components/Basic/YButton';
import YCheckBox from '../../Components/Basic/YCheckBox';
import YImage from '../../Components/Basic/YImage';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Icons
import checkIc from '../../Assets/Icons/approve-ic.png';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import {
  getAddress,
  deleteAddress,
  setUserConset,
  getUserConset,
} from '../../Redux/Actions/UserAction';

// Styles
import './styles/UserInformationStyles.css';

// Utils
import UserMenu from '../../Assets/Utils/UserMenu';

export default function UserInformation() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const conset = useSelector(state => state.user.conset);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [consetCheck, setConsetCheck] = useState(0);

  const [email, setEmail] = useState('');

  const [name, setName] = useState('');

  const [locationVisiable, setLocationVisiable] = useState(false);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
          dispatch(getUserConset());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  useEffect(() => {
    setConsetCheck(conset.isEConsent);
  }, [conset]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const onChangeCheckBox = () => {
    setConsetCheck(!consetCheck);
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onChangeConset = () => {
    if (email.length >= 4) {
      if (!validateEmail(email)) {
        return toast.error('Geçerli bir eposta hesabı giriniz.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
    dispatch(setUserConset(name, email, consetCheck));
    return toast.success('Bilgileriniz başarıyla güncellendi', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="user-account-container">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol lg="3">
                    <UserMenu ativeMenu={'user-information'} />
                  </MDBCol>
                  <MDBCol lg="9">
                    <MDBCard className="rounded-3 user-address-container">
                      <MDBCardBody>
                        <MDBRow className="">
                          <MDBCol lg="12">
                            <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                              <MDBTypography className="mb-0 user-address-headers">
                                Üyelik Bilgilerim
                              </MDBTypography>
                            </div>
                            <MDBRow>
                              <MDBCol lg="12">
                                <MDBRow>
                                  <div className="user-account-information-name-container">
                                    <input
                                      type="text"
                                      className="user-account-information-email-input"
                                      placeholder={
                                        conset.fullName
                                          ? conset.fullName
                                          : 'Adınız ve Soyadınız'
                                      }
                                      onChange={e => setName(e.target.value)}
                                    />
                                  </div>
                                </MDBRow>
                                <MDBRow>
                                  <div className="user-account-information-email-container">
                                    <input
                                      disabled
                                      type="number"
                                      className="user-account-information-email-input"
                                      placeholder={conset.phoneNumber}
                                    />
                                  </div>
                                </MDBRow>
                                {conset.isPhoneNumberConfirmed === true ? (
                                  <div className="d-flex justify-content-start d-flex align-items-center user-account-approved-container">
                                    <YImage
                                      imageSource={checkIc}
                                      inComingStyle={
                                        'user-account-information-icon'
                                      }
                                    />
                                    <p className="user-account-approved-text">
                                      ONAYLI
                                    </p>
                                  </div>
                                ) : null}
                                <MDBRow className="">
                                  <MDBTypography className="user-account-phone-change-warn"></MDBTypography>
                                </MDBRow>
                              </MDBCol>
                              <MDBCol lg="12">
                                <MDBRow>
                                  <div className="user-account-information-email-container">
                                    <input
                                      type="text"
                                      className="user-account-information-email-input"
                                      placeholder={conset.email}
                                      onChange={e => setEmail(e.target.value)}
                                    />
                                  </div>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                            <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                              <MDBTypography className="mb-0 user-account-information-communication-preferences-text">
                                İletişim Tercihlerim
                              </MDBTypography>
                            </div>
                            <div className="user-information-communication-preferences">
                              <div className="d-flex address-link custom-control custom-checkbox">
                                <YCheckBox
                                  value={consetCheck}
                                  onEvent={onChangeCheckBox}
                                />
                                <p className="mb-2 user-info-margin-left">
                                  {conset.eConsentText}
                                </p>
                              </div>
                              <div className="user-information-save-changes-button-container">
                                <YButton
                                  onEvent={onChangeConset}
                                  text={'Değişiklikleri Kaydet'}
                                  inComingClassName={
                                    'user-account-checkbox-list-button'
                                  }
                                />
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
