import React, { useState } from 'react';
import {
  MDBCard,
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { Button, Alert } from 'react-bootstrap';
import cookie from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

// Styles
import './styles/YAddressListStyles.css';

//Redux
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import { getBasket } from '../../Redux/Actions/BasketAction';

// Components
import YImage from '../Basic/YImage';
import YMapComponent from '../Basic/YMapComponent';
import YAddAddress from './YAddAddress';

// Icons
import WarnIcon from '../../Assets/Icons/warnIcon.png';

export default function YAddressList(props) {
  const {
    addressList,
    onEvent,
    navigate,
    deleteAddress,
    dispatch,
    setBasketProductItem,
    setUserAddressModal,
  } = props;

  const [checkUser, setCheckUser] = useState(false);

  const [lat, setLat] = useState(false);

  const [lng, setLng] = useState(false);

  const [addressId, setAddressId] = useState(false);

  const [newMarketId, setNewMarketId] = useState(false);

  const [long, setLong] = useState();

  const [valuess, setValue] = useState(null);

  const [mapError, setmMapError] = useState('');

  const [showAddress, setShowAddress] = useState(false);

  const [showAddressAdding, setShowAddressAdding] = useState(false);

  const onClickNewAddress = () => {
    setShowAddress(!showAddress);
  };

  const addingNewDevlieryAddress = () => {
    setShowAddressAdding(true);
  };

  const onDeleteAddress = id => {
    dispatch(deleteAddress(id));
  };

  const onSelectAddress = item => {
    let market = cookie.get('marketId');
    axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/store-around?pageNumber=1&pageSize=1&latitude=' +
          item.latitude +
          '&longitude=' +
          item.longitude,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        let data = response.data.data;
        if (data.length >= 1) {
          let cartId = cookie.get('cartId');
          if (Number(cartId) > 0) {
            //console.log(data[0].marketId , market)
            if (data[0].marketId === Number(market)) {
              onEvent(item.addressId, item.latitude, item.longitude);
              cookie.set('addressText', JSON.stringify(item.title));
            } else {
              let cartId = cookie.get('cartId');
              if (Number(cartId) > 0) {
                setLat(item.latitude);
                setLng(item.longitude);
                setAddressId(item.setAddressId);
                console.log(item);
                cookie.set('addressText', JSON.stringify(item.title));
                setCheckUser(true);
                setNewMarketId(data[0].marketId);
              } else {
                onEvent(item.addressId, item.latitude, item.longitude);
                cookie.set('addressText', JSON.stringify(item.title));
              }
            }
          } else {
            // create card to new user
            const tokenP = cookie.get('token');
            cookie.set('addressText', JSON.stringify(item.title));
            const tempDataItem = cookie.get('tempDataItem');
            const tempItem = JSON.parse(tempDataItem);
            const token = JSON.parse(tokenP);

            axios
              .post(
                process.env.REACT_APP_BASE_URL + 'carts',
                {
                  marketId: data[0].marketId,
                },
                {
                  'Content-Type': 'text/plain',
                  accept: 'application/json',
                  headers: {
                    Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
                    clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
                  },
                }
              )
              .then(response => {
                cookie.set('basketId', JSON.stringify(true));
                onEvent(addressId, lat, lng);
                dispatch(
                  setBasketProductItem(tempItem, data[0].marketId, 1, toast)
                );
                cookie.remove('tempDataItem', {
                  path: '',
                  domain: 'localhost',
                });
                cookie.remove('tempDataItem', {
                  path: '',
                  domain: 'main.d2dy9h886ievkm.amplifyapp.com',
                });
              })
              .catch(error => {
                toast.error(error.response.data.message, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          }
        }
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
    //onEvent(item.addressId , item.latitude , item.longitude);
  };

  const cancelChange = () => {
    setCheckUser(false);
    //setUserAddressModal(false);
  };

  const onApprove = () => {
    const data = cookie.get('token');
    const token = JSON.parse(data);
    axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/' +
          newMarketId +
          '/transfer-cart',
        {},
        {
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        dispatch(getBasket());
        onEvent(1, lat, lng);
        cookie.set('marketId', newMarketId);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const _onSelectAddressMap = () => {
    console.log(lat, long);
    axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/store-around?pageNumber=1&pageSize=1&latitude=' +
          lat +
          '&longitude=' +
          long,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        let data = response.data.data;
        if (data.length >= 1) {
          let cartId = cookie.get('cartId');
          if (Number(cartId) > 0) {
            let market = cookie.get('marketId');
            if (data[0].marketId === Number(market)) {
              dispatch(getMarketByGeoLocation(lat, long, toast));
            } else {
              let cartId = cookie.get('cartId');
              if (Number(cartId) > 0) {
                setLat(lat);
                setLng(long);
                setCheckUser(true);
                setNewMarketId(data[0].marketId);
              } else {
                dispatch(getMarketByGeoLocation(lat, long, toast));
              }
            }
          } else {
            // create card to new user
            const tokenP = cookie.get('token');
            const tempDataItem = cookie.get('tempDataItem');
            const token = JSON.parse(tokenP);

            axios
              .post(
                process.env.REACT_APP_BASE_URL + 'carts',
                {
                  marketId: data[0].marketId,
                  UserId: 1,
                },
                {
                  'Content-Type': 'text/plain',
                  accept: 'application/json',
                  headers: {
                    Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
                    clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
                  },
                }
              )
              .then(response => {
                cookie.set('basketId', JSON.stringify(true));
                onEvent(addressId, lat, lng);
                if (typeof tempDataItem !== 'undefined') {
                  const tempItem = JSON.parse(tempDataItem);
                  dispatch(
                    setBasketProductItem(tempItem, data[0].marketId, 1, toast)
                  );
                }
                cookie.remove('tempDataItem', {
                  path: '',
                  domain: 'localhost',
                });
                cookie.remove('tempDataItem', {
                  path: '',
                  domain: 'main.d2dy9h886ievkm.amplifyapp.com',
                });
              })
              .catch(error => {
                console.log(error);
                toast.error(error.response.data.Message, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          }
        }
        dispatch(getMarketByGeoLocation(lat, long, toast));
        let market = cookie.get('marketId');
        if (Number(market) !== response.data.data[0].marketId) {
          console.log(response);
          setCheckUser(true);
          setLat(lat);
          setLng(long);
          setNewMarketId(response.data.data[0].marketId);
        } else {
          setUserAddressModal(false);
        }
      })
      .catch(error => {
        console.log(error);
        setmMapError(error.response.data.message);
      });
  };

  const showMap = () => {
    setLat('40.1885');
    setLong('29.0610');
  };

  const onChangeMap = async (
    lat,
    lng,
    city,
    districtName,
    neighborhood,
    street,
    streetNumber
  ) => {
    setLong(lng);
    setLat(lat);
    cookie.set('addressText', JSON.stringify(city + ', ' + districtName));
    setValue(city + ', ' + districtName);
  };

  const onChangeAddess = e => {
    geocodeByAddress(e.label)
      .then(results => {
        let diraction = getLatLng(results[0]);
        diraction.then(value => {
          setLong(value.lng);
          setLat(value.lat);
          console.log(e.label);
          cookie.set('addressText', JSON.stringify(e.label));
          setValue(e.label);
        });
      })
      .catch(error => console.error(error));
  };

  const onCloseAddADDressModal = () => {
    setShowAddressAdding(false);
  };

  if (checkUser === false) {
    return (
      <div>
        {addressList?.length > 0 ? (
          <>
            {showAddressAdding === true ? (
              <YAddAddress
                onClickNewAddressBilling={onCloseAddADDressModal}
                type={1}
              />
            ) : (
              <>
                {addressList?.map((item, i) => {
                  if (item.addressType === 'Delivery') {
                    return (
                      <MDBCard
                        className="rounded-3 user-address-item-container"
                        key={item.title + i}
                        onClick={() => onSelectAddress(item)}
                      >
                        <MDBRow>
                          <MDBCol lg="12" size="12">
                            <MDBTypography
                              tag="span"
                              className="mb-0 user-address-item-header"
                            >
                              {item.title}
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>
                        <MDBTypography
                          tag="span"
                          className="mb-0 user-address-item-content-text"
                        >
                          {item.addressTitle}
                        </MDBTypography>
                      </MDBCard>
                    );
                  } else {
                    return null;
                  }
                })}
                <MDBCard
                  className="rounded-3 justify-content-center align-items-center user-address-add-button"
                  onClick={addingNewDevlieryAddress}
                >
                  <MDBRow>
                    <MDBCol
                      lg="1"
                      md={1}
                      className="user-address-item-icon-col"
                    >
                      <MDBIcon fas icon="plus me-2" />
                    </MDBCol>
                    <MDBCol
                      lg="10"
                      md={10}
                      className="user-address-item-icon-col"
                    >
                      <MDBTypography tag="span" className="mb-0">
                        Yeni Teslimat Adresi Ekle
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </>
            )}
          </>
        ) : (
          <>
            <div className="google-auto-input-location">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                selectProps={{
                  placeholder: 'Lütfen adresinizi yazınız...',
                  valuess,
                  onChange: onChangeAddess,
                }}
              />
            </div>

            <div className="header-map-container">
              {long ? (
                <YMapComponent
                  lat={lat}
                  long={long}
                  setLat={setLat}
                  setLong={setLong}
                  onChangeMap={onChangeMap}
                />
              ) : (
                <>
                  <img
                    src={require('../../Assets/Images/map.jpg')}
                    alt={'map'}
                    className="header-map-address-image"
                    onClick={() => showMap()}
                  />
                  <img
                    src={require('../../Assets/Images/mmap.jpg')}
                    alt={'map'}
                    className="header-map-address-image-mobile"
                    onClick={() => showMap()}
                  />
                </>
              )}
            </div>
            {mapError !== '' ? (
              <Alert variant="danger" className="map-none-user-error">
                {mapError}
              </Alert>
            ) : null}
            <Button
              variant="primary"
              type="submit"
              className="w-100 mb-4 set-address-btn"
              onClick={() => _onSelectAddressMap()}
            >
              İşaretlediğim Konum ile Güncelle
            </Button>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="user-address-item-warn-yAddressList">
          <YImage
            imageSource={WarnIcon}
            inComingStyle={'user-address-item-warn-yAddressList'}
          />
        </div>
        <div className="address-title-alert-container">
          <p>
            Adresinizi değiştiriyorsunuz! Sepetinizde bulunan ürünleri
            kaybedebilirsiniz!
          </p>
        </div>
        <div className="address-alert-contorller-container">
          <div
            className="address-alert-accpet-container"
            onClick={() => onApprove()}
          >
            <p className="address-alert-text">Kabul Et</p>
          </div>
          <div
            className="address-alert-cancel-container"
            onClick={() => cancelChange()}
          >
            <p className="address-alert-text">İptal</p>
          </div>
        </div>
      </div>
    );
  }
}
