import {
  SET_COMMERCIAL_INFOS,
  SET_CONTACTOPTIONS,
} from '../Constants/action-types';

export const getCommercialInfos = () => {
  return async dispatch => {
    const dummyData = [
      {
        phoneNumber: '0 850 466 69 46',
        mail: 'iletisim@ozhan.com.tr',
        website: 'https://kurumsal.ozhan.com.tr/',
        locationName:
          'Özhan Marketler Zinciri - NOSAB N.309 Sk. No:2 Nilüfer / BURSA',
        locationLat: 40.23498,
        locationLng: 28.9339,
        billingInformation: 'xxx yyy zzz',
        TradeRegisterNumber: '763298456',
        MersisNumber: '123456789432134',
      },
    ];
    dispatch({
      type: SET_COMMERCIAL_INFOS,
      payload: dummyData,
    });
  };
};

export const getHelpOptions = () => {
  return async dispatch => {
    const dummyData = [
      { id: 1, name: 'Öneri' },
      { id: 2, name: 'KVKK Başvuru' },
      { id: 3, name: 'Şikayet' },
      { id: 4, name: 'Başvuru' },
      { id: 5, name: 'Teşekkür' },
      { id: 6, name: 'Ürün Önerme' },
    ];
    dispatch({
      type: SET_CONTACTOPTIONS,
      payload: dummyData,
    });
  };
};
