import { SET_PAYMENT, SET_REPRODUCT, SET_DETAILS_PRODUCT } from "../Constants/action-types";

export const getPayment = () => {
  return async (dispatch) => {
    const dummyData = [
      {
        id: 1,
        image: "https://dummyimage.com/200x150/000/fff&text=Item1",
        title: "Product1",
        price: 20.82,
        count: 3,
      },
      {
        id: 2,
        image: "https://dummyimage.com/200x150/000/fff&text=Item2",
        title: "Product2",
        price: 20,
        count: 1,
      },
      {
        id: 3,
        image: "https://dummyimage.com/200x150/000/fff&text=Item3",
        title: "Product3",
        price: 20,
        count: 10,
      },
      {
        id: 4,
        image: "https://dummyimage.com/200x150/000/fff&text=Item4",
        title: "Product4",
        price: 20,
        count: 5,
      },
      {
        id: 5,
        image: "https://dummyimage.com/200x150/000/fff&text=Item5",
        title: "Product5",
        price: 20,
        count: 2,
      },
    ];
    dispatch({
      type: SET_PAYMENT,
      payload: dummyData,
    });
  };
};



export const getReProduct = () => {
  return async (dispatch) => {
    const dummyData = [
      {
          id: 192,
          title: "APİKOĞLU FERMENTE PARMAK SUCUK KG",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0025835_apikoglu-fermente-parmak-sucuk-kg.jpeg",
          price: 0,
          discountPrice: 0,
          count: 0
      },
      {
          id: 34,
          title: "SARIYER GURME AYVALIK KIRMA ZEYTİN KG.",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0049455_sariyer-gurme-ayvalik-kirma-zeytn-kg.jpeg",
          price: 19.9,
          discountPrice: 19.9,
          count: 1
      },
      {
          id: 137,
          title: "ANAVARZA ÇIT KAPAK ÇİÇEK BALI 325 GR",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg",
          price: 24.9,
          discountPrice: 24.9,
          count: 1
      },
      {
          id: 137,
          title: "ANAVARZA ÇIT KAPAK ÇİÇEK BALI 325 GR",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg",
          price: 24.9,
          discountPrice: 24.9,
          count: 1
      }
  ];
    dispatch({
      type: SET_REPRODUCT,
      payload: dummyData,
    });
  };
};


export const getDetailsProduct = () => {
  return async (dispatch) => {
    const dummyData = [
      {
          id: 192,
          title: "APİKOĞLU FERMENTE PARMAK SUCUK KG",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0025835_apikoglu-fermente-parmak-sucuk-kg.jpeg",
          price: 0,
          discountPrice: 0,
          count: 0
      },
      {
          id: 34,
          title: "SARIYER GURME AYVALIK KIRMA ZEYTİN KG.",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0049455_sariyer-gurme-ayvalik-kirma-zeytn-kg.jpeg",
          price: 19.9,
          discountPrice: 19.9,
          count: 1
      },
      {
          id: 137,
          title: "ANAVARZA ÇIT KAPAK ÇİÇEK BALI 325 GR",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg",
          price: 24.9,
          discountPrice: 24.9,
          count: 1
      },
      {
          id: 137,
          title: "ANAVARZA ÇIT KAPAK ÇİÇEK BALI 325 GR",
          image: "https://storageyawa.blob.core.windows.net/yawa-pick-anonymous/products-v2/0064710_anavarza-325gr-cicek-bali-akilli-kapak.jpeg",
          price: 24.9,
          discountPrice: 24.9,
          count: 1
      }
  ];
    dispatch({
      type: SET_DETAILS_PRODUCT,
      payload: dummyData,
    });
  };
};