export const WebMobilİzinBeyanı = `
<div class="modal-content">
    <div _ngcontent-serverapp-c10="" class="modal-header">
        <h4 _ngcontent-serverapp-c10="" class="modal-title pull-left">Web ve Mobil İçin İletişim İzni Alınması</h4>
    </div>
    <div _ngcontent-serverapp-c10="" class="modal-body">
        <h3 class="contract-h3"><strong>1. VERİ SORUMLUSU</strong></h3>

        <p>Kişisel verileriniz veri sorumlusu sıfatıyla Öztaner Gıda Ltd. Şti. (“Özhan Marketler”) tarafından,
        <br>
        6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca aşağıda açıklanan kapsamda işlenebilecektir. Adı geçen her iki şirket
        birlikte “Şirketler” olarak anılacaktır.
        </p>

        <p>Kişisel verilerinizin Şirketler tarafından işlenme amaçları konusunda detaylı bilgiler, Ozhan için https://kurumsal.ozhan.com.tr/yasal-uyari/ ’den erişilebilen Kişisel Verilerin Korunması ve İşlenmesi Politikası’nda, Özhan Marketler için "<a href="https://kurumsal.ozhan.com.tr/assets/kvk/kvk_basvuru-formu.pdf">Buraya Tıklayarak</a>" 
        erişilebilen Kişisel Verilerin Korunması ve İşlenmesi Politikası’nda yer almaktadır.</p>

        <h3 class="contract-h3"><strong>2. KİŞİSEL VERİLERİNİZİN İŞLENME AMACI</strong></h3>

        <p>Toplanan kişisel verileriniz, Şirketler tarafından iletişim bilgilerinize genel ve size özel reklam,<br>
            promosyon gibi amaçlarla ticari elektronik ileti gönderilmesi ve yine bu amaçlarla sınırlı olmak üzere<br>
            analiz edilmesi amaçlarıyla, Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve<br>
            amaçları dahilinde işlenebilecektir.</p>

        <h3 class="contract-h3"><strong>3. KİŞİSEL VERİLERİNİZİN AKTARILDIĞI TARAFLAR VE AKTARIM AMAÇLARI</strong></h3>

        <p>Toplanan kişisel verileriniz, yukarıda açıklanan amaçlarla, Kanun’un 8. ve 9. maddelerinde belirtilen<br>
            kişisel veri işleme şartlarına uygun olarak Şirketler’in tedarikçilerine, kanunen yetkili kamu<br>
            kurumlarına ve yetkili özel kişilere aktarılabilecektir.</p>

        <h3 class="contract-h3"><strong>4. KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</strong></h3>

        <p>Kişisel verileriniz fiziksel ortamda mağaza, bayi vb kanallardan ve/veya elektronik ortamda internet<br>
            sitesi, mobil uygulama, çağrı merkezi vb kanallardan sözlü olarak vereceğiniz veya dolduracağınız<br>
            çeşitli formlar aracılığıyla ve bizimle temas kurduğunuz kanal üzerinden tarafınıza ticari elektronik<br>
            ileti gönderilmesi ve bu kapsamla kişisel verilerinizin analiz edilmesi amaçlarıyla, açık rızanıza
            ilişkin<br>
            hukuki sebep ile Şirketler’in meşru menfaatine dayalı olarak; Kanun’un 5. ve 6. maddelerinde<br>
            belirtilen kişisel veri işleme şartları ve amaçları doğrultusunda toplanmaktadır.</p>

        <h3 class="contract-h3"><strong>5. KİŞİSEL VERİ SAHİBİ OLARAK KANUN’UN 11. MADDESİNDE SAYILAN HAKLARINIZ</strong></h3>

        <p>Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:</p>

        <ul>
            <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını</li>
            <li>öğrenme,</li>
            <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
            <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve</li>
            <li>bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini</li>
            <li>isteme,</li>
            <li>Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,</li>
            <li>işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya</li>
            <li>yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü</li>
            <li>kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle</li>
            <li>aleyhinize bir sonucun ortaya çıkması durumunda buna itiraz etme,</li>
            <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın</li>
            <li>giderilmesini talep etme.</li>
        </ul>

        <p>Yukarıda sıralanan haklarınıza yönelik başvurularınızı,<br>
            - Ozhan için https://kurumsal.ozhan.com.tr/assets/kvk/kvk_basvuru-formu.pdf
            den ulaşabileceğiniz<br>
            Veri Sahibi Başvuru Formu’nu doldurarak,<br>
            - Özhan Marketler için "<a href="https://kurumsal.ozhan.com.tr/assets/kvk/kvk_basvuru-formu.pdf
            ">Buraya Tıklayarak</a>" ulaşabileceğiniz
            Veri Sahibi Başvuru<br>
            Formu’nu doldurarak<br>
            iletebilirsiniz.</p>

        <p>Talebinizin niteliğine göre en kısa sürede ve en geç otuz gün içinde başvurularınız ücretsiz olarak<br>
            sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma<br>
            Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep edilebilecektir.</p>
    </div>
</div> 
`;
