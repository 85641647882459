import React, { useState } from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTypography,
    MDBIcon,
    MDBInput
} from "mdb-react-ui-kit";
import classnames from "classnames";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import valid from 'card-validator';
import { ToastContainer, toast } from 'react-toastify';
import cookie from "js-cookie";
import axios from 'axios';
import { useDispatch } from 'react-redux';

// constants
import { isObjEmpty } from "../../../Assets/Utils/Utility";

// Components
import YButton from '../../../Components/Basic/YButton';
import YText from '../../../Components/Basic/YText';
import YImage from '../../../Components/Basic/YImage';
import Address from './Address';
import YModal from '../../../Components/Basic/YModal';
import PoshetModal from './PoshetModal';
import AddAddress from './AddAddress';

// Icons
import WarnIcon from "../../../Assets/Icons/warnIcon.png";
import Loading from "../../../Assets/Images/loading-gif.gif";

//Redux 
import { getBasket } from "../../../Redux/Actions/BasketAction";

// Styles
import './styles/StepsStyles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Steps(props) {

    const {
        id,
        userAddress,
        totalPrice,
        nextStepEvent,
        navigate,
        selectedAddress,
        formState,
        setFormState,
        onClickAddress,
        selectedUserAddressDelivery,
        prevousRenderContent,
        onDeleteAddress,
        startPayment,
        setTransferModalAddress,
        transferModalAddress,
        newMarketId,
        setNewMarkerId,
        basket,
        showLoading,
        setShowLegal,
        showLegal,
        checkLegalContract,
        legalData

    } = props;

    const SignInSchema = yup.object().shape({
        cardName: yup.string().required("Card holder name is reqiured").min(3),
        cardNumber: yup.string().test('test-number', // this is used internally by yup
            'Credit Card number is invalid', //validation message
            value => valid.number(value).isValid).required(),
        cardExp : yup.string().typeError('Not a valid expiration date. Example: MM/YY')
            .max(5, 'Not a valid expiration date. Example: MM/YY')
            .matches(
                /([0-9]{2})\/([0-9]{2})/,
                'Not a valid expiration date. Example: MM/YY'
            )
            .required('Expiration date is required'),
        cardSecurity : yup.string().required('Card security number is reqiured').min(3).max(4)
    });

    const { register, handleSubmit , formState: { errors } } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(SignInSchema),
    });

    const onSubmit = (e) => {
        e.preventDefault();
        if(formState.cardName.length < 2) {
            toast.error('Kart sahibinin adı soyadı gereklidir', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        } else {
            if(formState.cardNumber.length !== 19) {
                toast.error('Kart numarası gereklidir', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            } else {
                if(formState.cardExp.length !== 5) {
                    toast.error('Kart son kullanma tarihi gereklidir', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                } else {
                    if(formState.cardSecurity.length !== 3) {
                        toast.error('Kart CVC/CVV gereklidir', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                    } else {
                        if(term === false) {
                            toast.error('Lütfen kullanım şartılarını kabul ediniz', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            })
                        } else {
                            if(policy === false)
                            {
                                toast.error('Lütfen kullanım şartılarını kabul ediniz', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                })
                            } else {
                                //onConvertToOrder(poshet); // old code
                                startPayment(formState , poshet);
                            }
                        }
                    }
                }
            }
        }
    };

    const dispatch = useDispatch();

    const [showBank, setShowBank] = useState(true);

    const [poshet, setPoshet] = useState(1);

    const [term, setTerm] = useState(false);

    const [policy, setPolicy] = useState(false);

    const [poshetModal, setPoshetModal] = useState(false);

    const [showAddress, setShowAddress] = useState(false);

    const [showAddressBilling, setShowAddressBilling] = useState(false);

    const [addressTempInfo, setAddressTempInfo] = useState([]);

    const addNewBankCard = () => {
        //setShowBank(!showBank)
    }

    const onChangeTerm = () => {
        setTerm(!term)
    }

    const onChangePolicy = () => {
        setPolicy(!policy);
    };

    const onClickNewAddress = () => {
        setShowAddress(!showAddress);
    }

    const onClickPoshet = () => {
        setPoshetModal(!poshetModal);
    };
    //Card Number
    const [cardNumber, setCardNumber] = useState("");

    const handleChange = (e) => {
    // Remove non-numeric characters from input
    const inputValue = e.target.value.replace(/\D/g, "");
          
    // Insert space after every 4 digits
    const formattedValue = inputValue
        .replace(/(\d{4})/g, "$1 ")
        .trim()
        .slice(0, 19); // Limit input to 19 digits (16 digits + 3 spaces)
          
        setCardNumber(formattedValue);
    };

    const handleChangeP = (e) => {
        // Remove non-numeric characters from input
        const inputValue = e.replace(/\D/g, "");
              
        // Insert space after every 4 digits
        const formattedValue = inputValue
            .replace(/(\d{4})/g, "$1 ")
            .trim()
            .slice(0, 19); // Limit input to 19 digits (16 digits + 3 spaces)
            setFormState({
                ...formState,
                cardNumber: formattedValue,
            })
            setCardNumber(formattedValue);
        };
          
    const handleKeyDown = (e) => {
        // Allow deleting digits with backspace or delete key
        if (e.keyCode === 8 || e.keyCode === 46) {
            setCardNumber(cardNumber.slice(0, cardNumber.length));
        }
    };
   
    const [text,setText] = useState("")
    const [textCardExp,setTextCardExp] = useState("")
          
    const cardNameInput = e => {
        const { value } = e.target;
                
        const re = /^[a-zA-ZğüşöçİĞÜŞÖÇ ]+$/;
        if (value === "" || re.test(value)) {
            setText(value);
        }
    }
          
    const cardExpInput = e => {
          
    let {value} = e.target;
                     
    const re = /^[0-9/]*$/;
          
                  if ((value === "" || re.test(value))) {
                      setTextCardExp(value);
                    }
                 
                   if(value.length === 2 && re.test(value)){
                      let value2 = value.substring(0,2)+"/"+value.substring(2)
                      setTextCardExp(value2)
                     
                   }
                   if(value.length === 2 && textCardExp.includes("/") && re.test(value)){
                      let value2 = value.substring(0,2)+value.substring(2)
                      setTextCardExp(value2)
                     
                   }
                   if(value.length > 2 && textCardExp.includes("/") && re.test(value)){
                      let value2 = value.substring(0,2)+"20"+value.substring(2)
                      setTextCardExp(value2)
                     
                   }
                   if(value.length > 2 && re.test(value)){
                      setTextCardExp(value)
                   } 
    }

    //FOR CVC

    const [cvc, setCvc] = useState("");

    const allowCvv = (e) => {
        // Allow only 3-digit numbers for CVC input
        const regex = /^\d{0,3}$/;
        const cvvValue = e.target.value;
                
        if (regex.test(cvvValue)) {
            setCvc(cvvValue);
        }
    };

    const checkAddress = (item) => {
        nextStepEvent();
    };

    const onSelectAddressMiddleware = (item) => {
        setAddressTempInfo(item);
        let market = cookie.get("marketId");
        axios.get(process.env.REACT_APP_BASE_URL_V2+'market/store-around?pageNumber=1&pageSize=1&latitude='+item.latitude+'&longitude='+item.longitude , {
            headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY }
          })
        .then((response) => {
            let martketId = response.data.data[0].marketId;
            if(martketId !== Number(market)) {
                setTransferModalAddress(true);
                setNewMarkerId(martketId);
            } else {
                onClickAddress(item.addressId);
            }
        });
    };

    const onApprove = () => {
        const data = cookie.get("token");
        const token = JSON.parse(data);
        cookie.set("marketId", newMarketId);
        axios.post(process.env.REACT_APP_BASE_URL_V2+"market/"+newMarketId+"/transfer-cart" ,{}, {
          headers: { 
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY 
            }
        })
        .then((response) => {
            dispatch(getBasket());
            onClickAddress(addressTempInfo.addressId);
            setTransferModalAddress(false);
        })
        .catch(error => {
            console.log(error);
        });
    };

    const cancelChange = () => {
        setTransferModalAddress(false);
    };

    const discount = cookie.get("discount");

    const deliveryFee = cookie.get("deliveryFee");

    const amountOfFreeDelivery = cookie.get("amountOfFreeDelivery");

    const productTotalAmount = cookie.get("productTotalAmount");

    const renderContent = () => {
        switch(id) {
            case 5:
                return(
                    <MDBContainer className="py-0 h-100 step-order-container">
                        <MDBRow className="justify-content-center align-items-center h-100">
                           
                                <MDBCard className='basket-card'>
                                    <MDBRow>
                                        <MDBCol lg="2" className="basket-items-contorls-icon" onClick={() => prevousRenderContent()}>
                                            <MDBIcon fas icon="chevron-left"/> Adres
                                        </MDBCol>
                                        <MDBCol lg="10" className="basket-items-contorls-text">
                                            <span className='step-top-counter-box'>1</span>
                                            <span className='step-top-text-ative'>ADRES</span>
                                            <YImage imageSource={require('../../../Assets/Images/arrow-left-yellow.png')} inComingStyle={'arrow-left-yellow'}/>
                                            <span className='step-top-counter-box active-box-margin-top-order'>2</span> 
                                            <span className='step-top-text-tive'>ÖDEME</span>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol lg="12">
                                            <p className="basket-title-text-head">Ödeme</p>
                                        </MDBCol>
                                    </MDBRow>
                                <MDBCardBody className="basket-item-container-no-padding">
                                    
                                        <MDBRow>
                                            <MDBCol lg="4">
                                                <p className='type-payment-text-title'>Ödeme yöntemi seçin</p>
                                                <MDBCard className="rounded-3 step-adddress-box-container payment-card-section-border-color">
                                                    <MDBCardBody className=''>
                                                        <MDBRow>
                                                            <MDBCol lg="1" size="1">
                                                                <input type="radio" id={'onlinepay'} name="deliveryAddress" value={1} checked="checked"/>
                                                            </MDBCol>
                                                            <MDBCol lg="11" size="11">
                                                                <p className='type-delivery-text'>Kredi kartı ile online öde</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCardBody>
                                            </MDBCard>
                                               <MDBCard className="rounded-3 step-adddress-box-container payment-card-section-border-color">
                                                    <MDBCardBody className=''>
                                                        <MDBRow>
                                                          
                                                            <MDBCol lg="11" size="11">
                                                                <p className='type-delivery-text'>Kurumsal satışımız yoktur</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                            <MDBCol lg="4">
                                                <p className='type-payment-text-title'>Kartlarım</p>
                                                {/*<MDBCard className="rounded-3 step-adddress-box-container">
                                                    <MDBCardBody>
                                                        <MDBRow>
                                                            <MDBCol lg="1" size="1">
                                                                <input type="radio" id={'user-card'} name="user-card" value={1} checked="checked" />
                                                            </MDBCol>
                                                            <MDBCol lg="9" size="8" className='card-name-holder-container'>
                                                                <span className='card-name-holder-text'>Oğulcan Yılmaz</span><br/>
                                                                <span className='card-name-holder-text-card-number'>4444-****-****-4444</span>
                                                            </MDBCol>
                                                            <MDBCol lg="2" size="3">
                                                                <YImage imageSource={require('../../../Assets/Images/master.png')} inComingStyle={'bankLogo'}/>
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <div className='peyment-delete-container' onClick={() => console.log('')}>
                                                            <YImage imageSource={require('../../../Assets/Images/delete.png')} inComingStyle={'card-delete'} />
                                                        </div>
                                                    </MDBCardBody>
                                                </MDBCard>*/}
                                                {showBank === true ?
                                                    <MDBCard className="rounded-3 payment-card-section-border-color">
                                                        <MDBCardBody>
                                                            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                                                                <MDBInput 
                                                                    className={classnames({ "is-invalid": errors["cardName"] } , 'mb-4 card-input') }
                                                                    type="text"
                                                                    value={text} 
                                                                    maxLength={250}
                                                                    size="sm"
                                                                    name="cardName"
                                                                    id="cardName"
                                                                    {...register('cardName')} 
                                                                    placeholder="Kart üzerindeki isim*" 
                                                                    contrast
                                                                    onChange={(e) =>{
                                                                        setFormState({
                                                                            ...formState,
                                                                            [e.target.name]: e.target.value,
                                                                        }); 
                                                                        cardNameInput(e)
                                                                    }
                                                                     
                                                                    }
                                                                />
                                                                {errors.cardName?.message && <p className="error">{errors.cardName?.message}</p>}

                                                                <MDBInput 
                                                                    className={classnames({ "is-invalid": errors["cardNumber"] } , 'mb-4 card-input') }
                                                                    type="text"
                                                                    maxLength={19}
                                                                    size="sm"
                                                                    name="cardNumber"
                                                                    id="cardNumber"
                                                                    value={cardNumber}
                                                                    {...register('cardNumber')} 
                                                                    placeholder="Kredi kartı numarası*"
                                                                    onPaste={(e) => {
                                                                        setFormState({
                                                                            ...formState,
                                                                            cardNumber: e.clipboardData.getData('Text'),
                                                                        })
                                                                        handleChangeP(e.clipboardData.getData('Text'))
                                                                    }}
                                                                    contrast
                                                                    onChange={(e) => {
                                                                        setFormState({
                                                                            ...formState,
                                                                            [e.target.name]: e.target.value,
                                                                        })
                                                                        handleChange(e)
                                                                    }
                                                                    }
                                                                    onKeyDown={handleKeyDown}

                                                                />
                                                                {errors.cardNumber?.message && <p className="error">{errors.cardNumber?.message}</p>}

                                                                <MDBRow className="mb-4">
                                                                    <MDBCol md="4">
                                                                        <MDBInput
                                                                            className={classnames({ "is-invalid": errors["cardExp"] } , 'mb-4 card-input') }
                                                                            type="text"
                                                                            onInput={(e) => e.target.value = e.target.value.slice(0, 7)}                                                                                
                                                                            size="sm"
                                                                            value={textCardExp}
                                                                            placeholder="MM/YY"
                                                                            name="cardExp"
                                                                            id="cardExp"
                                                                            {...register('cardExp')} 
                                                                            contrast
                                                                            onChange={(e) => {
                                                                                setFormState({
                                                                                    ...formState,
                                                                                    [e.target.name]: e.target.value,

                                                                                })
                                                                                cardExpInput(e)
                                                                            }
                                                                            }
                                                                        />
                                                                        {errors.cardExp?.message && <p className="error">{errors.cardExp?.message}</p>}

                                                                    </MDBCol>
                                                                    <MDBCol md="4">
                                                                        <MDBInput
                                                                            className={classnames({ "is-invalid": errors["cardSecurity"] } , 'mb-4 card-input') }
                                                                            type="text"
                                                                            size="sm"
                                                                            maxLength={3}
                                                                            value={cvc}
                                                                            name="cardSecurity"
                                                                            id="cardSecurity"
                                                                            placeholder="CVV"
                                                                            contrast
                                                                            {...register('cardSecurity')} 
                                                                            onChange={(e) => {
                                                                                setFormState({
                                                                                    ...formState,
                                                                                    [e.target.name]: e.target.value,
                                                                                })
                                                                                allowCvv(e)
                                                                                }
                                                                            }
                                                                        />
                                                                        {errors.cardSecurity?.message && <p className="error">{errors.cardSecurity?.message}</p>}

                                                                    </MDBCol>
                                                                    <MDBCol md="4">
                                                                        <MDBRow>
                                                                            <MDBCol size="4">
                                                                                <YImage imageSource={require('../../../Assets/Images/master.png')} inComingStyle={'bank-logo-bottom'}/>
                                                                            </MDBCol>
                                                                            <MDBCol size="4">
                                                                                <YImage imageSource={require('../../../Assets/Images/visa.png')} inComingStyle={'bank-logo-bottom'}/>
                                                                            </MDBCol>
                                                                            <MDBCol size="4">
                                                                                <YImage imageSource={require('../../../Assets/Images/express.png')} inComingStyle={'bank-logo-bottom'}/>
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </MDBCol>
                                                                </MDBRow>
                                                            </form>
                                                        </MDBCardBody>
                                                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                                                            <MDBRow className="d-flex justify-content-center align-items-center" >
                                                                {/*
                                                                <MDBCol className='step-add-remove-address' onClick={() => addNewBankCard()}>
                                                                    <MDBIcon fas icon="times me-1 delete-address-icon" size={'1x'}/>
                                                                        </MDBCol>
                                                                <MDBCol className='step-add-add-address' onClick={() => addNewBankCard()}>
                                                                    <MDBIcon fas icon="check me-2 add-address-icon" size={'1x'}/>
                                                                </MDBCol>
                                                                        */}
                                                            </MDBRow>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                :
                                                    <MDBCard className="rounded-3 step-adddress-box-container step-no-border payment-card-section-border-color">
                                                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                                                            <MDBRow className="d-flex justify-content-center align-items-center step-add-new-address" onClick={() => addNewBankCard()}>
                                                                <MDBIcon fas icon="plus me-2" style={{textAlign : 'center'}} size={'1x'}/>
                                                            </MDBRow>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                }

                                            </MDBCol>
                                            <MDBCol id='payment-aleignment-problem-fix' lg="4">
                                                <MDBCard className="rounded-3 payment-card-section-border-color">
                                                    <MDBCardBody>
                                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                                        <MDBTypography tag="h5" className="mb-0">
                                                            Sepet Özeti
                                                        </MDBTypography>
                                                        <p className="basket-title-sub">{basket?.length} Ürün</p>
                                                        </div>
                                                        <hr />

                                                        <form className="mt-4" onSubmit={onSubmit}>

                                                            <div className="d-flex justify-content-between order-text-hessab">
                                                                <p className="mb-2">Ürünlerin Toplam Tutarı</p>
                                                                <p className="mb-2">{Number(productTotalAmount).toFixed(2)} TL</p>
                                                            </div>

                                                            {Number(productTotalAmount) !== 0 ?
                                                            <>
                                                                <div className="d-flex justify-content-between">
                                                                        <p className="mb-2">Teslimat Tutarı</p>
                                                                        <p className="mb-2">{Number(deliveryFee).toFixed(2)} TL</p>

                                                                </div>
                                                                <span className='teslimQouteText'>{amountOfFreeDelivery} TL değerindeki siparişinize teslimat ücretsizdir.</span>
                                                            </>
                                                            : null }


                                                            {Number(discount) !== 0 ?
                                                            <div className="d-flex justify-content-between basket-discount-text">
                                                                <p className="mb-2">Uygulanan İndirim</p>
                                                                <p className="mb-2">{Number(discount).toFixed(2)} TL</p>
                                                            </div>
                                                            : null }

                                                            {Number(productTotalAmount) !== 0 ?
                                                                <div className="d-flex justify-content-between">
                                                                    <p className="mb-2 total-price-text-basket-text">Ödenecek Tutar</p>
                                                                    <p className="mb-2">{Number(Number(totalPrice) + Number(poshet * 0.25)).toFixed(2)} TL</p>
                                                                </div>
                                                            :
                                                                <div className="d-flex justify-content-between">
                                                                    <p className="mb-2 total-price-text-basket-text">Ödenecek Tutar</p>
                                                                    <p className="mb-2">0.00 TL</p>
                                                                </div>
                                                            }

                                                            <div className='order-text-total-width-border'>
                                                                <div className="d-flex justify-content-between ">
                                                                    <p className="mb-2">Poşet Ücreti <span className='count-of-poshet'>({poshet} adet)</span></p>
                                                                    <p className="mb-2">{poshet *0.25} TL</p>
                                                                </div>
                                                                <p className='mb-12 change-poshet-count' onClick={() => onClickPoshet()}>Artır</p>
                                                            </div>




                                                            <div className="d-flex justify-content-between custom-radio-container">
                                                                <p id='adress-steps-margin-need-checkbox' className="mb-12">
                                                                    <input type="checkbox" id={'termOfUse'} name="termOfUse" value={1} onClick={() => onChangeTerm()}/>
                                                                    <span className='order-term-use'>
                                                                        <span className='order-term-of-use-link' onClick={() => checkLegalContract(2)}>Bilgilendirme Formu</span>'nu okudum, onaylıyorum.
                                                                    </span>
                                                                </p>
                                                            </div>

                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-12">
                                                                    <input type="checkbox" id={'privacyPolicy'} name="privacyPolicy" value={1} onClick={() => onChangePolicy()}/>
                                                                    <span className='order-term-use'>
                                                                        <span className='order-term-of-use-link' onClick={() => checkLegalContract(1)}>Mesafeli Satış Sözleşmesi</span>’ni okudum, onaylıyorum.
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            {showLoading === false ?
                                                                <Button variant="primary" type="submit" className='order-submit'>
                                                                    Devam
                                                                </Button>
                                                            : 
                                                                <Button variant="primary" type="button" className='order-submit'>
                                                                    <YImage imageSource={Loading} inComingStyle={'payment-order-icon-loading'}/>
                                                                </Button>
                                                            }
                                                        </form>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                        </MDBRow>
                        <YModal 
                            show={poshetModal} 
                            onClose={onClickPoshet}
                            title={''}
                            inComingStyleHeader={'address-modal-header'}
                        >
                            <PoshetModal poshet={poshet} setPoshet={setPoshet}/>
                        </YModal>
                        <YModal 
                            show={showLegal} 
                            onClose={() => setShowLegal(!showLegal)}
                            title={''}
                            inComingStyleHeader={'address-modal-header'}
                            inComingStyleDialogModal={'modalContract'}
                        >
                            { <div dangerouslySetInnerHTML={{ __html: legalData }} /> }
                            <Button variant="primary" type="button" className='order-submit' onClick={() => setShowLegal(false)}>
                                Kapat
                            </Button>
                        </YModal>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                    </MDBContainer>
                );
            case 4:
                return(
                    <MDBContainer className="py-0 h-100 step-order-container">
                        <MDBRow className="justify-content-center align-items-center h-100">
                            <MDBCol>
                                <MDBCard className='basket-card'>
                                    <MDBRow>
                                        <MDBCol lg="2" className="basket-items-contorls-icon-my-basket" onClick={() => navigate('/')}>
                                            <MDBIcon fas icon="chevron-left" /> Alışverişe Devam Et
                                        </MDBCol>
                                        <MDBCol lg="10" className="basket-items-contorls-text">
                                            <span className='step-top-counter-box'>1</span>
                                            <span className='step-top-text-ative'>ADRES</span>
                                            <YImage imageSource={require('../../../Assets/Images/arrow-left.png')} inComingStyle={'arrow-left'}/>
                                            <span className='step-top-counter-box-ative'>2</span> 
                                            <span className='step-top-text-not-ative'>ÖDEME</span>
                                        </MDBCol>
                                    </MDBRow>
                                <MDBRow>
                                    <MDBCol lg="12">
                                        <p className="basket-title-text-head">Adres</p>
                                    </MDBCol>
                                </MDBRow>
                                    <div >
                                        <MDBRow>
                                            <MDBCol lg="8">
                                                <YText type={'h4'} value={'Teslimat Adresi'} incomingStyle={'step-text-deliver-at-home'} />
                                            </MDBCol>
                                            {/*
                                            <MDBCol lg="4">
                                                <YText type={'h4'} value={'Fatura adresi'} incomingStyle={'step-text-deliver-at-home'} />
                                                <input type="radio" id={'sameaddress'} name="sameaddress" className='same-address-radio' value={1} />
                                                <span className='same-address-text'>Fatura adresim aynı</span>
                                            </MDBCol>*/}
                                            <MDBCol lg="4"></MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol lg="8">
                                                {showAddress === true ?  <AddAddress onClickNewAddressBilling={onClickNewAddress} type={1}/> : null }

                                                <Address 
                                                    userAddress={userAddress} 
                                                    selectedAddress={selectedAddress} 
                                                    onClickAddress={onSelectAddressMiddleware} 
                                                    selectedUserAddressDeliveryBilling={selectedUserAddressDelivery}
                                                    name={'userHomeAddress'}
                                                    onDeleteAddress={onDeleteAddress}
                                                    type="Delivery"
                                                />
                                                {showAddress === false ?
                                                    <MDBCard className="rounded-3 step-adddress-box-container step-no-border">
                                                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                                                            <MDBRow className="d-flex justify-content-center align-items-center step-add-new-address" onClick={() => onClickNewAddress()}>
                                                                <MDBIcon fas icon="plus me-2" style={{textAlign : 'center'}} size={'1x'}/>
                                                            </MDBRow>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                : 
                                                    null
                                                }
                                            </MDBCol>
                                            {/*
                                            <MDBCol style={{marginTop:"-10px"}} lg="4">
                                                <Address 
                                                    userAddress={userAddress} 
                                                    selectedAddressBilling={selectedAddress} 
                                                    onClickAddress={onClickAddressBilling} 
                                                    selectedUserAddressDeliveryBilling={selectedUserAddressDeliveryBilling}
                                                    name={'userBillingAddress'}
                                                    onDeleteAddress={onDeleteAddress}
                                                    type="Billing"
                                                />
                                                {showAddressBilling === false ?
                                                    <MDBCard className="rounded-3 step-adddress-box-container step-no-border">
                                                        <MDBCardBody className="d-flex justify-content-center align-items-center">
                                                            <MDBRow className="d-flex justify-content-center align-items-center step-add-new-address" onClick={() => onClickNewAddressBilling()}>
                                                                <MDBIcon fas icon="plus me-2" style={{textAlign : 'center'}} size={'1x'}/>
                                                            </MDBRow>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                :
                                                    <AddAddress onClickNewAddressBilling={onClickNewAddressBilling} type={2}/>
                                                }
                                            </MDBCol>*/}
                                            <MDBCol lg="4">
                                                <MDBCard className="rounded-3">
                                                    <MDBCardBody className='basket-cart-body'>
                                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                                        <MDBTypography tag="h5" className="mb-0">
                                                            Sepet Özeti
                                                        </MDBTypography>
                                                        <p className="basket-title-sub">{basket?.length} Ürün</p>
                                                        </div>
                                                        <hr />

                                                        <div className="d-flex justify-content-between basket-total-first">
                                                            <p className="mb-2">Ürünlerin Toplam Tutarı</p>
                                                            <p className="mb-2">{Number(productTotalAmount).toFixed(2)} TL</p>
                                                        </div>

                                                        {Number(productTotalAmount) !== 0 ?
                                                            <>
                                                                <div className="d-flex justify-content-between">
                                                                        <p className="mb-2">Teslimat Tutarı</p>
                                                                        <p className="mb-2">{Number(deliveryFee).toFixed(2)} TL</p>

                                                                </div>
                                                                <span className='teslimQouteText'>{amountOfFreeDelivery} TL değerindeki siparişinize teslimat ücretsizdir.</span>
                                                            </>
                                                        : null }


                                                        {Number(discount) !== 0 ?
                                                            <div className="d-flex justify-content-between basket-discount-text">
                                                                <p className="mb-2">Uygulanan İndirim</p>
                                                                <p className="mb-2">{Number(discount).toFixed(2)} TL</p>
                                                            </div>
                                                        : null }


                                                        {Number(productTotalAmount) !== 0 ?
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-2 total-price-text-basket-text">Ödenecek Tutar</p>
                                                                <p className="mb-2">{Number(totalPrice).toFixed(2)} TL</p>
                                                            </div>
                                                        :
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-2 total-price-text-basket-text">Ödenecek Tutar</p>
                                                                <p className="mb-2">0.00 TL</p>
                                                            </div>
                                                        }

                                                        <YButton text={'Devam'} onEvent={checkAddress} inComingClassName={'basket-btn-devam'}/>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <YModal 
                            show={transferModalAddress} 
                            onClose={() => setTransferModalAddress(!transferModalAddress)}
                            title={''}
                            inComingStyleHeader={'user-address-delete-modal-container-item'}
                        >
                            <div>
                                <div className='user-address-item-warn-steps'>
                                    <YImage imageSource={WarnIcon} inComingStyle={'user-address-item-warn-steps'} />
                                </div>
                                <div className="address-title-alert-container">
                                    <p>Adresinizi değiştiriyorsunuz! Sepetinizde bulunan ürünleri kaybedebilirsiniz!</p>
                                </div>
                                <div className="address-alert-contorller-container">
                                    <div className="address-alert-accpet-container" onClick={() => onApprove()}>
                                        <p className="address-alert-text">Kabul Et</p>
                                    </div>
                                    <div className="address-alert-cancel-container" onClick={() => cancelChange()}>
                                        <p className="address-alert-text">İptal</p>
                                    </div>
                                </div>
                            </div>
                        </YModal>
                    </MDBContainer>
                );
            default:
                return null;
        };
    };

    return (
        <div>
            {renderContent()}
        </div>
    );
};
