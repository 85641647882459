import React, { useState } from 'react';

//Component
import YText from '../Basic/YText';
import YImage from '../Basic/YImage';
import YSeperator from '../Basic/YSeperator';
import YAddToBasketHeader from '../Product/YAddToBasketHeader';
import YButton from '../Basic/YButton';

//Const
import Sample from '../../Assets/Images/sample.png';
import Delete from '../../Assets/Images/delete.png';
import Loading from '../../Assets/Images/loading-gif.gif';

// Styles
import './styles/YBasketStyles.css';

export default function YBasket(props) {
  const { basket, onDeleteItem, updateBasket, onEvent, navigate } = props;

  const MAX_LENGTH_BASKET = 20;

  const [showLoader, setShowLoader] = useState(false);

  const [itemNumber, setItemNumber] = useState(0);

  const onDeletingItem = id => {
    setShowLoader(true);
    setItemNumber(id);
    onDeleteItem(id);
  };

  const CartDropdown = () => {
    if (basket?.length > 0) {
      return (
        <div className="cart-dropdown">
          <div className="cart-items">
            {basket?.map((item, i) => {
              let result = item.count.replace(',', '.');
              return (
                <div key={item.title + i}>
                  <div className="cart-item">
                    <div className="cart-image-container">
                      {item.image !== '' ? (
                        <div
                          className="cart-image"
                          style={{ backgroundImage: `url(${item.image})` }}
                        />
                      ) : (
                        <div
                          className="cart-image"
                          style={{ backgroundImage: `url(${Sample})` }}
                        />
                      )}
                    </div>
                    <div className="cart-detial">
                      <div className="cart-detial-top">
                        <div className="card-product-title-container">
                          {item.title.length > MAX_LENGTH_BASKET ? (
                            <YText
                              value={`${item.title.substring(
                                0,
                                MAX_LENGTH_BASKET
                              )}...`}
                              type={'h4'}
                              incomingStyle={'cart-product-title'}
                            />
                          ) : (
                            <YText
                              value={item.title}
                              type={'h4'}
                              incomingStyle={'cart-product-title'}
                            />
                          )}
                        </div>
                        {itemNumber === item.id && showLoader === true ? (
                          <div className="cart-delete-container">
                            <YImage
                              imageSource={Loading}
                              inComingStyle={'card-delete'}
                            />
                          </div>
                        ) : (
                          <div
                            id="de"
                            className="cart-delete-container"
                            onClick={() => onDeletingItem(item.id)}
                          >
                            <YImage
                              imageSource={Delete}
                              inComingStyle={'card-delete'}
                              id={'de'}
                            />
                          </div>
                        )}
                      </div>
                      <div className="cart-basket-container">
                        {item.discountPrice === 0 ? (
                          <YText
                            value={'₺ ' + Number(item.price).toFixed(2)}
                            type={'span'}
                            incomingStyle={'cart-product-price-header'}
                          />
                        ) : (
                          <>
                            <YText
                              value={'₺ ' + Number(item.price).toFixed(2)}
                              type={'span'}
                              incomingStyle={'cart-product-price-header'}
                            />
                            {/*<YDiscountPrice 
                                                    price={'₺ '+(item.price*item.count)}
                                                    discountedPrice={'₺ '+(item.discountPrice*item.count)}
                                                    inComingNewPriceStyleContainer={'cart-discount-price-container'}
                                                    inComingOldPriceStyle={'cart-discount-price-old'}
                                                    inComingNewPriceStyle={'cart-discount-price-new'}
                                            />*/}
                          </>
                        )}
                        <YAddToBasketHeader
                          unit={item.unit}
                          count={Number(result)}
                          upDateBasket={e => updateBasket(item, e)}
                          incomingStyle={'basket-add-to-card-container-header'}
                          onEvent={onEvent}
                        />
                      </div>
                    </div>
                  </div>
                  {basket.length !== i + 1 ? <YSeperator /> : null}
                </div>
              );
            })}
          </div>
          <div className="cart-summery-container">
            <div>
              <YButton
                onEvent={() => navigate('/basket')}
                text={'Sepete Git'}
                inComingClassName={'card-button cart-summery'}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="cart-dropdown">
          <div className="cart-summery-container-empty">
            Sepetiniz Henüz Boş
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <CartDropdown />
    </>
  );
}
