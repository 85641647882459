import React , { useEffect , useState} from "react";
import { MDBIcon } from "mdb-react-ui-kit";

// Components
import YText from "../../Components/Basic/YText";
import YImage from "../Basic/YImage";

//Const
import loadingImage from '../../Assets/Images/loading-gif.gif';

// Styles
import "./styles/YAddToBasketHeaderStyles.css";

export default function YAddToBasketHeader(props) {
  const { count, upDateBasket , incomingStyle , unit } = props;

  const [loader, setLoader] = useState(false);

  const addToBasket = () => {
    setLoader(true);
    setTimeout(
      () => {
        if(unit === 'KG') {
          upDateBasket(Number(count) + 0.5);
        } else {
          upDateBasket(Number(count) + 1);
        }
        setLoader(false);
      }, 
      1000
    );
  };

  const removeFromBasket = () => {
    setLoader(true);
    setTimeout(
      () => {
        if (count === 1) {
          if(unit !== 'KG') {
            upDateBasket(0);
          } else {
            upDateBasket(Number(count) - 0.5);
          }
        } else {
          if(unit === 'KG') {
            upDateBasket(Number(count) - 0.5);
          } else {
            upDateBasket(Number(count) - 1);
          }
        }
        setLoader(false);
      }, 
      1000
    );
  };

  return (
    <div className={count === 0 ? "containerZeroBasket" : incomingStyle} onClick={count === 0 ? addToBasket : null}>
        <div className='basket-header-modal basket-basket-page-modal-container'>
          {loader === false ?
            <div onClick={removeFromBasket} className='touchableAera-header-modal' id={'s'}>
                {count === 1 && unit === 'Adet' ? 
                  <MDBIcon fas icon="trash" className="trash-icon-basket-on-top"/>
                : null }
                {count === 1 && unit === 'KG' ? 
                  <MDBIcon fas icon="minus" className="basket-minus-header"/>
                : null }
                {count === 0.5 && unit === 'KG' ? 
                  <MDBIcon fas icon="trash" className="trash-icon-basket-on-top"/>
                : null }
                {count > 1 ? 
                  <MDBIcon fas icon="minus" className="basket-minus-header"/>
                : null }
            </div>
          :
            <div className='touchableAera-header-modal' id={'s'}>
                {count === 1 && unit === 'Adet' ? 
                  <MDBIcon fas icon="trash" className="trash-icon-basket-on-top"/>
                : null }
                {count === 1 && unit === 'KG' ? 
                  <MDBIcon fas icon="minus" className="basket-minus-header"/>
                : null }
                {count === 0.5 && unit === 'KG' ? 
                  <MDBIcon fas icon="trash" className="trash-icon-basket-on-top"/>
                : null }
                {count > 1 ? 
                  <MDBIcon fas icon="minus" className="basket-minus-header"/>
                : null }
            </div>
          }
          <div>
            {loader === false ?
              <YText
                type={"p"}
                value={" "+count+" "+unit }
                incomingStyle={"basket-count-text-header"}
            />
            : 
              <YImage imageSource={loadingImage} inComingStyle={'loading-add-to-basket-image'} />
            }
          </div>
          {loader === false ?
            <div onClick={addToBasket} className='touchableAera-header-modal' id={'s'}>
              <MDBIcon fas icon="plus" className="basket-minus-header" id={'s'}/>
            </div>
          : 
            <div className='touchableAera-header-modal' id={'s'}>
              <MDBIcon fas icon="plus" className="basket-minus-header" id={'s'}/>
            </div>
          }
        </div>
    </div>
  );
}
