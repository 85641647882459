import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import { MDBRow, MDBCol, MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YImage from '../../Components/Basic/YImage';
import ContactLocation from './Components/ContactLocation';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Constants
import { commercialInfo } from './Constants/CommercialInfos';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/contactUsStyles.css';

// Icons
import locationIc from '../../Assets/Icons/location-ic.png';
import mailIc from '../../Assets/Icons/mail-ic.png';
import phoneIc from '../../Assets/Icons/phone-ic.png';
import webIc from '../../Assets/Icons/web-ic.png';

// Redux
import { getCommercialInfos } from '../../Redux/Actions/CommercialAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

export default function ContactUs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const menu = useSelector(state => state.menu.menu);

  const sCommercial = useSelector(state => state.commercial.commercial);

  const addressList = useSelector(state => state.user.address);

  const [commercialItems, setCommercialItems] = useState([]);

  const basket = useSelector(state => state.basket.basket);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    dispatch(getCommercialInfos());
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof sCommercial !== 'undefined') {
      setCommercialItems([...sCommercial]);
    }
  }, [sCommercial]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <MDBRow className="justify-content-center align-items-center h-100 mb-4">
          <MDBCol lg="6" className="contact-us-location-map-col">
            <div>
              <ContactLocation
                locationLng={commercialInfo[0].locationLng}
                locationLat={commercialInfo[0].locationLat}
              />
            </div>
          </MDBCol>
          <MDBCol lg="6" className="">
            <MDBRow className="mb-5">
              <MDBCol md="1" className="contact-us-commercial-icons-container">
                <YImage
                  imageSource={locationIc}
                  caption={''}
                  inComingStyle={'contact-us-commercial-icons'}
                />
              </MDBCol>
              <MDBCol className="contact-us-commercial-header-col">
                <MDBTypography className="contact-us-commercial-header">
                  Genel Müdürlük
                </MDBTypography>
                <MDBTypography className="contact-us-commercial-content">
                  {commercialItems[0]?.locationName}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-5">
              <MDBCol md="1" className="contact-us-commercial-icons-container">
                <YImage
                  imageSource={mailIc}
                  caption={''}
                  inComingStyle={'contact-us-commercial-icons'}
                />
              </MDBCol>
              <MDBCol className="contact-us-commercial-header-col">
                <MDBTypography className="contact-us-commercial-header">
                  Müşteri Hizmetleri
                </MDBTypography>
                <MDBTypography className="contact-us-commercial-content">
                  <a href={'mailto:' + commercialItems[0]?.mail}>
                    {commercialItems[0]?.mail}
                  </a>
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-5">
              <MDBCol md="1" className="contact-us-commercial-icons-container">
                <YImage
                  imageSource={phoneIc}
                  caption={''}
                  inComingStyle={'contact-us-commercial-icons'}
                />
              </MDBCol>
              <MDBCol className="contact-us-commercial-header-col">
                <MDBTypography className="contact-us-commercial-header">
                  Çağrı Merkezi
                </MDBTypography>
                <MDBTypography className="contact-us-commercial-content">
                  {commercialItems[0]?.phoneNumber}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow className="">
              <MDBCol md="1" className="contact-us-commercial-icons-container">
                <YImage
                  imageSource={webIc}
                  caption={''}
                  inComingStyle={'contact-us-commercial-icons'}
                />
              </MDBCol>
              <MDBCol className="contact-us-commercial-header-col">
                <MDBTypography className="contact-us-commercial-header">
                  Kurumsal Web Adresi
                </MDBTypography>
                <MDBTypography className="contact-us-commercial-content">
                  <a
                    href={commercialItems[0]?.website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {commercialItems[0]?.website}
                  </a>
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center align-items-center h-100 mb-3">
          <MDBCol lg="12">{/*<ContactForm />*/}</MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
