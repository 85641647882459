import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBTypography,
} from 'mdb-react-ui-kit';

// Components
import YButton from '../../../Components/Basic/YButton';
import YPagenation from '../../../Components/Basic/YPagenation';
import YStep from '../../../Components/Basic/YStep';
import YImage from '../../../Components/Basic/YImage';

// Styles
import './styles/OrderListStyles.css';

export default function OrderList(props) {
  const {
    setCancelOrderModal,
    setTempOrderId,
    currentItems,
    currentPage,
    productsPerPage,
    userOrders,
    handlePageChange,
    onClickOrder,
    onRefundOrder,
    removeFromRefund,
  } = props;

  const onCancelOrder = orderId => {
    setCancelOrderModal(true);
    setTempOrderId(orderId);
  };

  return (
    <MDBCard className="rounded-3 user-order-history-container">
      <MDBCardBody>
        <MDBRow>
          <MDBCol>
            <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
              <MDBTypography className="mb-0 user-order-history-headers">
                Siparişlerim
              </MDBTypography>
            </div>
            {/*<div className="user-order-history-dropdown">
                            <YDropDown
                                title={'Son 1 Ay'}
                                items={items}
                                inComingDropDownStyle={'dropbutton'}
                                inComingButtonStyle={'dropbutton'}
                                inComingItemStyle={'dropbutton'}
                            />
                        </div>*/}
            {currentItems?.map((item, i) => {
              let activeStep = 0;
              if (item.trackingStatuses[0].colorRGBCode == '#94CF71') {
                activeStep = 0;
              }
              if (item.trackingStatuses[1].colorRGBCode == '#94CF71') {
                activeStep = 1;
              }
              if (item.trackingStatuses[2].colorRGBCode == '#94CF71') {
                activeStep = 2;
              }
              if (item.trackingStatuses[3].colorRGBCode == '#94CF71') {
                activeStep = 3;
              }

              //console.log(item);
              return (
                <MDBCard
                  className="user-order-container-pagenation-rendering"
                  key={i + item.orderNumber}
                >
                  <MDBCardBody>
                    <MDBRow>
                      {/* ORDER NUMBER AND ORDER PROCESS */}
                      <MDBCol md="4">
                        <div className="user-order-history-order-number-container">
                          <div className="user-order-history-order-number-text-margin">
                            <MDBTypography className="user-order-history-order-number-header">
                              Sipariş No:
                            </MDBTypography>
                          </div>
                          <div>
                            <MDBTypography className="user-order-history-order-number-content">
                              {item.orderNumber}
                            </MDBTypography>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol md="8">
                        <div className="stepper-wrapper stapper-wrapper-right-side">
                          <div className="user-order-history-order-number-container">
                            <div className="user-order-history-order-number-text-margin">
                              <MDBTypography className="user-order-history-order-number-header">
                                Sipariş Durumu:
                              </MDBTypography>
                            </div>
                            <div>
                              <MDBTypography className="user-order-history-order-number-content">
                                {item.status}
                              </MDBTypography>
                            </div>
                          </div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      {/* ORDER DATE */}
                      <MDBCol>
                        <MDBTypography className="user-order-history-order-date">
                          {item.dateCreated}
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="d-flex align-items-center justify-content-between user-order-history-order-details-container-mobile">
                      {/* ORDER DETAILS */}
                      <MDBCol className="">
                        <div className="user-order-history-order-number-container">
                          <div className="user-order-history-order-number-text-margin">
                            <MDBTypography className="user-order-history-order-number-header">
                              Sepetteki Ürünler ({item.numberOfProduct})
                            </MDBTypography>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol
                        className="user-order-history-order-details-container"
                        onClick={() => onClickOrder(item.orderId, item)}
                      >
                        <div>
                          <YButton
                            inComingClassName={
                              'user-order-history-order-details-button'
                            }
                            text={'Sipariş Detayı'}
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="d-flex align-items-center justify-content-between user-order-history-card-section">
                      {/* PRODUCTS AND MORE */}
                      <MDBCol className="user-order-history-order-product-container col-12 col-md-6 col-lg-4">
                        <MDBRow>
                          {/* Render 3 product img */}
                          {item.products.slice(0, 3).map(item => (
                            <div
                              key={item.productId + item.quantity}
                              className="user-order-history-order-product-image-container"
                            >
                              {item?.thumbnailUri !== '' ? (
                                <img
                                  src={item?.thumbnailUri}
                                  className="user-order-history-order-product-image"
                                  alt="..."
                                />
                              ) : (
                                <YImage
                                  imageSource={require('../../../Assets/Images/sample.png')}
                                  inComingStyle={
                                    'user-order-history-order-product-image'
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </MDBRow>
                        <MDBRow className="user-order-history-order-product-more-count-text">
                          {/* Specify remaining images count */}
                          {item.numberOfProduct - 3 > 0 && (
                            <MDBTypography className="user-order-history-order-product-more-count-text">
                              +{item.numberOfProduct - 3} Daha
                            </MDBTypography>
                          )}
                        </MDBRow>
                      </MDBCol>
                      {item.isCancellable === true ? (
                        <MDBCol
                          className="d-flex align-items-center user-order-history-order-re-order-container col-12 col-md-6 col-lg-4"
                          onClick={() => onCancelOrder(item.orderId)}
                        >
                          <MDBCol className="d-flex align-items-center user-order-history-cancel-order">
                            <div>
                              <YImage
                                imageSource={require('../../../Assets/Images/cancelOrder.png')}
                                inComingStyle={
                                  'user-order-history-order-re-order-image'
                                }
                              />
                            </div>
                            <div>
                              <MDBTypography className="user-order-history-order-re-order-text">
                                Siparişi İptal Et
                              </MDBTypography>
                            </div>
                          </MDBCol>
                          <span>
                            <MDBCol className="d-flex flex-row-reverse user-order-history-order-price-container">
                              <div className="user-order-history-total-price">
                                <MDBTypography className="user-order-history-order-price-header">
                                  Toplam Tutar:
                                </MDBTypography>
                                <MDBTypography className="user-order-history-order-price-content">
                                  {Number(
                                    item.paymentDetail.totalPrice
                                  ).toFixed(2)}{' '}
                                  TL
                                </MDBTypography>
                              </div>
                            </MDBCol>
                          </span>
                        </MDBCol>
                      ) : item.isReturnable === true ? (
                        <MDBCol
                          className="d-flex align-items-center user-order-history-order-re-order-container col-12 col-md-6 col-lg-4"
                          onClick={() => onRefundOrder(item)}
                        >
                          <MDBCol className="d-flex align-items-center user-order-history-cancel-order">
                            <div>
                              <YImage
                                imageSource={require('../../../Assets/Images/cancelOrder.png')}
                                inComingStyle={
                                  'user-order-history-order-re-order-image'
                                }
                              />
                            </div>
                            <div>
                              <MDBTypography className="user-order-history-order-re-order-text">
                                İade Emri
                              </MDBTypography>
                            </div>
                          </MDBCol>
                          <span>
                            <MDBCol className="d-flex flex-row-reverse user-order-history-order-price-container">
                              <div className="user-order-history-total-price">
                                <MDBTypography className="user-order-history-order-price-header">
                                  Toplam Tutar:
                                </MDBTypography>
                                <MDBTypography className="user-order-history-order-price-content">
                                  {Number(
                                    item.paymentDetail.totalPrice
                                  ).toFixed(2)}{' '}
                                  TL
                                </MDBTypography>
                              </div>
                            </MDBCol>
                          </span>
                        </MDBCol>
                      ) : (
                        <MDBCol className="d-flex flex-row-reverse user-order-history-order-price-container">
                          <div className="user-order-history-total-price-else">
                            <MDBTypography className="user-order-history-order-price-header">
                              Toplam Tutar:
                            </MDBTypography>
                            <MDBTypography className="user-order-history-order-price-content">
                              {Number(item.paymentDetail.totalPrice).toFixed(2)}{' '}
                              TL
                            </MDBTypography>
                          </div>
                        </MDBCol>
                      )}
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              );
            })}
            <MDBRow>
              <YPagenation
                activePage={currentPage}
                itemsCountPerPage={productsPerPage}
                totolCounts={userOrders.length}
                pageRangeDisplayed={5}
                onEvent={handlePageChange}
              />
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
}
