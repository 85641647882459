import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBContainer } from 'mdb-react-ui-kit';
import { Button } from 'react-bootstrap';

// Components
import YTheme from '../../../Components/Layout/YTheme';
import YImage from '../../../Components/Basic/YImage';

// Styles
import './styles/styles.css';

// Const
import CardImage from '../../../Assets/Images/cuteCard.png';

import ArrowRight from '../../../Assets/Images/arrow-right-white.png';

export default function OrderMobileFailed() {
  const navigate = useNavigate();

  return (
    <YTheme>
      <MDBContainer className="pageContainer mobile-container">
        <div className="card-failed-payment-container">
          <YImage
            imageSource={CardImage}
            inComingStyle={'card-failed-payment-image'}
          />
          <h4 className="card-failed-payment-text">Ödemeniz gerçekleşmedi!</h4>
        </div>
      </MDBContainer>
    </YTheme>
  );
}
