import React, { useState , useEffect } from "react";
import {
    MDBIcon,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";

// Components
import YImage from '../../../Components/Basic/YImage';

// Styles
import './styles/poshetModal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function PoshetModal(props) {

    const [count, setCount] = useState(props.poshet);

    const addMorePoshet = () => {
        setCount(count + 1);
        props.setPoshet(count + 1);
    }

    const removeMorePoshet = () => {
        if(count !== 0) {
            if (count === 1) {
                setCount(0);
                props.setPoshet(0);
            } else {
                setCount(count - 1);
                props.setPoshet(count - 1);
            }
        }
    }

    return (
        <div className='poshet-container'>
            <p className='poshet-title'>Poşet Ekleme</p>
            <p className='poshet-sub-title'>Adresi silmek istediğinize emin misiniz?</p>
            <div className='poshet-box-container-holder'>
                <div className='poshet-box-container'>
                    <div className='poshet-corner-box' onClick={() => removeMorePoshet()}>
                        <MDBIcon fas icon="minus"/>
                    </div>
                    <div className='posht-middle-box'>{count} Adet</div>
                    <div className='poshet-corner-box' onClick={() => addMorePoshet()}>
                        <MDBIcon fas icon="plus" />
                    </div>
                </div>
            </div>
        </div>
    );

};
