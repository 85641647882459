import React from 'react';

// Styles
import './styles/YColStyles.css'

export default function YCol(props) {

    const {
        inComingStyle,
        children,
    } = props;

    
    return(
        <div
            className={inComingStyle ? inComingStyle : 'containerCol'}
        >
            {children}
        </div>
    );
};