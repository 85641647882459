export const bilgiGuvenligi = `<div>
<div class="page-title">Bilgi Güvenliği Yönetim Sistemi Politikası</div>
    <p>Kişisel verilerin korunması konusu Öztaner Gıda ve İhtiyaç Mad. San. Tic. Ltd.Şti. &nbsp;için büyük önem arz
        etmektedir. Bizimle herhangi bir şekilde temas ederek herhangi bir kişisel verisini paylaşmış olan herkesin
        bilgilerini titizlikle muhafaza etmekteyiz. Bu bilgilerin güvenliğini sağlamak için şirket bünyemizde gerekli
        bütün idari ve teknik tedbirleri aldık. İşte bu nedenle Özhan Marketler markası ile kişisel verilerinizin
        güvende olacağı duygusuyla işlemlerinizi yapabilirsiniz. Gerek T.C. Anayasası gerekse ilgili Kanunlar ile
        güvence altına alınan verilerinizle ilgili haklarınıza riayet edeceğiz. Sahip olduğumuz bu bilinç ve güvenle,
        kişisel veri güvenliği konusundaki evrensel “şeffaflık” ve “hesap verilebilirlik” ilkelerine sadık kalarak
        hazırladığımız kurumsal Gizlilik Politikamızı burada sizlerle paylaşmak istedik.</p>
    <p>Sizlerden gelecek iyileştirme önerilerine, başvurulara ve olası şikâyetlere karşı bütün duyarlılığı
        göstereceğimizden emin olabilirsiniz. Kişisel verilerinizle ilgili bütün tereddütlerinizde bizlere
        başvurabilirsiniz. Bizler bütün hizmetlerimizde olduğu gibi kişisel verilerinizin korunmasında da aynı
        hassasiyeti ve özeni göstereceğiz.</p>
    <p>&nbsp;</p>
    <p>Saygılarımızla,</p>
    <p>Öztaner Gıda ve İhtiyaç Mad. San. Tic. Ltd.Şti. &nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Amacı</strong></p>
    <p>Bugüne kadar Öztaner Gıda ve İhtiyaç Mad. San. Tic. Ltd.Şti. &nbsp;(bundan sonra “Özhan Marketler”) olarak
        uğraştığımız işlerin hassasiyeti gereğince toplanan kişisel veriler gizli tutulmuş ve hiçbir zaman üçüncü
        kişilerle amacı dışında paylaşılmamıştır. Kişisel verilerin korunması, şirketimizin temel politikasıdır.
        Herhangi bir yasal düzenleme olmadan önce de şirketimiz, kişisel verilerin gizliliğine büyük önem vermiş ve bunu
        bir çalışma ilkesi olarak benimsemiştir. Kişisel Verilerin Korunması Kanunu’nun getirdiği bütün sorumluluklara
        da uymayı Özhan Marketler olarak taahhüt etmekteyiz.</p>
    <p>&nbsp;</p>
    <h2><strong>1.Kapsamı ve Değiştirilmesi</strong></h2>
    <p>Şirketimiz tarafından hazırlanan bu Politika, 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“KVK”) uygun
        olarak hazırlanmıştır. Kanun, bugün itibariyle bütün hükümleri ile yürürlüğe girmiştir.</p>
    <p>Sizlerden rızanızla ya da Kanunda sayılan diğer hukuka uygunluk halleri gereği elde edilmiş veriler, sunmuş
        olduğumuz hizmetlerin kalitelisinin arttırılması ve kalite politikamızın iyileştirilmesi amacıyla
        kullanılacaktır. Yine elimizdeki bazı veriler ise, kişisel olmaktan çıkarılmakta ve anonimleştirilmektedir. Bu
        veriler, istatistiki amaçlarla kullanılan verilerdir ve Kanun uygulamasına ve Politikamıza tabi değildir.</p>
    <p>Özhan Marketler Gizlilik Politikası, müşterilerimizin, müşteri adaylarımızın, çalışanlarımız ile bizimle çözüm
        ortaklığı içinde çalışan şirketlerin müşterileri ve çalışanlarının ya da diğer kişilerin otomatik olarak elde
        edilen verilerinin korunmasını amaçlar ve bunlara ilişkin düzenlemeleri içerir.</p>
    <p>Şirketimiz, Politikamızı ve Kişisel Verilere İlişkin Yönergemizi, Kanuna uygun olmak ve kişisel verilerin daha
        iyi korunması şartı ile, değiştirme hakkına sahiptir.</p>
    <p>&nbsp;</p>
    <h2><strong>2.Kişisel Verilerin İşlenmesi İle İlgili Temel Kurallar</strong></h2>
    <p><strong>1.Hukuka ve dürüstlük kurallarına uygun olma:</strong> Özhan Marketler, topladığı ya da kendisine diğer
        şirketlerden gelen verilerin kaynağını sorgular ve bunların hukuka uygun ve dürüstlük kuralları çerçevesinde
        elde edilmesine önem verir.</p>
    <p><strong>2.Doğru ve gerektiğinde güncel olma:</strong> Özhan Marketler, kurum bünyesinde bulunan bütün verilerin
        doğru bilgi olmasına, yanlış bilgi içermemesine ve nihayet kişisel verilerde değişiklik olduğu takdirde bunları
        kendisine iletildiği takdirde güncellemeye önem verir.</p>
    <p><strong>3.Belirli, açık ve meşru amaçlar için işlenme:</strong> Özhan Marketler, ancak sunduğu ve hizmet
        sırasında kişilerden onayını aldığı amaçlarla sınırlı şekilde verileri işler. İş amacı dışında verileri işlemez,
        kullanmaz ve kullandırtmaz.</p>
    <p><strong>4.İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma:</strong> Özhan Marketler, sadece verileri
        işlendikleri amaçla sınırlı ve hizmetin gerektirdiği ölçüde kullanır.</p>
    <p><strong>&nbsp; &nbsp; 1.İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza
            edilme:</strong> Özhan Marketler, sözleşmeler kaynaklı verileri Kanunun ihtilaf çıkma süreleri, ticaret ve
        vergi hukukunun gereklilikleri kadar bünyesinde muhafaza eder. Buna karşın bu amaçlar ortadan kalktığında veriyi
        siler ya da anonimleştirir. Bunları Kişisel Verileri Saklama, Silme, İmha Yönergesi’ne göre siler veya yok eder.
    </p>
    <p>Önemle belirtelim ki, Özhan Marketler, verileri ister rızaya dayanarak isterse kanuna uygun bir şekilde toplamış
        ya da işlemiş olsun yine de yukarıda sıraladığımız bu ilkeler geçerlidir.</p>
    <h2>3.Kişisel Veri Sahibinin KVK’nun 11. maddesinde Sayılan Hakları</h2>
    <p>KVK m.11’e göre aşağıda sayılan haklarınız bulunmaktadır. Bu haklarınızı kolaylaştırmak için sizler için ayrıca
        bir başvuru formu da Özhan Marketler tarafından hazırlanmış ve internet sayfanızda sizlere sunulmuştur.</p>
    <p>Kişisel verileri işlenen kişiler, Özhan Marketler tarafından internet sayfamızda duyurulan ilgilimize başvurarak
        kendi verisi ile ilgili olarak;</p>
    <p><strong>&nbsp;1. </strong>Kişisel veri işlenip işlenmediğini öğrenme,</p>
    <p><strong>&nbsp;2. </strong>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
    <p><strong>&nbsp;3. </strong>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
        öğrenme,</p>
    <p><strong>&nbsp;4. </strong>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
    <p><strong>&nbsp;5. </strong>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
        isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
    <p><strong>&nbsp;6. </strong>KVK Kanunu’nun 7. maddesinde öngörülen şartlar çerçevesinde, KVK Kanununun ve ilgili
        diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
        kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
        verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
    <p><strong>&nbsp;7. </strong>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
        kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</p>
    <p><strong>&nbsp;8. </strong>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
        zararın giderilmesini talep etme haklarına sahiptir. Özhan Marketler olarak bu haklara saygılıyız.</p>
    <p>&nbsp;</p>
    <h2><strong>4.Azami Tasarruf İlkesi/Cimrilik İlkesi</strong></h2>
    <p>Azami tasarruf ilkesi ya da cimrilik ilkesi adı verilen bu ilkemize göre Özhan Marketler’e ulaşan veriler, ancak
        gerekli olduğu kadar sisteme işlenir. Bu nedenle hangi verileri toplayacağımız amaca göre belirlenir. Gerekli
        olmayan veriler toplanmaz. Şirketimize intikal eden diğer veriler de aynı şekilde şirket bilişim sistemlerine
        aktarılır. Fazlalık bilgiler, sisteme kaydedilmez, silinir ya da anonim hale getirilir. Bu veriler, istatistiki
        amaçlarla kullanılabilir.</p>
    <p>&nbsp;</p>
    <h2><strong>5.Kişisel Verilerin Silinmes</strong><strong>i</strong></h2>
    <p>Kanunen saklanması gereken sürelerin dolması, yargı süreçlerinin tamamlanması ya da diğer gereklilikler ortadan
        kalktığında şirketimiz tarafından bu veriler kendiliğinden ya da ilgili kişinin talebi üzerine kişisel veriler
        silinir, yok edilir ya da anonim hale getirilir.</p>
    <p>&nbsp;</p>
    <h2><strong>6.Doğruluk ve Veri Güncelliği</strong></h2>
    <p>Özhan Marketler bünyesinde bulunan veriler, kural olarak ilgili kişilerin beyanı üzerine beyan ettiği şekilde
        işlenir. Özhan Marketler, müşteriler ya da Özhan Marketler ile temas kuran kişilerin beyan ettiği verilerin
        doğruluğunu araştırmak zorunda olmadığı gibi bu hukuken ve çalışma ilkelerimiz nedeniyle de yapılmaz. Beyan
        edilen veriler, doğru kabul edilir. Kişisel verilerin doğruluğu ve güncelliği ilkesi Özhan Marketler tarafından
        da benimsenmiştir. Şirketimizin kendisine ulaşan resmî belgelerden veya ilgilisinin talebi üzerine işlemiş
        olduğu kişisel verileri günceller. Bunun için gerekli önlemleri alır.</p>
    <p>&nbsp;</p>
    <h2><strong>7.Gizlilik ve Veri Güvenliği</strong></h2>
    <p>Kişisel veriler gizlidir ve Özhan Marketler bu gizliliğe riayet etmektedir. Kişisel verilere şirket içinde ancak
        yetki verilmiş kişiler ulaşabilir. Özhan Marketler tarafından toplanan kişisel verilerin korunması ve yetkisiz
        kişilerin eline geçmemesi ve veri sahibinin mağdur olmaması için gerekli teknik ve idari bütün tedbirler
        alınmaktadır. Bu çerçevede yazılımların standartlara uygun olması, üçüncü partilerin özenle seçilmesi ve şirket
        içinde de Gizlilik Politikasına riayet edilmesi sağlanmaktadır. Hukuka uygun olarak kişisel verileri
        paylaştığımız şirketlerden de verileri koruması talep edilir.</p>
    <p>&nbsp;</p>
    <h2><strong>8.Veri İşleme Amaçları</strong></h2>
    <p>Özhan Marketler’in Kişisel Verilerin İşlenmesi Aydınlatma Metninde belirtilen amaçlar doğrultusunda icra
        edilecektir.</p>
    <h2><strong>9.Müşteri, Muhtemel Müşteri ve İş ve Çözüm Ortakları Verisi</strong></h2>
    <h2><strong>a.Sözleşme İlişkisi İçin Verinin Toplanması ve İşlenmesi</strong></h2>
    <p>Müşteriler ve muhtemel müşterilerimizle bir sözleşme ilişkisi kurulmuş ise, toplanmış olan kişisel veriler,
        müşterinin onayı alınmaksızın kullanılabilir. Ancak bu kullanım, sözleşme amacı doğrultusunda gerçekleşir.
        Sözleşmenin daha iyi icrası ve hizmetin gereklilikleri ölçüsünde veriler kullanılır ve gerektiğinde müşterilerle
        irtibata geçilerek güncellenir.</p>
    <p>&nbsp;</p>
    <h3><strong>b.İş ve Çözüm Ortakları Verileri</strong></h3>
    <p>Özhan Marketler, gerek iş gerekse çözüm ortakları ile veri paylaşımı yaparken hukuka uygun davranmayı ilke
        edinir. İş ve çözüm ortakları ile veri gizliliği taahhüdü ile ve ancak hizmetin gerektirdiği kadar veri
        paylaşılmakta ve bu taraflardan mutlaka veri güvenliğinin sağlanmasına ilişkin tedbirleri alması talep edilir.
    </p>
    <p>&nbsp;</p>
    <h2><strong>10.Reklam Amaçlı Veri İşleme</strong></h2>
    <p>E-Ticaretin Düzenlenmesi Hakkındaki Kanun’un ile Ticari İletişim ve Ticari Elektronik İletiler Hakkındaki
        Yönetmeliğe uygun olarak ancak önceden onay alınan kişilere reklam amaçlı elektronik ileti gönderilebilir.
        Reklamın gönderileceği kişinin onayının açık bir şekilde mevcudiyeti şarttır.</p>
    <p>Yine aynı mevzuat uyarınca belirlenen “onay”ın detaylarına da Özhan Marketler riayet etmektedir. Alınacak onay,
        şirketinizin mal ve hizmetlerini tanıtmak, pazarlamak, işletmesini tanıtmak ya da kutlama ve temenni gibi
        içeriklerle tanınırlığını artırmak amacıyla alıcıların elektronik iletişim adreslerine gönderdiği tüm ticari
        elektronik iletileri kapsamalıdır. Bu onay, yazılı olarak fiziki ortamda veya her türlü elektronik iletişim
        aracıyla alınabilir. Önemli olan, alıcının ticari elektronik ileti gönderilmesini kabul ettiğine dair olumlu
        irade beyanı, adı ve soyadı ile elektronik iletişim adresinin bulunmasıdır.</p>
    <p>&nbsp;</p>
    <h2><strong>11.Şirketin Hukuki Yükümlülüğü veya Kanunda Açıkça Öngörülmesi Sebebiyle Yapılan Veri İşlemleri</strong>
    </h2>
    <p>Kişisel veriler, işlemenin ilgili mevzuatta açıkça belirtilmesi veya mevzuatla belirlenen bir hukuki yükümlülüğün
        yerine getirilmesi amacıyla ayrıca onay alınmadan işlenebilir. Veri işlemlerinin tür ve kapsamı, yasal olarak
        izin verilen veri işleme faaliyeti için gerekli olmalı ve ilgili yasal hükümlere uygun olmalıdır.</p>
    <p>&nbsp;</p>
    <h2><strong>12.Şirketin Veri İşlemesi</strong></h2>
    <p>Şirketin sunduğu hizmet ve meşru amaçları doğrultusunda kişisel veriler işlenebilir. Ancak veriler hiçbir şekilde
        hukuka aykırı hizmetler için kullanılamaz.</p>
    <p>&nbsp;</p>
    <h2><strong>13.Özel Nitelikli Verilerin İşlenmesi</strong></h2>
    <p>Özhan Marketler, özel nitelikli kişisel verilerin işlenmesinde, Kurul tarafından ayrıca belirlenen yeterli
        önlemlerin hepsini alır. Şirketimizde özel nitelikli kişisel veriler “Özel Nitelikli Kişisel Verilerin Korunması
        ve İşlenmesi Politikası”na göre işlenir.</p>
    <h2><strong>14.Otomatik Sistemlerle İşlenen Veriler</strong></h2>
    <p>Otomatik sistemler aracılığı ile işlenen veriler konusunda Özhan Marketler, Kanuna uygun davranır. Kişilerin açık
        rızası olmaksızın bu verilerden elde edilen bilgiler kişi aleyhine kullanılamaz. Ancak Özhan Marketler, kendi
        sistemindeki verileri kullanarak işlem yapacağı kişilerle ilgili kararlar alabilir.</p>
    <h2><strong>15.Kullanıcı Bilgileri ve İnternet</strong></h2>
    <p>Özhan Marketler’e ait internet siteleri ve diğer sistemlerde veya uygulamalarda kişisel verilerin toplanması,
        işlenmesi ve kullanılması durumunda ilgili kişiler gizlilik bildirimi ile ve gerekirse çerezler hakkında
        bilgilendirilir.</p>
    <p>Kişiler, internet sayfalarındaki uygulamalarımız konusunda bilgilendirilir. Kişisel veriler, hukuka uygun olarak
        işlenecektir.</p>
    <p>&nbsp;</p>
    <h2><strong>16.Çalışanlarımıza Ait Veriler</strong></h2>
    <h3><strong>a.İş İlişkisi İçin Verilerin İşlenmesi</strong></h3>
    <p>Çalışanlarımızın kişisel verileri, iş ilişkileri ve sağlık sigortası bakımından gerekli olduğu kadarıyla onay
        alınmaksızın işlenebilir. Ancak Özhan Marketler, çalışanlarına ait verilerin gizliliği ve korunmasını temin
        eder.</p>
    <p>&nbsp;</p>
    <h3><strong>b.Hukuki Yükümlülükler Gereği İşleme</strong></h3>
    <p>Özhan Marketler, çalışanlarına ait kişisel verileri, işlemenin ilgili mevzuatta açıkça belirtilmesi veya
        mevzuatla belirlenen bir hukuki yükümlülüğün yerine getirilmesi amacıyla ayrıca onay alınmadan verileri
        işleyebilir. Bu husus, kanundan kaynaklanan yükümlülüklerle sınırlıdır.</p>
    <p>&nbsp;</p>
    <h3><strong>c. Çalışanların Yararına İşlemeler</strong></h3>
    <p>Özhan Marketler, özel sağlık sigortaları gibi şirket çalışanlarının menfaatine olan işlemler için onay almaksızın
        kişisel verileri işleyebilir. İş ilişkilerinden kaynaklanan ihtilaflar için de Özhan Marketler, çalışanlara ait
        verileri işleyebilir.</p>
    <p>&nbsp;</p>
    <h3><strong>d.Özel Nitelikli Verilerin İşlenmesi</strong></h3>
    <p>Kanun’a göre Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları,
        kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik
        tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.</p>
    <p>Özhan Marketler, özel nitelikli kişisel verilerin işlenmesinde, ilgili kişinin onayı yanında Kurul tarafından
        ayrıca belirlenen yeterli önlemleri alır. Özel nitelikli kişisel veriler kişinin onayı olmaksızın ancak Kanunda
        izin verilen hallerle ilgili ve sınırlı olarak işlenebilir.</p>
    <p>&nbsp;</p>
    <h3><strong>e.Otomatik Sistemlerle İşlenen Veriler</strong></h3>
    <p>Çalışanların otomatik sistemlerle ilgili olarak işlenen verileri, şirket içi terfilerde ve performans
        değerlendirmelerinde kullanılabilir. Çalışanlarımız aleyhine çıkan sonuca itiraz etme hakkına sahiptir ve bunu
        şirket içi prosedürlere uyarak gerçekleştirirler. Çalışanların itirazları da yine şirket içinde değerlendirilir.
    </p>
    <h3><strong>f.Telekomünikasyon ve İnternet</strong></h3>
    <p>Şirket içinde çalışanlara tahsis edilen bilgisayar, telefon, e-posta ve diğer uygulamalar çalışana sadece iş
        amacı ile tahsis edilmiştir. Çalışan şirketin kendisine tahsis ettiği bu vasıtaların hiçbirini özel amaçları ve
        iletişimi için kullanamaz. Şirket bu araçlar üzerindeki bütün verileri kontrol edebilir ve denetleyebilir.
        Çalışan, işe başladığı andan itibaren kendisine tahsis edilen bilgisayarda, telefonlarda ya da diğer araçlarda
        iş dışında başka bir veri ya da bilgi bulundurmayacağını taahhüt etmektedir.</p>
    <h2><strong>17.Kişisel Verilerin Yurt İçi ve Dışına Aktarılması</strong></h2>
    <p>Kişisel veriler, Özhan Marketler tarafından hizmetin görülebilmesi amacıyla hâkim hissedar ile ayrıca iş ve çözüm
        ortakları ile paylaşılabilir.&nbsp;</p>
    <p>Özhan Marketler, kişisel verileri, Şirketimizin tedarikçiden dış kaynaklı olarak temin ettiği ve Şirketimizin
        ticari faaliyetlerini yerine getirmek için gerekli hizmetlerin Şirketimize sunulmasını sağlamak amacıyla sınırlı
        olarak Özhan Marketler’in tedarikçilerine aktarabilecektir.</p>
    <p>Özhan Marketler, Kurul tarafından belirlenen şartlar dahilinde kişisel verileri Kanundaki diğer şartlara uygun
        olarak ve kişinin onayına bağlı olarak yurt içi ve yurt dışına aktarma yetkisine sahiptir.</p>
    <h2><strong>18.İlgili Kişinin Hakları</strong></h2>
    <p>Özhan Marketler, Kanun kapsamında ilgili kişinin veri işlenmeden önce onayını alma hakkının olduğunu, verinin
        işlenmesinden sonra ise verisinin kaderini tayin etme hakkına sahip olduğunu kabul etmektedir.&nbsp;</p>
    <p>Özhan Marketler tarafından internet sayfamızda duyurulan ilgilimize başvurarak kişisel verilerle ilgili olarak;
    </p>
    <p><strong>&nbsp;a)</strong> Kişisel verinizin işlenip işlenmediğini öğrenme,</p>
    <p><strong>&nbsp;b)</strong> Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
    <p><strong>&nbsp;c)</strong> Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
        öğrenme,</p>
    <p><strong>&nbsp;ç)</strong> Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
    <p><strong>d)</strong> Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
    </p>
    <p><strong>e)</strong> 7’nci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok
        edilmesini isteme,</p>
    <p><strong>f) (d)</strong> ve <strong>(e)</strong> bentleri uyarınca yapılan işlemlerin, kişisel verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
    <p><strong>g)</strong> İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
        kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</p>
    <p><strong>ğ)</strong> Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
        giderilmesini talep etme hakkına sahiptir.</p>
    <p>Buna karşın,</p>
    <p>Şirket içinde anonimleştirilmiş verilerle ilgili olarak kişilerin bir hakkı bulunmamaktadır. Özhan Marketler,
        kişisel verileri, iş ve sözleşme ilişkisi gereğince, bir yargısal görevin ya da devlet otoritesinin Kanuni
        yetkilerini kullanması amacıyla ilgili kurum ve kuruluşlarca paylaşabilir.</p>
    <p>Kişisel veri sahipleri, yukarıda belirtilen haklarına ilişkin taleplerini Şirket resmi internet adresinden temin
        edebileceğiniz başvuru formunu eksiksiz doldurarak ve ıslak imza ile imzalayarak iadeli taahhütlü mektupla ve
        kimlik fotokopileriyle (nüfus cüzdanı için sadece ön yüz fotokopisi olacak şekilde) göndererek Şirkete
        iletebileceklerdir. Başvurularınız, başvurunuzun içeriğine göre en kısa sürede ya da şirketimize ulaşmasından
        sonra en geç 30 gün içinde cevaplanacaktır. Başvurularınızı, iadeli taahhütlü mektupla yapmanız gerekmektedir.
        Ayrıca başvurularınızın sadece sizlerle ilgili kısmı cevaplanacak olup, eşiniz, yakınınız ya da arkadaşınız
        hakkında yapılan bir başvuru kabul edilmeyecektir.</p>
    <p>Özhan Marketler, başvuru sahiplerinden başkaca ilgili bilgi ve belge talep edebilir.&nbsp;&nbsp;&nbsp;</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <h2><strong>19.Gizlilik İlkesi</strong></h2>
    <p>&nbsp;</p>
    <p>İster çalışanlar isterse diğer kişilerin Özhan Marketler’deki verileri gizlidir. Hiç kimse sözleşme ya da kanuna
        uygunluk olmaksızın başkaca hiçbir amaç için bu verileri kullanamaz, kopyalayamaz, çoğaltamaz, başkalarına
        aktaramaz, iş amaçları dışında kullanamaz.</p>
    <h2><strong>20.İşlem Güvenliği</strong></h2>
    <p>Özhan Marketler tarafından toplanan kişisel verilerin korunması ve yetkisiz kişilerin eline geçmemesi ve
        müşterilerimizin ve müşteri adaylarımızın mağdur olmaması için gerekli teknik ve idari bütün tedbirler
        alınmaktadır. Bu çerçevede yazılımların standartlara uygun olması, üçüncü partilerin özenle seçilmesi ve şirket
        içinde de Gizlilik Politikasına riayet edilmesi sağlanmaktadır. Güvenliğe ilişkin önlemler, sürekli olarak
        yenilenmekte ve geliştirilmektedir.</p>
    <p>&nbsp;</p>
    <h2><strong>21.Denetim</strong></h2>
    <p>Özhan Marketler, kişisel verilerin korunması konusunda gerekli iç ve dış denetimleri yaptırır.&nbsp;</p>
    <p>&nbsp;</p>
    <h2><strong>22.İhlallerin Bildirimi</strong></h2>
    <p>Özhan Marketler, kişisel verilerle ilgili herhangi bir ihlal olduğu kendisine bildirildiğinde, bu durumun
        öğrenildiği tarihten itibaren gecikmeksizin ve en geç 72 saat içinde KVK Kuruluna bildirimde bulunması gerektiği
        bilinciyle hareket eder. İlgilinin zararını en aza indirir ve zararı telafi eder. Kişisel verilerin dışarıdan
        yetkisiz kimselerce ele geçirildiğinde durumu derhal Kişisel Verileri Koruma Kurulu’na bildirir.</p>
    <p>İhlallerin bildirimi ile kurumsal internet adresimizde belirtilen usullere göre başvuruda bulunabilirsiniz.
        <strong>&nbsp;</strong><a href="http://kurumsal.ozhan.com.tr/assets/kvk/kvk_basvuru-formu.pdf"><strong>Başvuru
                ve Bilgi Talep Formu </strong>için</a> <a
            href="http://kurumsal.ozhan.com.tr/assets/kvk/kvk_basvuru-formu.pdf"><strong><em>tıklayınız</em></strong><strong>.</strong></a>
    </p>
    <h2>&nbsp;</h2>
    <h2><strong>23.Güncelleme</strong></h2>
    <p>&nbsp;</p>
    <p>İşbu Politika’da yapılan değişiklikler aşağıdaki tabloda gösterilmektedir.</p>
    <table>
        <tbody>
            <tr>
                <td width="302">
                    <p><strong>Politika Güncelleme Tarihi</strong></p>
                </td>
                <td width="302">
                    <p><strong>Değişiklikler</strong></p>
                </td>
            </tr>
            <tr>
                <td width="302">
                    <p><strong>&nbsp;</strong></p>
                </td>
                <td width="302">
                    <p>&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td width="302">
                    <p><strong>&nbsp;</strong></p>
                </td>
                <td width="302">
                    <p>&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </table>
    <p><img src="https://kurumsal.ozhan.com.tr/upload/files/photos/6/politika.jpg" alt="" width="925" height="1307"></p>
</div>`;
