import React from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import '../styles/sidebarStyles.css';
export default function SideBar({
  title,
  items,
  handleClick,
  brands,
  selected,
  headCate,
}) {
  const navigate = useNavigate();

  let value = selected[1].split('&');
  const selectedId = value[0];

  return (
    <div
      id="sidebar-category-container-mobile"
      className="sidebar-category-container"
    >
      <h3 className="sidebar-category-title">{title}</h3>
      <p className="sidebar-category-sub-item-text">Alt Kategoriler</p>
      <div className="sidebar-category">
        <ul className="sidebar-category-list">
          {items && items.length > 0 ? (
            items.map((item, id) => (
              <li key={id + item} className={'sidebar-category-list-item'}>
                {item.active === 1 ? (
                  <div className="active-category-circle"></div>
                ) : (
                  <div className="no-active-category-circle"></div>
                )}
                <a
                  id={`${
                    selectedId == item.categoryId
                      ? 'category-item-selected'
                      : 'none'
                  }`}
                  className="list-link"
                  onClick={() =>
                    navigate({
                      pathname: '/products',
                      search:
                        '?catgoryId=' +
                        item.categoryId +
                        '&categoryParent=' +
                        headCate,
                    })
                  }
                >
                  {item.categoryName}
                </a>
              </li>
            ))
          ) : (
            <p>No items available</p>
          )}
        </ul>
      </div>
      {/*<p className='sidebar-category-sub-item-text'>Markalar</p>
      <div className='sidebar-category'>
        <ul className='sidebar-category-list'>
          {brands && brands.length > 0 ? (
            brands.map((item, id) => (
              <li
                key={id + item}
                className={selected.includes(item) ? 'selected' : 'sidebar-category-list-item'}
                onClick={() => { handleSelect(item); }}
              >
                {item.active === 1 ?
                  <div className="active-category-circle"></div>
                 : 
                  <div className="no-active-category-circle"></div>
                }
                <a className='list-link' href='#'>
                  {item.brand} <span className="sidebar-item-count-text">({item.count})</span>
                </a>
              </li>
            ))
          ) : (
            <p>No items available</p>
          )}
        </ul>
          </div>*/}
    </div>
  );
}
