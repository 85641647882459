import React from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

// Slider
import YProductSlider from '../../../Components/Product/YProductSlider';

// Components
import YImage from '../../../Components/Basic/YImage';

// Styles
import './styles/relatedItemsStyles.css';

export default function Related(props) {
  const { data, onEvent, basket, navigate, useLocation } = props;

  return (
    <div className="home-product-slider-container">
      <MDBRow>
        <MDBCol size="12" lg="3">
          <YImage
            imageSource={require('../../../Assets/Images/meyve.png')}
            inComingStyle={'teb-image'}
          />
        </MDBCol>
        <MDBCol size="12" lg="9">
          <YProductSlider
            autoPlay={true}
            showArrow={true}
            showDots={true}
            data={data}
            countItems={4}
            onEvent={onEvent}
            basket={basket}
            navigate={navigate}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
}
