import React, { useState } from 'react';
import { MDBCol } from 'mdb-react-ui-kit';

// Components
import YTouchableContainer from '../../../Components/Basic/YTouchableContainer';
import YSingle from '../../../Components/Product/YSingle';

// Styles
import '../styles/ProductsStyles.css';

export default function Products(props) {
  const {
    data,
    inComingCardWidth,
    onEvent,
    basket,
    navigate,
    token,
    onLoginModal,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentDatas = data?.slice(indexOfFirstProduct, indexOfLastProduct);

  return (
    <>
      {data?.map((data, index) => (
        <MDBCol key={index} md="4">
          <YTouchableContainer incomingStyle="touchable ">
            <YSingle
              onEvent={onEvent}
              basket={basket}
              inComingCardWidth={inComingCardWidth}
              data={data}
              navigate={navigate}
              token={token}
              onLoginModal={onLoginModal}
            />
          </YTouchableContainer>
        </MDBCol>
      ))}
    </>
  );
}
