import { SET_COMMERCIAL_INFOS, SET_CONTACTOPTIONS } from '../Constants/action-types';

const initialState = [

]

export default function commercial(state = initialState, action) {
    switch (action.type) {
        case SET_COMMERCIAL_INFOS:
            return { ...state, commercial: action.payload };
        case SET_CONTACTOPTIONS:
            return { ...state, commercialContact: action.payload };
        default:
            return state
    }
};
