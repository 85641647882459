import React, { useState } from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import axios from 'axios';
import cookie from 'js-cookie';

// Components
import YImage from '../../../Components/Basic/YImage';
import YModal from '../../../Components/Basic/YModal';

// Styles
import './styles/StepsStyles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Icons
import WarnIcon from '../../../Assets/Icons/warnIcon.png';

export default function Address(props) {
  const type = props.type;

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [tempAddress, setTempAddress] = useState('');

  const onTryToDeleteAddress = item => {
    props.onDeleteAddress(item);
    /*let market = cookie.get("marketId");
        axios.get(process.env.REACT_APP_BASE_URL_V2+'market/store-around?pageNumber=1&pageSize=1&latitude='+item.latitude+'&longitude='+item.longitude , {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY }
        })
        .then((response) => {
            let addressMarketId = response.data.data[0].marketId;
            if(Number(market) === addressMarketId) {
                console.log(addressMarketId);
                setTempAddress(item)
                setUserAddressModal(true);
            }
            else {
                props.onDeleteAddress(item)
            }
    });*/
  };

  const onApprove = () => {
    props.onDeleteAddress(tempAddress);
    setUserAddressModal(false);
  };

  const cancelChange = () => {
    setUserAddressModal(false);
  };

  const selectedClassName = 'payment-address-section-border-color';

  return (
    <>
      {props.userAddress?.map((item, i) => {
        if (type === item.addressType) {
          return (
            <MDBCard
              className="rounded-3 step-adddress-box-container"
              key={item.title + i}
            >
              <MDBCardBody
                className={
                  props.selectedUserAddressDeliveryBilling === item.addressId
                    ? selectedClassName
                    : ''
                }
              >
                <MDBRow>
                  <MDBCol
                    lg="1"
                    size="1"
                    onClick={() => props.onClickAddress(item)}
                  >
                    {props.selectedUserAddressDeliveryBilling ===
                    item.addressId ? (
                      <input
                        type="radio"
                        id={item.addressTitle}
                        name={props.name}
                        value={item}
                        checked
                      />
                    ) : (
                      <input
                        type="radio"
                        id={item.addressTitle}
                        name={props.name}
                        value={item}
                      />
                    )}
                  </MDBCol>
                  <MDBCol
                    lg="11"
                    size="11"
                    onClick={() => props.onClickAddress(item)}
                  >
                    <p className="order-address-user-title-text">
                      {item.title}
                    </p>
                    <p className="order-address-user-details-text">
                      {item.addressLine}
                    </p>
                  </MDBCol>
                  <div onClick={() => onTryToDeleteAddress(item)}>
                    <YImage
                      imageSource={require('../../../Assets/Images/delete.png')}
                      inComingStyle={'step-delete-icon'}
                    />
                  </div>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          );
        } else {
          return null;
        }
      })}
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <div>
          <div className="user-address-item-warn-steps">
            <YImage
              imageSource={WarnIcon}
              inComingStyle={'user-address-item-warn-steps'}
            />
          </div>
          <div className="address-title-alert-container">
            <p>
              Bu adresinize teslim edilecek devam eden bir alışverişiniz mevcut.
              Adresinizi silerseniz sepetinizdeki ürünlerin bazılarını
              kaybedebilirsiniz.
            </p>
          </div>
          <div className="address-alert-contorller-container">
            <div
              className="address-alert-accpet-container"
              onClick={() => onApprove()}
            >
              <p className="address-alert-text">Anladım, evet sil</p>
            </div>
            <div
              className="address-alert-cancel-container"
              onClick={() => cancelChange()}
            >
              <p className="address-alert-text">Vazgeç</p>
            </div>
          </div>
        </div>
      </YModal>
    </>
  );
}
