import React from 'react';
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';

// Components
import YImage from '../../Components/Basic/YImage';
import TrackOrder from './TrackOrder';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

export default function OrderTrackDetails(props) {
  const {
    onSubmit,
    track,
    token,
    perviosOrders,
    orderTrachNumber,
    orderTrackDetails,
  } = props;

  console.log(orderTrackDetails);
  return (
    <>
      <h4 className="reoder-title-top">Sipariş Takibi</h4>
      {track === 1 ? (
        <div className="re-order-box-padding">
          {token !== false ? (
            <>
              {perviosOrders.length !== null && perviosOrders.length !== 0 ? (
                <MDBContainer>
                  <MDBRow>
                    <MDBCol
                      xs="12"
                      sm="3"
                      lg="3"
                      className="table-reoder-title-text table-reoder-title-text-responsive "
                    >
                      Tarih
                    </MDBCol>
                    <MDBCol
                      xs="12"
                      sm="3"
                      lg="3"
                      className="table-reoder-title-text table-reoder-title-text-responsive "
                    >
                      Tutar
                    </MDBCol>
                    <MDBCol
                      xs="12"
                      sm="3"
                      lg="3"
                      className="table-reoder-title-text table-reoder-title-text-responsive "
                    >
                      Sipariş No
                    </MDBCol>
                    <MDBCol
                      xs="12"
                      sm="3"
                      lg="3"
                      className="table-reoder-title-text table-reoder-title-text-responsive "
                    >
                      Sipariş Durumu
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              ) : null}
            </>
          ) : null}
          <TrackOrder
            token={token}
            perviosOrders={perviosOrders}
            onSubmit={onSubmit}
          />
        </div>
      ) : (
        <div>
          <p className="track-order-title-top-result">
            <b>{orderTrachNumber}</b> No’lu Siparişi
          </p>
          {orderTrackDetails?.length === 4 ? (
            <div className="re-order-box-padding-one">
              <MDBRow>
                <MDBCol
                  xs="2"
                  size="2"
                  lg="2"
                  className="table-reoder-title-text"
                >
                  {orderTrackDetails[0].colorRGBCode === '#FBD453' ? (
                    <YImage
                      imageSource={require('../../Assets/Images/Packet- Yellow.png')}
                      inComingStyle={'track-order-sep'}
                    />
                  ) : (
                    <YImage
                      imageSource={require('../../Assets/Images/Packet- Grey.png')}
                      inComingStyle={'track-order-sep'}
                    />
                  )}
                  <p
                    className="track-text-green"
                    style={{ color: orderTrackDetails[0].colorRGBCode }}
                  >
                    {orderTrackDetails[0].description}
                  </p>
                </MDBCol>
                <MDBCol
                  xs="1"
                  size="1"
                  lg="1"
                  className="table-reoder-title-text"
                >
                  <YImage
                    imageSource={require('../../Assets/Images/sep-green.png')}
                    inComingStyle={'track-order-sep-border'}
                  />
                </MDBCol>
                <MDBCol
                  lg="2"
                  xs="2"
                  size="2"
                  className="table-reoder-title-text"
                >
                  {orderTrackDetails[1].colorRGBCode === '#FBD453' ? (
                    <YImage
                      imageSource={require('../../Assets/Images/Basket- Yellow.png')}
                      inComingStyle={'track-order-sep-basket'}
                    />
                  ) : (
                    <YImage
                      imageSource={require('../../Assets/Images/Basket- Grey.png')}
                      inComingStyle={'track-order-sep-basket'}
                    />
                  )}
                  <p
                    className="track-text-green"
                    style={{ color: orderTrackDetails[1].colorRGBCode }}
                  >
                    {orderTrackDetails[1].description}
                  </p>
                </MDBCol>
                <MDBCol
                  lg="1"
                  xs="1"
                  size="1"
                  className="table-reoder-title-text"
                >
                  <YImage
                    imageSource={require('../../Assets/Images/sep-grey.png')}
                    inComingStyle={'track-order-sep-one'}
                  />
                </MDBCol>
                <MDBCol
                  lg="2"
                  xs="2"
                  size="2"
                  className="table-reoder-title-text"
                >
                  {orderTrackDetails[1].colorRGBCode === '#FBD453' ? (
                    <YImage
                      imageSource={require('../../Assets/Images/Motorcycle-Yellow.png')}
                      inComingStyle={'track-order-sep-basket'}
                    />
                  ) : (
                    <YImage
                      imageSource={require('../../Assets/Images/Motorcycle-Grey.png')}
                      inComingStyle={'track-order-sep-basket'}
                    />
                  )}
                  <p
                    className="track-text-green"
                    style={{ color: orderTrackDetails[2].colorRGBCode }}
                  >
                    {orderTrackDetails[2].description}
                  </p>
                </MDBCol>
                <MDBCol
                  lg="1"
                  xs="1"
                  size="1"
                  className="table-reoder-title-text"
                >
                  <YImage
                    imageSource={require('../../Assets/Images/sep-grey.png')}
                    inComingStyle={'track-order-sep-one'}
                  />
                </MDBCol>
                <MDBCol
                  lg="2"
                  xs="2"
                  size="2"
                  className="table-reoder-title-text"
                >
                  {orderTrackDetails[1].colorRGBCode === '#FBD453' ? (
                    <YImage
                      imageSource={require('../../Assets/Images/Location-Yellow.png')}
                      inComingStyle={'track-order-sep-basket'}
                    />
                  ) : (
                    <YImage
                      imageSource={require('../../Assets/Images/Location - Grey.png')}
                      inComingStyle={'track-order-sep-basket'}
                    />
                  )}
                  <p
                    className="track-text-green"
                    style={{ color: orderTrackDetails[3].colorRGBCode }}
                  >
                    {orderTrackDetails[3].description}
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
