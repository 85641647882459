export const teslimatBolgesi = 
`
<div class="modal-content">
    <div _ngcontent-serverapp-c10="" class="modal-header">
        <h4 _ngcontent-serverapp-c10="" class="modal-title pull-left">Teslimat Bölgesi</h4> 
    </div> <br/>
    <div _ngcontent-serverapp-c10="" class="modal-body">

        <p>Özhan Sanal Market'te minimum sipariş tutarı 75 TL'dir. Siparişleriniz için Özhan Sanal Market ekibi
            tarafından yapılan teslimatlar için 125TL ve üzeri alışverişleriniz için ek hizmet bedeli
            alınmamaktadır.&nbsp;<br>
            Teslimat bölgesi dışında kalan teslimat adreslerine sipariş alınmamaktadır.<br>
            Özhan Sanal Market'te online stok sistemi kullanıldığı için Bölge Seçim ekranında yapacağınız seçimle
            bulunduğunuz ya da siparişinizin teslim edilmesini istediğiniz bölgeye hizmet veren mağazanın stoklarına
            bağlanırsınız. Yapacağınız bu seçim size en kaliteli hizmeti vermemiz bakımından önem taşımaktadır.</p>

        <p>Bölge Seçim ekranında yapacağınız seçimi, siparişinizin teslim edilmesini istediğiniz bölgeye göre yapmanız
            gerekmektedir.</p>

        <p>Özhan Sanal Market’in hizmet verdiği il ve semtleri Mağaza Seçim ekranından görebilirsiniz.</p>

        <p>Özhan Sanal Market’ten oluşturduğunuz siparişiniz, Özhan Sanal Market ekibi tarafından hazırlandıktan sonra,
            seçmiş olduğunuz teslimat adresinize Özhan Sanal Market araçları ile Özhan Sanal Market ekibi tarafından
            teslim edilmektedir.&nbsp;</p>

    </div>
   
</div>
`