import React, { useEffect, useState, useRef } from "react";
import { Nav } from "react-bootstrap";
import { listenForOutsideClick } from "../../Assets/Utils/ListenForOutsideClicks";


//Component
import YText from '../Basic/YText';
import YImage from '../Basic/YImage';
import YBasket from './YBasket';

// Styles
import "./styles/YMenuStyles.css";

export default function YMenu(props) {
  const {
    totalPrice,
    basket,
    onDeleteItem,
    updateBasket,
    onEvent,
    navigate
  } = props;

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  

  useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));


  return (
    <div ref={menuRef} className={isOpen ? "m-menu -active" : "m-menu "}>
        <div onClick={toggle}>
            <div id="basket">
                <YImage imageSource={require('../../Assets/Images/sepet.png')} inComingStyle={'basket-icon'}/>
                <div className='number-of-item-in-basket-container'>
                    <span>{basket?.length > 0 ? basket?.length : 0}</span>
                </div>
                <div>
                    <YText value={'Sepetim'} type={'p'} incomingStyle={'basket-title-card'}/>
                    {Number(totalPrice) === 8 ?
                      <YText value={'0.00 TL'} type={'p'} incomingStyle={'basket-total-price-text-card'}/>
                    :
                      <YText value={Number(totalPrice).toFixed(2)+' TL'} type={'p'} incomingStyle={'basket-total-price-text-card'}/>
                    }
                </div>
                <YImage imageSource={require('../../Assets/Images/down.png')} inComingStyle={'arrow-down-basket'}/>
            </div>
        </div>
      <div className="m-menu__list">
        <YBasket 
          basket={basket} 
          onDeleteItem={onDeleteItem} 
          updateBasket={updateBasket} 
          onEvent={onEvent} 
          navigate={navigate}
        /> 
      </div>

    </div>
  );
}
