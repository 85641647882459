import React, { useState , useEffect } from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBInput,
    MDBRow,
} from "mdb-react-ui-kit";
import classnames from "classnames";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import valid from 'card-validator';

// Components
import YTheme from "../../../Components/Layout/YTheme";
import YContainer from "../../../Components/Layout/YContainer";

import { YBasketHanlder , YBasketDeleteItem} from '../../../Components/Product/YBasketHanlder';
import { onChangeProvinceHandler , onChangeDistrictHandler , onChangeNeighborhoodHandler , onChangeShopListHandler} from '../../../Components/Address/YAddressHandler';


// Header & Footer
import Header from '../../../Assets/Utils/Header';
import Footer from '../../../Assets/Utils/Footer';

// Redux
import { getBasket , setBasket } from '../../../Redux/Actions/BasketAction';
import { getProvince , getDistrict , getNeighborhood , getShopName } from '../../../Redux/Actions/DeliveryAction';

// Styles
import '../styles/AddNewCard.css';

// Utils
import UserMenu from '../../../Assets/Utils/UserMenu';
import { isObjEmpty } from "../../../Assets/Utils/Utility";

export default function AddNewCard() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const basket = useSelector((state) => state.basket.basket);

    const province = useSelector((state) => state.delivery.province);

    const district = useSelector((state) => state.delivery.district);
  
    const neighborhood = useSelector((state) => state.delivery.neighborhood);
  
    const shopList = useSelector((state) => state.delivery.shop);
    
    const [items, setItems] = useState([]);

    const [cartVisiable, setCartVisiable] = useState(false);

    const [totalPrice, setTotalPrice] = useState(0);

    const [deliveryType, setDeliveryType] = useState(0);

    const [userAddress , setUSerAddress] = useState('');
  
    const [provinceData , setProvinceData] = useState(0);
  
    const [districtData , setDistrictData] = useState(0);
  
    const [neighborhoodData , setNeighborhoodData] = useState(0);
  
    const [shopData , setShopData] = useState(0);

    const [locationVisiable, setLocationVisiable] = useState(false);

    const [formState, setFormState] = useState({
        cardName: '',
        cardNumber : '',
        cardExp: '',
        cardSecurity: '',
    });

    const SignInSchema = yup.object().shape({
        cardName: yup.string().required("Card holder name is reqiured").min(3),
        cardNumber: yup.string().test('test-number', // this is used internally by yup
            'Credit Card number is invalid', //validation message
            value => valid.number(value).isValid).required(),
        cardExp : yup.string().typeError('Not a valid expiration date. Example: MM/YY')
            .max(5, 'Not a valid expiration date. Example: MM/YY')
            .matches(
                /([0-9]{2})\/([0-9]{2})/,
                'Not a valid expiration date. Example: MM/YY'
            )
            .required('Expiration date is required'),
        cardSecurity : yup.string().required('Card security number is reqiured').min(3).max(4)
    });

    const { register, handleSubmit , formState: { errors } } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(SignInSchema),
    });

    const onSubmit = () => {
        if (isObjEmpty(errors)) {}
    };

    useEffect(() => {
        dispatch(getBasket());
        dispatch(getProvince());
        let data = cookie.get('userAddress');
        if(data !== undefined)
        {
          data = JSON.parse(data);
          setUSerAddress(data.address);
        }
     }, []);

     useEffect(() => {
        let data = {
          address : userAddress,
          deliveryType : deliveryType,
          provinceData : provinceData,
          districtData : districtData,
          neighborhoodData : neighborhoodData,
          shopData : shopData,
        };
        cookie.set("userAddress", JSON.stringify(data));
      }, [userAddress]);

    useEffect(() => {
        if(typeof basket !== 'undefined')
        {
            setItems([...basket]);
            let count = 0;
            basket.map((item) => {
                count = count + (item.discountPrice * item.count);
            });
            setTotalPrice(count);
        }
    }, [basket]);

    const onEvent = (data , count , type) => {
        YBasketHanlder(type , setCartVisiable , basket , data , count , dispatch , setBasket , getBasket);
    };
    
    const onDeleteItem = (e) => {
        YBasketDeleteItem(e , basket , dispatch , setBasket);
    };
    
    const onChangeProvince = (e , text) => {
        onChangeProvinceHandler(e , text , dispatch , getDistrict , setProvinceData , setUSerAddress);
    };
    
    const onChangeDistrict = (e , text) => {
        onChangeDistrictHandler(e , text , dispatch , getNeighborhood , getShopName , setDistrictData , userAddress , setUSerAddress)
    };
    
    const onChangeNeighborhood = (e , text) => {
        onChangeNeighborhoodHandler(e , text , setLocationVisiable , setNeighborhoodData , userAddress , setUSerAddress , setDeliveryType)
    };
    
    const onChangeShopList = (e , text) => {
        onChangeShopListHandler(e , text , setLocationVisiable , setShopData , userAddress , setUSerAddress , setDeliveryType);
    };

    return (
       <YTheme>
            <Header
                basket={basket}
                cartVisiable={cartVisiable}
                setCartVisiable={setCartVisiable}
                navigate={navigate}
                totalPrice={totalPrice}
                onEvent={onEvent}
                onDeleteItem={onDeleteItem}
                province={province}
                onChangeProvince={onChangeProvince}
                district={district}
                onChangeDistrict={onChangeDistrict}
                neighborhood={neighborhood}
                shopList={shopList}
                onChangeNeighborhood={onChangeNeighborhood}
                onChangeShopList={onChangeShopList}
                locationVisiable={locationVisiable}
                setLocationVisiable={setLocationVisiable}
                deliveryType={deliveryType}
                userAddress={userAddress}
            />
            <YContainer>
                <MDBContainer className="py-5 h-100">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol>
                            <MDBCard>
                                <MDBCardBody className="p-4">
                                    <MDBRow>
                                        <MDBCol lg="2">
                                            <UserMenu userAddress={'user-cards'} />
                                        </MDBCol>
                                        <MDBCol lg="10">
                                            <MDBCard className="rounded-3">
                                                <MDBCardBody>
                                                    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                                                        <MDBInput 
                                                            className={classnames({ "is-invalid": errors["cardName"] } , 'mb-4') }
                                                            type="text" 
                                                            name="cardName"
                                                            id="cardName"
                                                            {...register('cardName')} 
                                                            placeholder="Card holder Name" 
                                                            contrast
                                                            onChange={(e) =>
                                                                setFormState({
                                                                    ...formState,
                                                                    [e.target.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        {errors.cardName?.message && <p className="error">{errors.cardName?.message}</p>}


                                                        <MDBInput 
                                                            className={classnames({ "is-invalid": errors["cardNumber"] } , 'mb-4') }
                                                            type="number"
                                                            name="cardNumber"
                                                            id="cardNumber"
                                                            {...register('cardNumber')} 
                                                            placeholder="1234 5678 9012 3457"
                                                            contrast
                                                            onChange={(e) =>
                                                                setFormState({
                                                                    ...formState,
                                                                    [e.target.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        {errors.cardNumber?.message && <p className="error">{errors.cardNumber?.message}</p>}

                                                        <MDBRow>
                                                            <MDBCol lg="8">
                                                                <MDBInput
                                                                    className={classnames({ "is-invalid": errors["cardExp"] } , 'mb-4') }
                                                                    type="text"
                                                                    size="sm"
                                                                    placeholder="MM/YYYY"
                                                                    name="cardExp"
                                                                    id="cardExp"
                                                                    {...register('cardExp')} 
                                                                    contrast
                                                                    onChange={(e) =>
                                                                        setFormState({
                                                                        ...formState,
                                                                        [e.target.name]: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors.cardExp?.message && <p className="error">{errors.cardExp?.message}</p>}
                                                            </MDBCol>
                                                            <MDBCol lg="4">
                                                                <MDBInput
                                                                    className={classnames({ "is-invalid": errors["cardSecurity"] } , 'mb-4') }
                                                                    type="text"
                                                                    size="sm"
                                                                    name="cardSecurity"
                                                                    id="cardSecurity"
                                                                    placeholder="CVV"
                                                                    contrast
                                                                    {...register('cardSecurity')} 
                                                                    onChange={(e) =>
                                                                        setFormState({
                                                                        ...formState,
                                                                        [e.target.name]: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors.cardSecurity?.message && <p className="error">{errors.cardSecurity?.message}</p>}

                                                            </MDBCol>
                                                        </MDBRow>
                                                        <Button variant="primary" type="submit" className='w-100 mb-4'>
                                                                Add New Card
                                                        </Button>
                                                    </form>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </YContainer>
            <Footer/>
       </YTheme>
    );
};
