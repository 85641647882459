export const gizlilikSozlesmesi = 
`
<div class="">
    <div _ngcontent-serverapp-c10="" class="">
       <br/> <h4 _ngcontent-serverapp-c10="" class="">Gizlilik Politikası</h4> <br/>
    </div>
    <div _ngcontent-serverapp-c10="" class="">
        <p>Gizliliğiniz Özhan Market için önemlidir. Bu nedenle, topladığımız, kullandığımız, açıkladığımız,
            aktardığımız ve sakladığımız bilgilerinize ilişkin bir Gizlilik Politikası oluşturulmuştur.</p>

        <p><br>
            <strong>Kişisel Bilgi Toplama ve Kullanma</strong>
        </p>

        <p>Kişisel bilgi, bir kişiyi tanımlamak veya onunla iletişim kurmak için kullanılabilecek veriyi ifade
            etmektedir.</p>

        <p>Özhan Market ile iletişim halindeyken kişisel bilgilerinizi vermeniz istenebilir. Özhan Market, bu kişisel
            bilgileri işbu Gizlilik Politikası'na uygun olarak kullanabilir. Özhan Market, ürünlerini, hizmetlerini,
            içeriğini ve reklamlarını hazırlamak ve geliştirmek için bu bilgileri diğer bilgilerle birleştirebilecektir.
        </p>

        <p>Web sitemize girdiğiniz veya başka bir şekilde web sitemize verdiğiniz bilgiler (Kredi kartı bilgileriniz
            hariç olmak üzere) saklanmaktadır. Örneğin, bir sipariş verdiğinizde, bize kayıt olduğunuzda, bize e-posta
            gönderdiğinizde veya bizi aradığınızda, bir çekilişe katıldığınızda verdiğiniz bilgiler toplanmaktadır. Bu
            bilgiler aşağıdakileri (bunlar ile sınırlı olmamak üzere) içerebilir:</p>

        <ul>
            <li>Ad,soyad</li>
            <li>Posta adresi</li>
            <li>E-posta adresi</li>
            <li>Telefon numarası</li>
            <li>TC kimlik numarası</li>
        </ul>

        <p>Bu bilgiler ayrıca, örneğin siparişin teslim edileceği kişinin adı ve adresi gibi, diğer insanlar hakkında
            verdiğiniz bilgileri de içerebilmektedir.</p>

        <p>Sizden e-posta yoluyla şifre, kullanıcı adı, kredi kartı bilgileri veya diğer kişisel bilgileri asla talep
            edilmemektedir. "Phishing" adı verilen bu uygulama, kişisel bilgilerinizi çalmaya yönelik bir düzmecedir.
            Bizden gelmiş gibi görünen, ancak kişisel bilgilerinizi talep eden bir mesaj aldığınızda, yanıt
            vermemelisiniz.</p>

        <p><strong>Kişisel bilgilerinizin kullanımı</strong></p>

        <ul>
            <li>Topladığımız kişisel bilgiler Özhan Market’in en son ürün duyuruları ve gelecekteki etkinlikleri
                hakkında sizi bilgilendirmemize olanak vermektedir. Ayrıca, hizmetlerimizi, içeriğimizi ve
                reklamlarımızı geliştirmemize yardımcı olmaktadır. Duyuru listemizde yer almak istemiyorsanız,
                tercihlerinizi güncelleyerek dilediğiniz zaman ayrılabilirsiniz.</li>
            <li>Zaman zaman kişisel bilgiler, satın almalar hakkındaki mesajlar, kurallar, koşullar ve
                politikalarımızdaki değişiklikler gibi önemli uyarıları göndermek için kullanılabilmektedir.</li>
            <li>Kişisel bilgileriniz, Özhan Market ürünleri, hizmetleri ve müşteri iletişimini geliştirmek için denetim,
                veri analizi ve araştırma gibi amaçlarla kullanılabilecektir.</li>
            <li>Bir çekiliş, yarışma veya benzer bir promosyona katıldığınızda, bilgileriniz bu programların yönetiminde
                kullanılabilecektir.</li>
        </ul>

        <p><strong>Kişisel Olmayan Bilgi Toplama ve Kullanma</strong></p>

        <p>Herhangi bir bireyle doğrudan ilişkisi olmayan veriler niteliğindeki kişisel olmayan bilgileriniz de
            toplanabilmektedir. Kişisel olmayan bilgileriniz herhangi bir amaç için toplanabilir, kullanılabilir,
            aktarılabilir veya açıklanabilir. Topladığımız kişisel olmayan bilgilere ve bunları nasıl kullandığımıza
            dair örnekler aşağıda verilmiştir:</p>

        <ul>
            <li>Meslek, lisan, alan kodu, Site'nin kullanıldığı lokasyon ve saat gibi bilgileri, müşteri davranışını
                daha iyi anlayabilmek, ürünlerimizi, hizmetlerimizi ve reklamlarımızı geliştirmek amacıyla
                toplanabilmektedir.</li>
            <li>Web sitemizden ve diğer ürün ve hizmetlerimizden müşteri faaliyetlerine ilişkin bilgiler
                toplanabilmektedir. Bu bilgiler bir araya getirilerek, müşterilerimize daha faydalı bilgiler sağlamak ve
                web sitemizin, ürünlerimizin ve hizmetlerimizin en çok ilgi çeken kısımlarını anlamak için
                kullanılmaktadır. Birleştirilen veriler, işbu Gizlilik Politikası bakımından kişisel olmayan bilgi
                olarak değerlendirilir.</li>
        </ul>

        <p>Kişisel olmayan bilgileri kişisel bilgilerle birleştirdiğimizde, birleştirilmiş bilgiler, birleşik kaldığı
            sürece kişisel bilgi olarak kabul edilmektedir.</p>

        <p><br>
            <strong>Cookie'ler ve Diğer Teknolojiler</strong>
        </p>

        <p>Özhan Market web sitesi, online servisleri, interaktif uygulamaları, e-posta mesajları ve reklamları
            "cookie"ler , "pixel tag"leri ve "web beacon"ları gibi diğer teknolojileri kullanabilmektedir. Bu
            teknolojiler, kullanıcı davranışını daha iyi anlamamıza yardımcı olup, insanların web sitemizin hangi
            bölümlerini ziyaret ettiklerini göstermekte, reklamların ve web aramalarının etkinliğini ölçmektedir.
            Cookie'ler ve diğer teknolojilerle toplanan bilgiler kişisel olmayan bilgiler olarak kabul edilmektedir.</p>

        <p>Cookie'ler ve diğer teknolojiler, web sitemizi, online hizmetlerimizi ve uygulamalarımızı kullandığınız zaman
            kişisel bilgilerinizi anımsamak için de kullanılmaktadır. Bu durumlarda amacımız, Özhan Market'taki
            deneyiminizi kolaylaştırmak ve kişiselleştirmektir. Örneğin, adınızı bilmemiz, Özhan Sanal Market'i bir
            sonraki ziyaretinizde sizi anımsamamızı sağlamaktadır. Coğrafi konum tercihinizi bilmemiz, özel ve
            kolaylaştırılmış bir alışveriş deneyimi sunmamıza olanak vermektedir.</p>

        <p>Çoğu web sitesinde olduğu gibi, İnternet Protokolü (IP) adresleri, tarayıcı tipi ve lisanı, internet servis
            sağlayıcısı (ISP), referans ve çıkış sayfaları, işletim sistemi, tarih/zaman damgası ve tıklama verileri
            gibi bazı bilgiler otomatik olarak toplanmakta ve kaydedilmektedir.</p>

        <p>Bu bilgiler, trendleri anlamak ve analiz etmek, siteyi yönetmek, sitedeki kullanıcı davranışını öğrenmek ve
            bütün olarak kullanıcı tabanımız hakkında demografik bilgiler toplamak için kullanılmaktadır. Özhan Market,
            bu bilgileri, pazarlama ve reklam hizmetlerinde de kullanabilecektir.</p>

        <p><br>
            <strong>Üçüncü Şahıslarla Paylaşım</strong>
        </p>

        <p>Özhan Market, kişisel olmayan bilgileri, ürün ve hizmet sağlamak veya Özhan Market’in müşterilere yönelik
            pazarlama faaliyetlerine yardımcı olmak amacıyla Özhan Market ile çalışan stratejik ortaklara
            verebilmektedir. Kişisel olmayan bilgiler sadece ürünlerimizi, hizmetlerimizi ve reklamlarımızı geliştirmek
            amacıyla ve Gizlilik Politikamızın kurallarına uygun olarak Özhan Market tarafından paylaşılacaktır.</p>

        <p><strong>Güvenli Alışveriş</strong></p>

        <p>Kredi kartı numaranız online kredi kartı uygulamamız tarafından şifrelenerek bankanıza iletilmekte ve üçüncü
            şahıslarla asla paylaşılmamaktadır.</p>

        <p>Özhan Market, tamamen kendi insiyatifinde olmak üzere, müşterilerinin sipariş tamamlamaları için 3D ödeme
            seçeneğini zorunlu kılabilir.</p>

        <p><strong>Diğerleri</strong></p>

        <p>Kanun, hukuki süreç, davalar ve/veya kamu yetkililerinden gelen talep ile Özhan Market’in kişisel
            bilgilerinizi açıklaması gerekebilir. Milli güvenlik, kanunların uygulanması veya kamu için öneme sahip
            diğer konularda paylaşımın zorunlu olduğu hallerde bilgileriniz açıklanabilecektir.</p>

        <p><strong>Kişisel Bilgilerin Kullanımı</strong></p>

        <p>Özhan Market kişisel bilgilerinizin, kaybolma, hırsızlık ve suiistimale, ayrıca yetkisiz erişim, paylaşım,
            değişiklik ve imhaya karşı korunması için idari, teknik ve fiziksel tedbirler dahil olmak üzere tüm
            önlemleri almaktadır. Özhan &nbsp;Sanal Market online servisler ile kişisel bilgilerin toplandığı tüm web
            sayfalarında Secure Sockets Layer (SSL) şifrelemesini kullanmaktadır. Bu servislerden ürün almak için,
            Safari, Firefox veya Internet Explorer gibi SSL-destekli bir tarayıcı kullanmanız gerekmektedir. Bu sayede,
            İnternet üzerinden iletilen kişisel bilgilerinizin gizliliğini koruyabilirsiniz.</p>

        <p>Kişisel Bilgilerin Bütünlüğü ve Saklanması</p>

        <p>Özhan Market, kişisel bilgilerinizi doğru, eksiksiz ve güncel bir şekilde saklamayı kolaylaştırmaktadır.
            Kanunen daha uzun bir saklama dönemi gerekmediği veya müsaade edilmediği sürece kişisel bilgileriniz bu
            Gizlilik Politikası'nda belirtilen amaçları gerçekleştirmek için kanunen geçerli olan süre kadar
            saklanmaktadır.</p>

        <p><strong>Kişisel Bilgilere Erişim</strong></p>

        <p>Site adreslerimizden hesabınıza ulaşarak, iletişim bilgilerinizin ve tercihlerinizin doğru, eksiksiz ve
            güncel olmasını sağlayabilirsiniz.</p>

        <p><br>
            <strong>Konum Tabanlı Servisler</strong>
        </p>

        <p>Özhan Market ürünlerine ilişkin konum tabanlı servisler sağlamak amacıyla, Özhan Market bilgisayarınızın veya
            cihazınızın gerçek zamanlı coğrafi konumu gibi hassas konum bilgilerini toplayıp, kullanıp ve
            paylaşabilmektedirler. Bu konum verileri sizi kişisel olarak tanımlamayacak bir şekilde isimsiz olarak
            toplanıp Özhan Market tarafından konum tabanlı ürün ve hizmetlerin sağlanması ve geliştirilmesi amacıyla
            kullanılmaktadır. Bu politikayı kabul etmediğiniz takdirde Özhan Market tarafından sunulan bazı konum
            tabanlı servisleri kullanamayacaksınız.</p>

        <p>Üçüncü Şahıs Siteleri ve Servisleri</p>

        <p>Özhan Market web siteleri, ürünleri, uygulamaları ve servislerinde, üçüncü şahıs web siteleri, ürünleri ve
            hizmetlerine ait linkler bulunabilir. Ürünlerimiz ve servislerimizle beraber üçüncü şahısların ürünleri veya
            servisleri kullanılabilmekte veya sunulabilmektedir. Konum verileri veya iletişim bilgileri gibi şeyleri
            içeren, üçüncü şahıslar tarafından toplanan bilgiler, üçüncü şahısların gizlilik politikalarına tabidirler.
            Üçüncü şahısların gizlilik uygulamalarını kendilerinden öğrenmenizi tavsiye ederiz.</p>

        <p><br>
            <strong>Gizlilik Soruları</strong>
        </p>

        <p>Özhan Market’in Gizlilik Politikası veya bilgi işlem konusunda sorularınız veya endişeleriniz varsa, bizimle
            irtibat kurunuz. Özhan Market bu Gizlilik Politikasını zaman zaman güncelleyebilir. Bu politikayı önemli bir
            biçimde değiştirdiğimizde, web sitemizde güncellenmiş Gizlilik Politikası ile beraber bir uyarı
            yayınlanacaktır.</p>
    </div>
</div>
`