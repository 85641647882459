import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdb-react-ui-kit';

// Styles
import '../Css/general.css';

// Components
import YImage from '../../Components/Basic/YImage';

// CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Header(props) {
  const navigate = useNavigate();

  const handleLinkClick = id => {
    navigate({
      pathname: '/contracts',
      search: `?id=${id}`,
    });
  };

  return (
    <MDBFooter className="text-center text-lg-start footer-first">
      <section className="footer-second-container">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="2" lg="3" xl="2" className="mx-auto mb-4">
              <h6
                id="footer-container-first-section-ozhan"
                className="text-uppercase fw-bold mb-4 text-md-center"
              >
                <YImage
                  imageSource={require('../../Assets/Images/ozhan.png')}
                  inComingStyle={'logo'}
                />
              </h6>
              <div id="footer-container-ozhan-icons" className="text-md-center">
                <a
                  href="https://www.facebook.com/OzhanMarket/"
                  className="me-2 text-reset hover-overlay footer-container-icons"
                >
                  <MDBIcon fab icon="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/ozhanmarket/?hl=en"
                  className="me-2 text-reset hover-overlay footer-container-icons"
                >
                  <MDBIcon fab icon="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/@OzhanMarketim"
                  className="me-2 text-reset hover-overlay footer-container-icons"
                >
                  <MDBIcon fab icon="youtube" />
                </a>
                <a
                  href="https://twitter.com/ozhanmarket?lang=en"
                  className="me-2 text-reset hover-overlay footer-container-icons"
                >
                  <MDBIcon fab icon="twitter" />
                </a>
                <a
                  href="#https://www.linkedin.com/company/ozhan-market/?originalSubdomain=tr"
                  className="me-2 text-reset hover-overlay footer-container-icons"
                >
                  <MDBIcon fab icon="linkedin" />
                </a>
              </div>
            </MDBCol>

            <MDBCol
              md="2"
              lg="2"
              xl="2"
              className="mx-auto mb-4 footer-link ms-1"
            >
              <h6 className="text-uppercase fw-bold mb-4">
                ALIŞVERİŞ ve SİPARİŞ
              </h6>
              <p>
                <a
                  href="/user-information"
                  className="text-reset hover-overlay footer-text-link"
                >
                  Hesabım
                </a>
              </p>
              <p>
                <a
                  href="/user-order-history"
                  className="text-reset hover-overlay footer-text-link"
                >
                  Siparişler
                </a>
              </p>
              <p>
                <a
                  href="/user-address"
                  className="text-reset hover-overlay footer-text-link"
                >
                  Adres
                </a>
              </p>
              <p>
                <a
                  href="/basket"
                  className="text-reset hover-overlay footer-text-link"
                >
                  Alışveriş Sepeti
                </a>
              </p>
            </MDBCol>

            <MDBCol md="5" lg="3" xl="3" className="mx-auto mb-4 footer-link">
              <h6 className="text-uppercase fw-bold mb-4">YARDIM</h6>
              <p>
                <a
                  id={1}
                  onClick={() => handleLinkClick(1)}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  KVKK Aydınlatma Metni
                </a>
              </p>
              <p>
                <a
                  id={2}
                  onClick={() => handleLinkClick(2)}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  Özhan Market Bilgi Güvenliği Politikası
                </a>
              </p>
              <p>
                <a
                  id={3}
                  onClick={() => handleLinkClick(3)}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  Üyelik Sözleşmesi
                </a>
              </p>
              <p>
                <a
                  id={4}
                  onClick={() => handleLinkClick(4)}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  Gizlilik Politikası
                </a>
              </p>
              <p>
                <a
                  id={5}
                  onClick={() => handleLinkClick(5)}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  Teslimat Bölgesi
                </a>
              </p>
              <p>
                <a
                  id={6}
                  onClick={() => handleLinkClick(6)}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  Kişisel Verilerin İşlenmesi Rıza Beyanı
                </a>
              </p>
              <p>
                <a
                  id={7}
                  href={'https://www.izinizin.com/ozhanmarket'}
                  className="text-reset hover-overlay footer-text-link-contract"
                >
                  Web ve Mobil İçin İletişim İzni Alınması
                </a>
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4 footer-link">
              <h6 id="sanane" className="text-uppercase fw-bold mb-4">
                Kurumsal
              </h6>
              <p>
                <a
                  href="/contactus"
                  xl="1"
                  className="text-reset hover-overlay footer-text-link"
                >
                  Bizimle İletişime Geçin
                </a>
              </p>
              <p>
                <a
                  href="https://kurumsal.ozhan.com.tr/"
                  xl="1"
                  className="text-reset hover-overlay footer-text-link"
                >
                  https://kurumsal.ozhan.com.tr/
                </a>
              </p>
            </MDBCol>

            <MDBCol
              id="footer-cc-icons-section"
              md="3"
              lg="3"
              xl="2"
              className="mx-auto mb-md-0 mb-4 footer-link footer-link-bank"
            >
              <MDBRow>
                <MDBCol size="4">
                  <a
                    href="#!"
                    id="footer-master-icon-href"
                    type="submit"
                    className="text-white"
                  >
                    <YImage
                      imageSource={require('../../Assets/Images/master.png')}
                      inComingStyle={'footer-payment-logo'}
                    />
                  </a>
                </MDBCol>
                <MDBCol size="4">
                  <a
                    href="#!"
                    id="footer-visa-icon-href"
                    type="submit"
                    className="text-white"
                  >
                    <YImage
                      imageSource={require('../../Assets/Images/visa.jpg')}
                      inComingStyle={'footer-payment-logo'}
                    />
                  </a>
                </MDBCol>
                <MDBCol size="4">
                  <a
                    href="#!"
                    id="footer-amex-icon-href"
                    type="submit"
                    className="text-white"
                  >
                    <YImage
                      imageSource={require('../../Assets/Images/express 2.png')}
                      inComingStyle={'footer-payment-logo'}
                    />
                  </a>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <MDBContainer className="p-4 last-footer">
        © 2023 Copyright:
        <a
          className="text-reset fw-bold"
          href="https://vagani.com/"
          target="_blank"
          rel="noreferrer"
        >
          VAGA
        </a>
      </MDBContainer>
    </MDBFooter>
  );
}
