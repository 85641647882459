import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YImage from '../../Components/Basic/YImage';
import Progress from '../OrderPaymentReview/Components/Progress';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';
import YStep from '../../Components/Basic/YStep';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getDetailsProduct } from '../../Redux/Actions/PaymentAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

// Styles
import './styles/LastOrdersDetailsStyles.css';
import '../../Components/Basic/styles/YSeperatorStyles.css';

// Utils
import UserMenu from '../../Assets/Utils/UserMenu';

//Icons&Images
import sepetIcon from '../../Assets/Icons/sepetLastOrder.png';
import sepetEkle from '../../Assets/Images/yellowbasket.png';
import fatura from '../../Assets/Images/copy3.png';
import bilgiFisi from '../../Assets/Images/copy2.png';
import teslimat from '../../Assets/Images/copy1.png';
import backIcon from '../../Assets/Images/backIcon.png';

export default function LastOrdersDetails() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const [cartVisiable, setCartVisiable] = useState(false);

  const reorderdetails = useSelector(state => state.payment.reorderdetails);

  const addressList = useSelector(state => state.user.address);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [items, setItems] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [deliveryType, setDeliveryType] = useState(0);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    dispatch(getDetailsProduct());
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof reorderdetails !== 'undefined') {
      setItems([...reorderdetails]);
    }
  }, [reorderdetails]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        deliveryType={deliveryType}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="user-order-history-container">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol lg="3">
                    <UserMenu userAddress={'user-order'} />
                  </MDBCol>
                  <MDBCol lg="9">
                    <MDBCard className="rounded-3 user-order-history-container">
                      <MDBRow>
                        <MDBCol md="8">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol>
                                <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                                  <MDBTypography
                                    id="last-order-details-my-orders-text"
                                    className="mb-0 user-order-history-headers"
                                  >
                                    Siparişlerim
                                  </MDBTypography>
                                </div>
                                <div
                                  onClick={() =>
                                    navigate('/user-order-history')
                                  }
                                >
                                  <YImage
                                    inComingStyle="last-order-details-back-icon"
                                    imageSource={backIcon}
                                  />
                                </div>
                                <MDBCard>
                                  <MDBCardBody className="detaits-section-1">
                                    <MDBRow className="">
                                      <MDBCol>
                                        <MDBTypography className="last-order-details-order-container-no-header">
                                          Sipariş No:&nbsp;
                                          <span id="last-order-details-order-container-no">
                                            5678405
                                          </span>
                                        </MDBTypography>
                                      </MDBCol>
                                      {/*<MDBCol className='d-flex justify-content-end'>
                                                                                <span id='last-order-details-add-sepet-section' className="d-flex justify-content-end">
                                                                                    <YImage inComingStyle='last-order-details-add-sepet-icon' imageSource={sepetEkle} />
                                                                                    <MDBTypography className='last-order-details-add-sepet-text'>Siparişe Ek Yap</MDBTypography>
                                                                                </span>
                                                                            </MDBCol>*/}
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol>
                                        <MDBTypography className="last-order-details-history">
                                          24.03.2023
                                        </MDBTypography>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol>
                                        <div className="stepper-wrapper">
                                          <YStep activeStep={0} />
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <hr style={{ marginInline: '-16px' }}></hr>
                                    <MDBRow className="" id="last-order-adress">
                                      <MDBCol size="12">
                                        <MDBRow>
                                          <MDBTypography className="last-order-details-adress-text">
                                            Teslimat Adresi:
                                          </MDBTypography>
                                          <MDBTypography className="last-order-details-adress-text">
                                            Lorem ipsum dolor sit amet,
                                            consectetur Lorem, ipsum dolor.
                                            nesciunt odio possimus praesentium
                                            laudantium?
                                          </MDBTypography>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>
                                    <hr style={{ marginInline: '-16px' }}></hr>
                                    <div style={{ marginTop: '40px' }}>
                                      <MDBRow>
                                        <MDBCol
                                          id="order-details-count-header"
                                          className="col-set-set"
                                          md="2"
                                          size="2"
                                        >
                                          <MDBTypography className="d-flex justify-content-start last-order-details-product-headers">
                                            Miktar
                                          </MDBTypography>
                                        </MDBCol>
                                        <MDBCol
                                          id="order-details-basket-header"
                                          className="col-set-set"
                                          md="6"
                                          size="6"
                                        >
                                          <MDBTypography
                                            style={{ display: 'block' }}
                                            className="d-flex justify-content-start last-order-details-product-headers"
                                          >
                                            Sepetteki Ürünler<span>(9)</span>
                                          </MDBTypography>
                                        </MDBCol>
                                        <MDBCol
                                          id="order-details-price-header"
                                          className="col-set-set"
                                          md="4"
                                          size="4"
                                        >
                                          <MDBTypography className="d-flex justify-content-center last-order-details-product-headers">
                                            Ücret
                                          </MDBTypography>
                                        </MDBCol>
                                      </MDBRow>
                                      {items.map(e => (
                                        <MDBRow className="last-order-details-product-container-row">
                                          <MDBCol
                                            id="user-details-count-map"
                                            className="col-set-set"
                                            md="2"
                                            size="2"
                                          >
                                            <MDBTypography
                                              id="last-details-count"
                                              className="d-flex justify-content-start"
                                            >
                                              {e.count} kilo{' '}
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol
                                            id="order-details-basket-map"
                                            className="col-set-set"
                                            md="6"
                                            size="6"
                                          >
                                            <MDBTypography
                                              id="last-details-image"
                                              className="d-flex justify-content-start"
                                            >
                                              <YImage
                                                inComingStyle="last-order-details-product-pic"
                                                imageSource={e.image}
                                              ></YImage>
                                              <span className="last-order-details-text-title-item">
                                                {e.title}
                                              </span>
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol
                                            className="col-set-set"
                                            id="order-details-discount-price-map"
                                            md="4"
                                            style={{ display: 'block' }}
                                            size="4"
                                          >
                                            <MDBTypography
                                              id="last-order-details-price-overline"
                                              className="d-flex justify-content-center"
                                            >
                                              <span id="last-order-details-price-overline-text">
                                                {e.discountPrice} TL
                                              </span>
                                            </MDBTypography>
                                            <MDBTypography
                                              id="last-order-details-price-text"
                                              className="d-flex justify-content-center"
                                            >
                                              {e.price} TL
                                            </MDBTypography>
                                          </MDBCol>
                                        </MDBRow>
                                      ))}
                                    </div>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCol>
                        <MDBCol className="" md="4">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol>
                                <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                                  <MDBTypography
                                    id="last-order-details-my-orders-text-disabled"
                                    className="mb-0 user-order-history-headers"
                                  >
                                    Siparişlerim
                                  </MDBTypography>
                                </div>
                                <YImage
                                  inComingStyle="last-order-details-back-icon-disabled"
                                  imageSource={backIcon}
                                ></YImage>
                                <MDBCard>
                                  <MDBCardBody className="detaits-section-1">
                                    <MDBRow className="">
                                      <MDBCol>
                                        <MDBRow className="">
                                          <MDBCol>
                                            <MDBTypography
                                              id="las-order-details-fish-siparis-ozeti"
                                              className="last-order-details-fish-text"
                                            >
                                              Sipariş Özeti
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol>
                                            <span className="d-flex justify-content-end">
                                              <MDBTypography className="last-order-details-fish-count">
                                                (<span>9</span> Ürün)
                                              </MDBTypography>
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="">
                                          <MDBCol>
                                            <MDBTypography className="last-order-details-fish-text">
                                              Sipariş Tutarı
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol>
                                            <span className="d-flex justify-content-end">
                                              <MDBTypography>
                                                304,95
                                              </MDBTypography>
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="">
                                          <MDBCol>
                                            <MDBTypography className="last-order-details-fish-text">
                                              Teslimat Ücreti
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol>
                                            <span className="d-flex justify-content-end">
                                              <MDBTypography>
                                                85,25
                                              </MDBTypography>
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol>
                                            <MDBTypography
                                              id="last-order-details-fish-toplam-tutar"
                                              className="d-flex justify-content"
                                            >
                                              Toplam Tutar:
                                              <span>&nbsp;343,14</span>&nbsp;TL
                                            </MDBTypography>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>
                                    <hr style={{ marginInline: '-16px' }}></hr>
                                    <MDBRow className="mt-3">
                                      <MDBCol>
                                        <MDBRow className="">
                                          <MDBCol>
                                            <MDBTypography id="last-order-details-fish-payStyle">
                                              Ödeme Şekli
                                            </MDBTypography>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="">
                                          <MDBCol>
                                            <MDBTypography>
                                              Kredi Kartı ile Online Ödeme
                                            </MDBTypography>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="last-order-details-fish-items-text ms-3">
                                          <MDBCol id="last-order-details-card2-doc-1">
                                            <MDBTypography className="last-order-details-card2-order-doc mb-1">
                                              <YImage
                                                inComingStyle="last-order-details-fish-item-image-1"
                                                imageSource={teslimat}
                                              />
                                              <span className="last-order-details-fish-item-text">
                                                &nbsp;Teslimat Formu
                                              </span>
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol id="last-order-details-card2-doc-2">
                                            <MDBTypography className="last-order-details-card2-order-doc mb-1">
                                              <YImage
                                                inComingStyle="last-order-details-fish-item-image-2"
                                                imageSource={bilgiFisi}
                                              />
                                              <span className="last-order-details-fish-item-text">
                                                &nbsp;Bilgi Fişi
                                              </span>
                                            </MDBTypography>
                                          </MDBCol>
                                          <MDBCol id="last-order-details-card2-doc-3">
                                            <MDBTypography className="last-order-details-card2-order-doc mb-1">
                                              <YImage
                                                inComingStyle="last-order-details-fish-item-image-3"
                                                imageSource={fatura}
                                              />
                                              <span className="last-order-details-fish-item-text">
                                                &nbsp;E-Arşiv Fatura
                                              </span>
                                            </MDBTypography>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
