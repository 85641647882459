import {
  SET_USER,
  SET_ADDRESS,
  LAST_ORDER,
  SET_USER_ORDER,
  SET_USER_CONSET,
} from '../Constants/action-types';
import axios from 'axios';
import cookie from 'js-cookie';

export const setUser = user => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const getShops = offset => {
  return async dispatch => {};
};

export const getAddress = () => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL_V2 + 'user/addresses', {
        'Content-Type': 'text/plain',
        accept: 'application/json',
        headers: {
          Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
          clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
        },
      })
      .then(response => {
        dispatch({
          type: SET_ADDRESS,
          payload: response.data.data,
        });
        //console.log(response);
      });
  };
};

export const lastOrders = () => {
  const data = cookie.get('token');
  if (typeof data !== 'undefined') {
    const token = JSON.parse(data);
    if (typeof token?.stsTokenManager === 'object') {
      return async dispatch => {
        await axios
          .get(process.env.REACT_APP_BASE_URL_V2 + 'user/orders', {
            headers: {
              'Content-Type': 'text/plain',
              accept: 'application/json',
              Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
              clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            },
          })
          .then(response => {
            //console.log(response);
            dispatch({
              type: LAST_ORDER,
              payload: response.data.data,
            });
            //console.log(response);
          });
      };
    } else {
      return {
        type: SET_USER,
        payload: [],
      };
    }
  } else {
    return {
      type: SET_USER,
      payload: [],
    };
  }
};

export const registerUserEmail = (email, phone) => {
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'auth/register',
        {
          email: email,
          phoneNumber: phone,
          eConsent: true,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        return response;
      });
  };
};

export const addNewAddress = (newAddressData, lat, lng, type) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'user/add-or-update-user-address',
        {
          title: newAddressData.addressTitle,
          isActive: true,
          addressLine: newAddressData.addressDetails,
          postalCode: '41001',
          districtId: Number(newAddressData.addressCity),
          latitude: Number(lat),
          longitude: Number(lng),
          addressType: type,
          addressPhoneNumber: newAddressData.addressPhone,
          apartmentNumber: newAddressData.apartmentNumber,
          floorNumber: newAddressData.floorNumber,
          unitNumber: newAddressData.unitNumber,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        dispatch(getAddress());
      });
  };
};

export const deleteAddress = addressData => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'user/add-or-update-user-address',
        {
          addressId: addressData.addressId,
          isActive: false,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        dispatch(getAddress());
      });
  };
};

export const editNewAddress = (newAddressData, lat, lng, type, addressId) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'user/add-or-update-user-address',
        {
          addressId: addressId,
          title: newAddressData.addressTitle,
          isActive: true,
          addressLine: newAddressData.addressDetails,
          postalCode: '41001',
          districtId: Number(newAddressData.addressCity),
          latitude: Number(lat),
          longitude: Number(lng),
          addressType: type,
          addressPhoneNumber: newAddressData.addressPhone,
          apartmentNumber: newAddressData.apartmentNumber,
          floorNumber: newAddressData.floorNumber,
          unitNumber: newAddressData.unitNumber,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        dispatch(getAddress());
      });
  };
};

export const getUserOrder = setShowLoading => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL_V2 + 'user/orders', {
        'Content-Type': 'text/plain',
        accept: 'application/json',
        headers: {
          Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
          clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
        },
      })
      .then(response => {
        dispatch({
          type: SET_USER_ORDER,
          payload: response.data.data,
        });
        setShowLoading(false);
      });
  };
};

export const deleteORder = (orderId, setShowLoading, toast) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'order/cancel',
        {
          orderId: orderId,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        console.log(response);
        dispatch(getUserOrder(setShowLoading));
      })
      .catch(error => {
        toast.error(error.response.data.Message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };
};

export const getUserConset = () => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL_V2 + 'user/profile', {
        'Content-Type': 'text/plain',
        accept: 'application/json',
        headers: {
          Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
          clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
        },
      })
      .then(response => {
        dispatch({
          type: SET_USER_CONSET,
          payload: response.data.data,
        });
      });
  };
};

export const setUserConset = (name, email, status) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .put(
        process.env.REACT_APP_BASE_URL_V2 + 'user/update-user-profile',
        {
          fullName: name,
          email: email,
          isEConsent: status,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        dispatch(getUserConset());
      });
  };
};

export const getUserPersonalData = (
  setLegalModal,
  setLegalContent,
  setShowLoading
) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'legal/contract/disclosure-text-on-protection-personal-data',
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        setLegalContent(response.data.data.content);
        setLegalModal(true);
        setShowLoading(false);
      });
  };
};

export const getUserMemberShipData = (
  setLegalModal,
  setLegalContent,
  setShowLoading
) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'legal/contract/membership-contract',
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        setLegalContent(response.data.data.content);
        setLegalModal(true);
        setShowLoading(false);
      });
  };
};

export const getConset = (setLegalModal, setLegalContent, setShowLoading) => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'legal/contract/consent-for-commercial-communication',
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        setLegalContent(response.data.data.content);
        setLegalModal(true);
        setShowLoading(false);
      });
  };
};

export const refundOrderEndPonit = (
  orderData,
  orderId,
  reason,
  setShowLoading
) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);

  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'order/return',
        {
          returnReason: reason ? reason : 'Kısmi iade talep edildi',
          orderId: orderId,
          cartProductsToReturn: orderData,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
          },
        }
      )
      .then(response => {
        setShowLoading(false);
      });
  };
};
