import { combineReducers } from 'redux'
import user from './Reducers/user';
import menu from './Reducers/menu';
import basket from './Reducers/basket';
import product from './Reducers/product';
import delivery from './Reducers/delivery';
import commercial from './Reducers/commercial'
import legal from './Reducers/legal';
import market from './Reducers/market';
import payment from './Reducers/payment';
import campaign from './Reducers/campaign';

const rootReducer = combineReducers({
  user,
  menu,
  basket,
  product,
  delivery,
  commercial,
  legal,
  market,
  payment,
  campaign
})

export default rootReducer;