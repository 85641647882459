import React from "react";
import {
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import Image from 'react-bootstrap/Image';

// Slider
import YProductSlider from "../../../Components/Product/YProductSlider";

// Components
import YText from "../../../Components/Basic/YText";
import YImage from '../../../Components/Basic/YImage';

//Const
import ImageSrouce from '../../../Assets/Images/meyve.png'

// Styles
import "../styles/breakfastItemsStyles.css";

export default function BreakfastItems(props) {
  const { title , data , onEvent, basket , navigate , token , onLoginModal , cateId } = props;
  
  return (
    <div className='home-product-slider-container'>
      <div className='header-section-row'>
        <MDBCol>      
          <YText type={"h6"} value={title} incomingStyle={'best-seller-text'}/>
        </MDBCol>
        <div className={'srction-view-container'} onClick={() => navigate({
                pathname: '/products',
                search: '?catgoryId='+cateId,
             })}>
          <YText type={"h6"} value={"Tümünü Gör "}  incomingStyle={'view-all'}/>
          <YImage imageSource={require('../../../Assets/Images/arrow-view.png')} inComingStyle={'arrow-view'}/>
        </div>
      </div>
      <MDBRow>
        <MDBCol size="12" lg="3">
          <div 
            className="teb-image-back teb-image-back-margin-home" 
            style={{backgroundImage: `url(${ImageSrouce})`}}
          />
        </MDBCol>
        <MDBCol size="12" lg="9">
          <YProductSlider
            autoPlay={true}
            showArrow={true}
            showDots={true}
            data={data}
            countItems={4}
            onEvent={onEvent}
            basket={basket}
            navigate={navigate}
            token={token}
            onLoginModal={onLoginModal}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
}
