import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import axios from 'axios';

// Components
import YTheme from '../../Components/Layout/YTheme';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import {
  getBasket,
  setBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { deleteAddress, getAddress } from '../../Redux/Actions/UserAction';

// Images

import PicDeneme from '../../Assets/Images/Motorcycle-Grey.png';
import Deterjan from '../../Assets/Images/deterjan.png';
import Drinking from '../../Assets/Images/drinking.png';
import Fruit from '../../Assets/Images/fruit.png';
import HomePet from '../../Assets/Images/homePet.png';
import IceCream from '../../Assets/Images/ice-cream.png';
import Baklagil from '../../Assets/Images/baklagil.png';
import Bakery from '../../Assets/Images/bakery.png';
import Meal from '../../Assets/Images/meal.png';
import Milk from '../../Assets/Images/milkCate.png';
import Snack from '../../Assets/Images/snack.png';
import Selfcare from '../../Assets/Images/selfcare.png';
import Biberon from '../../Assets/Images/BabyPro.png';

// Utils
import CardCategoryMobile from './Components/CardCategoryMobile';

export default function CategoriesMobile() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [deleteAddressModal, setDeleteAddressModal] = useState(false);

  const deleteAddressModalShow = (state, id) => {
    setDeleteAddressModal(!deleteAddressModal);
    setActiveRemoveId(id);
  };

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [deliveryType, setDeliveryType] = useState(0);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [userAddressModals, setUserAddressModals] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const menu = useSelector(state => state.menu.menu);

  const [showAddress, setShowAddress] = useState(false);

  const tokenData = cookie.get('token');

  const [addresses, setAddresses] = useState([]);

  const [activeRemoveId, setActiveRemoveId] = useState(false);

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof addressList !== 'undefined') {
      setAddresses([...addressList]);
    }
  }, [addressList]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onDeleteItemAddress = () => {
    let market = cookie.get('marketId');
    axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 +
          'market/store-around?pageNumber=1&pageSize=1&latitude=' +
          activeRemoveId.latitude +
          '&longitude=' +
          activeRemoveId.longitude,
        {
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
        }
      )
      .then(response => {
        let addressMarketId = response.data.data[0].marketId;
        if (Number(market) === addressMarketId) {
          console.log(addressMarketId);
          setUserAddressModals(true);
          setDeleteAddressModal(false);
        } else {
          dispatch(deleteAddress(activeRemoveId));
          setDeleteAddressModal(false);
        }
      });
  };

  const cancelChange = () => {
    setUserAddressModals(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        deliveryType={deliveryType}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container mb-5">
        <MDBRow className="mobile-card-row mb-2">
          {menu.map(item => {
            console.log(item);
            return (
              <MDBCol key={item.categoryId} className="mobile-card-col mt-3">
                <CardCategoryMobile
                  pathName={'/products?catgoryId=' + item.categoryId}
                  mobileCategoriesImage={Fruit}
                  mobileCategoriesTitle={item.categoryName}
                />
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBContainer>
      <Footer />
    </YTheme>
  );
}
