import React from 'react';

// Styles
import './styles/YRowStyles.css'

export default function YRow(props) {

    const {
        inComingStyle,
        children,
    } = props;

    
    return(
        <div
            className={inComingStyle ? inComingStyle : 'containerRow'}
        >
            {children}
        </div>
    );
};