import '../Styles/syles.css';
export const kvkk = `<div class="w100">
<div class="wrap">

    <div class="page-title">KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ</div>
    <div class="page-about static-text">
    <p>Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ.  olarak, kişisel verilerinizin gizliliği ve güvenliğine büyük önem veriyor, Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ.  ile ilişkili şahıslara ait kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanununa (“KVK Kanunu”) uygun olarak işlenerek, muhafaza edilmesi hususunda gereken bütün gayreti gösteriyoruz.

    Bu çerçevede, Veri Sorumlusu sıfatıyla şirketimiz, KVK Kanunu doğrultusunda, kişisel verilerinizi aşağıda açıklanan şartlarda ve mevzuatta öngörülen sınırlar dahilinde işlemektedir.</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>1.Veri Sorumlusunun Kimliği</strong></p>
        <p>Kişisel verileriniz veri sorumlusu sıfatıyla
        KVK Kanunu’nun 3’üncü maddesinin 1’inci fıkrasının (ı) bendinde veri sorumlusu, “Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiler” olarak tanımlanmıştır. Bu çerçevede veri Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ. ‘dir. Şirketimizin iletişim bilgileri aşağıdaki gibidir:</p>
        <p>Öztaner Gıda ve İhtiyaç Mad. San. Tic. LTD.ŞTİ.</p>
        <p>NOSAB N.309 Sk. No:2</p>
        <p>Nilüfer / BURSA</p>
        <p>Web:<a href="https://kurumsal.ozhan.com.tr/" target="_blank" >https://kurumsal.ozhan.com.tr/</a></p>
        <p>Tel: 0224 411 13 00</p>
        <p>&nbsp;</p>
        <p><strong>2.Kişisel Verilerinizin İşlenme Amaçları</strong></p>
        <p>Kişisel verileriniz KVK Kanunu’nun 4. maddesinde belirtilen genel ilkeler; 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları kapsamında;</p>
        <p>a)İnsan kaynakları yönetimi ve politikalarımızın planlanması ve uygulanması,</p>
        <p>b)Çalışan adaylarının başvuru, seçme ve değerlendirme süreçlerinin planlaması ve/veya yürütülmesi,</p>
        <p>c)Yazılı ve görsel mecralarda Şirketimiz tarafından sunulan mal ve hizmetlerin tanıtılması, sadakat kart üyelik hizmetlerinin yerine getirilmesi, kurumsal/ürün bazlı imaj çalışması yapılması,</p>
        <p>d)Online ürün satış süreçlerinin sözleşme hükümlerine uygun bir şekilde yerine getirebilmesi,</p>
        <p>e)Üyeye özel promosyon / tanıtım / kampanya / çekiliş ve duyuru faaliyetlerinin yürütülmesi, müşteri ilişkileri yönetiminde kaliteyi artırmak amacıyla anket vb memnuniyeti uygulamaları ve bilgilendirmeler yapılması,</p>
        <p>f)Satış sonrası destek hizmetleri faaliyetlerinin yürütülmesi,</p>
        <p>g)Ürün ve hizmetlerin satış ve/veya pazarlaması için pazar araştırması yapılması,</p>
        <p>h)Ürün ve/veya hizmetlere erişim ve/veya kullanımda müşterinin kullanacağı kanallara uygun araçların ve/veya bilgilerin müşteriye sağlanması süreçlerinin planlanması ve/veya icrası,</p>
        <p>i)Tedarik zinciri yönetimi süreçlerinin planlanması ve/veya icrası,</p>
        <p>j)Ürün ve/veya hizmetlerin pazarlama süreçlerini yürütmek amacıyla, veri analizi, araştırma, istatistiksel çalışma, trendleri anlama, ve reklam faaliyetlerinin planlanması ve/veya yönetimi,</p>
        <p>k)Ticari ortaklıklarımızın ve stratejilerimizin planlanması ve yürütülmesi,</p>
        <p>l)Şirketimizin ve iş ortaklarımızın hukuki, ticari ve fiziki güvenliğinin temini,</p>
        <p>m)Şirket kurumsal işleyişinin sağlanması, finans ve/veya muhasebe işlerinin takibi ve yönetimi,</p>
        <p>n)İş sağlığı ve güvenliği alanına giren konularla ilgili standartlarının sağlanması ve iyileştirilmesi amacıyla gerekli tedbirlerin alınması,</p>
        <p>o)Veri güvenliğinin en üst düzeyde sağlanması ve veri tabanlarının oluşturulması</p>
        <p>p)Şirketimiz internet sitesinde sunulan hizmetlerin geliştirilmesi, </p>
        <p>q)İlgililerce yapılacak talep ve şikayetlerin takibi ve ilgililere geri dönüşün sağlanması,</p>
        <p>r)Şirketimiz internet sitesinde oluşan hataların giderilmesi ve sitede paylaşılmış olan Şirketimizin Gizlilik Politikasında yer alan hükümlere uygunluğun sağlanması</p>
        <p>amaçları ile işlenir.</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>3.İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</strong></p>
        <p>Kişisel verileriniz yukarıda belirtilen amaçlar kapsamında KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları çerçevesinde;</p>
        <ul>
            <li>* Şirketimizin hukuki yükümlülüklerini ve ticari faaliyetlerini yerine getirmesi için gerekli olan hallerde iş ortaklarımız, danışman firmalarımız, tedarikçilerimiz, özel kurum ve kuruluşlar ile kamu kurum ve kuruluşlarına,</li>
            <li>* Kişisel verilerinizin işlenmesine konu olan sözleşmelerin kurulması ve ifası kapsamında gerekli ödeme ve tahsilat işlemleri için ilgili bankalarına,</li>
            <li>* Şirketimizin yasal yükümlülüklerinin yerine getirilmesi kapsamında gerekli hallerde mahkemeler ve bilgi talep eden kamu kurum ve kuruluşlarına,</li>
            <li>* Şirketimizin haklarının tesisi, kullanılması ve korunması kapsamında hukuki destek almak amacıyla hukuk büroları ve diğer danışmanlara,</li>
            <li>* KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları çerçevesinde yurt içinde bulunan topluluk şirketlerimize,</li>
            <li>* Veri güvenliğinin sağlanması amacıyla arşiv firmaları ya da bulut hizmeti firmalarına aktarılabilecektir.</li>
            <li>Kişisel verilerinizin paylaşma süreci boyunca ve sonrasında veri güvenliğinin sağlanması için her türlü teknik ve idari tedbir alınmaktadır.</li>
        </ul>


        <p><strong>4. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong></p>
        <p>6698 sayılı KVK Kanunu’nun 4., 5. ve 6. maddeleri uyarınca kişisel verileriniz;</p>

        <p>a)      Hukuka ve dürüstlük kurallarına uygun</p>

        <p>b)      Doğru ve gerektiğinde güncel</p>
        
        <p>c)      Belirli, açık ve meşru amaçlar için</p>
        
        <p>d)      İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü</p>
        
        <p>e)      İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme kurallarına uygun bir şekilde toplanır.</p>
        <p>Kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle ve farklı kanallardan ofisler, çağrı merkezi, internet sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, fiziki ortamda, elektronik ortamda ya da kapalı devre görüntüleme sistemleri vasıtasıyla ve diğer yöntemlerle yasal yollardan elde edilebilmektedir. Ayrıca toplanan kişisel verileriniz, Şirketimiz tarafından verilen hizmet, ürün ya da ticari faaliyete bağlı olarak değişkenlik gösterebilir.</p>
        <p>Kişisel verileriniz, 6698 sayılı KVK Kanunu’nda (5. maddede) yer alan aşağıdaki hukuki sebeplere dayalı olarak işlenir:</p>
        <p>-  5. Maddesinin birinci fıkrasında yer alan kişinin açık rızasının olması,</p>
        <p><strong>-  5/2 (a) bendinde düzenlenen “kanunlarda açıkça öngörülmesi”</strong>hukuki sebebine dayalı olarak kişisel verileriniz başta Türk Ticaret Kanunu ve Vergi Usul Kanunu olmak üzere Şirketimizin tabi olduğu mevzuatın açıkça izin vermesi,</p>
        <p>-  5/2(b) bendinde düzenlenen fiili imkânsızlık,</p>
        <p>-  5/2 (c) bendinde düzenlenen “<strong>sözleşmenin kurulması ya da ifası” hukuki sebebine dayalı olarak kişisel verileriniz; kişisel verilerinizi açıklamanıza konu olan sözleşme gereklerinin yerine getirilmesi ve devamının sağlanması, cari hesap açılışı ile ödeme ve tahsilat işlemlerinin gerçekleştirilmesi,</strong></p>
        <p><strong>-  5/2 (ç) bendinde düzenlenen “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi”</strong>hukuki sebebine dayalı olarak Şirket, kişisel verilerinizi yasal beyanname ve bildirimlerin düzenlenmesi, Şirketin tabi olduğu mevzuatta öngörülen saklama sürelerine uyulması ve ilgili mevzuatta öngörülen diğer yükümlülüklerin yerine getirilmesi, mahkemeler ve bilgi talep eden kamu kurum ve kuruluşlarının taleplerinin ve sair yasal yükümlülüklerin yerine getirilmesi,</p>
        <p>-  5/2 (e) bendinde düzenlenen “ilgili kişinin kendisi tarafından alenileştirilmiş olması”,</p>
        <p>-  5/2 (e) bendinde düzenlenen “bir hakkın tesisi, kullanılması veya korunması” hukuki sebebine dayalı olarak Şirket, kişisel verilerinizi olası uyuşmazlıklarda ispat vesilesi olması, hukuki danışmanlık ve teknik destek alınabilmesi.</p>
        <p>-  5/2 (f) bendinde düzenlenen Şirketimizin meşru menfaatleri için kişisel verilerinizin işlenmesi.

        İşbu Aydınlatma Metninde belirtilen amaçlarla özel nitelikli kişisel verileriniz KVK Kanunu’nun 6. Maddesinde belirtilen aşağıdaki hukuki sebeplere dayalı olarak işlenir:</p>

        <p>a)Açık Rıza (m.6/2)</p>
        <p>b)Kanunlarda öngörülme (m.6/3: Sağlık ve cinsel hayat dışındaki özel nitelikli kişisel veriler için)</p>
        <p>c)Sağlık ve cinse hayata ilişkin verilerin tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, koruyucu hekimlik, kamu sağlığının korunması, sağlık hizmetleri ve finansmanının planlanması ve yönetimi amacıyla işlenmesi (m.6/3).</p>
        <p>Bu hukuki sebeplerle toplanan kişisel verileriniz KVK Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında sadece bu Aydınlatma Metninde belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.</p>


        <p><strong>&nbsp;</strong></p>
        <p><strong>5.Kişisel Veri Sahibinin Hakları</strong></p>
        <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, işbu Aydınlatma Metninde sonraki maddede düzenlenen yöntemlerle Şirketimize iletmeniz durumunda Şirketimiz niteliğine göre, talebi en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücreti alınabilecektir.
        </p>
        <p>Kişisel veri sahibinin KVK Kanunu’nun 11. Maddesinde belirtilen; </p>
        <p>- Kişisel veri işlenip işlenmediğini öğrenme,</p>
        <p>- Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
        <p>- Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
        </p>
        <p>- Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
        <p>- Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
        <p>- Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
        <p>- İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</p>
        <p>- Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme hakları bulunmaktadır.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>

</div>
</div>`;
