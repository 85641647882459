import { SELECTED_MARKET_DATA } from '../Constants/action-types';

const initialState = [];

export default function market(state = initialState, action) {
  switch (action.type) {
    case SELECTED_MARKET_DATA:
      return { ...state, market: action.payload };
    default:
      return state
  }
};
