import React, { useState , useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker} from '@react-google-maps/api';

const YMapComponent = (props) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY
  });

  const defaultMapOptions = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    disableDefaultUI: true,
    mapTypeControl : false,
    scaleControl: true,
    zoomControl: true,
  };

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(props.lat, props.long);
    geocoder.geocode({ 'location': latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          let city = '';
          let district = '';
          let neighborhood = '';
          let street = '';
          let streetNumber = '';

          addressComponents.forEach(component => {
            if (component.types.includes('administrative_area_level_1')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_2')) {
              district = component.long_name;
            } else if (component.types.includes('neighborhood') || component.types.includes('sublocality')) {
              neighborhood = component.long_name;
            } else if (component.types.includes('route')) {
              street = component.long_name;
            } else if (component.types.includes('street_number')) {
              streetNumber = component.long_name;
            }
          });
          props.onChangeMap(
            parseFloat(props.lat),
            parseFloat(props.long),
            city,
            district,
            neighborhood,
            street,
            streetNumber
          )
        }
      }
    });
    setSelectedAddress({
      lat: parseFloat(props.lat),
      lng: parseFloat(props.long),
    });
  }, [props.lat, props.long]);

  const mapRef = React.useRef();

  const onMapClick = React.useCallback((event) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
    geocoder.geocode({ 'location': latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          let city = '';
          let district = '';
          let neighborhood = '';
          let street = '';
          let streetNumber = '';

          addressComponents.forEach(component => {
            if (component.types.includes('administrative_area_level_1')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_2')) {
              district = component.long_name;
            } else if (component.types.includes('neighborhood') || component.types.includes('sublocality')) {
              neighborhood = component.long_name;
            } else if (component.types.includes('route')) {
              street = component.long_name;
            } else if (component.types.includes('street_number')) {
              streetNumber = component.long_name;
            }
          });
          props.onChangeMap(
            parseFloat(event.latLng.lat()),
            parseFloat(event.latLng.lng()),
            city,
            district,
            neighborhood,
            street,
            streetNumber
          )
        }
      }
    });
    setSelectedAddress({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    });
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        zoom={18}
        center={selectedAddress ? selectedAddress : { lat: 37.4220, lng: -122.0841 }}
        onClick={onMapClick}
        ref={mapRef}
        options={defaultMapOptions}
      >
        {selectedAddress && (
          <Marker position={selectedAddress} />
        )}
      </GoogleMap>
    </div>
  );
}

export default YMapComponent;