import React from 'react';
import Container from 'react-bootstrap/Container';

// Styles
import './styles/YContainerStyles.css'

export default function YContainer(props) {

    const {
        inComingStyle,
        children,
    } = props;

    
    return(
        <Container
            fluid
            className={inComingStyle ? inComingStyle : 'container'}
        >
            {children}
        </Container>
    );
};