export const SET_USER = 'SET_USER';
export const SET_MENU = 'SET_MENU';
export const SET_BASKET = 'SET_BASKET';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PROVINCE = 'SET_PROVINCE';
export const SET_DISTRICT = 'SET_DISTRICT';
export const SET_NEIGHBORHOOD = 'SET_NEIGHBORHOOD';
export const SET_SHOP = 'SET_SHOP';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_DELIVERY_DATE_TIME = 'SET_DELIVERY_DATE_TIME';
export const SET_RELATED_PRODUCT = 'SET_RELATED_PRODUCT';
export const SET_BRANDS = 'SET_BRANDS';
export const SINGLE_PRODUCT = 'SINGLE_PRODUCT';
export const LAST_ORDER = 'LAST_ORDER';
export const SET_COMMERCIAL_INFOS = 'SET_COMMERCIAL_INFOS';
export const LEGAL_KVKK = 'LEGAL_KVKK';
export const LEGAL_GDPR = 'LEGAL_GDPR';
export const LEGAL_TERMOFUSE = 'LEGAL_TERMOFUSE';
export const LEGAL_PRIVACYPOLICY = 'LEGAL_PRIVACYPOLICY';
export const SELECTED_MARKET_DATA = 'SELECTED_MARKET_DATA';
export const DISCOUNT_PRODUCT = 'DISCOUNT_PRODUCT';
export const SET_MARKET = 'SET_MARKET';
export const SET_ACTIVE_CARD_NUMBER = 'SET_ACTIVE_CARD_NUMBER';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_REPRODUCT = 'SET_REPRODUCT';
export const SET_CONTACTOPTIONS = 'SET_CONTACTOPTIONS';
export const SET_DETAILS_PRODUCT = 'SET_DETAILS_PRODUCT';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_CATEONE = 'SET_CATEONE';
export const SET_CATETWO = 'SET_CATETWO';
export const SET_CHILD_CATEGORY = 'SET_CHILD_CATEGORY';
export const SET_USER_ORDER = 'SET_USER_ORDER';
export const SET_SEARCH_CATEGORIES = 'SET_SEARCH_CATEGORIES';
export const SET_USER_CONSET = 'SET_USER_CONSET';

