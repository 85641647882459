import "../Styles/syles.css"
export const uyelikSozlesmesi = 

`<div class="">
    <div _ngcontent-serverapp-c10="" class="">
        <h4 _ngcontent-serverapp-c10="" class="page-title">Üyelik Sözleşmesi</h4>
    </div>
    <div _ngcontent-serverapp-c10="" class="modal-body">
        
        <p><span class="">ÜYELİK KOŞULLARI</span></p>
        <p><span class="">1. "Üye ismi" ismi belirlerken ahlaka ve hukuka aykırı isimler belirlenemez. Bu
                üye isimleri üçüncü şahısların telif hakkı, marka, ticari unvan gibi yasal haklarını ihlal edecek
                nitelikte olamaz. Bu durumlarda </span><a href="https://www.ozhan.com/"
                target="_blank">https://www.ozhan.com.tr/</a>&nbsp;<span class="">&nbsp;&nbsp;sitesi
                yönetimi bu üye ismi ile üyelik oluşturmaya izin vermeyeceği gibi oluşturulmuş olsa dahi istediği
                zamanda ve şekilde bu üyeliği askıya alabilir veya sonlandırabilir.</span></p>
        <p><span class="">2. Üye iş bu sözleşmenin kabulü ile kendisine 6563 sayılı yasa ve ilgili mevzuat
                uyarınca </span><a href="https://www.ozhan.com/" target="_blank">https://www.ozhan.com.tr/</a> <span
                class="">sitesi yönetimi tarafından mal ve hizmetlerinin tanıtılması, pazarlanması,
                işletmesinin tanıtılması ya da kutlama ve temenni gibi içeriklerle tanınırlığını artırmak amacıyla
                üyenin elektronik iletişim adreslerine, telefon, çağrı merkezleri, faks, otomatik arama makineleri,
                akıllı ses kaydedici sistemler, elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak
                elektronik ortamda gerçekleştirilen ve ticari amaçlarla gönderilen veri, ses ve görüntü içerikli her tür
                iletinin gönderilmesine muvafakat etmektedir.</span></p>
        <p><span class="">3. Üyeler yorum ve beğenilerinde genel ahlak ve hukuk kurallarına aykırı
                kullanımda bulunamazlar. Bu türden iletilerin tespiti halinde </span><a href="https://www.ozhan.com/"
                target="_blank">https://www.ozhan.com.tr/</a> <span class="">&nbsp;üyeye haber vermeksizin
                ve gerekçe göstermeksizin ilgili iletiyi kaldırabilir ve üyeliği tek taraflı olarak askıya alabilir ve
                sonlandırabilir. Üyenin bu duruma itiraz ve şikayet hakkı olup başvuru üzerine </span><a
                href="https://www.ozhan.com.tr/" target="_blank">https://www.ozhan.com.tr/</a> <span
                class="">sitesi yönetimi tarafından verilecek karar kesindir.</span></p>
        <p><span class="">4. Üye ismi ve şifre güvenliği, saklanması, üçüncü kişilerle paylaşılmaması
                tamamen Üye’'lerin sorumluluğundadır. Hukuka aykırı olarak ele geçirilmiş üye hesaplarının askıya
                alınması veya sonlandırılması</span> <a href="https://www.ozhan.com.tr/"
                target="_blank">https://www.ozhan.com.tr/</a> <span class="">sitesi yönetiminin
                takdirindedir.</span></p>
        <p><span class="">5.</span> <a href="https://www.ozhan.com.tr/"
                target="_blank">https://www.ozhan.com.tr/</a> <span class="">&nbsp;&nbsp;sitesi yönetimi
                istediği zamanda ve şekilde bu kullanıcı koşullarını tek taraflı olarak değiştirebilir veya tamamen
                ortadan kaldırabilir.</span></p>
        <p><span class="">6. İşbu Kullanıcı Koşulları’nın uygulanmasında Türk Hukuku uygulanacaktır. İşbu
                Kullanıcı Sözleşmesi'nden doğan veya doğabilecek her türlü ihtilafın hallinde, Bursa Mahkemeleri ve İcra
                Daireleri yetkilidir.</span></p>
    </div>
</div> `