import React, { useState, useRef, useEffect } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBIcon,
} from 'mdb-react-ui-kit';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// constants
import { isObjEmpty } from '../../../Assets/Utils/Utility';

// Components
import YImage from '../../../Components/Basic/YImage';
import YModal from '../../../Components/Basic/YModal';
import YMapComponent from '../../../Components/Basic/YMapComponent';

// Redux
import {
  getProvince,
  getDistrict,
} from '../../../Redux/Actions/DeliveryAction';
import { addNewAddress } from '../../../Redux/Actions/UserAction';

// Styles
import './styles/AddAddressStyles.css';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/min';

export default function AddAddress(props) {
  const { onClickNewAddressBilling, type } = props;

  const dispatch = useDispatch();

  const [mapModal, setMapModal] = useState(true);

  const [valuess, setValue] = useState(null);

  const [proviceName, setProviceName] = useState(null);

  const [cityName, setCityName] = useState(null);

  const [long, setLong] = useState();

  const [province, setprovince] = useState([]);

  const [district, setDistrict] = useState([]);

  const [lat, setLat] = useState();

  const textInput = useRef < HTMLInputElement > null;

  const [formState, setFormState] = useState({
    addressTitle: '',
    addressHolderName: '',
    addressPhone: '',
    addressProvince: '',
    addressCity: '',
    addressDetails: '',
    apartmentNumber: '',
    floorNumber: '',
    unitNumber: '',
  });

  const provinceList = useSelector(state => state.delivery.province);

  const districtList = useSelector(state => state.delivery.district);

  useEffect(() => {
    dispatch(getProvince());
  }, []);

  useEffect(() => {
    if (typeof provinceList !== 'undefined') {
      setprovince(provinceList);
    }
  }, [provinceList]);

  useEffect(() => {
    if (typeof districtList !== 'undefined') {
      setDistrict(districtList);
    }
  }, [districtList]);

  const SignInSchema = yup.object().shape({
    addressTitle: yup.string().required('Lütfen adres başlığı giriniz!'),
    apartmentNumber: yup.string().required('Lütfen Bina numarasını giriniz!'),
    floorNumber: yup.string().required('Lütfen Kat numarasını giriniz!'),
    unitNumber: yup.string().required('Lütfen Daire numarasını giriniz!'),
    addressHolderName: yup
      .string()
      .required('Lütfen adınızı, soyadınızı giriniz!'),
    addressPhone: yup
      .string('Lütfen cep telefonu bilginizi giriniz!')
      .required('Lütfen cep telefonu bilginizi giriniz!')
      .min(10)
      .max(13),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(SignInSchema),
  });

  const onSubmit = () => {
    if (isObjEmpty) {
      if (formState.addressProvince !== '') {
        if (formState.addressCity !== '') {
          dispatch(addNewAddress(formState, lat, long, type));
          onClickNewAddressBilling();
        } else {
          toast.error('"Lütfen bir ilçe seçiniz!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } else {
        toast.error('Lütfen bir il seçiniz!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
      console.log(formState);
    }
  };

  const onChangeAddess = e => {
    geocodeByAddress(e.label)
      .then(results => {
        let diraction = getLatLng(results[0]);
        diraction.then(value => {
          setLong(value.lng);
          setLat(value.lat);
          console.log(e.label);
          setValue(e.label);
        });
      })
      .catch(error => console.error(error));
  };

  const showMap = () => {
    setLat('41.112663');
    setLong('29.021330');
  };

  const onSelectProvince = e => {
    dispatch(getDistrict(e.target.value));
    setFormState({
      ...formState,
      addressProvince: e.target.value,
    });
  };

  const onSelectDistrict = e => {
    setFormState({
      ...formState,
      addressCity: e.target.value,
    });
  };

  const _onSelectAddressMap = () => {
    if (typeof lat !== 'undefined') {
      axios
        .get(
          process.env.REACT_APP_BASE_URL_V2 +
            'market/store-around?pageNumber=1&pageSize=1&latitude=' +
            lat +
            '&longitude=' +
            long,
          {
            headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
          }
        )
        .then(response => {
          setMapModal(false);
          textInput.current?.focus();
        })
        .catch(error => {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        });
    } else {
      toast.error('Lütfen teslimat adresinizi seçiniz!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const onChangeMap = async (
    lat,
    lng,
    city,
    districtName,
    neighborhood,
    street,
    streetNumber
  ) => {
    setLong(lng);
    setLat(lat);
    console.log(
      city +
        ', ' +
        districtName +
        ', ' +
        neighborhood +
        ', ' +
        street +
        ', ' +
        streetNumber
    );
    province?.map((item, i) => {
      if (item.name === city) {
        //setProviceName(city)
        dispatch(getDistrict(item.cityId));
        setFormState({
          ...formState,
          addressProvince: item.cityId,
          addressDetails:
            city +
            ', ' +
            districtName +
            ', ' +
            neighborhood +
            ', ' +
            street +
            ', ' +
            streetNumber,
        });
        district?.map((item, i) => {
          if (item.name === districtName) {
            //setCityName(districtName);
            /*setFormState({
                            ...formState,
                            addressCity: item.districtId,
                        });*/
          }
        });
      }
    });

    console.log(formState);
  };

  const [addbtnClassName, setAddbtnClassName] = useState(
    'step-add-add-address'
  );

  const [allInputsFilled, setAllInputsFilled] = useState(false);

  function controllForGreen() {
    const inputs = document.getElementsByClassName(
      classnames({ 'is-invalid': errors['addressDetails'] }, 'mb-4 card-input')
    );
    console.log(inputs);
    const allFilled = [...inputs].every(input => input.value.trim() !== '');

    setAllInputsFilled(allFilled);

    if (allFilled) {
      setAddbtnClassName('step-add-add-address-controll');
    } else {
      setAddbtnClassName('step-add-add-address');
    }
  }

  return (
    <MDBCard className="rounded-3 mobile-version-top-margin adding-address-order-page-container">
      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <MDBCardBody>
          <p className="text-in-adding-new-address">Adress Başlığı</p>
          <MDBInput
            className={classnames(
              { 'is-invalid': errors['addressTitle'] },
              'mb-4 card-input'
            )}
            type="text"
            size="sm"
            ref={textInput}
            name="addressTitle"
            id="addressTitle"
            {...register('addressTitle')}
            placeholder="Adres Başlığı*"
            contrast
            onChange={e => {
              setFormState({
                ...formState,
                [e.target.name]: e.target.value,
              });
              controllForGreen(e);
            }}
          />
          {errors.addressTitle?.message && (
            <p className="error">{errors.addressTitle?.message}</p>
          )}

          <p className="text-in-adding-new-address">İletişim Bilgileri</p>

          <MDBInput
            className={classnames(
              { 'is-invalid': errors['addressHolderName'] },
              'mb-4 card-input'
            )}
            type="text"
            size="sm"
            name="addressHolderName"
            id="addressHolderName"
            {...register('addressHolderName')}
            placeholder="Adı Soyadı*"
            contrast
            onChange={e => {
              setFormState({
                ...formState,
                [e.target.name]: e.target.value,
              });
              controllForGreen(e);
            }}
          />
          {errors.addressHolderName?.message && (
            <p className="error">{errors.addressHolderName?.message}</p>
          )}

          <MDBInput
            className={classnames(
              { 'is-invalid': errors['addressPhone'] },
              'mb-4 card-input'
            )}
            type="number"
            size="sm"
            name="addressPhone"
            id="addressPhone"
            onInput={e => (e.target.value = e.target.value.slice(0, 13))}
            maxLength={13}
            {...register('addressPhone')}
            placeholder="Cep Telefonu*"
            contrast
            onChange={e => {
              setFormState({
                ...formState,
                [e.target.name]: e.target.value,
              });
              controllForGreen(e);
            }}
          />
          {errors.addressPhone?.message && (
            <p className="error">{errors.addressPhone?.message}</p>
          )}

          <p className="text-in-adding-new-address">Adres Bilgileri</p>

          <MDBRow className="mb-6">
            <MDBCol md="6">
              <Form.Select
                aria-label={'addressProvince'}
                defaultValue={0}
                name="addressProvince"
                className={classnames(
                  { 'is-invalid-select': errors['addressProvince'] },
                  'mb-4 addAddressSelectFrom'
                )}
                {...register('addressProvince')}
                onChange={e => onSelectProvince(e)}
              >
                <option value={0} disabled={true}>
                  {proviceName !== null ? proviceName : 'İl'}
                </option>

                {province?.map((item, i) => {
                  return (
                    <option
                      key={i + item.name}
                      value={item.cityId}
                      selected={proviceName === item.name ? true : false}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors.addressProvince?.message && (
                <p className="error">{errors.addressProvince?.message}</p>
              )}
            </MDBCol>
            <MDBCol md="6">
              <Form.Select
                aria-label={'addressCity'}
                defaultValue={0}
                className="addAddressSelectFrom"
                {...register('addressCity')}
                onChange={e => onSelectDistrict(e)}
              >
                <option value={0} disabled={true}>
                  {cityName !== null ? cityName : 'ilçe'}
                </option>
                {district?.map((item, i) => {
                  return (
                    <option
                      key={i + item.name}
                      value={item.districtId}
                      selected={cityName === item.name ? true : false}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors.addressCity?.message && (
                <p className="error">{errors.addressCity?.message}</p>
              )}
            </MDBCol>
            <MDBInput
              className={classnames(
                { 'is-invalid': errors['addressDetails'] },
                'mb-4 card-input'
              )}
              type="text"
              size="sm"
              name="addressDetails"
              id="addressDetails"
              {...register('addressDetails')}
              placeholder="Adres*"
              value={formState.addressDetails}
              contrast
              onChange={e => {
                setFormState({
                  ...formState,
                  [e.target.name]: e.target.value,
                });
                controllForGreen(e);
              }}
            />
            {errors.addressDetails?.message && (
              <p className="error">{errors.addressDetails?.message}</p>
            )}
          </MDBRow>
          <MDBRow className="mb-6">
            <MDBCol md="4">
              <MDBInput
                className={classnames(
                  { 'is-invalid': errors['unitNumber'] },
                  'mb-4 card-input'
                )}
                type="text"
                size="sm"
                name="unitNumber"
                id="unitNumber"
                {...register('unitNumber')}
                placeholder="Bina no*"
                contrast
                onChange={e => {
                  setFormState({
                    ...formState,
                    [e.target.name]: e.target.value,
                  });
                  controllForGreen(e);
                }}
              />
              {errors.unitNumber?.message && (
                <p className="error">{errors.unitNumber?.message}</p>
              )}
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                className={classnames(
                  { 'is-invalid': errors['floorNumber'] },
                  'mb-4 card-input'
                )}
                type="text"
                size="sm"
                name="floorNumber"
                id="floorNumber"
                {...register('floorNumber')}
                placeholder="Kat no*"
                contrast
                onChange={e => {
                  setFormState({
                    ...formState,
                    [e.target.name]: e.target.value,
                  });
                  controllForGreen(e);
                }}
              />
              {errors.floorNumber?.message && (
                <p className="error">{errors.floorNumber?.message}</p>
              )}
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                className={classnames(
                  { 'is-invalid': errors['apartmentNumber'] },
                  'mb-4 card-input'
                )}
                type="text"
                size="sm"
                name="apartmentNumber"
                id="apartmentNumber"
                {...register('apartmentNumber')}
                placeholder="Daire no*"
                contrast
                onChange={e => {
                  setFormState({
                    ...formState,
                    [e.target.name]: e.target.value,
                  });
                  controllForGreen(e);
                }}
              />
              {errors.apartmentNumber?.message && (
                <p className="error">{errors.apartmentNumber?.message}</p>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
        <MDBCardBody className="d-flex justify-content-center align-items-center">
          <MDBRow className="d-flex justify-content-center align-items-center">
            <MDBCol
              className="step-add-remove-address"
              onClick={() => onClickNewAddressBilling()}
            >
              <MDBIcon fas icon="times me-1 delete-address-icon" size={'1x'} />
            </MDBCol>
            <Button type="submit" className={addbtnClassName}>
              <MDBIcon fas icon="check me-2 add-address-icon" size={'1x'} />
            </Button>
          </MDBRow>
        </MDBCardBody>
        <div
          className="address-change-map-location-again-container"
          onClick={() => setMapModal(true)}
        >
          <div className="address-change-map-location-again-container-btn">
            <YImage
              imageSource={require('../../../Assets/Images/location-white.png')}
              inComingStyle={'address-change-map-location-again-container-icon'}
            />
            <span className="address-change-map-location-again-container-text">
              Haritadan konum seç
            </span>
          </div>
        </div>
      </Form>
      <YModal
        show={mapModal}
        onClose={() => setMapModal(!mapModal)}
        title={'Teslimat Adresi Belirle'}
        inComingStyleHeader={'address-modal-header'}
      >
        <div className="google-auto-input-location">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_MAP_KEY}
            selectProps={{
              placeholder: 'Lütfen adresinizi yazınız...',
              valuess,
              onChange: onChangeAddess,
            }}
          />
        </div>

        <div className="header-map-container">
          {long ? (
            <YMapComponent
              lat={lat}
              long={long}
              setLat={setLat}
              setLong={setLong}
              onChangeMap={onChangeMap}
            />
          ) : (
            <>
              <img
                src={require('../../../Assets/Images/map.jpg')}
                alt={'map'}
                className="header-map-address-image"
                onClick={() => showMap()}
              />
              <img
                src={require('../../../Assets/Images/mmap.jpg')}
                alt={'map'}
                className="header-map-address-image-mobile"
                onClick={() => showMap()}
              />
            </>
          )}
        </div>
        <Button
          variant="primary"
          type="submit"
          className="w-100 mb-4 set-address-btn"
          onClick={() => _onSelectAddressMap()}
        >
          İşaretlediğim Konum ile Güncelle
        </Button>
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </MDBCard>
  );
}
