import { SET_MENU , SET_CHILD_CATEGORY } from '../Constants/action-types';
import axios from 'axios';
  
  const initialState = {
    menu : [],
    child: [],
  };
  
  export default function user(state = initialState, action) {
    switch (action.type) {
      case SET_MENU:
        return { ...state, menu: action.payload };
      case SET_CHILD_CATEGORY:
        return { ...state, child: action.payload };
      default:
        return state
    }
  };
  