import React from "react";
import {
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import Image from 'react-bootstrap/Image';

// Slider
import YProductSlider from "../../../Components/Product/YProductSlider";

// Components
import YText from "../../../Components/Basic/YText";
import YImage from '../../../Components/Basic/YImage';

// Styles
import "../styles/discountStyles.css";

export default function Discounts(props) {
  const { data , onEvent, basket , navigate , token , onLoginModal } = props;
  // add sizes
  return (
    <div className='home-product-slider-container'>
      <div className='header-section-row'>
        <MDBCol>      
          <YText type={"h5"} value={"Çok Satanlar · "} incomingStyle={'best-seller-text'}/>
        <YText type={"h5"} value={" Fırsat Ürünleri "} incomingStyle={'best-opp-text'} />
        </MDBCol>
        <div className={'srction-view-container'} onClick={() => navigate('/products')}>
          <YText type={"h6"} value={"Tümünü Gör "}  incomingStyle={'view-all'}/>
          <YImage imageSource={require('../../../Assets/Images/arrow-view.png')} inComingStyle={'arrow-view'}/>
        </div>
      </div>
      <MDBRow>
        <MDBCol size="12" lg="3">
          <Image
            src={require('../../../Assets/Images/cosmtic.png')}
            className={'teb-image'}
          />
        </MDBCol>
        <MDBCol size="12" lg="9">
          <YProductSlider
            autoPlay={true}
            showArrow={true}
            showDots={true}
            data={data}
            countItems={3}
            onEvent={onEvent}
            basket={basket}
            navigate={navigate}
            token={token}
            onLoginModal={onLoginModal}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
}
