import React from 'react';
import { Form } from 'react-bootstrap';

export default function YSelect(props) {

    const {
        name,
        errors,
        placeholder,
        data,
        disabled,
        onEvent,
        size = 'lg',
    } = props;

    
    return(
        <Form.Group className="mb-3 product-list-select" controlId={name}>
            <Form.Select 
                aria-label={name} 
                defaultValue={0}
                name={name}
                disabled={disabled} 
                onChange={(e) => onEvent(e)} 
                size={size}
            >
                <optgroup>
                    <option value={0} disabled>{placeholder}</option>
                    {data?.map((item ,i) => {
                        return (
                            <option key={i+item.name} value={item.id}>{item.name}</option>
                        );
                    })}
                </optgroup>
            </Form.Select>
            {errors?.select?.message && <p className="error">{errors?.select?.message}</p>}
        </Form.Group>
    );
};