import { 
  SET_PRODUCT,
  SET_RELATED_PRODUCT,
  SET_BRANDS,
  SINGLE_PRODUCT,
  DISCOUNT_PRODUCT,
  SET_MARKET,
  SET_SEARCH,
  SET_CATEONE,
  SET_CATETWO,
  SET_SEARCH_CATEGORIES
} from '../Constants/action-types';

const initialState = {
  cateOne : [],
  cateTwo : [],
  product : []
}

export default function product(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT:
      return { ...state, product: action.payload };
    case SET_RELATED_PRODUCT:
      return { ...state, related: action.payload };
    case SET_BRANDS:
      return { ...state, related: action.payload };
    case SINGLE_PRODUCT:
      return { ...state, single: action.payload };
    case DISCOUNT_PRODUCT:
      return { ...state, discount: action.payload };
    case SET_MARKET:
      return { ...state, market: action.payload };
    case SET_SEARCH:
      return { ...state, search: action.payload };
    case SET_CATEONE:
      return { ...state, cateOne: action.payload };
    case SET_CATETWO:
      return { ...state, cateTwo: action.payload };
    case SET_SEARCH_CATEGORIES:
      return { ...state, searchCategories: action.payload };
    default:
      return state
  }
};
