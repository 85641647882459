import React from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import '../styles/sidebarStyles.css';

export default function SideBar({ title, items, decodedTitle }) {
  const navigate = useNavigate();

  let MAX_LENGTH;

  if (window.innerWidth <= 900) {
    MAX_LENGTH = 100;
  } else if (window.innerWidth <= 1200) {
    MAX_LENGTH = 12;
  } else if (window.innerWidth <= 1400) {
    MAX_LENGTH = 16;
  } else if (window.innerWidth <= 1800) {
    MAX_LENGTH = 19;
  } else if (window.innerWidth <= 2200) {
    MAX_LENGTH = 22;
  }

  return (
    <div className="sidebar-category-container">
      <h3 className="sidebar-category-title">{title}</h3>
      <p className="sidebar-category-sub-item-text">Alt Kategoriler</p>
      <div className="sidebar-category">
        <ul className="sidebar-category-list">
          {items && items?.length > 0 ? (
            items.map((item, id) => (
              <li key={id + item} className={'sidebar-category-list-item'}>
                {item.active === 1 ? (
                  <div className="active-category-circle"></div>
                ) : (
                  <div className="no-active-category-circle"></div>
                )}
                <a
                  className="list-link"
                  onClick={() =>
                    navigate({
                      pathname: '/search',
                      search:
                        '?keyword=' +
                        decodedTitle +
                        '&catgoryId=' +
                        item.categoryId,
                    })
                  }
                >
                  {item.categoryName.length > MAX_LENGTH ? (
                    `${item.categoryName.substring(0, MAX_LENGTH)}...`
                  ) : (
                    <>{item.categoryName}</>
                  )}
                  ({item.helpText.numberOfProducts})
                </a>
              </li>
            ))
          ) : (
            <p>No items available</p>
          )}
        </ul>
      </div>
      {/*<p className='sidebar-category-sub-item-text'>Markalar</p>
      <div className='sidebar-category'>
        <ul className='sidebar-category-list'>
          {brands && brands.length > 0 ? (
            brands.map((item, id) => (
              <li
                key={id + item}
                className={selected.includes(item) ? 'selected' : 'sidebar-category-list-item'}
                onClick={() => { handleSelect(item); }}
              >
                {item.active === 1 ?
                  <div className="active-category-circle"></div>
                 : 
                  <div className="no-active-category-circle"></div>
                }
                <a className='list-link' href='#'>
                  {item.brand} <span className="sidebar-item-count-text">({item.count})</span>
                </a>
              </li>
            ))
          ) : (
            <p>No items available</p>
          )}
        </ul>
          </div>*/}
    </div>
  );
}
