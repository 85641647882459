import React from 'react';
import Button from 'react-bootstrap/Button';
// Styles
import './styles/YButtonStyles.css';

export default function YButton(props) {
  const { text, isDisabled, onEvent, inComingClassName } = props;

  return (
    <Button
      className={inComingClassName ? inComingClassName : 'buttonStyle'}
      disabled={isDisabled === true ? true : false}
      onClick={onEvent}
    >
      {text}
    </Button>
  );
}
