import cookie from "js-cookie";

export const YBasketHanlder = (
  type , 
  setCartVisiable , 
  data , 
  count ,
  dispatch,
  setBasketProductItem,
  toast,
  setUserAddressModal
) => {
    const basketId = cookie.get("basketId");
    if (type !== 1)
      setCartVisiable(false);

    //----------
    if (typeof basketId !== 'undefined') {
      const marketId = cookie.get("marketId");
      dispatch(setBasketProductItem(data , Number(marketId) , count , toast)); // later shopId must be
    } else {
      setUserAddressModal(true);
      cookie.set("tempDataItem", JSON.stringify(data));
      //console.log('injas')
      //dispatch(setBasket(shopId , toast)); // later shopId must be
    }
  };


  export const YBasketDeleteItem = (temp , shopData , count,  basket, dispatch, setBasketProductItem , toast) => {
    dispatch(setBasketProductItem(temp , shopData , count , toast));
  };