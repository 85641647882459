import React from 'react';
import {
    MDBCol,
    MDBRow,
  } from "mdb-react-ui-kit";

// Components
import YModal from './YModal';
import YImage from './YImage';

// Styles
import './styles/YLoading.css';

export default function YLoading(props) {
  const { showLoading, setShowLoading } = props;

  return (
    <YModal 
    show={showLoading} 
    onClose={() => setShowLoading(!showLoading)}
    title={''}
    inComingStyleHeader={'address-modal-header'}
    inComingStyleModal={'loading-modal-container'}
    >
    <MDBRow>
        <MDBCol size="12" className="loading-image-container">
        <YImage imageSource={require('../../Assets/Images/loading.gif')} inComingStyle={'loader'}/>
        </MDBCol>
    </MDBRow>
    </YModal>
  );
}
