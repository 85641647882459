import React, { useState , useEffect } from "react";
import { useLocation , useNavigate} from "react-router-dom";
import { Card } from "react-bootstrap";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTypography,
} from "mdb-react-ui-kit";


//Const

// Components
import YText from "../../../Components/Basic/YText";
import YImage  from "../../../Components/Basic/YImage";

// Styles
import "../styles.css"

export default function CardCategoryMobile(props) {

  const  {mobileCategoriesTitle, mobileCategoriesImage, cssExtra, pathName} = props ;

  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(pathName);
}

  return (
     
      <MDBCard onClick={handleCardClick} className="card-mobile-category-card">
          <MDBCardBody className="mobile-categories-card-body">
              <MDBRow className="row-after-cardbody-mobile-cate">
                  <MDBCol className="mobile-card-col-after-cardbody-row">
                      <MDBRow>
                          <MDBCol>
                              <YImage
                                  imageSource={mobileCategoriesImage}
                                  alt={""}
                                  inComingStyle={"card-mobile-categories-img"}
                              />
                          </MDBCol>
                      </MDBRow>
                      <MDBRow>
                          <MDBCol className={cssExtra}>
                              <YText
                                  incomingStyle={"card-mobile-categories-text"}
                                  value={mobileCategoriesTitle}
                                  type={"h4"}
                              />
                          </MDBCol>
                      </MDBRow>
                  </MDBCol>
              </MDBRow>
          </MDBCardBody>
      </MDBCard>
         
  );
  }