import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { MDBRow, MDBContainer, MDBCol } from 'mdb-react-ui-kit';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YCol from '../../Components/Layout/YCol';
import YRow from '../../Components/Layout/YRow';
import YPagenation from '../../Components/Basic/YPagenation';
import YModal from '../../Components/Basic/YModal';
import YUserLoginModal from '../../Components/User/YUserLoginModal';
import YAddressList from '../../Components/Address/YAddressList';
import YLoading from '../../Components/Basic/YLoading';

// Handler Components
import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Sections
import Products from './Components/Products';
import SideBar from './Components/Sidebar';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/SearchStyles.css';

// Redux
import {
  getSearch,
  getMarketByGeoLocation,
  getSearchGategories,
  getSearchByCategory,
} from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

export default function Search(props) {
  const navigate = useNavigate();

  const location = useLocation();

  const decodedTitle = document.URL.replace(/.*keyword=([^&]*).*|(.*)/, '$1');

  const cate = document.URL.replace(/.*catgoryId=([^&]*).*|(.*)/, '$1');

  const pageNumber = sessionStorage.getItem('pageNumber');

  const [currentPage, setCurrentPage] = useState(pageNumber ? pageNumber : 1);

  const [showLoading, setShowLoading] = useState(true);

  const dispatch = useDispatch();

  const product = useSelector(state => state.product.search);

  const categories = useSelector(state => state.product.searchCategories);

  const addressList = useSelector(state => state.user.address);

  const basket = useSelector(state => state.basket.basket);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const menu = useSelector(state => state.menu.menu);

  const [loginModal, setLoginModal] = useState(false);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market ';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    dispatch(getMenu(marketId));
  }, [dispatch, marketId]);

  useEffect(() => {
    console.log(decodedTitle, currentPage, cate.length);
    if (cate.length === 0) {
      dispatch(getSearch(marketId, decodedTitle, setShowLoading, currentPage));
    } else {
      dispatch(
        getSearchByCategory(
          marketId,
          decodedTitle,
          cate,
          setShowLoading,
          currentPage
        )
      );
    }
    dispatch(getSearchGategories(decodedTitle, marketId));
  }, [menu, location]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  // Change page
  const handlePageChange = pageNumber => {
    sessionStorage.setItem('pageNumber', pageNumber);
    setCurrentPage(pageNumber);
    if (cate === false) {
      dispatch(getSearch(marketId, decodedTitle, setShowLoading, pageNumber));
    } else {
      dispatch(
        getSearchByCategory(
          marketId,
          decodedTitle,
          cate,
          setShowLoading,
          pageNumber
        )
      );
    }
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <YRow inComingStyle={'product-list-breadcrumb-dropdown-container'}>
          <YCol inComingStyle={'product-list-first-col'}></YCol>
        </YRow>
        <MDBRow>
          {product?.data?.length > 0 ? (
            <>
              <MDBCol lg="3" xs="12" sm="12">
                <SideBar
                  title={'Arama Sonuçları : ' + decodedTitle}
                  items={categories}
                  decodedTitle={decodedTitle}
                />
              </MDBCol>
              <MDBCol lg="9" xs="12" sm={12}>
                <MDBRow className="search-col">
                  <Products
                    inComingCardWidth={'product-size'}
                    data={product.data}
                    onEvent={onEvent}
                    basket={basket}
                    navigate={navigate}
                    token={tokenData}
                    onLoginModal={setLoginModal}
                  />
                </MDBRow>
              </MDBCol>
            </>
          ) : (
            <p className="search-no-item-found">
              Aradığınız ürünle ilgili bir sonuç bulamadık.
            </p>
          )}
        </MDBRow>
        <MDBRow inComingStyle="pagination-alignment">
          <YPagenation
            activePage={currentPage}
            itemsCountPerPage={1}
            totolCounts={product?.totalItems}
            pageRangeDisplayed={50}
            onEvent={handlePageChange}
          />
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={loginModal}
        onClose={() => setLoginModal(!loginModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <YUserLoginModal
          loginModal={loginModal}
          setLoginModal={setLoginModal}
        />
      </YModal>
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
