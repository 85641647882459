import React from 'react';

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBTypography,
} from 'mdb-react-ui-kit';

// Components
import YImage from '../../../Components/Basic/YImage';

// Styles
import './styles/SingleOrderStyles.css';
import '../../../Components/Basic/styles/YSeperatorStyles.css';

//Icons&Images
import backIcon from '../../../Assets/Images/backIcon.png';
import refund from '../../../Assets/Images/cancelOrder.png';
import deleteIcon from '../../../Assets/Images/3687412.png';

export default function Refund(props) {
  const {
    onCloseOrder,
    itemData,
    orderData,
    onSelectRefundOneOrder,
    finishRefunding,
    removeFromRefund,
  } = props;

  return (
    <MDBCard className="rounded-3 user-order-history-container">
      <MDBRow>
        <MDBCol md="12">
          <MDBCardBody>
            <MDBRow>
              <MDBCol>
                <div className="d-flex justify-content-start align-items-start mb-3 address-normal-pointer">
                  <MDBTypography
                    id="last-order-details-my-orders-text"
                    className="mb-0 user-order-history-headers"
                  >
                    Ürünleri İade Et
                  </MDBTypography>
                </div>
                <div onClick={() => onCloseOrder()}>
                  <YImage
                    inComingStyle="last-order-details-back-icon"
                    imageSource={backIcon}
                  />
                </div>
                <MDBCard>
                  <MDBCardBody className="detaits-section-1">
                    <div>
                      <MDBRow>
                        <MDBCol
                          id="order-details-count-header"
                          className="col-set-set"
                          md="2"
                          size="2"
                        >
                          <MDBTypography className="d-flex justify-content-start last-order-details-product-headers">
                            Miktar
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol
                          id="order-details-basket-header"
                          className="col-set-set"
                          md="6"
                          size="6"
                        >
                          <MDBTypography
                            style={{ display: 'block' }}
                            className="d-flex justify-content-start last-order-details-product-headers"
                          >
                            Sepetteki Ürünler
                            <span>({itemData.products.length})</span>
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol
                          id="order-details-price-header"
                          className="col-set-set"
                          md="2"
                          size="2"
                        >
                          <MDBTypography className="d-flex justify-content-center last-order-details-product-headers">
                            Ücret
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol
                          id="order-details-price-header"
                          className="col-set-set"
                          md="2"
                          size="2"
                        >
                          <MDBTypography className="d-flex justify-content-center last-order-details-product-headers">
                            İade
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      {itemData.products?.map((e, i) => (
                        <MDBRow
                          className="last-order-details-product-container-row"
                          key={i}
                        >
                          <MDBCol
                            id="user-details-count-map"
                            className="col-set-set"
                            md="2"
                            size="2"
                          >
                            <MDBTypography
                              id="last-details-count"
                              className="d-flex justify-content-start"
                            >
                              {e.quantity} {e.unit}{' '}
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            id="order-details-basket-map"
                            className="col-set-set"
                            md="6"
                            size="6"
                          >
                            <MDBTypography
                              id="last-details-image"
                              className="d-flex justify-content-start"
                            >
                              {e?.thumbnailUri !== '' ? (
                                <img
                                  src={e?.thumbnailUri}
                                  className="last-order-details-product-pic"
                                  alt="..."
                                />
                              ) : (
                                <YImage
                                  imageSource={require('../../../Assets/Images/sample.png')}
                                  inComingStyle={
                                    'last-order-details-product-pic'
                                  }
                                />
                              )}
                              <span className="last-order-details-text-title-item">
                                {e.name}
                              </span>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            className="col-set-set"
                            id="order-details-discount-price-map"
                            md="2"
                            style={{ display: 'block' }}
                            size="2"
                          >
                            <MDBTypography
                              id=""
                              className="d-flex justify-content-center"
                            >
                              <span id="last-order-details-price-overline-text"></span>
                            </MDBTypography>
                            <MDBTypography
                              id="last-order-details-price-text"
                              className="d-flex justify-content-center"
                            >
                              {e.price} TL
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol
                            className="col-set-set"
                            id="order-details-discount-price-map"
                            md="2"
                            style={{ display: 'block' }}
                            size="2"
                          >
                            <MDBTypography
                              id=""
                              className="d-flex justify-content-center"
                            >
                              <span id="last-order-details-price-overline-text"></span>
                            </MDBTypography>
                            <MDBTypography
                              id="last-order-details-price-text"
                              className="d-flex justify-content-center"
                            >
                              {e.isRefundable === true ? (
                                orderData &&
                                orderData?.some(
                                  s => s.cartProductId === e.cartProductId
                                ) ? (
                                  <div onClick={() => removeFromRefund(e)}>
                                    <p className="change-refund-text">
                                      Kaldır
                                      <YImage
                                        inComingStyle="last-order-details-refund-icon delete-icon"
                                        imageSource={deleteIcon}
                                      />
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => onSelectRefundOneOrder(e)}
                                  >
                                    <YImage
                                      inComingStyle="last-order-details-refund-icon"
                                      imageSource={refund}
                                    />
                                  </div>
                                )
                              ) : (
                                <p>İade edilemez</p>
                              )}
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    </div>
                  </MDBCardBody>
                  <div className="address-alert-contorller-container">
                    <div
                      className="address-alert-accpet-container refund-btn-full refund-last-btn"
                      onClick={() => finishRefunding()}
                    >
                      <p className="address-alert-text">
                        Seçtiğim ürünleri iade edeceğim
                      </p>
                    </div>
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBCard>
  );
}
