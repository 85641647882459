import React from "react";
import {
  MDBIcon,
  MDBRow,
  MDBCol,
  } from "mdb-react-ui-kit";

// Components
import YText from "../../../Components/Basic/YText";

// Styles
import "../styles/AddToBasketStyles.css";

export default function AddToBasket(props) {
  const { count, upDateBasket , incomingStyle } = props;

  const addToBasket = () => {
    upDateBasket(count + 1);
  };

  const removeFromBasket = () => {
    if (count === 1) {
      upDateBasket(0);
    } else {
      upDateBasket(count - 1);
    }
  };

  return (
    <div className={count === 0 ? "containerZeroBasket" : incomingStyle}>
        <div className='basket-header-modal'>
          <div onClick={removeFromBasket} className='touchableAera-header-modal'>
          {count === 1 ? 
                <MDBIcon fas icon="trash" className="trash-icon-basket-on-top" size="1x"/>
                :
                <MDBIcon fas icon="minus" className="basket-minus-header"/>
                }
            
          </div>
          <div>
            <YText
              type={"p"}
              value={" "+count+" " }
              incomingStyle={"basket-count-text-header"}
                  />
          </div>
          <div onClick={addToBasket} className='touchableAera-header-modal'>
            <MDBIcon fas icon="plus" className="basket-minus-header"/>
          </div>
        </div>
    </div>
  );
}

