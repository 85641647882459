import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { MDBCol, MDBRow, MDBInput, MDBContainer } from 'mdb-react-ui-kit';

// Components
import YPagenation from '../../Components/Basic/YPagenation';

export default function TrackOrder(props) {
  const [currentPage, setCurrentPage] = useState(1);

  const [productsPerPage] = useState(5);

  const [showPagination, setShowPagination] = useState(true);

  // Get current products
  const indexOfLastItem = currentPage * productsPerPage;
  const indexOfFirstItem = indexOfLastItem - productsPerPage;
  const currentItems = props.perviosOrders?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    if (props.perviosOrders?.length / 10 < currentPage) {
      let value = false;
      setShowPagination({ ...value });
    }
  }, [props.perviosOrders]);

  if (props.token === false) {
    return (
      <>
        <p className="track-order-text-header">
          Siparişini telefon ve sipariş numaran ile takip edebilirsin
        </p>
        <Form onSubmit={props.onSubmit}>
          <Form.Group className="mb-12" controlId="phone">
            <MDBInput
              className="mb-12 track-order-input"
              id="phone"
              type="number"
              placeholder="0 (500) 000 00 00"
              onChange={e => console.log(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-12" controlId="phone">
            <MDBInput
              className="mb-12 track-order-input"
              id="phone"
              type="number"
              placeholder="249607768"
              onChange={e => console.log(e.target.value)}
            />
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
            className="mb-12 track-order-btn"
          >
            Siparişimi Göster
          </Button>
        </Form>
      </>
    );
  } else {
    if (typeof props.perviosOrders === 'object') {
      return (
        <>
          {currentItems.length === 0 ? (
            <p className="order-empty-text">
              Henüz bir siparişiniz bulunmamaktadır.
            </p>
          ) : (
            <>
              {currentItems?.map((item, i) => {
                console.log(currentItems);
                return (
                  <div
                    key={i}
                    /*onClick={e =>
                      props.onSubmit(e, item.orderId, item.orderNumber)
                    }*/
                  >
                    <MDBContainer>
                      <MDBRow className={'green-border-box'}>
                        <MDBCol
                          xs="2"
                          sm="3"
                          md="2"
                          className="table-reoder-title-text table-reoder-title-text-mobile"
                        >
                          Tarih
                        </MDBCol>
                        <MDBCol lg="3" md="3" xs="12">
                          <p className="track-order-title-text-date">
                            {item?.dateCreated}
                          </p>
                        </MDBCol>

                        <MDBCol
                          xs="2"
                          sm="3"
                          md="2"
                          className="table-reoder-title-text table-reoder-title-text-mobile"
                        >
                          Tutar
                        </MDBCol>
                        <MDBCol lg="3" md="3" xs="12">
                          <p className="track-order-title-text-date">
                            {item?.paymentDetail.totalPrice} TL
                          </p>
                        </MDBCol>

                        <MDBCol
                          xs="2"
                          sm="3"
                          md="2"
                          className="table-reoder-title-text table-reoder-title-text-mobile"
                        >
                          Sipariş No
                        </MDBCol>
                        <MDBCol lg="3" md="3" xs="12">
                          <p className="track-order-title-text-date">
                            {item.orderNumber}
                          </p>
                        </MDBCol>

                        <MDBCol
                          xs="12"
                          sm="3"
                          md="3"
                          lg="3"
                          className="table-reoder-title-text table-reoder-title-text-mobile"
                        >
                          Sipariş Durumu
                        </MDBCol>
                        <MDBCol lg="3" md="3" xs="12">
                          <p className="track-order-title-text-date">
                            {item.status}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </div>
                );
              })}
              <MDBRow>
                {showPagination === true ? (
                  <YPagenation
                    activePage={currentPage}
                    itemsCountPerPage={productsPerPage}
                    totolCounts={props.perviosOrders[0].length}
                    pageRangeDisplayed={5}
                    onEvent={handlePageChange}
                  />
                ) : null}
              </MDBRow>
            </>
          )}
        </>
      );
    } else {
      return (
        <p className={'green-border-box'}>
          Şu anda aktif bir siparişiniz bulunmamaktadır.
        </p>
      );
    }
  }
}
