import React from "react";
import {
  MDBIcon,
  MDBRow,
  MDBCol,
  } from "mdb-react-ui-kit";

// Components
import YImage from "../../Components/Basic/YImage";
import YText from "../../Components/Basic/YText";

// Styles
import "./styles/YAddToBasketStyles.css";

export default function YAddToBasket(props) {
  const { count, upDateBasket , incomingStyle , token , onLoginModal , unit } = props;

  const addToBasket = () => {
    if(token === 'null') {
      onLoginModal(true);
    }
    else {
      if(unit === 'KG') {
        //console.log(Number(count) + 0.5);
        upDateBasket(Number(count) + 0.5);
      } else {
        upDateBasket(count + 1);
      }
    }
  };

  const removeFromBasket = () => {
    if (count === 1) {
      if(unit !== 'KG') {
        upDateBasket(0);
      } else {
        upDateBasket(Number(count) - 0.5);
      }
    } else {
      if(unit === 'KG') {
        upDateBasket(Number(count) - 0.5);
      } else {
        upDateBasket(count - 1);
      }
    }
  };

  return (
    <div className={count === 0 ? "containerZeroBasket" : incomingStyle} onClick={count === 0 ? addToBasket : null}>
      {count === 0 ? (
        <div className="single-add-to-basket-first-time">
          <YImage imageSource={require('../../Assets/Images/addToBasket.png')} inComingStyle={'add-to-basket-icon'} />
          <span className="sepete-text-basket">Sepete Ekle</span>
        </div>
      ) : (
        
        <div className='basket'>
          <MDBRow className="basket-row">
            <MDBCol className="left-box-basket" onClick={removeFromBasket}>
              <div className='touchableAera'>
                {count === 1 && unit === 'Adet' ? 
                  <MDBIcon fas icon="trash"/>
                : null }
                {count === 1 && unit === 'KG' ? 
                  <MDBIcon fas icon="minus"/>
                : null }
                {count === 0.5 && unit === 'KG' ? 
                  <MDBIcon fas icon="trash"/>
                : null }
                {count > 1 ? 
                  <MDBIcon fas icon="minus"/>
                : null }
              </div>
            </MDBCol>
            <MDBCol className="center-box-basket">
              <YText
                type={"p"}
                value={" "+count+" "+unit }
                incomingStyle={"basket-count-text"}
              />
            </MDBCol>
            <MDBCol className="right-box-basket" onClick={addToBasket}>
              <div className='touchableAera'>
                <MDBIcon fas icon="plus" />
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      )}
    </div>
  );
}
