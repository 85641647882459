const breadcrumbItems = [
  { name: "Home", link: "/", active: false },
  { name: "Library", link: "/library", active: false },
  { name: "Meyve", link: "/library/data", active: true },
];
const sortby = [
  { title: "Low to high", href: "/", target: null, hasURL: false },
  { title: "High to low", href: "/", target: null, hasURL: false },
];
const categoryItems = [
  { id: 1, brand: "Meyve" , count : '10' , active : 1 },
  { id: 2, brand: "Sebze" , count : '7'  , active : 0},
];

const categoryBrands = [
  { id: 1, brand: "Reyondan" , count : '10' , active : 1 },
  { id: 2, brand: "Yıldızlı" , count : '7'  , active : 0},
  { id: 2, brand: "Diğer" , count : '19'  , active : 0},
];

const categoryDiscounted = [{ id: 1, brand: "Discounted" }];
const products = [
  {
    id: 1,
    image: "https://dummyimage.com/200x150/000/fff&text=Item1",
    title: "Product1",
    price: 20.82,
    newPrice: 10,
  },
  {
    id: 2,
    image: "https://dummyimage.com/200x150/000/fff&text=Item2",
    title: "Product2",
    price: 20,
    newPrice: 10,
  },
  {
    id: 3,
    image: "https://dummyimage.com/200x150/000/fff&text=Item3",
    title: "Product3",
    price: 20,
    newPrice: 10,
  },
  {
    id: 4,
    image: "https://dummyimage.com/200x150/000/fff&text=Item4",
    title: "Product4",
    price: 20,
    newPrice: 10,
  },
  {
    id: 5,
    image: "https://dummyimage.com/200x150/000/fff&text=Item5",
    title: "Product5",
    price: 20,
    newPrice: 10,
  },
  {
    id: 6,
    image: "https://dummyimage.com/200x150/000/fff&text=Item6",
    title: "Product6",
    price: 20,
    newPrice: 10,
  },
  {
    id: 7,
    image: "https://dummyimage.com/200x150/000/fff&text=Item7",
    title: "Product7",
    price: 20,
    newPrice: 10,
  },
  {
    id: 8,
    image: "https://dummyimage.com/200x150/000/fff&text=Item8",
    title: "Product8",
    price: 20,
    newPrice: 10,
  },
  {
    id: 9,
    image: "https://dummyimage.com/200x150/000/fff&text=Item9",
    title: "Product9",
    price: 20,
    newPrice: 10,
  },
  {
    id: 10,
    image: "https://dummyimage.com/200x150/000/fff&text=Item10",
    title: "Product10",
    price: 20,
    newPrice: 10,
  },
  {
    id: 11,
    image: "https://dummyimage.com/200x150/000/fff&text=Item11",
    title: "Product11",
    price: 20,
    newPrice: 10,
  },
  {
    id: 12,
    image: "https://dummyimage.com/200x150/000/fff&text=Item12",
    title: "Product12",
    price: 20,
    newPrice: 10,
  },
  {
    id: 13,
    image: "https://dummyimage.com/200x150/000/fff&text=Item13",
    title: "Product13",
    price: 20,
    newPrice: 10,
  },
  {
    id: 14,
    image: "https://dummyimage.com/200x150/000/fff&text=Item14",
    title: "Product14",
    price: 20,
    newPrice: 10,
  },
  {
    id: 15,
    image: "https://dummyimage.com/200x150/000/fff&text=Item15",
    title: "Product15",
    price: 20,
    newPrice: 10,
  },
  {
    id: 16,
    image: "https://dummyimage.com/200x150/000/fff&text=Item16",
    title: "Product16",
    price: 20,
    newPrice: 10,
  },
];

const productListSortByData = [
  { id: 1, name: "Fiyat artan" },
  { id: 2, name: "Fiyat azalan" },
];

export { breadcrumbItems, sortby, categoryItems, categoryDiscounted, products, productListSortByData , categoryBrands };
