import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdb-react-ui-kit';

// Components
import YTheme from '../../../Components/Layout/YTheme';
import YImage from '../../../Components/Basic/YImage';

// Styles
import './styles/styles.css';
import '../../../Components/Basic/styles/YSeperatorStyles.css';

//Icons&Images
import GreenBasket from '../../../Assets/Images/green-basket.png';
import ArrowRight from '../../../Assets/Images/arrow-right-white.png';

export default function PaymentMobileSucsess() {
  const navigate = useNavigate();

  return (
    <YTheme>
      <MDBContainer className="pageContainer mobile-container">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="user-order-history-container">
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol lg="3"></MDBCol>
                  <MDBCol lg="6">
                    <MDBCard className="rounded-3 user-order-history-container">
                      <div className="sucess-order-container-box">
                        <MDBRow>
                          <MDBCol lg="12">
                            <div>
                              <YImage
                                imageSource={GreenBasket}
                                inComingStyle={'payment-green-basket-icon'}
                              />
                              <span className="payment-green-basket-title-text">
                                Siparişiniz alındı.
                              </span>
                            </div>
                            <p className="payment-green-basket-help">
                              Sipariş detaylarına Hesabım -> Siparişlerim
                              ekranından ulaşabilirsiniz.
                            </p>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol lg="3"></MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </YTheme>
  );
}
