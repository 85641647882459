import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { MDBTypography, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import cookie from 'js-cookie';

// Auth
import { useUserAuth } from '../../../Assets/Context/UserAuthContext';

// CSS
import '../styles/signUpStyles.css';

const SignUp = props => {
  const [error, setError] = useState(props.error);

  const [number, setNumber] = useState('');

  const [name, setName] = useState('');

  const [family, setFamily] = useState('');

  const [email, setEmail] = useState('');

  const [numberError, setNumberError] = useState(false);

  const [nameError, setNameError] = useState(false);

  const [familyError, setFamilyError] = useState(false);

  const [mailError, setmailError] = useState(false);

  const [kvkkError, setkvkkError] = useState(false);

  const [contractError, setContractError] = useState(false);

  const [kvkk, setKvkk] = useState(false);

  const [contract, setContract] = useState(false);

  const [flag, setFlag] = useState(false);

  const [otp, setOtp] = useState('');

  const [result, setResult] = useState('');

  const { setUpRecaptha } = useUserAuth();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getOtp = async e => {
    setLoading(true);
    e.preventDefault();
    if (number === '' || number === undefined || number.length < 13) {
      return setNumberError(true);
    } else {
      if (validateEmail(email)) {
        if (kvkk === false) {
          return setkvkkError(true);
        } else {
          if (contract === false) {
            return setContractError(true);
          } else {
            if (name === '' || name === undefined || name.length < 3) {
              setNameError(true);
            } else {
              if (family === '' || family === undefined || family.length < 3) {
                setFamilyError(true);
              } else {
                setkvkkError(false);
                setContractError(false);
                const register = await axios
                  .post(
                    process.env.REACT_APP_BASE_URL_V2 + 'auth/register',
                    {
                      email: email,
                      phoneNumber: number,
                      eConsent: true,
                      fullName: name + ' ' + family,
                    },
                    {
                      'Content-Type': 'text/plain',
                      accept: 'application/json',
                      headers: {
                        clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
                      },
                    }
                  )
                  .then(response => {
                    return response;
                  })
                  .catch(error => {
                    setError(error.response.data.message);
                  });
                if (register?.status === 200) {
                  try {
                    const response = await setUpRecaptha(number);
                    setResult(response);
                    setFlag(true);
                  } catch (err) {
                    setError(
                      'Doğrulama problemi oluştu. Lütfen tekrar deneyiniz.'
                    );
                    console.log(err);
                  }
                }
              }
            }
          }
        }
      } else {
        setmailError(true);
      }
    }
    setLoading(false);
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const verifyOtp = async e => {
    e.preventDefault();
    setError('');
    if (otp === '' || otp === null || otp.length !== 6) return;
    try {
      await result.confirm(otp);
      const data = cookie.get('token');
      const token = JSON.parse(data);
      await axios
        .post(
          process.env.REACT_APP_BASE_URL_V2 + 'auth/validate-phone-number',
          {
            phoneNumber: number,
          },
          {
            'Content-Type': 'text/plain',
            accept: 'application/json',
            headers: {
              Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
              clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            },
          }
        )
        .then(response => {
          navigate('/');
        });
    } catch (err) {
      setError('Doğrulama problemi oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  const onChangeKvkk = e => {
    setKvkk(e.target.checked);
  };

  const onChangeContract = e => {
    setContract(e.target.checked);
  };

  const checkBtnColor = (type, value) => {
    if (type === 1) {
      // number
      setNumber(value);
    } else if (type === 2) {
      // email
      setEmail(value);
    } else if (type === 3) {
      onChangeKvkk();
    } else if (type === 4) {
      onChangeContract();
    } else if (type === 5) {
      setName(value);
    } else if (type === 6) {
      setFamily(value);
    }
    if (
      number !== null &&
      email.length > 0 &&
      name !== null &&
      family !== null
    ) {
      let elements = document.getElementsByClassName(
        'sign-in-form-login-button'
      );
      elements[0].style.backgroundColor = '#fad45f';
      elements[0].style.color = '#000';
    }
  };

  const checkValue = (type, value) => {
    if (type === 1) {
      // number
      if (number === '' || number === undefined || number.length < 13) {
        return setNumberError(true);
      } else {
        return setNumberError(false);
      }
    } else if (type === 2) {
      // email
      if (validateEmail(email)) {
        return setmailError(false);
      } else {
        return setmailError(true);
      }
    } else if (type === 3) {
      onChangeKvkk();
    } else if (type === 4) {
      onChangeContract();
    } else if (type === 5) {
      if (name === '' || name === undefined || name.length < 3) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    } else if (type === 6) {
      if (family === '' || family === undefined || family.length < 3) {
        setFamilyError(true);
      } else {
        setFamilyError(false);
      }
    }
  };

  const whitelisted_conutries = ['TR'];

  const personalDataModal = () => {
    props.openLegalModal(1); // 1 : personal data
  };

  const memberShipModal = () => {
    props.openLegalModal(2); // 2 : membership data
  };

  const consetModal = () => {
    props.openLegalModal(3); // 3 : conset data
  };

  return (
    <MDBContainer className="my-3 sign-in-form-login-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={getOtp} style={{ display: !flag ? 'block' : 'none' }}>
        <MDBTypography className="mb-2">
          Cep telefonunu girerek devam et.
        </MDBTypography>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <PhoneInput
            defaultCountry="TR"
            countries={whitelisted_conutries}
            value={number}
            className={
              numberError === true
                ? 'phone-input-number is-invalid'
                : 'phone-input-number'
            }
            onChange={e => checkBtnColor(1, e)}
            onBlur={e => checkValue(1, e)}
            placeholder="(501) 000 00 00"
            maxLength="13"
            countryPickerProps={{
              countryCodes: ['TR'],
            }}
          />
          {numberError === true && (
            <p className="error-mobile">
              Lütfen geçerli telefon numarası giriniz!
            </p>
          )}
        </Form.Group>
        <div className="row name-register-two-new-filed">
          <div className="col-md-6 col-lg-6 col-xl-6">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <MDBInput
                className={
                  nameError === true
                    ? 'pmb-4 sign-in-form-register-name-input is-invalid'
                    : 'mb-4 sign-in-form-register-name-input'
                }
                id="name"
                type="text"
                placeholder="İsim"
                onChange={e => checkBtnColor(5, e.target.value)}
                onBlur={e => checkValue(5, e.target.value)}
              />
              {nameError === true && (
                <p className="error-mobile-email">Lütfen adınızı giriniz</p>
              )}
            </Form.Group>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <MDBInput
                className={
                  familyError === true
                    ? 'pmb-4 sign-in-form-register-name-input is-invalid'
                    : 'mb-4 sign-in-form-register-name-input'
                }
                id="family"
                type="text"
                placeholder="Soy İsim"
                onChange={e => checkBtnColor(6, e.target.value)}
                onBlur={e => checkValue(6, e.target.value)}
              />
              {familyError === true && (
                <p className="error-mobile-email">Lütfen soy isim giriniz</p>
              )}
            </Form.Group>
          </div>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <MDBInput
            className={
              mailError === true
                ? 'pmb-4 sign-in-form-register-mail-input is-invalid'
                : 'mb-4 sign-in-form-register-mail-input'
            }
            id="email"
            type="text"
            placeholder="ornek@mail.com"
            onChange={e => checkBtnColor(2, e.target.value)}
            onBlur={e => checkValue(2, e.target.value)}
          />
          {mailError === true && (
            <p className="error-mobile-email">
              Lütfen geçerli e-mail adresi giriniz!
            </p>
          )}
        </Form.Group>
        <label className="sign-in-form-contract-label">
          <span
            className="sign-in-form-coloric-text"
            onClick={() => personalDataModal()}
          >
            Kişisel Verilerin Korunması Hakkında Aydınlatma Metni
          </span>{' '}
          için tıklayın.
        </label>
        <label className="sign-in-form-contract-label">
          <input
            className="sign-in-form-contract-input"
            type="checkbox"
            id="contractRegister"
            name="contractRegister"
            value={kvkk}
            onChange={e => {
              onChangeKvkk(e);
              checkBtnColor(4, '');
            }}
          />
          &nbsp;&nbsp;{' '}
          <span
            className="sign-in-form-coloric-text"
            onClick={() => memberShipModal()}
          >
            Üyelik Sözleşmesi
          </span>
          'ni onaylıyorum ve{' '}
          <span
            className="sign-in-form-coloric-text"
            onClick={() => memberShipModal()}
          >
            Açık Rıza Metni
          </span>{' '}
          kapsamında kişisel verilerimin işlenmesine ve paylaşılmasına onay
          veriyorum.
          {contractError === true && (
            <p className="error-mobile-contaract">Lütfen onaylayın</p>
          )}
        </label>
        <label className="sign-in-form-contract-label">
          <input
            className="sign-in-form-contract-input"
            type="checkbox"
            id="contractRegister"
            name="contractRegister"
            value={contract}
            onChange={e => {
              onChangeContract(e);
              checkBtnColor(4, '');
            }}
          />
          &nbsp;&nbsp;Kampanya, avantaj ve indirimlerden ilk haber alanların
          arasında olmak için{' '}
          <span
            className="sign-in-form-coloric-text"
            onClick={() => consetModal()}
          >
            Ticari İletişim İzni
          </span>{' '}
          kapsamında iletişim izni veriyorum.
          {contractError === true && (
            <p className="error-mobile-contaract">Lütfen onaylayın</p>
          )}
        </label>
        <div id="recaptcha-container"></div>

        <Button
          type="submit"
          variant="primary"
          className="mb-2 sign-in-form-login-button regiser-havij"
        >
          {loading ? 'Giriş Yapılıyor' : 'Kayıt Ol'}
        </Button>
      </Form>

      <Form onSubmit={verifyOtp} style={{ display: flag ? 'block' : 'none' }}>
        <MDBTypography className="title-verfication-text">
          Telefonunu Doğrula
        </MDBTypography>
        <MDBTypography className="sub-title-verfication-text">
          Telefonuna gelen SMS kodunu girerek doğrulama işlemini tamamla
        </MDBTypography>
        <MDBTypography className="title-input-box-verfication-text">
          SMS Kodu*
        </MDBTypography>
        <Form.Group className="mb-3" controlId="formBasicOtp">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputType={'number'}
            renderInput={props => <input {...props} />}
            shouldAutoFocus={true}
            containerStyle={'otp-input-container'}
            inputStyle={'otp-input-box'}
          />
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mb-2 sign-in-form-login-button"
        >
          Doğrula
        </Button>
      </Form>
    </MDBContainer>
  );
};

export default SignUp;
