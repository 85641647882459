import React from 'react';
import { Modal, Button } from 'react-bootstrap';

// Styles
import './styles/YModalStyles.css';

export default function YModal(props) {
  const {
    children,
    onEvent,
    show,
    title,
    hasFooter,
    inComingStyleModal,
    inComingStyleHeader,
    inComingStyleTitle,
    inComingStyleBody,
    inComingStyleFooter,
    inComingStyleCloseBtn,
    inComingStyleSaveBtn,
    inComingStyleDialogModal,
  } = props;

  // Handling modal close
  const handleClose = () => props.onClose();

  const handleSave = () => {
    // Save formData to your backend here
    {
      onEvent();
    }
    // After saving, the modal will close automatically.
    // If you don't want it, delete the line below.
    props.onClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={inComingStyleModal ? inComingStyleModal : ''}
      dialogClassName={
        inComingStyleDialogModal ? inComingStyleDialogModal : null
      }
      style={{ overlay: { background: 'black' } }}
    >
      <Modal.Header
        className={inComingStyleHeader ? inComingStyleHeader : ''}
        closeButton
      >
        <Modal.Title className={inComingStyleTitle ? inComingStyleTitle : ''}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={inComingStyleBody ? inComingStyleBody : ''}>
        {children}
      </Modal.Body>
      {hasFooter === true ? (
        <Modal.Footer
          className={inComingStyleFooter ? inComingStyleFooter : ''}
        >
          <Button
            variant="secondary"
            onClick={handleClose}
            className={inComingStyleCloseBtn ? inComingStyleCloseBtn : ''}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            className={inComingStyleSaveBtn ? inComingStyleSaveBtn : ''}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}
