import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import { MDBContainer } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import parse from 'html-react-parser';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YImage from '../../Components/Basic/YImage';
import YModal from '../../Components/Basic/YModal';
import YAddressList from '../../Components/Address/YAddressList';
import YLoading from '../../Components/Basic/YLoading';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/loginStyles.css';
import 'react-phone-input-2/lib/style.css';

// Redux
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import {
  getAddress,
  deleteAddress,
  getUserPersonalData,
  getUserMemberShipData,
  getConset,
} from '../../Redux/Actions/UserAction';

// Sections
import SignIn from './Components/SignIn';
import SignUp from './Components/SignUp';

// Auth
import { AuthChecker } from '../../Assets/Context/UserAuthChecker';

export default function Login() {
  const navigate = useNavigate();

  const token = AuthChecker();

  if (typeof token === 'object') {
    navigate('/');
  }

  const dispatch = useDispatch();

  const basket = useSelector(state => state.basket.basket);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const addressList = useSelector(state => state.user.address);

  const [totalPrice, setTotalPrice] = useState(0);

  const [legalModal, setLegalModal] = useState(false);

  const [legalContent, setLegalContent] = useState('data');

  const [error, setError] = useState('');

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = 'Ozhan Market';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const SwitchOptions = {
    OPTION1: 'Option 1',
    OPTION2: 'Option 2',
  };

  const [activeOption, setActiveOption] = useState(SwitchOptions.OPTION1);

  const handleSwitchClick = option => {
    setActiveOption(option);
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const openLegalModal = type => {
    setShowLoading(true);
    if (type === 1) {
      dispatch(
        getUserPersonalData(setLegalModal, setLegalContent, setShowLoading)
      );
    } else if (type === 2) {
      dispatch(
        getUserMemberShipData(setLegalModal, setLegalContent, setShowLoading)
      );
    } else if (type === 3) {
      dispatch(getConset(setLegalModal, setLegalContent, setShowLoading));
    }
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />

      <MDBContainer className="d-flex justify-content-center login-register-container mb-3">
        <YImage
          imageSource={require('../../Assets/Images/ozhan.png')}
          inComingStyle={'logo'}
        />
      </MDBContainer>
      <div>
        <MDBContainer className="d-flex justify-content-center login-register-container mb-5">
          <div className="login-register-section">
            <div className="login-register-switch-container">
              <div
                className="login-register-switch-item"
                style={{
                  backgroundColor:
                    activeOption === SwitchOptions.OPTION1
                      ? '#fff'
                      : 'transparent',
                }}
                onClick={() => handleSwitchClick(SwitchOptions.OPTION1)}
              >
                <div className={'login-register-switch-text'}>Giriş Yap</div>
              </div>
              <div
                className="login-register-switch-item"
                style={{
                  backgroundColor:
                    activeOption === SwitchOptions.OPTION2
                      ? '#fff'
                      : 'transparent',
                }}
                onClick={() => handleSwitchClick(SwitchOptions.OPTION2)}
              >
                <div className={'login-register-switch-text'}>Kayıt ol</div>
              </div>
            </div>
            {activeOption === SwitchOptions.OPTION2 ? (
              <SignUp
                error={error}
                openLegalModal={openLegalModal}
                setLegalModal={setLegalModal}
                setShowLoading={setShowLoading}
                showLoading={showLoading}
              />
            ) : (
              <SignIn
                changeView={setActiveOption}
                data={SwitchOptions}
                setError={setError}
              />
            )}
          </div>
        </MDBContainer>
      </div>
      <Footer />
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YModal
        show={legalModal}
        onClose={() => setLegalModal(!legalModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
        inComingStyleDialogModal={'modalContract'}
      >
        {parse(legalContent)}
        <div className="legal-modal-btn-container">
          <div
            className="address-alert-accpet-container "
            onClick={() => setLegalModal(!legalModal)}
          >
            <p className="address-alert-text">Kapat</p>
          </div>
        </div>
      </YModal>
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
