import React , { useState , useEffect } from "react";
import {
  MDBIcon,
  MDBRow,
  MDBCol,
  } from "mdb-react-ui-kit";

// Components
import YImage from "../../Components/Basic/YImage";
import YText from "../../Components/Basic/YText";

//Const
import loadingImage from '../../Assets/Images/loading-gif.gif';

// Styles
import "./styles/YAddToBasketStyles.css";

export default function YAddToBasketSingle(props) {
  const { count, upDateBasket , incomingStyle , token , onLoginModal , unit } = props;

  const [basketCount, setBasketCount] = useState(count);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(false);
    if(Number.isNaN(count) === true) {
      setBasketCount(0);
    }
    else {
      setBasketCount(count)
    }
  }, [count]);

  const addToBasket = () => {
    console.log('times')
    setLoader(true);
    if(unit === 'KG') {
      upDateBasket(Number(count) + 0.5);
    } else {
      upDateBasket(Number(count) + 1);
    }
    
  };

  const removeFromBasket = () => {
    setLoader(true);
    if (count === 1) {
      if(unit !== 'KG') {
        upDateBasket(0);
      } else {
        upDateBasket(Number(count) - 0.5);
      }
    } else {
      if(unit === 'KG') {
        upDateBasket(Number(count) - 0.5);
      } else {
        upDateBasket(Number(count) - 1);
      }
      
    }
  };



  return (
    <div className={basketCount === 0 ? "containerZeroBasket" : incomingStyle} onClick={basketCount === 0 ? addToBasket : null}>
      {basketCount === 0 ? (
        <div className="single-add-to-basket-first-time">
          <YImage imageSource={require('../../Assets/Images/addToBasket.png')} inComingStyle={'add-to-basket-icon'}/>
          <span className="sepete-text-basket">Sepete Ekle</span>
        </div>
      ) : (
        
        <div className='basket'>
          <MDBRow className="basket-row">
            <MDBCol className="right-box-basket-single" size="3" style={loader === false ? {paddingTop : '10px'} : {paddingTop : '0px'}}>
            {loader === false ?
                <div onClick={removeFromBasket} className='touchableAera'>
                  {basketCount === 1 && unit === 'Adet' ? 
                    <MDBIcon fas icon="trash"/>
                  : null }
                  {basketCount === 1 && unit === 'KG' ? 
                    <MDBIcon fas icon="minus"/>
                  : null }
                  {basketCount === 0.5 && unit === 'KG' ? 
                    <MDBIcon fas icon="trash"/>
                  : null }
                  {basketCount > 1 ? 
                    <MDBIcon fas icon="minus"/>
                  : null }
                </div>
              :
                <div className='touchableAera'>
                  {basketCount === 1 && unit === 'Adet' ? 
                    <MDBIcon fas icon="trash"/>
                  : null }
                  {basketCount === 1 && unit === 'KG' ? 
                    <MDBIcon fas icon="minus"/>
                  : null }
                  {basketCount === 0.5 && unit === 'KG' ? 
                    <MDBIcon fas icon="trash"/>
                  : null }
                  {basketCount > 1 ? 
                    <MDBIcon fas icon="minus"/>
                  : null }
                </div>
            }
            </MDBCol>
            <MDBCol className="center-box-basket" size="6">
              {loader === false ?
                <YText
                  type={"p"}
                  value={" "+basketCount+" "+unit }
                  incomingStyle={"basket-count-text"}
                />
              : 
                <YImage imageSource={loadingImage} inComingStyle={'loading-add-to-basket-image'} />
              }
            </MDBCol>
            <MDBCol className="right-box-basket-single" size="3" style={loader === false ? {paddingTop : '10px'} : {paddingTop : '0px'}}>
            {loader === false ?
                <div onClick={addToBasket} className='touchableAera'>
                  <MDBIcon fas icon="plus" />
                </div>
              :
                <div className='touchableAera'>
                  <MDBIcon fas icon="plus" />
                </div>
              }
            </MDBCol>
          </MDBRow>
        </div>
      )}
    </div>
  );
}
