import { SET_BASKET , SET_ACTIVE_CARD_NUMBER } from '../Constants/action-types';
  
  const initialState = [

  ]
  
  export default function basket(state = initialState, action) {
    switch (action.type) {
      case SET_BASKET:
        return { ...state, basket: action.payload };
      case SET_ACTIVE_CARD_NUMBER:
        return { ...state, basketId : action.payload };
      default:
        return state 
    }
  };
  