import {
  SET_PROVINCE,
  SET_DISTRICT,
  SET_NEIGHBORHOOD,
  SET_SHOP,
  SET_DELIVERY_DATE_TIME,
} from '../Constants/action-types';
import cookie from 'js-cookie';
import axios from 'axios';

export const getProvince = () => {
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + 'addresses/cities/1', {
        headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
      })
      .then(response => {
        dispatch({
          type: SET_PROVINCE,
          payload: response.data.data,
        });
      });
  };
};

export const getDistrict = id => {
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + 'addresses/districts/' + id, {
        headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
      })
      .then(response => {
        dispatch({
          type: SET_DISTRICT,
          payload: response.data.data,
        });
      });
  };
};

export const getNeighborhood = id => {
  return async dispatch => {
    let data = cookie.get('basket');
    data = [
      {
        id: 1,
        name: 'Saltank Sokak',
        parent: 1,
      },
      {
        id: 2,
        name: 'Kaan Sokak',
        parent: 1,
      },
      {
        id: 3,
        name: 'Ruuuhi Sokak',
        parent: 1,
      },
    ];
    dispatch({
      type: SET_NEIGHBORHOOD,
      payload: data,
    });
  };
};

export const getShopName = id => {
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + 'markets/area/' + id, {
        headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
      })
      .then(response => {
        dispatch({
          type: SET_SHOP,
          payload: response.data.data,
        });
      });
  };
};

export const getDeliveryProductDateTime = () => {
  return async dispatch => {
    const dummyData = [
      {
        id: 1,
        title: 'Today',
        dateText: 'Sunday 8,Feb',
        slots: [
          {
            id: 1,
            title: '10:00 - 12:00',
          },
          {
            id: 2,
            title: '12:00 - 14:00',
          },
          {
            id: 3,
            title: '16:00 - 18:00',
          },
          {
            id: 4,
            title: '20:00 - 22:00',
          },
        ],
      },
      {
        id: 2,
        title: 'Tomorrow',
        dateText: 'Sunday 9,Feb',
        slots: [
          {
            id: 1,
            title: '10:00 - 12:00',
          },
          {
            id: 2,
            title: '12:00 - 14:00',
          },
          {
            id: 3,
            title: '16:00 - 18:00',
          },
          {
            id: 4,
            title: '20:00 - 22:00',
          },
        ],
      },
    ];
    dispatch({
      type: SET_DELIVERY_DATE_TIME,
      payload: dummyData,
    });
  };
};
