import React from 'react';
import Pagination from 'react-js-pagination';

// Styles
import './styles/YPagenation.css';

export default function YPagenation(props) {
  const { onEvent, activePage, totolCounts, pageRangeDisplay } = props;

  const onChange = e => {
    //const urlParams = new URLSearchParams(window.location.search);
    //urlParams.set('pageNumber', e);
    //window.location.search = urlParams.toString();
    onEvent(e);
    window.scrollTo(0, 0);
  };

  return (
    <Pagination
      activePage={Number(activePage)}
      itemsCountPerPage={50}
      totalItemsCount={totolCounts}
      pageRangeDisplayed={pageRangeDisplay}
      onChange={e => onChange(e)}
      lastPageText={''}
      firstPageText={''}
      prevPageText={''}
      nextPageText={''}
      itemClassNext={'pagenation-last-item'}
      itemClassPrev={'pagenation-first-item'}
    />
  );
}
