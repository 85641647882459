import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBIcon,
  MDBTypography,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YButton from '../../Components/Basic/YButton';
import ReOrderItems from './Components/Items';
import YModal from '../../Components/Basic/YModal';
import YUserLoginModal from '../../Components/User/YUserLoginModal';
import YAddressList from '../../Components/Address/YAddressList';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getPayment, getReProduct } from '../../Redux/Actions/PaymentAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

// Styles
import './styles/ReOrderStyles.css';

export default function ReOrder() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const reorder = useSelector(state => state.payment.reorder);

  const addressList = useSelector(state => state.user.address);

  const [items, setItems] = useState([]);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [deliveryType, setDeliveryType] = useState(0);

  const [userAddress, setUSerAddress] = useState('');

  const [locationVisiable, setLocationVisiable] = useState(false);

  const menu = useSelector(state => state.menu.menu);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  const [loginModal, setLoginModal] = useState(false);

  useEffect(() => {
    document.title = 'Ozhan Market ';
    dispatch(getPayment());
    dispatch(getReProduct());
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof reorder !== 'undefined') {
      setItems([...reorder]);
    }
  }, [reorder]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const checkout = () => {
    navigate('/order');
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        deliveryType={deliveryType}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container mb-5">
        <section>
          <MDBContainer fluid className="">
            <MDBRow className="justify-content-center align-items-center h-100">
              <MDBCol>
                <MDBCard className="basket-card">
                  <MDBRow>
                    <div
                      className="re-order-my-basket-back-icon"
                      onClick={() => navigate('/user-order-history')}
                    >
                      <MDBIcon fas icon="chevron-left" />
                    </div>
                    <MDBCol
                      sm={1}
                      className="re-order-my-basket-back-icon-text"
                      onClick={() => navigate('/user-order-history')}
                    >
                      <MDBTypography>Sepetim</MDBTypography>
                    </MDBCol>
                  </MDBRow>
                  <div className="re-order-my-basket-container">
                    <p className="re-order-basket-summary-header">Sepetim</p>
                    <p className="re-order-basket-summary-count-items">
                      {items.length} Ürün
                    </p>
                  </div>
                  <MDBCardBody className="">
                    <MDBRow>
                      <MDBCol lg="8" className="mb-3">
                        <MDBCard className="rounded-3 basket-product-border">
                          {items.map((item, i) => (
                            <ReOrderItems
                              data={item}
                              i={i}
                              length={items.length}
                              key={i + item.title}
                              onDeleteItem={onDeleteItem}
                              onEvent={onEvent}
                            />
                          ))}
                        </MDBCard>
                      </MDBCol>

                      <MDBCol lg="4">
                        <MDBCard className="re-order-basket-container">
                          <MDBCardBody className="basket-cart-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <MDBTypography tag="h5" className="mb-0">
                                Sepet Özeti
                              </MDBTypography>
                              <p className="basket-title-sub">
                                {items.length} Ürün
                              </p>
                            </div>
                            <div className="d-flex justify-content-between basket-total-first">
                              <p className="re-order-basket-total-cost mb-2">
                                Toplam Tutar
                              </p>
                              <p className="re-order-basket-total-cost mb-2">
                                {Number(totalPrice).toFixed(2)} TL
                              </p>
                            </div>

                            <div className="d-flex justify-content-between basket-discount-text mb-1">
                              <p className="re-order-basket-discount mb-2">
                                İndirim
                              </p>
                              <p className="re-order-basket-discount mb-2">
                                2.00 TL
                              </p>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="re-order-basket-total-final mb-2">
                                Ödenecek tutar
                              </p>
                              <p className="re-order-basket-total-final mb-2">
                                {Number(totalPrice).toFixed(2)} TL
                              </p>
                            </div>

                            <YButton
                              text={'Devam'}
                              onEvent={checkout}
                              inComingClassName={
                                're-order-basket-btn-container'
                              }
                            />
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBContainer>
      <Footer />
      <YModal
        show={loginModal}
        onClose={() => setLoginModal(!loginModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <YUserLoginModal />
      </YModal>
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
