import { SELECTED_MARKET_DATA } from '../Constants/action-types';
import cookie from "js-cookie";
import axios from 'axios';

export const getMarket = (long , lat) => {
    return async (dispatch) => {
        await axios
          .get(process.env.REACT_APP_BASE_URL_V2+ 'market/store-around?pageNumber=1&pageSize=1&latitude='+lat+'&longitude='+long , {
            headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY }
          })
          .then((response) => {
            dispatch({
                type: SELECTED_MARKET_DATA,
                payload: response.data.data
            });
          });
    };
};



