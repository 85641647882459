import React from 'react';
import Image from 'react-bootstrap/Image';
// Styles
import './styles/YImageStyles.css';

export default function YImage(props) {
  const { imageSource, caption, inComingStyle , id } = props;

  return (
    <Image
      id={id}
      src={imageSource}
      alt={caption}
      className={inComingStyle ? inComingStyle : 'imageSize'}
    />
  );
}
