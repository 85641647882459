import React, { useState } from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';

// Components
import YTheme from "../../Components/Layout/YTheme";
import YImage from '../../Components/Basic/YImage';
import YModal from "../../Components/Basic/YModal";

// Styles
import "./styles/loginStyles.css";
import 'react-phone-input-2/lib/style.css';


// Sections
import SignIn from './Components/SignIn';
import SignUp from './Components/SignUp';

//Redux
import { getUserPersonalData , getUserMemberShipData , getConset } from '../../Redux/Actions/UserAction';



export default function YUserLoginModal(props) {

  const [error , setError] = useState('');

  const dispatch = useDispatch();

  const [legalModal, setLegalModal] = useState(false);

  const [legalContent, setLegalContent] = useState('data');

  const SwitchOptions = {
    OPTION1: "Option 1",
    OPTION2: "Option 2"
  };

  const [activeOption, setActiveOption] = useState(
    SwitchOptions.OPTION1
  );

  const handleSwitchClick = (option) => {
    setActiveOption(option);
  };

  const openLegalModal = (type) => {
    if(type === 1)
    {
      dispatch(getUserPersonalData(setLegalModal , setLegalContent));
    } else if(type === 2) {
      dispatch(getUserMemberShipData(setLegalModal , setLegalContent));
    } else if(type === 3) {
      dispatch(getConset(setLegalModal , setLegalContent));
    }
  };


  return (
    <YTheme>
      <MDBContainer className='d-flex justify-content-center login-register-container mb-3'>
        <YImage imageSource={require('../../Assets/Images/ozhan.png')} inComingStyle={'logo'}/>
      </MDBContainer>
      <div>
        <MDBContainer className='d-flex justify-content-center login-register-container mb-5'>
          <div className='login-register-section-modal'>
            <div className="login-register-switch-container">
              <div
                className="login-register-switch-item"
                style={{
                  backgroundColor:
                  activeOption === SwitchOptions.OPTION1 ? "#fff" : "transparent"
                }}
                onClick={() => handleSwitchClick(SwitchOptions.OPTION1)}
                >
                <div className={"login-register-switch-text"}>Giriş Yap</div>
              </div>
              <div
                className="login-register-switch-item"
                style={{
                  backgroundColor:
                  activeOption === SwitchOptions.OPTION2 ? "#fff" : "transparent"
                }}
                onClick={() => handleSwitchClick(SwitchOptions.OPTION2)}
                >
                <div className={"login-register-switch-text"}>Üye Ol</div>
              </div>
            </div>
            {activeOption === SwitchOptions.OPTION2 ? 
              <SignUp error={error} setLoginModal={props.setLoginModal} loginModal={props.loginModal} openLegalModal={openLegalModal} setLegalModal={setLegalModal}/> 
            : 
              <SignIn changeView={setActiveOption} data={SwitchOptions} setError={setError} setLoginModal={props.setLoginModal} loginModal={props.loginModal}/>
            }
          </div>
        </MDBContainer>
      </div>
      <YModal 
          show={legalModal} 
          onClose={() => setLegalModal(!legalModal)}
          title={''}
          inComingStyleHeader={'address-modal-header'}
          inComingStyleDialogModal={'modalContract'}
      >
        {parse(legalContent)}
        <div className='legal-modal-btn-container'>
          <div className="address-alert-accpet-container " onClick={() => setLegalModal(!legalModal)}>
              <p className="address-alert-text">Kapat</p>
          </div>
        </div>
      </YModal>
    </YTheme>
  );
}