import { SET_USER , SET_ADDRESS , LAST_ORDER , SET_USER_ORDER , SET_USER_CONSET } from '../Constants/action-types';
  
  const initialState = {
    userOrder : [],
    conset: [],
  }
  
  export default function user(state = initialState, action) {
    switch (action.type) {
      case SET_USER:
        return { ...state, user: action.payload };
      case SET_ADDRESS:
        return { ...state, address: action.payload };
      case LAST_ORDER:
        return { ...state, lastOrder: action.payload };
      case SET_USER_ORDER:
          return { ...state, userOrder: action.payload };
      case SET_USER_CONSET:
        return { ...state, conset: action.payload };
      default:
        return state
    }
  };
  