import React, { useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
    MDBTypography,
    MDBContainer,
    MDBInput
} from 'mdb-react-ui-kit';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import cookie from "js-cookie";

// Auth
import { useUserAuth } from "../../../Assets/Context/UserAuthContext";

// CSS
import './styles/signUpStyles.css'

const SignUp = (props) => {
  const [error, setError] = useState(props.error);

  const [number, setNumber] = useState("");

  const [email, setEmail] = useState("");

  const [kvkk, setKvkk] = useState(false);

  const [contract, setContract] = useState(false);

  const [flag, setFlag] = useState(false);

  const [otp, setOtp] = useState("");

  const [result, setResult] = useState("");

  const { setUpRecaptha } = useUserAuth();

  const [numberError, setNumberError] = useState(false);

  const [mailError, setmailError] = useState(false);

  const [kvkkError, setkvkkError] = useState(false);

  const [contractError, setContractError] = useState(false);

  const [btnStatus, setBtnStatus] = useState(false);

  const getOtp = async (e) => {
    e.preventDefault();
    if (number === "" || number === undefined || number.length < 13) {
      return setNumberError(true);
    }
    else {
      if (validateEmail(email)) {
        if (kvkk === false) {
          return setkvkkError(true);
        } else {
          if(contract === false) {
            return setContractError(true);
          } else {
            setkvkkError(false);
            setContractError(false);
            const register = await axios
            .post(process.env.REACT_APP_BASE_URL_V2+"auth/register" , {
                "email" : email,
                "phoneNumber" : number,
                "eConsent" : true,
            },{
                'Content-Type': 'text/plain',
                'accept': 'application/json',
                headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY }
            })
            .then((response) => {
              return(response);
            })
            .catch(error => {
              setError(error.response.data.Message);
            });
            if(register.status === 200) {
              try {
                const response = await setUpRecaptha(number);
                setResult(response);
                setFlag(true);
              } catch (err) {
                setError('Doğrulama problemi oluştu. Lütfen tekrar deneyiniz.');
              }
            }
          }
        }
      }
      else {
        setmailError(true);
      }
    }
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null || otp.length !== 6) return;
    try {
      await result.confirm(otp);
      console.log(result);
      const data = cookie.get("token");
      const token = JSON.parse(data);
      await axios
      .post(process.env.REACT_APP_BASE_URL_V2+"auth/validate-phone-number" , {
          "phoneNumber" : number,
      },{
          'Content-Type': 'text/plain',
          'accept': 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY
          }
      })
      .then((response) => {
        props.setLoginModal(false)
      });
    } catch (err) {
      setError('Doğrulama problemi oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  const onChangeKvkk = () => {
    setKvkk(!kvkk);
  };

  const onChangeContract = () => {
    setContract(!contract);
  };

  const checkBtnColor = (type , value) => {
    if(type === 1) { // number
      setNumber(value)
    } else if(type === 2){ // email
      setEmail(value);
    } else if(type === 3) {
      onChangeKvkk();
    } else if(type === 4) {
      onChangeContract();
    }
    if(number !== null && email.length > 0) {
      setBtnStatus(true);
      let elements = document.getElementsByClassName('sign-in-form-login-button');
      elements[0].style.backgroundColor="#fad45f";
      elements[0].style.color="#000";
    }
  }

  const checkValue = (type , value) => {
    if(type === 1) { // number
      if (number === "" || number === undefined || number.length < 13) {
        return setNumberError(true);
      }else{
        return setNumberError(false);
      }
    } else if(type === 2){ // email
      if (validateEmail(email)) {
        return setmailError(false);
      } else {
        return setmailError(true);
      }
    } else if(type === 3) {
      onChangeKvkk();
    } else if(type === 4) {
      onChangeContract();
    }
  }

  const personalDataModal = () => {
    props.openLegalModal(1); // 1 : personal data
  };

  const memberShipModal = () => {
    props.openLegalModal(2); // 2 : membership data
  };

  const consetModal = () => {
    props.openLegalModal(3); // 3 : conset data
  };


  const whitelisted_conutries = ['TR'];

  return (
      <MDBContainer className="my-3 sign-in-form-login-container">
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
          <MDBTypography className="mb-2">Cep telefonunu girerek devam et.</MDBTypography>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <PhoneInput
              defaultCountry="TR"
              value={number}
              className={numberError === true  ? "phone-input-number is-invalid" : "phone-input-number"}
              onChange={(e) => checkBtnColor(1 , e)}
              onBlur={(e) => checkValue(1 , e)}
              placeholder="(501) 000 00 00"
              maxLength="13"
              countries={whitelisted_conutries}
            />
            {numberError === true && <p className="error-mobile">Lütfen geçerli telefon numarası giriniz!</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <MDBInput 
              className={mailError === true  ? "pmb-4 sign-in-form-register-mail-input is-invalid" : "mb-4 sign-in-form-register-mail-input"}
              id='email' 
              type='text' 
              placeholder="ornek@mail.com"
              onChange={(e) => checkBtnColor(2, e.target.value)}
              onBlur={(e) => checkValue(2 , e.target.value)}
            />
            {mailError === true && <p className="error-mobile-email">Lütfen geçerli e-mail adresi giriniz!</p>}
          </Form.Group>
          <label className="sign-in-form-contract-label">              
              <span className="sign-in-form-coloric-text" onClick={() => personalDataModal()}>Kişisel Verilerin Korunması Hakkında Aydınlatma Metni</span> için tıklayın.
          </label>
          <label className="sign-in-form-contract-label">
            <input 
              className="sign-in-form-contract-input" 
              type="checkbox" 
              id="contractKVKK" 
              name="contractKVKK"
              value={kvkk}
              onChange={(e) => { checkBtnColor(3 , '')} }  
            /> 
                 <span className="sign-in-form-coloric-text" onClick={() => memberShipModal()}>&nbsp;&nbsp;Üyelik Sözleşmesi</span>'ni onaylıyorum ve  <span className="sign-in-form-coloric-text" onClick={() => memberShipModal()}>Açık Rıza Metni</span> kapsamında kişisel verilerimin işlenmesine ve paylaşılmasına onay veriyorum.
               {kvkkError === true && <p className="error-mobile-contaract">Lütfen onaylayın</p>}
          </label>
          
          <label className="sign-in-form-contract-label">
            <input 
              className="sign-in-form-contract-input" 
              type="checkbox" 
              id="contractRegister" 
              name="contractRegister"
              value={contract}
              onChange={(e) => { checkBtnColor(4 , '')}} 
            /> 
            &nbsp;&nbsp;Kampanya, avantaj ve indirimlerden ilk haber alanların arasında olmak için <span className="sign-in-form-coloric-text" onClick={() => consetModal()}>Ticari İletişim İzni</span> kapsamında iletişim izni veriyorum.
            {contractError === true && <p className="error-mobile-contaract">Lütfen onaylayın</p>}
          </label>
          <div id="recaptcha-container"></div>

            <Button 
              type="submit" 
              variant="primary" 
              className='mb-2 sign-in-form-login-button'
            >
              Kayıt ol
            </Button>
        </Form>

        <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
          <MDBTypography className='title-verfication-text'>Telefonunu Doğrula</MDBTypography>
          <MDBTypography className='sub-title-verfication-text'>Telefonuna gelen SMS kodunu girerek doğrulama işlemini tamamla</MDBTypography>
          <MDBTypography className='title-input-box-verfication-text'>SMS Kodu*</MDBTypography>
          <Form.Group className="mb-3" controlId="formBasicOtp">
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType={'number'}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus={true}
                containerStyle={'otp-input-container'}
                inputStyle={'otp-input-box'}
            />
          </Form.Group>
          <Button type="submit" variant="primary" className='mb-2 sign-in-form-login-button'>
            Doğrula
          </Button>
        </Form>
    </MDBContainer>
  );
};

export default SignUp;