import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import { ToastContainer, toast } from 'react-toastify';
import {
    MDBContainer , MDBCardBody, MDBTypography, MDBIcon
}
    from 'mdb-react-ui-kit';
import OtpInput from 'react-otp-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from "react-hook-form";
import { Button } from 'react-bootstrap';


// Firebase
import { useUserAuth } from "../../Assets/Context/UserAuthContext";


// Components
import YTheme from "../../Components/Layout/YTheme";
import YImage from '../../Components/Basic/YImage';
import { YBasketHanlder, YBasketDeleteItem } from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/OTPStyles.css';

// Redux
import { setBasket, getBasket, setBasketProductItem } from '../../Redux/Actions/BasketAction';
import { getDiscountProduct, getMarketByGeoLocation } from "../../Redux/Actions/ProductAction";
import { getMarket } from '../../Redux/Actions/MarketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';

// Non-UI Constants,Data
import { isObjEmpty } from '../../Assets/Utils/Utility';

export default function OTP(props) {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { setUpRecaptha } = useUserAuth();

    const basket = useSelector((state) => state.basket.basket);

    const [cartVisiable, setCartVisiable] = useState(false);

    const [locationVisiable, setLocationVisiable] = useState(false);

    const [totalPrice, setTotalPrice] = useState(0);

    const [deliveryType, setDeliveryType] = useState(0);

    const [userAddress, setUSerAddress] = useState('');

    const [provinceData, setProvinceData] = useState(0);

    const [districtData, setDistrictData] = useState(0);

    const [neighborhoodData, setNeighborhoodData] = useState(0);

    const [shopData, setShopData] = useState(0);

    const menu = useSelector((state) => state.menu.menu);

    const [otp, setOtp] = useState('');

    useEffect(() => {
        dispatch(getBasket());
        let data = cookie.get("userAddress");
        if (data !== undefined) {
            data = JSON.parse(data);
            setDeliveryType(data.deliveryType);
            setUSerAddress(data.address);
            setShopData(data.shopData);
            dispatch(getMenu(data.shopData));
            dispatch(getDiscountProduct(shopData));
        }
        else {
            navigator.geolocation.getCurrentPosition(function (position) {
                dispatch(getMarket(position.coords.longitude, position.coords.latitude))
            });
        }
    }, []);

    useEffect(() => {
        if (typeof basket !== 'undefined') {
            let count = 0;
            basket.map((item) => {
                if (item.discountPrice !== 0) {
                    count = count + (item.discountPrice * item.count);
                } else {
                    count = count + (item.price * item.count);
                }
            });
            setTotalPrice(count);
        }
    }, [basket]);

    useEffect(() => {
        let data = {
            address: userAddress,
            deliveryType: deliveryType,
            provinceData: provinceData,
            districtData: districtData,
            neighborhoodData: neighborhoodData,
            shopData: shopData,
        };
        cookie.set("userAddress", JSON.stringify(data));
    }, [userAddress]);

    const onEvent = (data, count, type,toast) => {
        YBasketHanlder(
            type,
            setCartVisiable,
            basket,
            data,
            count,
            dispatch,
            setBasket,
            setBasketProductItem,
            getBasket,
            shopData,
            toast
        );
    };

    const onDeleteItem = (e) => {
        let temp = { productId : e};
        YBasketDeleteItem(temp , shopData , 0 ,  basket, dispatch, setBasketProductItem , toast);
      };

    const onChangeShopList = (e, text) => {
        setDeliveryType(1);
        dispatch(getMenu(e));
        dispatch(getDiscountProduct(e));
        let data = {
            address: text,
            shopData: e,
            deliveryType: 1
        };
        cookie.set("userAddress", JSON.stringify(data));
    };

    const onSelectAddressMap = (lat, lng, addressText) => {
        setUSerAddress(addressText);
        dispatch(getMarketByGeoLocation(lat, lng));
    };

    const verficationCode = async () => {
        let data = cookie.get("auth");
        data = JSON.parse(data);
        try {
            await data.confirm(otp);
            navigate("/home");
          } catch (err) {
            console.log(err.message);
        }
    };

    return (
        <YTheme>
            <Header
                basket={basket}
                cartVisiable={cartVisiable}
                setCartVisiable={setCartVisiable}
                navigate={navigate}
                totalPrice={totalPrice}
                onEvent={onEvent}
                onDeleteItem={onDeleteItem}
                onChangeShopList={onChangeShopList}
                locationVisiable={locationVisiable}
                setLocationVisiable={setLocationVisiable}
                deliveryType={deliveryType}
                menu={menu}
                onSelectAddressMap={onSelectAddressMap}
                userAddressTop={userAddress}
            />
            <MDBContainer className='d-flex justify-content-center align-items-center flex-column otp-verification-container'>
                <YImage imageSource={require('../../Assets/Images/ozhan.png')} inComingStyle={'otp-verification-logo'} />
                <MDBCardBody className='otp-verification-phone-container'>
                <MDBIcon fas icon="times" className='close-icon-verification' />
                    <MDBTypography className='title-verfication-text'>Telefonunu Doğrula</MDBTypography>
                    <MDBTypography className='sub-title-verfication-text'>Telefonuna gelen SMS kodunu girerek doğrulama işlemini tamamla</MDBTypography>
                    <MDBTypography className='title-input-box-verfication-text'>SMS Kodu*</MDBTypography>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType={'number'}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus={true}
                        containerStyle={'otp-input-container'}
                        inputStyle={'otp-input-box'}
                    />
                    <MDBTypography className='count-down-verfication-text'>05:00</MDBTypography>
                    <Button variant="primary" type="submit" className='mb-2 sign-in-form-login-button' onClick={verficationCode}>
                        Doğrula
                    </Button>
                    <MDBTypography className='resend-otp-verfication-textt'>Kodu tekrar gönder</MDBTypography>
                </MDBCardBody>
            </MDBContainer>
            <Footer />
        </YTheme>
    );
}