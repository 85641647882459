/* Radio Box */
import React from "react";
import { Form } from "react-bootstrap";

// Styles
import "./styles/YCheckBoxStyles.css";

export default function YCheckBox(props) {
  const { id, label, isDisabled, errors, onEvent , value } =
    props;

  return (
    <Form>
      <div key={`default-checkbox}`} className='mb-3'>
        <Form.Check
          type='checkbox'
          id={'checkbox-style'}
          label={label}
          checked={value === true ? true : false}
          disabled={isDisabled}
          onChange={(e) =>
            onEvent()
          }
        />
        {errors?.checkbox?.message && (
          <p className='CError'>{errors?.checkbox?.message}</p>
        )}
      </div>
    </Form>
  );
}
