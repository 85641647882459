import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useUserAuth } from '../../../Assets/Context/UserAuthContext';
import { useDispatch } from 'react-redux';
import { MDBTypography, MDBContainer } from 'mdb-react-ui-kit';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import cookie from 'js-cookie';

//Redux
import { getBasket } from '../../../Redux/Actions/BasketAction';

import './styles/signInStyles.css';

const SignIn = props => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [number, setNumber] = useState('');
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState('');
  const [result, setResult] = useState('');
  const { setUpRecaptha } = useUserAuth();
  const [numberError, setNumberError] = useState(false);

  const getOtp = async e => {
    e.preventDefault();
    setError('');
    if (number === '' || number === undefined) return setNumberError(true);
    try {
      let validNumber = number.substring(1);
      const login = await axios
        .get(
          process.env.REACT_APP_BASE_URL_V2 +
            'auth/login-info?phoneNumber=%2B' +
            validNumber,
          {
            headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY },
          }
        )
        .then(response => {
          return response;
        });
      if (login.data.data.action === 'Register') {
        props.changeView(props.data.OPTION2);
        props.setError('Lütfen kayıt olunuz.');
      } else {
        const response = await setUpRecaptha(number);
        setResult(response);
        setFlag(true);
      }
    } catch (err) {
      setError('Doğrulama problemi oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  const onChangeNumber = e => {
    console.log(e);
    setNumber(e);
    if (e?.length === 13) {
      let elements = document.getElementsByClassName(
        'sign-in-form-login-button'
      );
      elements[0].style.backgroundColor = '#fad45f';
      elements[0].style.color = '#000';
    } else {
      let elements = document.getElementsByClassName(
        'sign-in-form-login-button'
      );
      elements[0].style.backgroundColor = '#F1F2F6';
      elements[0].style.color = '#000';
    }
  };

  const onChangeOtp = e => {
    setOtp(e);
    if (otp?.length === 5) {
      let elements = document.getElementsByClassName(
        'sign-in-form-login-button'
      );
      elements[1].style.backgroundColor = '#fad45f';
      elements[1].style.color = '#000';
    } else {
      let elements = document.getElementsByClassName(
        'sign-in-form-login-button'
      );
      elements[1].style.backgroundColor = '#F1F2F6';
      elements[1].style.color = '#000';
    }
  };

  const verifyOtp = async e => {
    e.preventDefault();
    setError('');
    if (otp === '' || otp === null) return;
    try {
      await result.confirm(otp);
      const marketId = cookie.get('marketId');
      dispatch(getBasket(marketId));
      props.setLoginModal(false);
    } catch (err) {
      setError('Doğrulama kodunu hatalı girdiniz.');
      //setError(err.message);
    }
  };

  const whitelisted_conutries = ['TR'];

  return (
    <MDBContainer className="my-3 sign-in-form-login-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={getOtp} style={{ display: !flag ? 'block' : 'none' }}>
        <MDBTypography className="mb-2">
          Cep telefonunu girerek devam et.
        </MDBTypography>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <PhoneInput
            defaultCountry="TR"
            value={number}
            className={
              numberError === true
                ? 'phone-input-number is-invalid'
                : 'phone-input-number'
            }
            onChange={e => onChangeNumber(e)}
            placeholder="(501) 000 00 00"
            maxLength="13"
            countries={whitelisted_conutries}
          />
          {numberError === true && (
            <p className="error-mobile">
              Lütfen geçerli telefon numarası giriniz!
            </p>
          )}
          <div id="recaptcha-container"></div>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mb-2 sign-in-form-login-button"
          disabled={number?.length < 13 ? true : null}
        >
          Giriş Yap
        </Button>
      </Form>

      <Form onSubmit={verifyOtp} style={{ display: flag ? 'block' : 'none' }}>
        <MDBTypography className="title-verfication-text">
          Telefonunu Doğrula
        </MDBTypography>
        <MDBTypography className="sub-title-verfication-text">
          Telefonuna gelen SMS kodunu girerek doğrulama işlemini tamamla
        </MDBTypography>
        <MDBTypography className="title-input-box-verfication-text">
          SMS Kodu*
        </MDBTypography>
        <Form.Group className="mb-3" controlId="formBasicOtp">
          <OtpInput
            value={otp}
            onChange={e => onChangeOtp(e)}
            numInputs={6}
            inputType={'number'}
            renderInput={props => <input {...props} />}
            shouldAutoFocus={true}
            containerStyle={'otp-input-container'}
            inputStyle={'otp-input-box'}
          />
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mb-2 sign-in-form-login-button"
          disabled={otp?.length < 6 ? true : null}
        >
          Doğrula
        </Button>
      </Form>
    </MDBContainer>
  );
};

export default SignIn;
