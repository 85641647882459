import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YButton from '../../Components/Basic/YButton';
import Items from './Components/Items';
import YModal from '../../Components/Basic/YModal';
import YUserLoginModal from '../../Components/User/YUserLoginModal';
import YAddressList from '../../Components/Address/YAddressList';

import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Redux
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu } from '../../Redux/Actions/MenuAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';
import { getMarketByGeoLocation } from '../../Redux/Actions/ProductAction';

// Styles
import './styles/basketStyles.css';

export default function Basket() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const basket = useSelector(state => state.basket.basket);

  const addressList = useSelector(state => state.user.address);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [deliveryType, setDeliveryType] = useState(0);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [loginModal, setLoginModal] = useState(false);

  const tokenData = cookie.get('token');

  const marketId = cookie.get('marketId');

  const menu = useSelector(state => state.menu.menu);

  useEffect(() => {
    document.title = 'Ozhan Market ';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  const checkout = () => {
    if (Number(mini) < Number(totalPrice)) {
      navigate('/order');
    }
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const discount = cookie.get('discount');

  const mini = cookie.get('minimumOrderAmount');

  const deliveryFee = cookie.get('deliveryFee');

  const amountOfFreeDelivery = cookie.get('amountOfFreeDelivery');

  const productTotalAmount = cookie.get('productTotalAmount');

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        deliveryType={deliveryType}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <section>
          <MDBContainer className="py-0 h-100">
            <MDBRow className="justify-content-center align-items-center h-100">
              <MDBCard className="basket-card">
                <MDBRow className="basket-navigation basket-navigation-top">
                  <MDBCol
                    lg="2"
                    className="basket-items-contorls-icon"
                    onClick={() => navigate('/')}
                  >
                    <MDBIcon fas icon="chevron-left" /> Alışverişe Devam Et
                  </MDBCol>
                </MDBRow>
                <MDBRow className="basket-navigation">
                  <MDBCol lg="2">
                    <p className="basket-title-text">Sepetim</p>
                  </MDBCol>
                  <MDBCol>
                    <p className="basket-title-sub">{basket?.length} Ürün</p>
                  </MDBCol>
                </MDBRow>
                <MDBCardBody className="basket-item-container-no-padding">
                  <MDBRow>
                    <MDBCol lg="7">
                      {basket?.length > 0 ? (
                        <MDBCard className="rounded-3 basket-product-border">
                          {basket?.map((item, i) => (
                            <Items
                              data={item}
                              i={i}
                              length={basket.length}
                              controllIndex={basket?.length}
                              key={i + item.title}
                              onDeleteItem={onDeleteItem}
                              onEvent={onEvent}
                            />
                          ))}
                        </MDBCard>
                      ) : null}
                    </MDBCol>
                    <MDBCol lg="5">
                      <MDBCard className="rounded-3">
                        <MDBCardBody className="basket-cart-body">
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <MDBTypography tag="h5" className="mb-0">
                              Sepet Özeti
                            </MDBTypography>
                            <p className="basket-title-sub">
                              {basket?.length} Ürün
                            </p>
                          </div>
                          <hr />

                          <div className="d-flex justify-content-between basket-total-first">
                            <p className="mb-2">Ürünlerin Toplam Tutarı</p>
                            <p className="mb-2">
                              {Number(productTotalAmount).toFixed(2)} TL
                            </p>
                          </div>
                          {Number(productTotalAmount) !== 0 ? (
                            <>
                              <div className="d-flex justify-content-between">
                                <p className="mb-2">Teslimat Tutarı</p>
                                <p className="mb-2">
                                  {Number(deliveryFee).toFixed(2)} TL
                                </p>
                              </div>
                              <span className="teslimQouteText">
                                {amountOfFreeDelivery} TL değerindeki
                                siparişinize teslimat ücretsizdir.
                              </span>
                            </>
                          ) : null}
                          {Number(discount) !== 0 ? (
                            <div className="d-flex justify-content-between basket-discount-text">
                              <p className="mb-2">Uygulanan İndirim</p>
                              <p className="mb-2">
                                {Number(discount).toFixed(2)} TL
                              </p>
                            </div>
                          ) : null}
                          {Number(productTotalAmount) !== 0 ? (
                            <div className="d-flex justify-content-between">
                              <p className="mb-2 total-price-text-basket-text">
                                Ödenecek Tutar
                              </p>
                              <p className="mb-2">
                                {Number(totalPrice).toFixed(2)} TL
                              </p>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between">
                              <p className="mb-2 total-price-text-basket-text">
                                Ödenecek Tutar
                              </p>
                              <p className="mb-2">0.00 TL</p>
                            </div>
                          )}
                          {Number(mini) > Number(productTotalAmount) ? (
                            <>
                              <hr />
                              <p className="mini-error">
                                Siparişinizi tamamlamak için sepetinize{' '}
                                {Number(mini - productTotalAmount).toFixed(2)}{' '}
                                TL değerinde daha ürün eklemeniz yeterlidir.
                              </p>
                            </>
                          ) : null}
                          <YButton
                            text={'Devam'}
                            onEvent={checkout}
                            inComingClassName={'basket-btn-devam'}
                          />
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBContainer>
      <Footer />
      <YModal
        show={loginModal}
        onClose={() => setLoginModal(!loginModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <YUserLoginModal
          loginModal={loginModal}
          setLoginModal={setLoginModal}
        />
      </YModal>
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
