const commercialInfo = [
  {
    phoneNumber: '90 544 472 6573',
    mail: 'alper.tas@vagani.com',
    locationName: 'Bilişim Vadisi, Gebze',
    locationLat: 40.234693,
    locationLng: 28.851488,
    billingInformation: 'xxx yyy zzz',
    TradeRegisterNumber: '763298456',
    MersisNumber: '123456789432134',
  },
];

export { commercialInfo };
