import React, { useState } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBIcon,
    MDBTypography,
} from "mdb-react-ui-kit";

// Styles
import '../styles/ItemsStyles.css'

// Components
import AddToBasket from "./AddToBasket";
import YText from "../../../Components/Basic/YText";
import YSeperator from "../../../Components/Basic/YSeperator";

export default function ReOrderItems(props) {

    const { data, i, length, onDeleteItem, onEvent } = props;

    const MAX_LENGTH = 40;

    const [count, setCount] = useState(data.count);

    const upDateBasket = (newCount) => {
        setCount(newCount);
        onEvent(data, newCount, 1);
    };

    return (
        <MDBCard className="mb-3 basket-card">
            <MDBCardBody>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                        <div >
                            <MDBCardImage
                                src={data.image}
                                fluid className="re-order-product-item-image"
                                alt="Shopping item"
                            />
                        </div>
                        <div className="basket-items-mobile">
                            <MDBTypography className="basket-item-title-text mb-4">
                                {data.title.length > MAX_LENGTH ?
                                    (
                                        data.title.substring(0, MAX_LENGTH) + "..."
                                    ) :
                                    data.title
                                }
                            </MDBTypography>
                            <a href="#!" className="re-order-basket-items-add-note-icon">
                                <MDBIcon fas icon="edit" onClick={() => onDeleteItem(data.id)} /> Ürün Notu Ekle
                            </a>
                            <a href="#!" className="re-order-basket-items-delete-item-icon">
                                <MDBIcon fas icon="trash-alt" onClick={() => onDeleteItem(data.id)} /> Sil
                            </a>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div>
                            <div className="d-flex justify-content-between basket-item-price-text-container">
                                {data.discountPrice !== 0 ?
                                    <div className='priceArea'>
                                        <YText
                                            incomingStyle={"newPrice"}
                                            value={data.price + " TL"}
                                            type={"p"}
                                        />
                                    </div>
                                    :
                                    <div className='priceArea'>
                                        <YText
                                            incomingStyle={"newPrice"}
                                            value={data.price + " TL"}
                                            type={"p"}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="d-flex justify-content-between deneme">
                                {count === 0 ?
                                    <YText type={"p"} value="TÜKENDİ" incomingStyle={"re-order-basket-product-sold-out"} />
                                    :
                                    <AddToBasket count={count} upDateBasket={upDateBasket} incomingStyle={'basket-add-to-card-container-basket-item'} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </MDBCardBody>
            {length !== i ? <div className="re-order-products-seperator"><YSeperator /></div> : null}
        </MDBCard>
    );
};
