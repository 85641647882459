import React from 'react';

export default function YText(props) {
  const { incomingStyle, value, type } = props;

  switch (type) {
    case 'p':
      return <p className={incomingStyle}>{value}</p>;
    case 'h1':
      return <h1 className={incomingStyle}>{value}</h1>;
    case 'h2':
      return <h2 className={incomingStyle}>{value}</h2>;
    case 'h3':
      return <h3 className={incomingStyle}>{value}</h3>;
    case 'h4':
      return <h4 className={incomingStyle}>{value}</h4>;
    case 'h5':
      return <h5 className={incomingStyle}>{value}</h5>;
    case 'h6':
      return <h6 className={incomingStyle}>{value}</h6>;
    case 'label':
      return <label className={incomingStyle}>{value}</label>;
    case 'span':
      return <span className={incomingStyle}>{value}</span>;
    case 'em':
      return <em className={incomingStyle}>{value}</em>;
    case 'blockquote':
      return <blockquote className={incomingStyle}>{value}</blockquote>;
    default:
      return <p className={incomingStyle}>{value}</p>;
  }
}
