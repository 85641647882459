import React from "react";

// Styles
import "./styles/YTouchableContainerStyles.css";

export default function YTouchableContainer(props) {
  const { incomingStyle, onEvent, children } = props;

  return (
    <a className={incomingStyle ? incomingStyle : "touchable-container"} href={onEvent}>
      {children}
    </a>
  );
}
