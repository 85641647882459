import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';

// Context
import { UserAuthContextProvider } from '../src/Assets/Context/UserAuthContext';
import ProtectedRoute from '../src/Assets/Context/ProtectedRoute';

// Pages
import Login from './Views/Login';
import Home from './Views/Home';
import Basket from './Views/Basket';
import Order from './Views/Order';
import ProductList from './Views/ProductList';
import Single from './Views/Single';
import UserAddress from './Views/Address';
import AddNewAddressDelivery from './Views/Address/AddDeliveryAddress';
import UserInformation from './Views/UserInformation';
import LastOrders from './Views/LastOrders';
import AddNewCard from './Views/MyCards/AddNewCard';
import ContactUs from './Views/ContactUs';
import Kvkk from './Views/KVKK';
import Gdpr from './Views/GDPR';
import PrivacyPolicy from './Views/PrivacyPolicy';
import TermOfUse from './Views/TermOfUse';
import OTP from './Views/OTP';
import ReOrder from './Views/ReOrder';
import LastOrdersDetails from './Views/LastOrdersDetails';
import NoFound from './Views/NotFound';
import Search from './Views/Search';
import ScrollToTop from './Assets/Utils/ScrollTop';
import OrderDetails from './Views/OrderDetails';
import Contracts from './Views/Contracts';
import OrderFailed from './Views/OrderFailed';
import PaymentMobileSucsess from './Views/PaymentMobile/Success';
import OrderMobileFailed from './Views/PaymentMobile/Failed';
// Redux config
import rootReducer from './Redux/store';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/Css/general.css';
import CategoriesMobile from './Views/CategoriesMobile';

const store = createStore(rootReducer, applyMiddleware(thunk));

const rootElement = ReactDOM.createRoot(document.getElementById('root'));

rootElement.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <UserAuthContextProvider>
        <Routes>
          <Route path={`*`} element={<NoFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/basket" element={<Basket />} />
          <Route path="/order" element={<Order />} />
          <Route path={`/single`} element={<Single />} />
          <Route path={`/products`} element={<ProductList />} />
          <Route path={`/search`} element={<Search />} />
          <Route
            path="/order-details"
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order-failed"
            element={
              <ProtectedRoute>
                <OrderFailed />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-address"
            element={
              <ProtectedRoute>
                <UserAddress />
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories-mobile"
            element={
              <ProtectedRoute>
                <CategoriesMobile />
              </ProtectedRoute>
            }
          />

          <Route
            path="/user-information"
            element={
              <ProtectedRoute>
                <UserInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-order-history"
            element={
              <ProtectedRoute>
                <LastOrders />
              </ProtectedRoute>
            }
          />
          {/* 
          <Route 
            path='/user-cards'
            element={
              <ProtectedRoute>
                <MyCards />
              </ProtectedRoute>
            } 
          />
           */}
          <Route
            path="/new-delivery-address"
            element={
              <ProtectedRoute>
                <AddNewAddressDelivery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-card"
            element={
              <ProtectedRoute>
                <AddNewCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/re-order"
            element={
              <ProtectedRoute>
                <ReOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-order-details"
            element={
              <ProtectedRoute>
                <LastOrdersDetails />
              </ProtectedRoute>
            }
          />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/kvkk" element={<Kvkk />} />
          <Route path="/gdpr" element={<Gdpr />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-of-use" element={<TermOfUse />} />
          <Route path="/contracts" element={<Contracts />} />
          <Route path="/order-failed-mobile" element={<OrderMobileFailed />} />
          <Route
            path="/order-details-mobile"
            element={<PaymentMobileSucsess />}
          />
          {/* 
          <Route path="/campaign" element={<Campaign />} />
           */}
          <Route path="/verification" element={<OTP />} />
          {/* 
              for using protect the screens that need auth before use this role for elements 
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
            */}
        </Routes>
      </UserAuthContextProvider>
    </BrowserRouter>
  </Provider>
);
