import { SET_BASKET, SET_ACTIVE_CARD_NUMBER } from '../Constants/action-types';
import axios from 'axios';
import cookie from 'js-cookie';

export const getBasket = () => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + 'carts/markets/active', {
        headers: {
          Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
          clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
        },
      })
      .then(response => {
        if (response.data.data?.cartId !== null)
          //console.log(response.data.data);
          cookie.set('basketId', JSON.stringify(true));
        cookie.set('marketId', JSON.stringify(response.data.data.marketId));
        cookie.set('cartId', JSON.stringify(response.data.data.cartId));
        cookie.set('discount', JSON.stringify(response.data.data.discount));
        cookie.set(
          'minimumOrderAmount',
          JSON.stringify(response.data.data.minimumOrderAmount)
        );
        cookie.set(
          'deliveryFee',
          JSON.stringify(response.data.data.deliveryFee)
        );
        cookie.set(
          'totalPrice',
          JSON.stringify(response.data.data.totalPrice.price)
        );
        cookie.set(
          'amountOfFreeDelivery',
          JSON.stringify(response.data.data.amountOfFreeDelivery)
        );
        cookie.set(
          'productTotalAmount',
          JSON.stringify(response.data.data.productTotalAmount)
        );
        //console.log(response);
        let categories = response.data.data.categories;
        let product = [];
        for (let i = 0; categories?.length > i; i++) {
          let cateData = categories[i].products;
          for (let j = 0; cateData.length > j; j++) {
            //console.log(cateData[j])
            let valueToPush = {};
            valueToPush.id = cateData[j].productId;
            valueToPush.title = cateData[j].name;
            valueToPush.image = cateData[j].thumbnailUrl;
            valueToPush.price = cateData[j].total.amount;
            valueToPush.discountPrice = cateData[j].total.amount;
            valueToPush.count = cateData[j].quantity;
            valueToPush.unit = cateData[j].unit;
            product.push(valueToPush);
          }
        }
        //console.log(product);
        dispatch({
          type: SET_ACTIVE_CARD_NUMBER,
          payload: response.data.data,
        });
        dispatch({
          type: SET_BASKET,
          payload: product,
        });
      })
      .catch(error => {
        //console.log(error);
      });
  };
};

export const setBasket = (id, toast) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  if (typeof token?.stsTokenManager === 'object') {
    return async dispatch => {
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + 'carts',
          {
            marketId: Number(id),
          },
          {
            'Content-Type': 'text/plain',
            accept: 'application/json',
            headers: {
              Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
              clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            },
          }
        )
        .then(response => {
          //dispatch(getBasket());
          cookie.set('basketId', JSON.stringify(true));
        })
        .catch(error => {
          if (
            error.response.data.Message ===
            'Aktif bir sepetiniz var. Tekrar sepet oluşturamazsınız.'
          ) {
            cookie.set('basketId', JSON.stringify(true));
            dispatch(getBasket());
          } else if (
            error.response.data.Message === 'Anahtar(token) bulunamadı.'
          ) {
            toast.error('Oturumunuz sona erdi. Lütfen sayfayı yenileyin', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          } else {
            toast.error(error.response.data.Message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
        });
    };
  } else {
    return async dispatch => {
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + 'users/anonymous',
          {},
          {
            'Content-Type': 'text/plain',
            accept: 'application/json',
            headers: {
              clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
            },
          }
        )
        .then(response => {
          //console.log(response.data);

          let data = { result: response.data };
          cookie.set('anonymousBasketId', JSON.stringify(data));
          axios
            .post(
              process.env.REACT_APP_BASE_URL +
                'carts/?anonymousId=' +
                response.data,
              {
                marketId: Number(id),
              },
              {
                'Content-Type': 'text/plain',
                accept: 'application/json',
              }
            )
            .then(response => {
              console.log(response);
            });
        });
    };
  }
};

export const getCardNumber = marketId => {
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'carts/markets/' +
          Number(marketId) +
          '/active'
      )
      .then(response => {
        console.log(response);
        dispatch({
          type: SET_ACTIVE_CARD_NUMBER,
          payload: response,
        });
      });
  };
};

export const setBasketProductItem = (product, id, count, toast) => {
  //console.log(count);
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + 'cart-products',
        {
          marketId: Number(id),
          productId: product.productId
            ? Number(product.productId)
            : Number(product.id),
          quantity: count,
          isBadProduct: true,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        //console.log(response);
        dispatch(getBasket(id));
      })
      .catch(error => {
        if (error.response.data.Message !== 'Bu ürün şu anda müsait değil.') {
          if (
            error.response.data.Message !==
            'İstediğiniz miktar için yeterli stok bulunmamaktadır.'
          ) {
            dispatch(setBasket(id, toast));
          }
        }
        if (error.response.data.Message === 'Anahtar(token) bulunamadı.') {
          toast.error('Oturumunuz sona erdi. Lütfen sayfayı yenileyin', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        } else {
          toast.error(error.response.data.Message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
        console.log(error.response.data);
      });
  };
};

export const setCartAnonymous = () => {
  console.log('injam???');
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + 'users/anonymous',
        {},
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
        }
      )
      .then(response => {
        console.log(response);
        let data = { result: response.data };
        cookie.set('anonymousBasketId', JSON.stringify(data));
        console.log(response);
      });
  };
};

export const convertToOrder = (shopId, bagNumber, toast, param, addressId) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'order/create',
        {
          bagQuantity: bagNumber,
          addressId: addressId,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        let orderId = response.data.data.orderId;
        //dispatch(getOrderId(shopId , toast));
        dispatch(payment(param, orderId, toast)); // need to be changed
      })
      .catch(error => {
        toast.error(error.response.data.Message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(error.response.data.Message);
      });
  };
};

export const payment = (formState, cartId, toast) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL_V2 + 'payment/pay-3d',
        {
          fullName: formState.cardName,
          cardNumber: formState.cardNumber,
          expiryMonth: Number(formState.cardMonth),
          expiryYear: "20" +Number(formState.cardYear),
          cvv: formState.cardSecurity,
          orderId: Number(cartId),
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        toast.error(error.response.data.Message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(error.response.data.Message);
      });
  };
};

export const getOrderId = (shopId, toast) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          'orders/latests/market/' +
          Number(shopId),
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        toast.error(error.response.data.Message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(error.response.data.Message);
      });
  };
};

export const setAddressLogistics = (orderId, addressId, toast) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + 'logistics/create-order',
        {
          orderId: orderId,
          addressId: addressId,
        },
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        toast.error(error.response.data.Message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(error.response.data.Message);
      });
  };
};

export const getOrderById = (orderId, toast, setOrderDetails) => {
  const data = cookie.get('token');
  const token = JSON.parse(data);
  return async dispatch => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL_V2 + 'order/' + orderId + '/success',
        {
          'Content-Type': 'text/plain',
          accept: 'application/json',
          headers: {
            Authorization: `Bearer ${token.stsTokenManager.accessToken}`,
            clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY,
          },
        }
      )
      .then(response => {
        setOrderDetails(response.data.data);
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        toast.error(error.response.data.Message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.log(error.response.data.Message);
      });
  };
};
