import { SET_MENU , SET_CHILD_CATEGORY } from '../Constants/action-types';
import axios from 'axios';

export const getMenu = (id) => {
    return async (dispatch) => {
        await axios
          .get(process.env.REACT_APP_BASE_URL_V2+'market/'+Number(id)+'/categories',{
            headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY }
          })
          .then((response) => {
            //console.log(response)
            dispatch({
                type: SET_MENU,
                payload: response.data.data
            });
          });
      };
};

export const getChildMenu = (id , cateId) => {
  return async (dispatch) => {
      await axios
        .get(process.env.REACT_APP_BASE_URL+'markets/'+Number(id)+'/category/'+cateId+'/children',{
          headers: { clientPublicKey: process.env.REACT_APP_CLIENT_API_KEY }
        })
        .then((response) => {
          dispatch({
              type: SET_CHILD_CATEGORY,
              payload: response.data.data
          });
        });
    };
};
