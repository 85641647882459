import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';

// Components
import YTheme from '../../Components/Layout/YTheme';
import YCol from '../../Components/Layout/YCol';
import YRow from '../../Components/Layout/YRow';
import YPagenation from '../../Components/Basic/YPagenation';
import YSelect from '../../Components/Form/YSelect';
import YBreadcrumb from '../../Components/Basic/YBreadcrumb';
import YModal from '../../Components/Basic/YModal';
import YUserLoginModal from '../../Components/User/YUserLoginModal';
import YAddressList from '../../Components/Address/YAddressList';
import YLoading from '../../Components/Basic/YLoading';

// Handler Components
import {
  YBasketHanlder,
  YBasketDeleteItem,
} from '../../Components/Product/YBasketHanlder';

// Sections
import SideBar from './Components/Sidebar';
import Products from './Components/Products';

// Header & Footer
import Header from '../../Assets/Utils/Header';
import Footer from '../../Assets/Utils/Footer';

// Styles
import './styles/productListStyles.css';
import './styles/breadcrumbStyles.css';

// Redux
import {
  getProductByCategory,
  getMarketByGeoLocation,
} from '../../Redux/Actions/ProductAction';
import {
  getBasket,
  setBasketProductItem,
} from '../../Redux/Actions/BasketAction';
import { getMenu, getChildMenu } from '../../Redux/Actions/MenuAction';
import { getAddress, deleteAddress } from '../../Redux/Actions/UserAction';

// Constanst
import { productListSortByData, categoryBrands } from './Constants/dummyData';

export default function ProductList() {
  const location = useLocation();

  const pair = location.search.split('=');

  const pageNumber = sessionStorage.getItem('pageNumber');

  const cate = document.URL.replace(/.*catgoryId=([^&]*).*|(.*)/, '$1');

  const headCate = document.URL.replace(
    /.*categoryParent=([^&]*).*|(.*)/,
    '$1'
  );

  const [currentPage, setCurrentPage] = useState(pageNumber ? pageNumber : 1);

  const [userAddressModal, setUserAddressModal] = useState(false);

  const [showLoading, setShowLoading] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const product = useSelector(state => state.product.product);

  const addressList = useSelector(state => state.user.address);

  const basket = useSelector(state => state.basket.basket);

  const child = useSelector(state => state.menu.child);

  const [cartVisiable, setCartVisiable] = useState(false);

  const [locationVisiable, setLocationVisiable] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  const [cateName, setCateName] = useState('');

  const menu = useSelector(state => state.menu.menu);

  const [loginModal, setLoginModal] = useState(false);

  const tokenData = cookie.get('token');

  const breadcrumbItems = [
    { name: 'Ana Sayfa', link: '/', active: false },
    { name: cateName, link: '/products/?catgoryId=' + cate, active: true },
  ];

  const marketId = cookie.get('marketId');

  useEffect(() => {
    document.title = 'Ozhan Market';
    if (typeof marketId !== 'undefined') {
      if (tokenData !== 'null') {
        const token = JSON.parse(tokenData);
        if (typeof token?.stsTokenManager === 'object') {
          dispatch(getBasket(marketId));
          dispatch(getMenu(marketId));
          dispatch(getAddress());
        } else {
          dispatch(getMenu(marketId));
        }
      } else {
        dispatch(getMenu(marketId));
      }
    } else {
      cookie.set('marketId', JSON.stringify(2));
      dispatch(getMenu(2));
    }
  }, [dispatch, tokenData]);

  useEffect(() => {
    dispatch(getMenu(marketId));
  }, [dispatch, marketId]);

  useEffect(() => {
    if (typeof headCate !== 'undefined')
      dispatch(getChildMenu(marketId, Number(headCate)));
    else dispatch(getChildMenu(marketId, Number(cate)));
  }, [location]);

  useEffect(() => {
    dispatch(
      getProductByCategory(
        Number(cate),
        marketId,
        'asc',
        setShowLoading,
        currentPage
      )
    );
    //setCurrentPage();
    menu.map(item => {
      if (Number(cate) === item.categoryId) {
        setCateName(item.categoryName);
      }
    });
  }, [menu, location]);

  useEffect(() => {
    if (typeof basket !== 'undefined') {
      let count = cookie.get('totalPrice');
      typeof count === 'undefined' ? setTotalPrice(0) : setTotalPrice(count);
    }
  }, [basket]);

  const onEvent = (data, count, type) => {
    YBasketHanlder(
      type,
      setCartVisiable,
      data,
      count,
      dispatch,
      setBasketProductItem,
      toast,
      setUserAddressModal
    );
  };

  const onDeleteItem = e => {
    let temp = { productId: e };
    let marketId = cookie.get('marketId');
    YBasketDeleteItem(
      temp,
      marketId,
      0,
      basket,
      dispatch,
      setBasketProductItem,
      toast
    );
  };

  // Change page
  const handlePageChange = pageNumber => {
    sessionStorage.setItem('pageNumber', pageNumber);
    setCurrentPage(pageNumber);
    dispatch(
      getProductByCategory(cate, marketId, 'asc', setShowLoading, pageNumber)
    );
  };

  const onSelectAddressMap = (lat, lng, addressText) => {
    cookie.set('addressText', JSON.stringify(addressText));
    dispatch(getMarketByGeoLocation(lat, lng, toast));
  };

  const onPickAddressId = (id, lat, lng) => {
    dispatch(getMarketByGeoLocation(lat, lng, toast));
    setUserAddressModal(false);
  };

  const onChangeFilter = e => {
    let sort = '';
    if (Number(e.target.value) === 1) {
      sort = 'asc';
    } else {
      sort = 'desc';
    }
    dispatch(getProductByCategory(pair[1], marketId, sort));
  };

  //console.log(product);

  return (
    <YTheme>
      <Header
        basket={basket}
        cartVisiable={cartVisiable}
        setCartVisiable={setCartVisiable}
        navigate={navigate}
        totalPrice={totalPrice}
        onEvent={onEvent}
        onDeleteItem={onDeleteItem}
        locationVisiable={locationVisiable}
        setLocationVisiable={setLocationVisiable}
        menu={menu}
        onSelectAddressMap={onSelectAddressMap}
        setUserAddressModal={setUserAddressModal}
      />
      <MDBContainer className="pageContainer mobile-container">
        <YRow inComingStyle={'product-list-breadcrumb-dropdown-container'}>
          <YCol inComingStyle={'product-list-first-col'}>
            <YBreadcrumb items={breadcrumbItems} />
          </YCol>
          <YCol inComingStyle={'product-list-second-col'}>
            <YSelect
              data={productListSortByData}
              onEvent={onChangeFilter}
              placeholder={'Sırala'}
            />
          </YCol>
        </YRow>
      </MDBContainer>
      <MDBContainer className="pageContainer mobile-container flex-category">
        <MDBRow style={{ width: '100%' }}>
          <MDBCol lg="3" xs="12" sm="12">
            <SideBar
              title={cateName}
              items={child}
              brands={categoryBrands}
              selected={pair}
              headCate={cate}
            />
          </MDBCol>
          <MDBCol lg="9" xs="12" md="4" sm={12}>
            <MDBRow className="search-col">
              <Products
                inComingCardWidth={'product-size'}
                data={product?.data}
                onEvent={onEvent}
                basket={basket}
                navigate={navigate}
                token={tokenData}
                onLoginModal={setLoginModal}
              />
            </MDBRow>
            <MDBRow inComingStyle="pagination-alignment">
              <YPagenation
                activePage={currentPage}
                itemsCountPerPage={50}
                totolCounts={product?.totalItems}
                pageRangeDisplayed={12}
                onEvent={handlePageChange}
              />
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
      <YModal
        show={loginModal}
        onClose={() => setLoginModal(!loginModal)}
        title={''}
        inComingStyleHeader={'address-modal-header'}
      >
        <YUserLoginModal
          loginModal={loginModal}
          setLoginModal={setLoginModal}
        />
      </YModal>
      <YModal
        show={userAddressModal}
        onClose={() => setUserAddressModal(!userAddressModal)}
        title={'Lütfen adresinizi seçiniz'}
        inComingStyleHeader={'address-modal-header'}
      >
        <YAddressList
          addressList={addressList}
          navigate={navigate}
          deleteAddress={deleteAddress}
          dispatch={dispatch}
          onEvent={onPickAddressId}
          setBasketProductItem={setBasketProductItem}
          setUserAddressModal={setUserAddressModal}
        />
      </YModal>
      <YLoading showLoading={showLoading} setShowLoading={setShowLoading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </YTheme>
  );
}
