import cookie from "js-cookie";

export const AuthChecker = () => {
    const tokenData = cookie.get("token");
    if(typeof cookie.get("token") !== 'undefined') {
      const token = JSON.parse(tokenData);
      if (typeof token?.stsTokenManager !== 'object') {
          return false;
      }
      else
      {
        return token;
      }
    }
    else {
      return false;
    }
}